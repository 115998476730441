import * as ToastPrimitive from '@radix-ui/react-toast'
import { memo, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import useEcpTranslation from 'hooks/common/useEcpTranslation'
import { cn } from 'utils/StyleUtils'

import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon, XIcon } from './icons'
import { toastDefaultFunction, ToastState } from './observer'
import { Toast } from './types'

type ToasterProps = {
  swipeDirection?: 'right' | 'left' | 'up' | 'down'
}

export const Toaster = memo(({ swipeDirection = 'down' }: ToasterProps) => {
  const [toasts, setToasts] = useState<Toast[]>([])
  const { t } = useEcpTranslation()

  useEffect(() => {
    return ToastState.subscribe((toast) => {
      setTimeout(() => {
        ReactDOM.flushSync(() => {
          setToasts((toasts: Toast[]) => {
            // To find existing toast
            const indexOfExistingToast = toasts.findIndex(
              (t) => t.id === toast.id
            )

            // To update toast, if already exists
            if (indexOfExistingToast !== -1) {
              return [
                ...toasts.slice(0, indexOfExistingToast),
                { ...toasts[indexOfExistingToast], ...toast },
                ...toasts.slice(indexOfExistingToast + 1)
              ]
            }

            return [toast, ...toasts]
          })
        })
      })
    })
  }, [])

  // TODO: once entire ecp is moved to new design, replace below classnames with rem tailwind class value
  // this is a temporary fix to make sure the toast is displayed correctly in old design as well
  return (
    <ToastPrimitive.Provider swipeDirection={swipeDirection}>
      {toasts.map((toast: Toast) => {
        return (
          <ToastPrimitive.Root
            className={cn(
              'relative flex gap-[12px] rounded-[6px] border border-[#f2f2f2] bg-[#ffffff]! p-3 px-[10px] shadow-xs data-[swipe=cancel]:translate-y-0 data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[state=closed]:animate-hide data-[state=open]:animate-slideInY data-[swipe=end]:animate-swipeOutY data-[swipe=cancel]:transition-[transform_200ms_ease-out] md:data-[swipe=cancel]:translate-x-0 md:data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] md:data-[state=open]:animate-slideIn md:data-[swipe=end]:animate-swipeOut'
            )}
            duration={toast.duration}
            key={`toast-${toast.id}`}
            style={{
              fontSize: '0.875rem'
            }}
          >
            <ToastPrimitive.Close
              aria-label={t('common.close')}
              className="absolute right-0 top-1 size-[24px]! text-[#585b7a] hover:text-[#1c1d2f]"
            >
              <XIcon className="size-[16px]" />
            </ToastPrimitive.Close>

            <div className="flex items-center">{Icon(toast.type)}</div>
            <div
              className={cn('flex', {
                'flex-col': toast.description
              })}
            >
              <ToastPrimitive.Title className="flex items-center text-[14px] font-bold text-[#1c1d2f]">
                {toast.title}
              </ToastPrimitive.Title>

              {toast.description ? (
                <ToastPrimitive.Description className="mt-0.5 text-[14px] leading-[20px] text-[#1c1d2f]">
                  {toast.description}
                </ToastPrimitive.Description>
              ) : null}
            </div>
          </ToastPrimitive.Root>
        )
      })}

      <ToastPrimitive.Viewport className="fixed top-0 z-2147483647 m-auto flex w-[390px] max-w-[100vw] list-none flex-col gap-[10px] p-[var(--viewport-padding)] outline-hidden inset-safe [--viewport-padding:_25px] max-sm:bottom-[unset] max-sm:top-safe md:bottom-0 md:left-[unset] md:top-[unset] md:m-0" />
    </ToastPrimitive.Provider>
  )
})

Toaster.displayName = 'Toaster'

const Icon = (type: Toast['type']) => {
  switch (type) {
    case 'success':
      return <SuccessIcon className="size-[24px] text-[#18794E]" />
    case 'info':
      return <InfoIcon className="size-[24px] text-[#006ADC]" />
    case 'warning':
      return <WarningIcon className="size-[24px] text-[#ff801f]" />
    case 'error':
      return <ErrorIcon className="size-[24px] text-[#CD2B31]" />
    default:
      return null
  }
}

export const toast = Object.assign(toastDefaultFunction, {
  success: ToastState.success,
  info: ToastState.info,
  warning: ToastState.warning,
  error: ToastState.error
})
