import { MeterReading } from '@epilot/customer-portal-client'

export const MOCK_METER_READINGS: {
  results: MeterReading[]
  hits: number
} = {
  results: [
    {
      value: 34234,
      read_by: 'Jakub R+S Günther GmbH & Co. KG',
      reason: '',
      meter_id: '566df5e1-d88d-428b-9d0d-5c29cfcf8498',
      counter_id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
      direction: 'feed-out',
      timestamp: '2025-02-04T09:01:11Z',
      source: 'ECP',
      status: ''
    },
    {
      value: 35000,
      read_by: 'Jakub R+S Günther GmbH & Co. KG',
      reason: '',
      meter_id: '566df5e1-d88d-428b-9d0d-5c29cfcf8498',
      counter_id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
      direction: 'feed-out',
      timestamp: '2025-02-06T02:39:26Z',
      source: 'ECP',
      status: ''
    },
    {
      value: 36000,
      read_by: 'Jakub R+S Günther GmbH & Co. KG',
      reason: '',
      meter_id: '566df5e1-d88d-428b-9d0d-5c29cfcf8498',
      counter_id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
      direction: 'feed-out',
      timestamp: '2025-02-06T02:41:27Z',
      source: 'ECP',
      status: ''
    },
    {
      value: 37000,
      read_by: 'Jakub R+S Günther GmbH & Co. KG',
      reason: '',
      meter_id: '566df5e1-d88d-428b-9d0d-5c29cfcf8498',
      counter_id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
      direction: 'feed-out',
      timestamp: '2025-02-06T02:49:54Z',
      source: 'ECP',
      status: ''
    },
    {
      value: 38000,
      read_by: 'Jakub R+S Günther GmbH & Co. KG',
      reason: '',
      meter_id: '566df5e1-d88d-428b-9d0d-5c29cfcf8498',
      counter_id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
      direction: 'feed-out',
      timestamp: '2025-02-06T03:31:57Z',
      source: 'ECP',
      status: ''
    },
    {
      value: 39000,
      read_by: 'Jakub R+S Günther GmbH & Co. KG',
      reason: '',
      meter_id: '566df5e1-d88d-428b-9d0d-5c29cfcf8498',
      counter_id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
      direction: 'feed-out',
      timestamp: '2025-02-06T03:45:41Z',
      source: 'ECP',
      status: ''
    },
    {
      value: 40000,
      read_by: 'Jakub R+S Günther GmbH & Co. KG',
      reason: '',
      meter_id: '566df5e1-d88d-428b-9d0d-5c29cfcf8498',
      counter_id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
      direction: 'feed-out',
      timestamp: '2025-02-06T04:15:00Z',
      source: 'ECP',
      status: ''
    }
  ],
  hits: 7
}
