import isNil from 'lodash/fp/isNil'

// TODO: Get rid of this - this should be handled by the dummy mode
// This replaced the previous implementation using local storage
// It was buggy as it was not cleared when the portal was left
let translationOverrides: string | null = null

export const getPersistedTranslationOverrides = () => translationOverrides

export const setPersistedTranslationOverrides = (overrides: string) => {
  translationOverrides = overrides
}

export const resetPersistedTranslationOverrides = () => {
  translationOverrides = null
}

export const parsedTranslations = (overriddenTranslations: any) => {
  try {
    if (!isNil(overriddenTranslations)) {
      return JSON.parse(overriddenTranslations)
    }

    return null
  } catch (error) {
    try {
      return JSON.parse(overriddenTranslations)
    } catch (e) {
      console.error('Error parsing translation JSON', error)
    }
  }
}
