import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'

import { BREAK_POINTS, MEDIA_SIZES } from '../../../utils/StyleUtils'
import EditableImage from '../editable/EditableImage'
import OnboardSvg from '../svg/OnboardSvg'

const ONBOARD_ICON_SIZES = {
  SMALL: {
    width: 200,
    height: 103.25
  },
  BIG: {
    width: 399,
    height: 206
  }
}

const RESIZE_KEY = 'resize'

const DefaultWelcomeBanner = () => {
  const [showSmall, setShowSmall] = useState<boolean>(false)

  useLayoutEffect(() => {
    const resizeListener = () => {
      if (window.innerWidth < BREAK_POINTS.tablet && !showSmall) {
        setShowSmall(true)
      }

      if (window.innerWidth >= BREAK_POINTS.tablet && showSmall) {
        setShowSmall(false)
      }
    }

    // Fire on first render
    resizeListener()

    window.addEventListener(RESIZE_KEY, resizeListener)

    return () => window.removeEventListener(RESIZE_KEY, resizeListener)
  }, [showSmall])

  if (showSmall) {
    return (
      <OnboardSvg
        height={ONBOARD_ICON_SIZES.SMALL.height}
        width={ONBOARD_ICON_SIZES.SMALL.width}
      />
    )
  }

  return (
    <OnboardSvg
      height={ONBOARD_ICON_SIZES.BIG.height}
      width={ONBOARD_ICON_SIZES.BIG.width}
    />
  )
}

const EditableBanner = styled(EditableImage)`
  object-fit: contain;

  width: ${ONBOARD_ICON_SIZES.BIG.width}px;
  height: ${ONBOARD_ICON_SIZES.BIG.height}px;

  @media ${MEDIA_SIZES.lower.tablet} {
    width: ${ONBOARD_ICON_SIZES.SMALL.width}px;
    height: ${ONBOARD_ICON_SIZES.SMALL.height}px;
  }
`

export { DefaultWelcomeBanner, EditableBanner }
