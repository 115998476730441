import { OpportunityDetails } from '../../utils/OpportunityUtils'

export const MOCK_OPPORTUNITY_DETAILS: OpportunityDetails = {
  entity: {
    source: {
      title: 'manual',
      href: null
    },
    source_type: 'manual',
    _schema: 'opportunity',
    _title: 'Wallbox Opportunity',
    multi_select_attribute: ['multi'],
    text_attribute: 'Happy face',
    email_attribute: 'addida@yahoo.com',
    phone_attribute: '09936464678',
    tags_attribute: ['suggestions'],
    datetime_attribute: '1999-02-01T11:12:00.000Z',
    currency_attribute: 10000,
    currency_attribute_decimal: '100',
    currency_attribute_currency: 'EUR',
    country_attribute: 'AQ',
    boolean_attribute: true,
    nadeem_test_new: 'Naddeem',
    link_attribute: {
      title: 'Google',
      href: 'https://google.com'
    },
    customer: {
      $relation: [
        {
          entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          _tags: []
        }
      ]
    },
    billing_address: {
      $relation_ref: [
        {
          entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          path: 'address.0'
        }
      ]
    },
    delivery_address: {
      $relation_ref: [
        {
          entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          path: 'address.1'
        }
      ]
    },
    address: {
      $relation_ref: [
        {
          entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          path: 'address.0'
        }
      ]
    },
    opportunity_title: 'Wallbox Opportunity',
    due_date: '2024-01-30T18:35:00.000Z',
    status: 'in_progress',
    assignee: [
      {
        type: 'user',
        user_id: '10011645',
        display_name: 'Martin Juan',
        image_uri: {},
        org_id: '739224',
        email: 'm.juan@test.com',
        phone: null
      },
      {
        type: 'user',
        user_id: '10002611',
        display_name: 'User 5',
        image_uri: null,
        org_id: '739224',
        email: 'username@gmail.com',
        phone: null
      }
    ],
    description: 'This is a description',
    number_id: '66737',
    _id: '953ecdc3-4627-43a3-b9d8-00cbb4dc950c',
    _org: '739224',
    _owners: [
      {
        org_id: '739224',
        user_id: '10011645'
      }
    ],
    _created_at: '2023-04-06T10:01:16.192Z',
    _updated_at: '2023-04-06T13:02:28.448Z',
    opportunity_number: 'OP-566',
    sequence_attribute: 'SEQUPDATED-355',
    sequence_test: 'NADEEM-261',
    _acl: {
      view: [],
      edit: [],
      delete: []
    },
    _files: {},
    prozessstatus: 'Post',
    new_attribute_1676161540446: 'Banana',
    test_migration_tool: 'Apple',
    my_select: 'PV-Anlage',
    boolean: true,
    dates: [
      {
        _tags: ['deadline'],
        dates: '',
        value: '2023-04-07T10:02:00.000Z'
      }
    ],
    payment: {
      $relation_ref: [
        {
          entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          path: 'payment.0'
        },
        {
          entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          path: 'payment.2'
        }
      ]
    },
    items: {
      $relation: [
        {
          entity_id: '5059c666-55ba-4050-90eb-6e9667b7ba39',
          _tags: []
        },
        {
          entity_id: '97c014e3-e831-407d-b3a0-7336fc803b6c',
          _tags: []
        }
      ]
    },
    products: {
      $relation: [
        {
          entity_id: 'b7185fb7-b10f-4875-bda7-288631446555',
          _schema: 'product',
          _tags: []
        }
      ]
    },
    workflows: {
      ERRpfKSO: [
        {
          id: 'uyc2wnnmpp8',
          definition_id: 'ERRpfKSO',
          name: 'ECP Labeling',
          status: 'STARTED',
          assignees: ['10016846', '11000600'],
          task_id: '1e0o6y8q3ip',
          task_name: 'Second inner',
          task_assignees: ['10010728', '10011645'],
          task_execution_type: 'MANUAL',
          phase_id: 'ypov50x25b9',
          phase_name: 'Second',
          last_update_time: '2023-04-06T10:26:00.589Z'
        }
      ],
      primary: {
        id: 'uyc2wnnmpp8',
        definition_id: 'ERRpfKSO',
        name: 'ECP Labeling',
        status: 'STARTED',
        assignees: ['10016846', '11000600'],
        task_id: '1e0o6y8q3ip',
        task_name: 'Second inner',
        task_assignees: ['10010728', '10011645'],
        task_execution_type: 'MANUAL',
        phase_id: 'ypov50x25b9',
        phase_name: 'Second',
        last_update_time: '2023-04-06T10:26:00.589Z'
      }
    },
    status2: 'Second'
  },
  orders: [
    {
      billing_first_name: 'Lisa',
      billing_last_name: 'Hirten',
      billing_phone: '01010101010',
      billing_email: 'dustinlisa@lisa.com',
      source: {
        title: 'Journey: Verkaufs',
        href: '/app/journey-builder/journeys/d28f0b30-8a75-11ed-96fd-f3cb2d09cb2b'
      },
      billing_company_name: 'Hirten COKG',
      payment_method: [
        {
          type: 'payment_invoice'
        }
      ],
      delivery_address: [
        {
          country: 'DE',
          city: 'Köln',
          _tags: ['billing', 'delivery'],
          street: 'Brandenburger Str.',
          street_number: '12',
          _id: 'u1wK5zBWVSGlqt2cxRE-E',
          postal_code: '50668'
        }
      ],
      billing_address: [
        {
          country: 'DE',
          city: 'Köln',
          _tags: ['billing', 'delivery'],
          street: 'Brandenburger Str.',
          street_number: '12',
          _id: '_h_XxSeHXGn27Pjhetld3',
          postal_code: '50668'
        }
      ],
      line_items: [
        {
          product_id: 'b7185fb7-b10f-4875-bda7-288631446555',
          price_id: 'b9ad49ae-2bd8-43ae-b620-ab8aa857ed17',
          quantity: 1,
          currency: 'EUR',
          _price: {
            _id: 'b9ad49ae-2bd8-43ae-b620-ab8aa857ed17',
            description: 'Test - 8 ',
            unit_amount: 12300,
            unit_amount_decimal: '123',
            unit_amount_currency: 'EUR',
            type: 'one_time',
            billing_scheme: 'Per Unit',
            tiers_mode: 'Standard',
            _schema: 'price',
            _org: '739224',
            _created_at: '2021-12-01T11:09:55.218Z',
            _updated_at: '2022-12-13T10:19:40.284Z',
            _title: 'Test - 8',
            active: true,
            sales_tax: 'standard',
            tax_behavior: 'inclusive',
            price_display_in_journeys: 'show_price',
            tax: [
              {
                _id: '79cac814-18c8-4047-ae45-1d0828598a18',
                type: 'Custom',
                rate: 19,
                behavior: 'inclusive',
                active: true,
                region: 'DE',
                _schema: 'tax',
                _title: 'GST',
                description: 'GST',
                _org: '739224',
                _created_at: '2022-04-26T08:41:38.696Z',
                _updated_at: '2022-12-06T15:28:16.765Z',
                $relation: {
                  entity_id: '79cac814-18c8-4047-ae45-1d0828598a18'
                }
              }
            ],
            billing_period: 'weekly',
            billing_duration_unit: 'months',
            notice_time_unit: 'months',
            termination_time_unit: 'months',
            renewal_duration_unit: 'months'
          },
          _product: {
            _id: 'b7185fb7-b10f-4875-bda7-288631446555',
            code: '904460583',
            type: 'product',
            name: 'Alfen Eve Single S-line Wallbox',
            _title: 'Alfen Eve Single S-line Wallbox',
            _tags: ['product review demo', 'asd']
          },
          taxes: [
            {
              tax: {
                type: 'Custom',
                rate: 19,
                behavior: 'inclusive',
                active: true,
                region: 'DE',
                _schema: 'tax',
                _title: 'GST',
                description: 'GST',
                _id: '79cac814-18c8-4047-ae45-1d0828598a18',
                _org: '739224',
                _created_at: '2022-04-26T08:41:38.696Z',
                _updated_at: '2022-12-06T15:28:16.765Z'
              },
              amount: 1964
            }
          ],
          description: 'Test - 8 ',
          unit_amount: 12300,
          unit_amount_net: 10336,
          unit_amount_decimal: '123',
          amount_subtotal: 10336,
          amount_total: 12300
        }
      ],
      currency: 'EUR',
      _files: {
        $relation: []
      },
      status: 'quote',
      source_id: 'd28f0b30-8a75-11ed-96fd-f3cb2d09cb2b',
      source_type: 'journey',
      additional_addresses: [],
      tags: [],
      metadata: [
        {
          key: '_origin',
          value: 'journey checkout'
        }
      ],
      _tags: ['product review demo', 'asd'],
      prices: {
        $relation: [
          {
            entity_id: 'b9ad49ae-2bd8-43ae-b620-ab8aa857ed17',
            _schema: 'price',
            _tags: []
          }
        ]
      },
      products: {
        $relation: [
          {
            entity_id: 'b7185fb7-b10f-4875-bda7-288631446555',
            _schema: 'product',
            _tags: []
          }
        ]
      },
      amount_subtotal: 10336,
      amount_total: 12300,
      total_details: {
        amount_tax: 1964,
        breakdown: {
          taxes: [
            {
              tax: {
                _id: '79cac814-18c8-4047-ae45-1d0828598a18',
                type: 'Custom',
                rate: 19
              },
              amount: 1964
            }
          ],
          recurrences: [
            {
              type: 'one_time',
              amount_subtotal: 10336,
              amount_total: 12300,
              amount_tax: 1964
            }
          ]
        }
      },
      _schema: 'order',
      _id: '97c014e3-e831-407d-b3a0-7336fc803b6c',
      _org: '739224',
      _created_at: '2023-01-02T08:46:12.678Z',
      _updated_at: '2023-04-06T13:09:21.129Z',
      order_number: 'OR-426',
      _title: 'OR-426',
      billing_contact: {
        $relation: [
          {
            _tags: ['customer'],
            entity_id: '1d758f55-57f5-4cd6-93cb-55bca93239f7'
          }
        ]
      },
      customer: {
        $relation: [
          {
            entity_id: '3ad3f351-8526-412e-a554-5862ae7da770'
          },
          {
            entity_id: '187bdfea-52af-4238-bc9e-fdfbebb82e70'
          }
        ]
      },
      _acl_sync: '2023-04-06T13:09:21.081Z',
      _owners: [
        {
          org_id: '739224'
        }
      ],
      _acl: {
        view: ['org_911215'],
        edit: ['org_911215'],
        delete: ['org_911215']
      },
      productThumbnail:
        'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/43244367-ec6b-4610-8eb8-ab3f67c1cae5/Image%2520from%2520iOS%2520(1)%2520(1).jpg'
    },
    {
      _org: '739224',
      _schema: 'order',
      status: '1e034d65-7c86-4408-a61d-73622324c526',
      feedback: 'asdf',
      _id: '5059c666-55ba-4050-90eb-6e9667b7ba39',
      _created_at: '2023-03-15T13:44:43.130Z',
      _updated_at: '2023-04-05T12:51:49.624Z',
      order_number: 'OR-2600',
      source: {
        title: 'manual',
        href: null,
        source_type: 'manual'
      },
      _title: 'OR-2600',
      workflows: {
        V_opYKRV: [
          {
            id: 'k36rvjykh4s',
            definition_id: 'V_opYKRV',
            name: 'Life',
            status: 'STARTED',
            assignees: ['59014', '10016846', '10002602'],
            task_id: 'uozp7j6u598',
            task_name: 'Paint the roof 🎨',
            task_assignees: [],
            task_execution_type: 'MANUAL',
            last_update_time: '2023-03-15T13:44:45.379Z'
          },
          {
            id: '07rk75xmq8y',
            definition_id: 'V_opYKRV',
            name: 'Life',
            status: 'STARTED',
            assignees: ['59014', '10016846', '10002602'],
            task_id: '3kvjm5lbash',
            task_name: 'Paint the roof 🎨',
            task_assignees: [],
            task_execution_type: 'MANUAL',
            last_update_time: '2023-03-15T13:45:49.698Z'
          }
        ],
        primary: {
          id: '07rk75xmq8y',
          definition_id: 'V_opYKRV',
          name: 'Life',
          status: 'STARTED',
          assignees: ['59014', '10016846', '10002602'],
          task_id: '3kvjm5lbash',
          task_name: 'Paint the roof 🎨',
          task_assignees: [],
          task_execution_type: 'MANUAL',
          last_update_time: '2023-03-15T13:45:49.698Z'
        }
      },
      mapped_entities: {
        $relation: [
          {
            entity_id: '09ec1140-cbf8-4ae6-864e-124d639705ae',
            _tags: []
          }
        ]
      },
      _files: {},
      _owners: [
        {
          org_id: '739224'
        }
      ],
      _acl: {
        view: [],
        edit: [],
        delete: []
      }
    }
  ],
  files: [],
  relations: [
    {
      _id: 'b7185fb7-b10f-4875-bda7-288631446555',
      name: 'Alfen Eve Single S-line Wallbox',
      code: '904460583',
      categories: ['House Connection'],
      type: 'product',
      active: true,
      _schema: 'product',
      _org: '739224',
      _created_at: '2021-11-10T18:01:39.681Z',
      _updated_at: '2023-03-31T10:38:41.171Z',
      _title: 'Alfen Eve Single S-line Wallbox',
      _tags: ['product review demo', 'asd'],
      feature: [
        {
          _tags: [],
          feature: 'Bis zu 11 kW Ladeleistung (5x schneller laden)'
        },
        {
          _tags: [],
          feature: 'Konfigurierbare Ladeleistung'
        },
        {
          _tags: [],
          feature: 'Zugangskontrolle über RFID-Karten'
        }
      ],
      price_options: {
        $relation: [
          {
            entity_id: '8ca293d6-89f5-4b20-98f5-fa68341ef611',
            _tags: []
          },
          {
            entity_id: '67ea3c64-3193-4650-b194-322047d65e80',
            _tags: []
          },
          {
            entity_id: 'e3a426da-1f24-4e6e-b804-f30415ea4ecd',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: 'dfc0c532-4e97-4dbc-9eeb-07d06a1fdd0b',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '42dd91e3-329b-45bf-9339-871b3d4840ba',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: 'b9ad49ae-2bd8-43ae-b620-ab8aa857ed17',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '4d37db74-d501-4059-a564-4d02d3d5f472',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '1a9d853b-4651-4da3-897a-0d2f77676e92',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: 'e3ba142c-95e9-4188-aa0a-2a8d219fd932',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '57ab66bf-08a1-4f1d-9d1c-65af6a401851',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '4ff1b065-2ab1-4a84-a997-367262ab3eb6',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '819f50d4-250f-4f9a-bce9-d207ef4b6ab4',
            _tags: []
          },
          {
            entity_id: '2186ac62-c632-4abf-b80c-aefdd4156745',
            _schema: 'price',
            _tags: []
          }
        ]
      },
      product_images: {
        $relation: [
          {
            entity_id: '2bad25df-63af-4e61-95e7-729ac61d3537',
            _tags: []
          }
        ]
      },
      _acl_sync: '2023-03-31T10:38:40.880Z',
      _owners: [
        {
          org_id: '739224'
        }
      ],
      _acl: {
        view: [],
        edit: [],
        delete: []
      }
    },
    {
      _created_at: '2022-09-01T09:36:00.000Z',
      last_name: 'Dashtaki',
      full_name: 'sdfsdd TEST',
      demo_test: 'something',
      _id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
      _title: 'Martin Juan',
      _org: '739224',
      _schema: 'contact',
      test: 'Solar Panel 2',
      salutation: 'Contact Person',
      title: 'Dr.',
      first_name: 'Martin Juan',
      birthdate: '2022-09-01T09:36:00.000Z',
      contact_owner: [
        {
          type: 'user',
          org_id: '739224',
          user_id: '10011645',
          display_name: 'Martin Juan',
          image_uri: {}
        },
        {
          type: 'user',
          user_id: '10014532',
          display_name: 'Alex',
          image_uri: {},
          org_id: '739224',
          email: 'username@epilot.cloud',
          phone: '78458 64524'
        }
      ],
      email: [
        {
          _tags: [],
          email: 'm.juan@test.com',
          id: '5gfa5o1'
        }
      ],
      payment: [
        {
          _tags: [],
          type: 'payment_invoice'
        }
      ],
      consent_email_marketing: {
        status: 'pending',
        identifiers: [
          {
            identifier: 'm.juan@test.com',
            status: 'declined',
            events: [
              {
                type: 'OPT_OUT',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:04:55.035Z'
              },
              {
                type: 'OPT_IN',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:03:40.067Z'
              },
              {
                type: 'OPT_OUT',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:07:28.666Z'
              },
              {
                type: 'OPT_IN',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:03:22.884Z'
              },
              {
                type: 'OPT_OUT',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:02:55.970Z'
              },
              {
                type: 'OPT_IN',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:01:44.621Z'
              },
              {
                type: 'OPT_OUT',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                    token_use: 'id',
                    auth_time: 1663843771,
                    exp: 1664880494,
                    iat: 1664876894,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.56.132',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-04T10:35:35.502Z'
              },
              {
                type: 'OPT_IN',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                    token_use: 'id',
                    auth_time: 1663843771,
                    exp: 1664880494,
                    iat: 1664876894,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.56.132',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-04T10:35:24.980Z'
              },
              {
                type: 'OPT_OUT',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                    token_use: 'id',
                    auth_time: 1663843771,
                    exp: 1664880494,
                    iat: 1664876894,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.56.132',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-04T10:30:51.821Z'
              },
              {
                type: 'OPT_IN',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                    token_use: 'id',
                    auth_time: 1663843771,
                    exp: 1664880494,
                    iat: 1664876894,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.56.132',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-04T10:30:28.760Z'
              }
            ]
          }
        ]
      },
      consent_sms_marketing: {
        status: 'unknown',
        identifiers: []
      },
      consent_phone_call: {
        status: 'unknown',
        identifiers: []
      },
      consent_print_marketing: {
        status: 'pending',
        identifiers: [
          {
            identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
            status: 'declined',
            events: [
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:17:38.821Z'
              },
              {
                type: 'OPT_IN',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:04:58.952Z'
              },
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:04:31.461Z'
              },
              {
                type: 'OPT_IN',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:03:24.890Z'
              },
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:03:09.922Z'
              },
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:02:56.532Z'
              },
              {
                type: 'OPT_IN',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:03:22.858Z'
              },
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:03:10.372Z'
              },
              {
                type: 'OPT_IN',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:02:45.003Z'
              },
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:01:44.506Z'
              }
            ]
          }
        ]
      },
      address: [
        {
          _tags: [],
          street: 'Krefelder',
          street_number: '898',
          postal_code: '4567890',
          city: 'London',
          country: 'UA',
          additional_info: ''
        }
      ],
      phone: null,
      language: 'en',
      account: [
        {
          _id: '18bb4d9f-1d82-4bda-9680-d02576c1baf0',
          _org: '739224',
          _schema: 'account',
          email: [
            {
              email: 'alezandre@asfafa.afsf'
            }
          ],
          payment: [
            {
              _tags: [],
              _id: 'MJoCluZ7_ubKLMasED5NM',
              type: 'payment_invoice'
            }
          ],
          address: [
            {
              country: 'DE',
              city: 'Köln',
              _tags: ['billing', 'delivery'],
              additional_info: '999',
              street: 'Am Salzmagazin',
              street_number: '22222',
              _id: 'Zw7eBpd94iwY5vhKFTTMh',
              postal_code: '50668'
            }
          ],
          name: 'LEXCOMP',
          _created_at: '2022-11-03T15:20:03.672Z',
          _updated_at: '2022-11-16T08:31:02.106Z',
          _title: 'LEXCOMP',
          $relation: {
            entity_id: '18bb4d9f-1d82-4bda-9680-d02576c1baf0'
          }
        }
      ],
      customer_number: '100000',
      company_name: 'Company name',
      product_types: [],
      mj_test: 'This is me!',
      new_attribute_1676161815664: 'MJD',
      customer: 'Beautiful.',
      _files: {
        $relation: [
          {
            entity_id: 'ffc934a6-2723-40da-9edb-662f19ff7a27'
          },
          {
            entity_id: '4cab5a3e-98dc-45a0-a403-5910212ae44e'
          }
        ]
      },
      number_attribute: '2',
      date: '2023-03-04T16:59:00.000Z',
      test_phone_number: '',
      demo_sample: 'demo test value',
      new_attribute_1679427761812: 'dfghjkl',
      _acl_sync: '2023-04-07T07:13:43.121Z',
      _owners: [
        {
          org_id: '739224'
        }
      ],
      _acl: {
        view: ['org_907251', 'org_911215', 'org_911221'],
        edit: ['org_907251', 'org_911215', 'org_911221'],
        delete: ['org_911215', 'org_911221']
      },
      files: [],
      test_number_attribute: 'dasf',
      _updated_at: '2023-04-11T12:26:38.910Z'
    }
  ],
  workflow: [
    {
      version: 2,
      lastUpdateTime: '2023-04-06T10:26:00.589Z',
      trigger: 'MANUAL',
      assignedTo: ['10016846', '11000600'],
      creationTime: '2023-04-06T10:25:39.931Z',
      status: 'STARTED',
      updateEntityAttributes: [
        {
          source: 'current_section',
          target: {
            entitySchema: 'opportunity',
            entityAttribute: 'status2'
          }
        }
      ],
      selectedClosingReasons: [],
      enableECPWorkflow: true,
      name: 'ECP Labeling',
      nextOpenStep: {
        entityRefId: '099e1cdb-0454-4877-be6b-a041e567fe16',
        id: '1e0o6y8q3ip'
      },
      flow: [
        {
          requirements: [],
          created: '2023-04-06T10:25:40.311Z',
          executionType: 'MANUAL',
          assignedToInProgress: '-',
          entityRefId: '0b9c3f49-b9ec-4caf-8a76-e723f5caf54a',
          type: 'STEP',
          assignedTo: ['10011645'],
          enabled: true,
          lastUpdated: '2023-04-06T10:25:54.184Z',
          userIds: [10011645],
          name: 'A first step in the root',
          id: '47npzqlzhsq',
          definitionId: 'CFQIet',
          status: 'COMPLETED',
          manuallyCreated: false
        },
        {
          name: 'Second',
          id: 'ypov50x25b9',
          type: 'SECTION',
          steps: [
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:40.344Z',
              executionType: 'MANUAL',
              assignedToInProgress: '10011645',
              sectionId: 'ypov50x25b9',
              entityRefId: '099e1cdb-0454-4877-be6b-a041e567fe16',
              type: 'STEP',
              assignedTo: ['10010728', '10011645'],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:59.420Z',
              userIds: [10010728, 10011645],
              name: 'Second inner',
              id: '1e0o6y8q3ip',
              definitionId: 'woQwyL',
              status: 'IN_PROGRESS',
              manuallyCreated: false
            }
          ],
          definitionId: 'QT4SL6'
        },
        {
          requirements: [],
          created: '2023-04-06T10:25:40.347Z',
          executionType: 'MANUAL',
          assignedToInProgress: '-',
          entityRefId: '106c90c3-a8e6-43c6-b56d-3173d690bcf4',
          type: 'STEP',
          assignedTo: [],
          enabled: true,
          lastUpdated: '2023-04-06T10:25:40.347Z',
          ecp: {
            label: 'Initiate contract negotiations'
          },
          userIds: [],
          name: 'Third',
          id: 'ufweslpi74b',
          definitionId: '6bjP3F',
          status: 'UNASSIGNED',
          manuallyCreated: false
        },
        {
          name: 'Fourth',
          id: '9xid1u2nko5',
          type: 'SECTION',
          steps: [
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:40.385Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: 'd8221a86-93cd-4c3d-9ff0-0d7fdb9aa008',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:40.385Z',
              ecp: {
                label: 'Sign contract'
              },
              userIds: [],
              name: 'Fourth inner',
              id: 'lv26tgmibqh',
              definitionId: 'Ysujny',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:40.538Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '57fd878c-c916-499f-af75-56d5886fdb90',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:40.538Z',
              userIds: [],
              name: '1',
              id: 'czrf1edzjhg',
              definitionId: '2IPVGCd9',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:40.315Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '1d27ef2b-11ed-4397-bdde-29b687ca955b',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:40.315Z',
              userIds: [],
              name: '2',
              id: 'r7k7m3049o0',
              definitionId: 's2JJau9d',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:40.280Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '5081a629-728a-4856-8f9e-569d6217c673',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:40.280Z',
              userIds: [],
              name: '3',
              id: 'hisgzqxlgxx',
              definitionId: 'C_3GokKF',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:40.360Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '2d558c9d-b11f-4ac6-b520-8e8c6e2809bb',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:40.360Z',
              userIds: [],
              name: '4',
              id: 'gkzx02ax7ai',
              definitionId: '1Xz47KJc',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:40.369Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '2bb91fd2-f248-4d91-bde1-77b5d96d6364',
              type: 'STEP',
              assignedTo: ['10002602', '10002610', '10014629'],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:40.369Z',
              userIds: [10002602, 10002610, 10014629],
              name: '5',
              id: '9vk5z6dorsn',
              definitionId: 'bcrTE8ux',
              status: 'ASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:40.047Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '94b28fc6-3af5-4498-910e-64e5a10d445e',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:40.047Z',
              userIds: [],
              name: '6',
              id: 'ouxqd5q3si2',
              definitionId: 'uDKYkrPa',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:41.979Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '25161b26-2105-4c6c-a347-159d405fb217',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:41.979Z',
              userIds: [],
              name: '7',
              id: 'w4rb0qlwfp3',
              definitionId: 'vUF0tGko',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:41.949Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: 'df093c60-f44b-4191-9dc0-2982852c65e9',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:41.949Z',
              userIds: [],
              name: '8',
              id: 'ucfucpbzzst',
              definitionId: '95DxpX-E',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:41.742Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '873e3a36-0e81-4ebe-8f37-df234be5dc61',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:41.742Z',
              userIds: [],
              name: '9',
              id: 'pjtmfra5khu',
              definitionId: 'PKKCg0Gt',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:41.711Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '924a7596-9567-4bc9-b020-dea5f4cc6c77',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:41.711Z',
              userIds: [],
              name: '10',
              id: 'gicdvnetjs8',
              definitionId: '3HTYXivi',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:41.669Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: 'bb1c9125-b1cf-48f2-af82-dd98c597fbe1',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:41.669Z',
              userIds: [],
              name: '11',
              id: 'yv7akfrc76h',
              definitionId: 'b8zTrurD',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:41.661Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: 'dd947abd-db62-4f15-91bf-7a2fe8a4afd0',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:41.661Z',
              userIds: [],
              name: '12',
              id: 'bbot6jaepcl',
              definitionId: 'hbG5H7rH',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:41.790Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '38cfe0db-ba78-482f-83f1-589ca367a43a',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:41.790Z',
              userIds: [],
              name: '13',
              id: '893acfh1f9l',
              definitionId: 'H3sPj9Jx',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:41.436Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '63b95fe2-e3a9-4e34-9831-dd8be3b790a3',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:41.436Z',
              userIds: [],
              name: '15',
              id: 'mmic6baj6xg',
              definitionId: 'kYsLwS61',
              status: 'UNASSIGNED',
              manuallyCreated: false
            },
            {
              requirements: [],
              startedTime: '2023-04-06T10:25:40.344Z',
              completedTime: '2023-04-06T10:25:59.420Z',
              created: '2023-04-06T10:25:41.226Z',
              executionType: 'MANUAL',
              assignedToInProgress: '-',
              sectionId: '9xid1u2nko5',
              entityRefId: '5cbda7bc-d07a-42b7-a532-a2944b4564ba',
              type: 'STEP',
              assignedTo: [],
              enabled: true,
              lastUpdated: '2023-04-06T10:25:41.226Z',
              userIds: [],
              name: '16',
              id: 'iqxyp92hhnq',
              definitionId: 'uMhNtwW7',
              status: 'UNASSIGNED',
              manuallyCreated: false
            }
          ],
          definitionId: 'niR2I8'
        },
        {
          requirements: [],
          created: '2023-04-06T10:25:40.789Z',
          executionType: 'MANUAL',
          assignedToInProgress: '-',
          entityRefId: '5e838bb7-fc02-41f9-9593-b8eb71787794',
          type: 'STEP',
          assignedTo: [],
          enabled: true,
          lastUpdated: '2023-04-06T10:25:40.789Z',
          ecp: {
            label: 'Start product collection'
          },
          userIds: [],
          name: 'Fifth',
          id: 'u3z9jseg3gu',
          definitionId: 'MBmYm1',
          status: 'UNASSIGNED',
          manuallyCreated: false
        },
        {
          requirements: [],
          created: '2023-04-06T10:25:40.405Z',
          executionType: 'MANUAL',
          assignedToInProgress: '-',
          entityRefId: 'b50674a2-b999-4be6-8c61-2bb352651184',
          type: 'STEP',
          assignedTo: [],
          enabled: true,
          lastUpdated: '2023-04-06T10:25:40.405Z',
          ecp: {
            label: 'Send out team to install order'
          },
          userIds: [],
          name: 'Sixth',
          id: '2lonwfo51i6',
          definitionId: 'kJO7aq',
          status: 'UNASSIGNED',
          manuallyCreated: false
        }
      ],
      configuredClosingReasonSnapshot: [
        {
          title: 'Reason 1',
          id: 'wIxKYsg5WrTs1Tp-BO5qR'
        },
        {
          title: 'Shit',
          id: 'p-8Fne6CeYwZ3HVO9gCzK'
        }
      ],
      contexts: [
        {
          id: '953ecdc3-4627-43a3-b9d8-00cbb4dc950c',
          schema: 'opportunity',
          title: 'Wallbox Opportunity'
        }
      ],
      orgId: '739224',
      definitionId: 'ERRpfKSO',
      id: 'uyc2wnnmpp8'
    }
  ],
  journey_actions: []
}
