import { CUSTOMER_QUERY_IDS } from 'api/Queries'
import {
  MOCK_DOCUMENTS,
  MOCK_OPPORTUNITIES,
  MOCK_ORDERS
} from 'dummy/MockedData'

import { MOCK_BALANCE } from './MockBalance'
import { MOCK_CONSUMPTIONS } from './MockConsumptions'
import { MOCK_CONTACT_DETAILS } from './MockContactDetails'
import { MOCK_CONTRACT_DETAILS } from './MockContractDetails'
import { MOCK_CONTRACT_LIST } from './MockContractList'
import { MOCK_COSTS } from './MockCosts'
import {
  MOCK_METER_COUNTER_DETAIL,
  MOCK_METER_COUNTER_DETAILS
} from './MockMeterCounterDetails'
import { MOCK_METER_DETAILS } from './MockMeterDetails'
import { MOCK_METER_LIST } from './MockMeterList'
import { MOCK_METER_READINGS } from './MockMeterReadings'
import { MOCK_OPPORTUNITY_DETAILS } from './MockOpportunityDetails'
import { MOCK_ORDER_DETAILS } from './MockOrderDetails'
import { MOCK_ORGANIZATION_SETTINGS } from './MockOrganizationSettings'
import { MOCK_PAYMENTS } from './MockPayments'
import { MOCK_PORTAL_CONFIG } from './MockPortalConfig'
import { MOCK_PORTAL_USER } from './MockPortalUser'
import { MOCK_PRICES } from './MockPrices'
import { MOCK_SCHEMAS } from './MockSchemas'
import { MOCK_WIDGETS } from './MockWidgets'

const DUMMY_MOCKS = {
  [CUSTOMER_QUERY_IDS.GET_CONTRACT_DETAILS]: MOCK_CONTRACT_DETAILS,
  [CUSTOMER_QUERY_IDS.GET_CONTRACTS]: MOCK_CONTRACT_LIST,
  [CUSTOMER_QUERY_IDS.GET_METER_DETAILS]: MOCK_METER_DETAILS,
  [CUSTOMER_QUERY_IDS.GET_METERS]: MOCK_METER_LIST,
  [CUSTOMER_QUERY_IDS.GET_ORGANIZATION_SETTINGS]: MOCK_ORGANIZATION_SETTINGS,
  [CUSTOMER_QUERY_IDS.GET_METER_COUNTERS]: MOCK_METER_COUNTER_DETAILS,
  [CUSTOMER_QUERY_IDS.GET_COUNTER_DETAILS]: MOCK_METER_COUNTER_DETAIL,
  [CUSTOMER_QUERY_IDS.GET_READINGS_BY_INTERVAL]: MOCK_METER_READINGS,
  [CUSTOMER_QUERY_IDS.GET_BILLING_EVENTS]: MOCK_PAYMENTS,
  [CUSTOMER_QUERY_IDS.GET_BALANCE]: MOCK_BALANCE,
  [CUSTOMER_QUERY_IDS.GET_PUBLIC_EXTENSIONS]: [],
  [CUSTOMER_QUERY_IDS.GET_ORDER_DETAILS]: MOCK_ORDER_DETAILS,
  [CUSTOMER_QUERY_IDS.GET_OPPORTUNITY_DETAILS]: MOCK_OPPORTUNITY_DETAILS,
  [CUSTOMER_QUERY_IDS.GET_ALL_ORDERS]: MOCK_ORDERS,
  [CUSTOMER_QUERY_IDS.GET_ALL_OPPORTUNITIES]: MOCK_OPPORTUNITIES,
  [CUSTOMER_QUERY_IDS.GET_CONTACT]: MOCK_CONTACT_DETAILS,
  [CUSTOMER_QUERY_IDS.PORTAL_USER]: MOCK_PORTAL_USER,
  [CUSTOMER_QUERY_IDS.GET_SCHEMAS]: MOCK_SCHEMAS,
  [CUSTOMER_QUERY_IDS.GET_PORTAL_CONFIG]: MOCK_PORTAL_CONFIG,
  [CUSTOMER_QUERY_IDS.GET_PORTAL_WIDGETS]: MOCK_WIDGETS,
  [CUSTOMER_QUERY_IDS.GET_CONSUMPTIONS]: MOCK_CONSUMPTIONS,
  [CUSTOMER_QUERY_IDS.GET_COSTS]: MOCK_COSTS,
  [CUSTOMER_QUERY_IDS.GET_PRICES]: MOCK_PRICES,
  [CUSTOMER_QUERY_IDS.GET_DOCUMENTS]: MOCK_DOCUMENTS
}

export default DUMMY_MOCKS
