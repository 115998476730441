import { BillingEvent } from '@epilot/customer-portal-client'

export const MOCK_PAYMENTS: { results: BillingEvent[]; hits: number } = {
  results: [
    {
      type: 'installment',
      _org: '739224',
      _title: 'installment 1.4.2024',
      _schema: 'billing_event',
      _tags: ['created'],
      billing_amount: 10050,
      billing_amount_decimal: '100.50',
      billing_currency: 'EUR',
      external_id: 'd4fb2a4e-3f74-4fc4-8fba-6fdaaaa3b08e',
      contract: {
        $relation: [{ entity_id: 'e51b1722-19ab-4b90-b57e-ab4e01c21912' }]
      },
      due_date: '2024-04-01T13:57:07.424Z',
      paid_date: '',
      _id: 'e4e18780-d5a8-4508-8d89-13e34e8a7c31',
      _created_at: '2024-05-01T14:08:43.281Z',
      _updated_at: '2024-05-01T14:08:43.281Z',
      _relations: [
        {
          entity_id: 'e51b1722-19ab-4b90-b57e-ab4e01c21912'
        }
      ]
    },
    {
      type: 'installment',
      _org: '739224',
      _title: 'installment 1.6.2024',
      _schema: 'billing_event',
      _tags: ['created'],
      billing_amount: 10050,
      billing_amount_decimal: '100.50',
      billing_currency: 'EUR',
      external_id: 'd4fb2a4e-3f74-4fc4-8fba-6fdaaaa3b08e',
      contract: {
        $relation: [{ entity_id: 'e51b1722-19ab-4b90-b57e-ab4e01c21912' }]
      },
      due_date: '2024-06-01T13:57:07.424Z',
      paid_date: '',
      _id: '9087dbdc-f052-41d3-b840-58991acf85fd',
      _created_at: '2024-05-01T14:12:10.234Z',
      _updated_at: '2024-05-01T14:12:10.234Z',
      _relations: [
        {
          entity_id: 'e51b1722-19ab-4b90-b57e-ab4e01c21912'
        }
      ]
    },
    {
      type: 'installment',
      _org: '739224',
      _title: 'installment 30.1.2025',
      _schema: 'billing_event',
      _tags: ['='],
      billing_amount: 10050,
      billing_amount_decimal: '100.50',
      billing_currency: 'EUR',
      external_id: 'd4fb2a4e-3f74-4fc4-8fba-6fdaaaa3b08e',
      contract: {
        $relation: [{ entity_id: 'e51b1722-19ab-4b90-b57e-ab4e01c21912' }]
      },
      due_date: '2025-01-30T14:39:08.471Z',
      _id: '064f6581-6373-4190-9737-8fe766a95f8e',
      _created_at: '2025-01-15T16:16:32.357Z',
      _updated_at: '2025-01-15T16:16:32.357Z',
      _relations: [
        {
          entity_id: 'e51b1722-19ab-4b90-b57e-ab4e01c21912'
        }
      ]
    },
    {
      type: 'installment',
      _org: '739224',
      _title: 'installment 18.2.2025',
      _schema: 'billing_event',
      _tags: ['='],
      billing_amount: 10050,
      billing_amount_decimal: '100.50',
      billing_currency: 'EUR',
      external_id: 'd4fb2a4e-3f74-4fc4-8fba-6fdaaaa3b08e',
      contract: {
        $relation: [{ entity_id: 'e51b1722-19ab-4b90-b57e-ab4e01c21912' }]
      },
      due_date: '2025-02-18T14:39:08.471Z',
      _id: 'f8a8dea2-3b31-4b44-9717-638edfeb4a57',
      _created_at: '2025-01-31T11:27:19.385Z',
      _updated_at: '2025-01-31T11:27:19.385Z',
      _relations: [
        {
          entity_id: 'e51b1722-19ab-4b90-b57e-ab4e01c21912'
        }
      ]
    }
  ],
  hits: 4
}
