import { PortalConfig } from '@epilot/customer-portal-client'

export const MOCK_PORTAL_CONFIG: PortalConfig = {
  id: '',
  organization_id: '',
  design_id: '',
  config: '',
  cognito_details: {},
  contact_identifiers: [],
  contract_identifiers: [],
  approval_state_attributes: {},
  registration_identifiers: [],
  is_epilot_domain: true,
  self_registration_setting: 'ALLOW_WITH_CONTACT_CREATION',
  feature_settings: {
    start_page: true,
    change_due_date: true,
    new_design: true
  },
  enabled: true,
  images: {},
  entity_identifiers: {},
  triggered_journeys: [],
  entity_edit_rules: [],
  prevent_search_engine_indexing: true,
  origin: 'END_CUSTOMER_PORTAL',
  name: 'End Customer Portal',
  advanced_mfa: {
    enabled: false
  },
  accessToken: '',
  domain: '',
  allowed_file_extensions: {
    document: [
      'pdf',
      'xps',
      'ps',
      'doc',
      'dot',
      'docx',
      'docm',
      'dotx',
      'dotm',
      'odt',
      'ott',
      'txt',
      'rtf',
      'pub'
    ],
    image: [
      'jpeg',
      'jpg',
      'jpm',
      'jfif',
      'jp2',
      'png',
      'gif',
      'heic',
      'bmp',
      'tiff',
      'tif',
      'webp',
      'avif',
      'heif',
      'dng',
      'ico',
      'raw'
    ],
    spreadsheet: [
      'xls',
      'xlt',
      'xlsm',
      'xltm',
      'xlm',
      'xlsx',
      'xltx',
      'ods',
      'ots',
      'numbers',
      'csv'
    ],
    presentation: [
      'ppt',
      'pot',
      'pptx',
      'pptm',
      'potx',
      'potm',
      'odp',
      'otp',
      'key'
    ],
    audioVideo: [
      'mp3',
      'wav',
      'wma',
      'flac',
      'aac',
      'm4a',
      'ogg',
      'oga',
      'mp4',
      'm4v',
      'mov',
      'mpg',
      'mpeg',
      'wmv',
      'avi',
      'flv',
      'mkv',
      'webm'
    ],
    archive: ['zip', 'rar', '7z'],
    cad: ['axm', 'bdc', 'dxf', '3ds', 'dwg', 'clk'],
    email: ['eml', 'msg'],
    calendar: ['ics', 'ifb', 'ical', 'vcs'],
    other: ['vcf', 'mpp']
  },
  grants: [
    {
      action: 'entity:*',
      resource: 'billing_event:*'
    },
    {
      action: 'entity:*',
      resource: 'meter_counter:*'
    },
    {
      action: 'entity:view',
      resource: 'file:*'
    },
    {
      action: 'entity:create',
      resource: 'file'
    },
    {
      action: 'entity:attribute:view',
      resource: 'file:*:*'
    },
    {
      action: 'entity:attribute:edit',
      resource: 'file:*:*'
    },
    {
      action: 'entity:delete',
      resource: 'file:*'
    },
    {
      action: 'entity:*',
      resource: 'opportunity:*'
    },
    {
      action: 'entity:*',
      resource: 'contract:*'
    },
    {
      action: 'entity:*',
      resource: 'order:*'
    },
    {
      action: 'entity:capability:*',
      resource: 'order_items:*'
    },
    {
      action: 'entity:view',
      resource: 'contact:*'
    },
    {
      action: 'entity:attribute:view',
      resource: 'contact:*:*'
    },
    {
      action: 'entity:edit',
      resource: 'contact:*'
    },
    {
      action: 'entity:attribute:edit',
      resource: 'contact:*:*'
    },
    {
      action: 'entity:*',
      resource: 'meter:*'
    },
    {
      action: 'meter_reading:*',
      resource: '*'
    }
  ],
  org_settings: {
    canary: {
      enabled: true
    },
    notracking: {
      enabled: false
    }
  },
  feature_flags: {
    useAdvancedMfaOnPortals: true,
    approvalState: true,
    journeyTriggers: true,
    externalLinksIcons: true,
    portalsAddressesPaymentsTimeSlices: true,
    dynamicTariff: true,
    portalAnalytics: true,
    portalSSO: true
  },
  identity_providers: []
}
