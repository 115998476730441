import { PortalConfig } from '@epilot/customer-portal-client'
import {
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { embeddedListener } from './communication'

let dummyMode = true

export type ExtendedPortalConfig = PortalConfig & { isDummy?: boolean }

export const isInDummyMode = (defaultConfig?: ExtendedPortalConfig) => {
  const isEmbedded = window.parent !== window

  if (!isEmbedded) return false

  if (defaultConfig?.isDummy) {
    dummyMode = true
  }

  return dummyMode
}

let isListenerAdded = false

export const getDummyApi = async () => {
  const isDummy = isInDummyMode()

  if (!isDummy) return null

  if (!isListenerAdded) {
    window.addEventListener('message', embeddedListener)
    isListenerAdded = true
    window.addEventListener('beforeunload', () => {
      window.removeEventListener('message', embeddedListener)
      isListenerAdded = false // Reset flag
    })
  }

  return import('./index')
}

export function useQueryWithMock<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends string[] = string[]
>({
  queryKey,
  queryFn,
  ...params
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<
  TData,
  TError
> {
  return useQuery({
    // @ts-expect-error Unfortunately it cannot match it against any of the overloads - hopefully it's fixed with v5
    queryKey,
    queryFn: withDummyMock(queryFn, queryKey?.[0]),
    ...params
  })
}

export function withDummyMock<T>(
  queryFn: T,
  queryKey: string,
  response?: Partial<AxiosResponse>
) {
  const isDummy = isInDummyMode() // Check if in dummy mode

  // If not in dummy mode, return the actual query function
  if (!isDummy) return queryFn

  // Return a wrapped query function to mock data
  return async () => {
    const Dummy = await getDummyApi() // Get the dummy API

    // Try to fetch mock data using the queryKey
    const matchedMock = Dummy.getMock(queryKey)

    // If mock data is found, return it
    if (matchedMock) {
      return {
        data: matchedMock,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
        ...response
      } as AxiosResponse
    } else {
      // If no mock data is found, return a 404 response
      return {
        data: null,
        status: 404,
        statusText: 'Not Found',
        headers: {},
        config: {},
        ...response
      } as AxiosResponse
    }
  }
}
