import {
  Contract,
  EntityItem,
  JourneyActions
} from '@epilot/customer-portal-client'

import { Workflow } from 'utils/WorkflowUtils'

export const MOCK_CONTRACT_DETAILS: {
  entity: Contract
  orders: EntityItem[]
  meters: EntityItem[]
  files: EntityItem[]
  relations: EntityItem[]
  workflow: Workflow[]
  journey_actions: JourneyActions[]
} = {
  entity: {
    contract_name: 'WER',
    contract_number: 'QWEQWE-123',
    payment: [
      {
        _id: '33e44294-768e-4750-a14f-1c10c90143a1',
        _tags: [],
        type: 'payment_sepa',
        data: {
          bank_name: 'Landesbank Berlin - Berliner Sparkasse',
          iban: '',
          bic_number: 'BELADEBEXXX',
          fullname: 'Nadi Manta'
        },
        id: 'x79bo66',
        $relation_ref: {
          entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          path: 'payment',
          _id: '33e44294-768e-4750-a14f-1c10c90143a1'
        }
      }
    ],
    customer: {
      $relation: [
        {
          entity_id: 'e407f7e4-e05a-4017-a4b6-4963c5a1551c',
          _tags: [],
          _schema: 'contact'
        },
        {
          entity_id: '754dce41-8c6f-48e0-801b-673abf56f83e',
          _tags: []
        }
      ]
    },
    type: 'recurring',
    billing_period: 'monthly',
    billing_duration_amount: null,
    billing_duration_unit: null,
    notice_time_amount: null,
    notice_time_unit: null,
    termination_time_amount: null,
    termination_time_unit: null,
    renewal_duration_amount: null,
    renewal_duration_unit: null,
    _schema: 'contract',
    status: 'active',
    _id: '681f2c18-bc80-4ade-8b18-a6e64240e19b',
    _org: '739224',
    _created_at: '2024-01-09T07:40:46.111Z',
    installment_amount: 13200,
    balance: 10000,
    sequence_attr: '168',
    _title: 'WER',
    installment_amount_currency: 'EUR',
    installment_amount_decimal: '132.00',
    other_address: [
      {
        _id: 'bJws8YEyYj3ci_Y-K55YC',
        _tags: [],
        street: 'greece street',
        street_number: '78',
        postal_code: '34444',
        city: 'Athens 23',
        country: 'GR',
        additional_info: '',
        $relation_ref: {
          entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          path: 'address',
          _id: 'bJws8YEyYj3ci_Y-K55YC'
        }
      },
      {
        _id: 'vKn8EvUccnOuZZhwxvMer',
        country: 'DE',
        city: 'Bochum',
        street: 'Hermann-Hußmann-Str.',
        street_number: '11',
        postal_code: '44787',
        $relation_ref: {
          entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          path: 'address',
          _id: 'vKn8EvUccnOuZZhwxvMer'
        }
      }
    ],
    balance_currency: 'EUR',
    balance_decimal: '100.00',
    _updated_at: '2025-01-29T09:56:48.783Z',
    line_items: [
      {
        price_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
        product_id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
        quantity: 1,
        description: 'flat fee installation',
        unit_amount: 0,
        unit_amount_decimal: '0.00',
        pricing_model: 'tiered_volume',
        _price: {
          _id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
          _title: 'flat fee installation',
          pricing_model: 'tiered_volume',
          variable_price: true,
          description: 'flat fee installation',
          tiers: [
            {
              up_to: 90.555555,
              unit_amount: 1000,
              unit_amount_decimal: '10',
              flat_fee_amount: 0,
              flat_fee_amount_decimal: '0'
            },
            {
              up_to: 300,
              unit_amount: 2500,
              unit_amount_decimal: '25',
              flat_fee_amount: 0,
              flat_fee_amount_decimal: '0',
              display_mode: 'on_request'
            },
            {
              up_to: 600,
              unit_amount: 6000,
              unit_amount_decimal: '60',
              flat_fee_amount: 0,
              flat_fee_amount_decimal: '0',
              display_mode: 'on_request'
            },
            {
              up_to: null,
              unit_amount: 10000,
              unit_amount_decimal: '100',
              flat_fee_amount: 0,
              flat_fee_amount_decimal: '0'
            }
          ],
          type: 'one_time',
          price_display_in_journeys: 'show_as_starting_price',
          unit_amount: 0,
          is_tax_inclusive: true,
          active: true,
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          internal_description: 'flat fee instalar',
          unit: 'm',
          unit_amount_decimal: '0.00',
          unit_amount_currency: 'EUR'
        },
        _product: {
          type: 'product',
          _title: '[Flat Fee] - Composite Installation',
          name: '[Flat Fee] - Composite Installation',
          active: true,
          price_options: [
            {
              _id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
              _schema: 'price',
              _title: 'flat fee installation',
              pricing_model: 'tiered_volume',
              variable_price: true,
              description: 'flat fee installation',
              tiers: [
                {
                  up_to: 90.555555,
                  unit_amount: 1000,
                  unit_amount_decimal: '10',
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0'
                },
                {
                  up_to: 300,
                  unit_amount: 2500,
                  unit_amount_decimal: '25',
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0',
                  display_mode: 'on_request'
                },
                {
                  up_to: 600,
                  unit_amount: 6000,
                  unit_amount_decimal: '60',
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0',
                  display_mode: 'on_request'
                },
                {
                  up_to: null,
                  unit_amount: 10000,
                  unit_amount_decimal: '100',
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0'
                }
              ],
              type: 'one_time',
              price_display_in_journeys: 'show_as_starting_price',
              _org: '739224',
              _owners: [
                {
                  org_id: '739224',
                  user_id: '10009172'
                }
              ],
              _created_at: '2023-05-29T12:34:47.681Z',
              unit_amount: 0,
              is_tax_inclusive: true,
              active: true,
              billing_period: 'weekly',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              _acl: {
                view: ['org_739224'],
                edit: ['org_739224'],
                delete: ['org_739224']
              },
              _updated_at: '2024-10-21T08:33:56.979Z',
              internal_description: 'flat fee instalar',
              unit: 'm',
              _acl_sync: '2024-01-11T17:26:48.410Z',
              unit_amount_decimal: '0.00',
              unit_amount_currency: 'EUR',
              _files: [],
              $relation: {
                entity_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                _tags: []
              }
            },
            {
              _id: '5dab027d-086d-412b-b245-17611592e588',
              pricing_model: 'per_unit',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0.00',
              is_tax_inclusive: true,
              price_display_in_journeys: 'show_price',
              active: true,
              type: 'one_time',
              billing_period: 'weekly',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              is_composite_price: true,
              _tags: ['composite'],
              _schema: 'price',
              _title: 'test composite',
              description: 'test composite',
              price_components: [],
              _org: '739224',
              _owners: [
                {
                  org_id: '739224',
                  user_id: '10009172'
                }
              ],
              _created_at: '2023-05-29T15:54:36.174Z',
              _updated_at: '2024-10-24T08:57:46.274Z',
              _acl: {
                view: ['org_739224'],
                edit: ['org_739224'],
                delete: ['org_739224']
              },
              internal_description: 'test composite',
              $relation: {
                entity_id: '5dab027d-086d-412b-b245-17611592e588',
                _tags: []
              }
            },
            {
              _id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
              pricing_model: 'tiered_graduated',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0.00',
              is_tax_inclusive: true,
              price_display_in_journeys: 'show_price',
              active: true,
              type: 'one_time',
              billing_period: 'weekly',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              _tags: [],
              _schema: 'price',
              _title: 'graduated test',
              description: 'graduated test',
              variable_price: true,
              tiers: [
                {
                  up_to: 90.555555,
                  unit_amount: 1000,
                  unit_amount_decimal: '10',
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0'
                },
                {
                  up_to: 300,
                  unit_amount: 5000,
                  unit_amount_decimal: '50',
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0'
                },
                {
                  up_to: null,
                  unit_amount: 10000,
                  unit_amount_decimal: '100',
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0'
                }
              ],
              _org: '739224',
              _owners: [
                {
                  org_id: '739224',
                  user_id: '10009172'
                }
              ],
              _created_at: '2023-05-30T08:42:31.011Z',
              _updated_at: '2024-01-11T17:26:48.500Z',
              _acl: {
                view: ['org_739224'],
                edit: ['org_739224'],
                delete: ['org_739224']
              },
              internal_description: 'graduated test',
              _acl_sync: '2024-01-11T17:26:48.405Z',
              $relation: {
                entity_id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                _tags: []
              }
            }
          ],
          _id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
          _availability_files: [],
          internal_name: '[Flat Fee] - Composite Installation',
          product_images: [
            {
              _id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
              },
              category: 'unknown',
              filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
              access_control: 'public-read',
              type: 'image',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
              size_bytes: 332081,
              readable_size: '324.3 KB',
              mime_type: 'image/png',
              versions: [
                {
                  s3ref: {
                    bucket: 'epilot-dev-user-content',
                    key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                  },
                  category: 'unknown',
                  filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                  access_control: 'public-read',
                  type: 'image',
                  public_url:
                    'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                  size_bytes: 332081,
                  readable_size: '324.3 KB',
                  mime_type: 'image/png'
                }
              ],
              _schema: 'file',
              _org: '739224',
              _owners: [
                {
                  org_id: '739224',
                  user_id: '11000656'
                }
              ],
              _created_at: '2024-06-27T07:13:31.962Z',
              _updated_at: '2024-09-24T19:44:09.352Z',
              _title: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
              _acl: {
                view: ['org_739224'],
                edit: ['org_739224'],
                delete: ['org_739224']
              },
              source_url:
                'https://file.dev.sls.epilot.io/v1/files/public/links/739224-jo2Rg-vdKgFxyqpjT9lla/Screenshot%202023-01-16%20at%205.24.48%20PM.png',
              $relation: {
                entity_id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                _tags: []
              }
            }
          ],
          order_number: 'PRODUCT-1039'
        },
        taxes: [
          {
            rate: 'nontaxable',
            rateValue: 0,
            amount: 0
          }
        ],
        price_mappings: [
          {
            price_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
            value: 1
          }
        ],
        currency: 'EUR',
        unit_amount_net: 1000,
        unit_amount_gross: 1000,
        amount_subtotal: 1000,
        amount_total: 1000,
        amount_tax: 0,
        tiers_details: [
          {
            quantity: 1,
            unit_amount: 1000,
            unit_amount_decimal: '10',
            unit_amount_gross: 1000,
            unit_amount_net: 1000,
            amount_subtotal: 1000,
            amount_total: 1000,
            amount_tax: 0
          }
        ],
        is_tax_inclusive: true,
        unit_amount_net_decimal: '10',
        unit_amount_gross_decimal: '10',
        amount_subtotal_decimal: '10',
        amount_total_decimal: '10'
      },
      {
        price_id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a',
        product_id: '5e42fccd-8f49-4a36-8459-14918d30cf61',
        quantity: 1,
        description: '[Flat Fee] - Installation',
        unit_amount: 0,
        unit_amount_decimal: '0.00',
        pricing_model: 'tiered_flatfee',
        _price: {
          _id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a',
          active: true,
          billing_duration_unit: 'months',
          description: '[Flat Fee] - Installation',
          is_tax_inclusive: false,
          notice_time_unit: 'months',
          price_display_in_journeys: 'show_as_starting_price',
          pricing_model: 'tiered_flatfee',
          renewal_duration_unit: 'months',
          termination_time_unit: 'months',
          tiers: [
            {
              flat_fee_amount: 5000,
              flat_fee_amount_decimal: '50',
              unit_amount: 0,
              unit_amount_decimal: '0',
              up_to: 5
            },
            {
              flat_fee_amount: 7500,
              flat_fee_amount_decimal: '75',
              unit_amount: 0,
              unit_amount_decimal: '0',
              up_to: 10
            },
            {
              flat_fee_amount: 10000,
              flat_fee_amount_decimal: '100',
              unit_amount: 0,
              unit_amount_decimal: '0',
              up_to: 15
            },
            {
              flat_fee_amount: 12000,
              flat_fee_amount_decimal: '120',
              unit_amount: 0,
              unit_amount_decimal: '0'
            }
          ],
          type: 'one_time',
          unit: 'kwh',
          unit_amount: 0,
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '0.00',
          variable_price: true,
          internal_description: '[Flat Fee] - Installation',
          billing_period: 'weekly',
          _title: '[Flat Fee] - Installation'
        },
        _product: {
          active: true,
          feature: [],
          internal_name: '[Flat Fee] - Installation',
          name: '[Flat Fee] - Installation',
          price_options: [
            {
              _id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a',
              active: true,
              billing_duration_unit: 'months',
              description: '[Flat Fee] - Installation',
              is_tax_inclusive: false,
              notice_time_unit: 'months',
              price_display_in_journeys: 'show_as_starting_price',
              pricing_model: 'tiered_flatfee',
              renewal_duration_unit: 'months',
              termination_time_unit: 'months',
              tiers: [
                {
                  flat_fee_amount: 5000,
                  flat_fee_amount_decimal: '50',
                  unit_amount: 0,
                  unit_amount_decimal: '0',
                  up_to: 5
                },
                {
                  flat_fee_amount: 7500,
                  flat_fee_amount_decimal: '75',
                  unit_amount: 0,
                  unit_amount_decimal: '0',
                  up_to: 10
                },
                {
                  flat_fee_amount: 10000,
                  flat_fee_amount_decimal: '100',
                  unit_amount: 0,
                  unit_amount_decimal: '0',
                  up_to: 15
                },
                {
                  flat_fee_amount: 12000,
                  flat_fee_amount_decimal: '120',
                  unit_amount: 0,
                  unit_amount_decimal: '0'
                }
              ],
              type: 'one_time',
              unit: 'kwh',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0.00',
              variable_price: true,
              _schema: 'price',
              _org: '739224',
              _owners: [
                {
                  org_id: '739224',
                  user_id: 'api_bcyn5xLgUYEJZSs8LdACs'
                }
              ],
              _created_at: '2024-07-26T14:06:10.296Z',
              _updated_at: '2024-07-26T14:06:10.296Z',
              internal_description: '[Flat Fee] - Installation',
              billing_period: 'weekly',
              _title: '[Flat Fee] - Installation',
              _acl: {
                view: ['org_739224'],
                edit: ['org_739224'],
                delete: ['org_739224']
              },
              $relation: {
                entity_id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a'
              }
            }
          ],
          type: 'product',
          _id: '5e42fccd-8f49-4a36-8459-14918d30cf61',
          order_number: 'PRODUCT-1067',
          _title: '[Flat Fee] - Installation'
        },
        taxes: [
          {
            rate: 'nontaxable',
            rateValue: 0,
            amount: 0
          }
        ],
        price_mappings: [
          {
            price_id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a',
            value: 123
          }
        ],
        currency: 'EUR',
        unit_amount_net: 12000,
        unit_amount_gross: 12000,
        amount_subtotal: 12000,
        amount_total: 12000,
        amount_tax: 0,
        tiers_details: [
          {
            quantity: 123,
            unit_amount: 12000,
            unit_amount_decimal: '120',
            unit_amount_gross: 12000,
            unit_amount_net: 12000,
            amount_subtotal: 12000,
            amount_total: 12000,
            amount_tax: 0
          }
        ],
        is_tax_inclusive: false,
        unit_amount_net_decimal: '120',
        unit_amount_gross_decimal: '120',
        amount_subtotal_decimal: '120',
        amount_total_decimal: '120'
      },
      {
        price_id: '8a837980-8d1c-4e1c-a92b-00f0feb94929',
        product_id: 'e0ffaab9-3dae-4853-8753-8bf117c6d545',
        quantity: 1,
        description: '[Flat Fee] - Installation',
        unit_amount: 0,
        unit_amount_decimal: '0',
        pricing_model: 'tiered_flatfee',
        _price: {
          _id: '8a837980-8d1c-4e1c-a92b-00f0feb94929',
          _tags: [],
          active: true,
          billing_duration_unit: 'months',
          description: '[Flat Fee] - Installation',
          is_tax_inclusive: false,
          notice_time_unit: 'months',
          price_display_in_journeys: 'show_as_starting_price',
          pricing_model: 'tiered_flatfee',
          renewal_duration_unit: 'months',
          termination_time_unit: 'months',
          tiers: [
            {
              flat_fee_amount: 5000,
              flat_fee_amount_decimal: '50',
              unit_amount: 0,
              unit_amount_decimal: '0',
              up_to: 5
            },
            {
              flat_fee_amount: 7500,
              flat_fee_amount_decimal: '75',
              unit_amount: 0,
              unit_amount_decimal: '0',
              up_to: 10
            },
            {
              flat_fee_amount: 10000,
              flat_fee_amount_decimal: '100',
              unit_amount: 0,
              unit_amount_decimal: '0',
              up_to: 15
            },
            {
              flat_fee_amount: 12000,
              flat_fee_amount_decimal: '120',
              unit_amount: 0,
              unit_amount_decimal: '0'
            }
          ],
          type: 'one_time',
          unit: 'kwh',
          unit_amount: 0,
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '0',
          variable_price: true,
          billing_period: 'weekly',
          internal_description: '[Flat Fee] - Installation',
          _title: '[Flat Fee] - Installation'
        },
        _product: {
          _purpose: [],
          _tags: [],
          active: true,
          feature: [],
          internal_name: '[Flat Fee] - Installation',
          name: '[Flat Fee] - Installation',
          price_options: [
            {
              _id: '8a837980-8d1c-4e1c-a92b-00f0feb94929',
              _schema: 'price',
              _tags: [],
              active: true,
              billing_duration_unit: 'months',
              description: '[Flat Fee] - Installation',
              is_tax_inclusive: false,
              notice_time_unit: 'months',
              price_display_in_journeys: 'show_as_starting_price',
              pricing_model: 'tiered_flatfee',
              renewal_duration_unit: 'months',
              termination_time_unit: 'months',
              tiers: [
                {
                  flat_fee_amount: 5000,
                  flat_fee_amount_decimal: '50',
                  unit_amount: 0,
                  unit_amount_decimal: '0',
                  up_to: 5
                },
                {
                  flat_fee_amount: 7500,
                  flat_fee_amount_decimal: '75',
                  unit_amount: 0,
                  unit_amount_decimal: '0',
                  up_to: 10
                },
                {
                  flat_fee_amount: 10000,
                  flat_fee_amount_decimal: '100',
                  unit_amount: 0,
                  unit_amount_decimal: '0',
                  up_to: 15
                },
                {
                  flat_fee_amount: 12000,
                  flat_fee_amount_decimal: '120',
                  unit_amount: 0,
                  unit_amount_decimal: '0'
                }
              ],
              type: 'one_time',
              unit: 'kwh',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0',
              variable_price: true,
              _acl_sync: '2024-08-23T12:45:21.316Z',
              billing_period: 'weekly',
              internal_description: '[Flat Fee] - Installation',
              _org: '739224',
              _owners: [
                {
                  org_id: '739224',
                  user_id: '11000933'
                }
              ],
              _created_at: '2024-08-28T09:09:07.557Z',
              _updated_at: '2024-08-28T09:09:07.557Z',
              _title: '[Flat Fee] - Installation',
              _acl: {
                view: ['org_739224'],
                edit: ['org_739224'],
                delete: ['org_739224']
              },
              $relation: {
                entity_id: '8a837980-8d1c-4e1c-a92b-00f0feb94929'
              }
            }
          ],
          type: 'product',
          order_number: 'PRODUCT-1031',
          _id: 'e0ffaab9-3dae-4853-8753-8bf117c6d545',
          _title: '[Flat Fee] - Installation'
        },
        taxes: [
          {
            rate: 'nontaxable',
            rateValue: 0,
            amount: 0
          }
        ],
        price_mappings: [
          {
            price_id: '8a837980-8d1c-4e1c-a92b-00f0feb94929',
            value: 123
          }
        ],
        currency: 'EUR',
        unit_amount_net: 12000,
        unit_amount_gross: 12000,
        amount_subtotal: 12000,
        amount_total: 12000,
        amount_tax: 0,
        tiers_details: [
          {
            quantity: 123,
            unit_amount: 12000,
            unit_amount_decimal: '120',
            unit_amount_gross: 12000,
            unit_amount_net: 12000,
            amount_subtotal: 12000,
            amount_total: 12000,
            amount_tax: 0
          }
        ],
        is_tax_inclusive: false,
        unit_amount_net_decimal: '120',
        unit_amount_gross_decimal: '120',
        amount_subtotal_decimal: '120',
        amount_total_decimal: '120'
      },
      {
        price_id: '4555625e-e408-476e-aa35-0392868ba372',
        product_id: 'c9835627-2471-4a51-8344-bea082e7eab2',
        quantity: 1,
        description: '[Graduated] - Composite',
        unit_amount: 0,
        unit_amount_decimal: '0',
        pricing_model: 'per_unit',
        item_components: [
          {
            price_id: 'ecf5315a-2632-4c89-ad7d-3eca1a9592d1',
            product_id: 'c9835627-2471-4a51-8344-bea082e7eab2',
            quantity: 1,
            description: '[Graduated] - Consumption II',
            unit_amount: 0,
            unit_amount_decimal: '0',
            pricing_model: 'tiered_graduated',
            _price: {
              _id: 'ecf5315a-2632-4c89-ad7d-3eca1a9592d1',
              _tags: [],
              active: true,
              billing_duration_unit: 'months',
              description: '[Graduated] - Consumption II',
              is_tax_inclusive: false,
              long_description: 'component description',
              notice_time_unit: 'months',
              price_display_in_journeys: 'show_price',
              pricing_model: 'tiered_graduated',
              renewal_duration_unit: 'months',
              tax: [
                {
                  _schema: 'tax',
                  active: true,
                  description: 'test 19% tax',
                  rate: '19',
                  region: 'DE',
                  type: 'VAT',
                  region_label: 'DE',
                  _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '11000933'
                    }
                  ],
                  _created_at: '2024-08-27T14:24:53.739Z',
                  _updated_at: '2024-08-27T14:24:53.739Z',
                  _title: 'test 19% tax',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  }
                }
              ],
              termination_time_unit: 'months',
              tiers: [
                {
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0',
                  unit_amount: 10,
                  unit_amount_decimal: '0.1',
                  up_to: 1000
                },
                {
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0',
                  unit_amount: 5,
                  unit_amount_decimal: '0.054',
                  up_to: 2000
                },
                {
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0',
                  unit_amount: 5,
                  unit_amount_decimal: '0.053',
                  up_to: 3000
                },
                {
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0',
                  unit_amount: 5,
                  unit_amount_decimal: '0.05'
                }
              ],
              type: 'recurring',
              unit: 'kwh',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0',
              variable_price: true,
              billing_period: 'weekly',
              internal_description: '[Graduated] - Consumption II',
              _title: '[Graduated] - Consumption II'
            },
            _product: {
              _purpose: [],
              _tags: [],
              active: true,
              description: 'product description',
              feature: [],
              internal_name: '[Graduated] - Composite',
              name: '[Graduated] - Composite',
              price_options: [
                {
                  _id: '4555625e-e408-476e-aa35-0392868ba372',
                  _schema: 'price',
                  _tags: [],
                  active: true,
                  billing_duration_unit: 'months',
                  description: '[Graduated] - Composite',
                  is_composite_price: true,
                  is_tax_inclusive: true,
                  long_description: 'price option description',
                  notice_time_unit: 'months',
                  price_components: [],
                  price_display_in_journeys: 'show_price',
                  pricing_model: 'per_unit',
                  renewal_duration_unit: 'months',
                  termination_time_unit: 'months',
                  tiers: [],
                  type: 'one_time',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0',
                  variable_price: false,
                  billing_period: 'weekly',
                  internal_description: '[Graduated] - Composite',
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '11000933'
                    }
                  ],
                  _created_at: '2024-08-27T14:24:55.184Z',
                  _updated_at: '2024-08-27T14:24:55.184Z',
                  _title: '[Graduated] - Composite',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  $relation: {
                    entity_id: '4555625e-e408-476e-aa35-0392868ba372'
                  }
                }
              ],
              type: 'product',
              order_number: 'PRODUCT-1079',
              _id: 'c9835627-2471-4a51-8344-bea082e7eab2',
              _title: '[Graduated] - Composite'
            },
            taxes: [
              {
                tax: {
                  _schema: 'tax',
                  active: true,
                  description: 'test 19% tax',
                  rate: '19',
                  region: 'DE',
                  type: 'VAT',
                  region_label: 'DE',
                  _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '11000933'
                    }
                  ],
                  _created_at: '2024-08-27T14:24:53.739Z',
                  _updated_at: '2024-08-27T14:24:53.739Z',
                  _title: 'test 19% tax',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  }
                },
                amount: 4
              }
            ],
            type: 'recurring',
            billing_period: 'weekly',
            currency: 'EUR',
            unit_amount_net: 10,
            unit_amount_gross: 12,
            amount_subtotal: 20,
            amount_total: 24,
            amount_tax: 4,
            tiers_details: [
              {
                quantity: 2,
                unit_amount: 10,
                unit_amount_decimal: '0.1',
                unit_amount_gross: 12,
                unit_amount_net: 10,
                amount_subtotal: 20,
                amount_total: 24,
                amount_tax: 4
              }
            ],
            is_tax_inclusive: false,
            unit_amount_net_decimal: '0.1',
            unit_amount_gross_decimal: '0.119',
            amount_subtotal_decimal: '0.2',
            amount_total_decimal: '0.238'
          },
          {
            price_id: 'c9ac3f90-f4d2-482f-a1cd-9667d3e659b8',
            product_id: 'c9835627-2471-4a51-8344-bea082e7eab2',
            quantity: 1,
            description: 'With unit_price',
            unit_amount: 1234,
            unit_amount_decimal: '12.34',
            pricing_model: 'per_unit',
            _price: {
              _id: 'c9ac3f90-f4d2-482f-a1cd-9667d3e659b8',
              _tags: [],
              active: true,
              billing_duration_unit: 'months',
              description: 'With unit_price',
              is_tax_inclusive: true,
              long_description: 'with unit_price description ',
              notice_time_unit: 'months',
              price_display_in_journeys: 'show_price',
              pricing_model: 'per_unit',
              renewal_duration_unit: 'months',
              termination_time_unit: 'months',
              tiers: [],
              type: 'one_time',
              unit: 'kwh',
              unit_amount: 1234,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '12.34',
              variable_price: false,
              billing_period: 'weekly',
              internal_description: 'With unit_price',
              _title: 'With unit_price'
            },
            _product: {
              _purpose: [],
              _tags: [],
              active: true,
              description: 'product description',
              feature: [],
              internal_name: '[Graduated] - Composite',
              name: '[Graduated] - Composite',
              price_options: [
                {
                  _id: '4555625e-e408-476e-aa35-0392868ba372',
                  _schema: 'price',
                  _tags: [],
                  active: true,
                  billing_duration_unit: 'months',
                  description: '[Graduated] - Composite',
                  is_composite_price: true,
                  is_tax_inclusive: true,
                  long_description: 'price option description',
                  notice_time_unit: 'months',
                  price_components: [],
                  price_display_in_journeys: 'show_price',
                  pricing_model: 'per_unit',
                  renewal_duration_unit: 'months',
                  termination_time_unit: 'months',
                  tiers: [],
                  type: 'one_time',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0',
                  variable_price: false,
                  billing_period: 'weekly',
                  internal_description: '[Graduated] - Composite',
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '11000933'
                    }
                  ],
                  _created_at: '2024-08-27T14:24:55.184Z',
                  _updated_at: '2024-08-27T14:24:55.184Z',
                  _title: '[Graduated] - Composite',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  $relation: {
                    entity_id: '4555625e-e408-476e-aa35-0392868ba372'
                  }
                }
              ],
              type: 'product',
              order_number: 'PRODUCT-1079',
              _id: 'c9835627-2471-4a51-8344-bea082e7eab2',
              _title: '[Graduated] - Composite'
            },
            taxes: [
              {
                rate: 'nontaxable',
                rateValue: 0,
                amount: 0
              }
            ],
            type: 'one_time',
            currency: 'EUR',
            unit_amount_net: 1234,
            unit_amount_net_decimal: '12.34',
            unit_amount_gross: 1234,
            unit_amount_gross_decimal: '12.34',
            amount_subtotal: 1234,
            amount_total: 1234,
            amount_tax: 0,
            is_tax_inclusive: true,
            amount_subtotal_decimal: '12.34',
            amount_total_decimal: '12.34'
          },
          {
            price_id: 'f021b371-ca04-4fda-b9b7-dc79538bc452',
            product_id: 'c9835627-2471-4a51-8344-bea082e7eab2',
            quantity: 1,
            description: 'with unit_price piece',
            unit_amount: 4321,
            unit_amount_decimal: '43.21',
            pricing_model: 'per_unit',
            _price: {
              _id: 'f021b371-ca04-4fda-b9b7-dc79538bc452',
              _tags: [],
              active: true,
              billing_duration_unit: 'months',
              description: 'with unit_price piece',
              is_tax_inclusive: true,
              long_description: 'price description with unit_price/piece',
              notice_time_unit: 'months',
              price_display_in_journeys: 'show_price',
              pricing_model: 'per_unit',
              renewal_duration_unit: 'months',
              termination_time_unit: 'months',
              tiers: [],
              type: 'one_time',
              unit_amount: 4321,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '43.21',
              variable_price: false,
              billing_period: 'weekly',
              internal_description: 'with unit_price piece',
              _title: 'with unit_price piece'
            },
            _product: {
              _purpose: [],
              _tags: [],
              active: true,
              description: 'product description',
              feature: [],
              internal_name: '[Graduated] - Composite',
              name: '[Graduated] - Composite',
              price_options: [
                {
                  _id: '4555625e-e408-476e-aa35-0392868ba372',
                  _schema: 'price',
                  _tags: [],
                  active: true,
                  billing_duration_unit: 'months',
                  description: '[Graduated] - Composite',
                  is_composite_price: true,
                  is_tax_inclusive: true,
                  long_description: 'price option description',
                  notice_time_unit: 'months',
                  price_components: [],
                  price_display_in_journeys: 'show_price',
                  pricing_model: 'per_unit',
                  renewal_duration_unit: 'months',
                  termination_time_unit: 'months',
                  tiers: [],
                  type: 'one_time',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0',
                  variable_price: false,
                  billing_period: 'weekly',
                  internal_description: '[Graduated] - Composite',
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '11000933'
                    }
                  ],
                  _created_at: '2024-08-27T14:24:55.184Z',
                  _updated_at: '2024-08-27T14:24:55.184Z',
                  _title: '[Graduated] - Composite',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  $relation: {
                    entity_id: '4555625e-e408-476e-aa35-0392868ba372'
                  }
                }
              ],
              type: 'product',
              order_number: 'PRODUCT-1079',
              _id: 'c9835627-2471-4a51-8344-bea082e7eab2',
              _title: '[Graduated] - Composite'
            },
            taxes: [
              {
                rate: 'nontaxable',
                rateValue: 0,
                amount: 0
              }
            ],
            type: 'one_time',
            currency: 'EUR',
            unit_amount_net: 4321,
            unit_amount_net_decimal: '43.21',
            unit_amount_gross: 4321,
            unit_amount_gross_decimal: '43.21',
            amount_subtotal: 4321,
            amount_total: 4321,
            amount_tax: 0,
            is_tax_inclusive: true,
            amount_subtotal_decimal: '43.21',
            amount_total_decimal: '43.21'
          }
        ],
        _price: {
          _id: '4555625e-e408-476e-aa35-0392868ba372',
          _tags: [],
          active: true,
          billing_duration_unit: 'months',
          description: '[Graduated] - Composite',
          is_composite_price: true,
          is_tax_inclusive: true,
          long_description: 'price option description',
          notice_time_unit: 'months',
          price_components: [
            {
              _id: 'ecf5315a-2632-4c89-ad7d-3eca1a9592d1',
              _tags: [],
              active: true,
              billing_duration_unit: 'months',
              description: '[Graduated] - Consumption II',
              is_tax_inclusive: false,
              long_description: 'component description',
              notice_time_unit: 'months',
              price_display_in_journeys: 'show_price',
              pricing_model: 'tiered_graduated',
              renewal_duration_unit: 'months',
              tax: {
                $relation: [
                  {
                    entity_id: '11bffddb-838b-48bc-b57f-c1e4b8dba459'
                  }
                ]
              },
              termination_time_unit: 'months',
              tiers: [
                {
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0',
                  unit_amount: 10,
                  unit_amount_decimal: '0.1',
                  up_to: 1000
                },
                {
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0',
                  unit_amount: 5,
                  unit_amount_decimal: '0.054',
                  up_to: 2000
                },
                {
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0',
                  unit_amount: 5,
                  unit_amount_decimal: '0.053',
                  up_to: 3000
                },
                {
                  flat_fee_amount: 0,
                  flat_fee_amount_decimal: '0',
                  unit_amount: 5,
                  unit_amount_decimal: '0.05'
                }
              ],
              type: 'recurring',
              unit: 'kwh',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0',
              variable_price: true,
              billing_period: 'weekly',
              internal_description: '[Graduated] - Consumption II',
              _title: '[Graduated] - Consumption II'
            },
            {
              _id: 'c9ac3f90-f4d2-482f-a1cd-9667d3e659b8',
              _tags: [],
              active: true,
              billing_duration_unit: 'months',
              description: 'With unit_price',
              is_tax_inclusive: true,
              long_description: 'with unit_price description ',
              notice_time_unit: 'months',
              price_display_in_journeys: 'show_price',
              pricing_model: 'per_unit',
              renewal_duration_unit: 'months',
              termination_time_unit: 'months',
              tiers: [],
              type: 'one_time',
              unit: 'kwh',
              unit_amount: 1234,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '12.34',
              variable_price: false,
              billing_period: 'weekly',
              internal_description: 'With unit_price',
              _title: 'With unit_price'
            },
            {
              _id: 'f021b371-ca04-4fda-b9b7-dc79538bc452',
              _tags: [],
              active: true,
              billing_duration_unit: 'months',
              description: 'with unit_price piece',
              is_tax_inclusive: true,
              long_description: 'price description with unit_price/piece',
              notice_time_unit: 'months',
              price_display_in_journeys: 'show_price',
              pricing_model: 'per_unit',
              renewal_duration_unit: 'months',
              termination_time_unit: 'months',
              tiers: [],
              type: 'one_time',
              unit_amount: 4321,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '43.21',
              variable_price: false,
              billing_period: 'weekly',
              internal_description: 'with unit_price piece',
              _title: 'with unit_price piece'
            }
          ],
          price_display_in_journeys: 'show_price',
          pricing_model: 'per_unit',
          renewal_duration_unit: 'months',
          termination_time_unit: 'months',
          tiers: [],
          type: 'one_time',
          unit_amount: 0,
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '0',
          variable_price: false,
          billing_period: 'weekly',
          internal_description: '[Graduated] - Composite',
          _title: '[Graduated] - Composite'
        },
        _product: {
          _purpose: [],
          _tags: [],
          active: true,
          description: 'product description',
          feature: [],
          internal_name: '[Graduated] - Composite',
          name: '[Graduated] - Composite',
          price_options: [
            {
              _id: '4555625e-e408-476e-aa35-0392868ba372',
              _schema: 'price',
              _tags: [],
              active: true,
              billing_duration_unit: 'months',
              description: '[Graduated] - Composite',
              is_composite_price: true,
              is_tax_inclusive: true,
              long_description: 'price option description',
              notice_time_unit: 'months',
              price_components: [],
              price_display_in_journeys: 'show_price',
              pricing_model: 'per_unit',
              renewal_duration_unit: 'months',
              termination_time_unit: 'months',
              tiers: [],
              type: 'one_time',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0',
              variable_price: false,
              billing_period: 'weekly',
              internal_description: '[Graduated] - Composite',
              _org: '739224',
              _owners: [
                {
                  org_id: '739224',
                  user_id: '11000933'
                }
              ],
              _created_at: '2024-08-27T14:24:55.184Z',
              _updated_at: '2024-08-27T14:24:55.184Z',
              _title: '[Graduated] - Composite',
              _acl: {
                view: ['org_739224'],
                edit: ['org_739224'],
                delete: ['org_739224']
              },
              $relation: {
                entity_id: '4555625e-e408-476e-aa35-0392868ba372'
              }
            }
          ],
          type: 'product',
          order_number: 'PRODUCT-1079',
          _id: 'c9835627-2471-4a51-8344-bea082e7eab2',
          _title: '[Graduated] - Composite'
        },
        price_mappings: [
          {
            price_id: 'ecf5315a-2632-4c89-ad7d-3eca1a9592d1',
            value: 2
          }
        ],
        currency: 'EUR',
        amount_subtotal: 5575,
        amount_total: 5579,
        amount_tax: 4,
        total_details: {
          amount_tax: 4,
          breakdown: {
            taxes: [
              {
                tax: {
                  _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                  type: 'VAT',
                  rate: '19'
                },
                amount: 4
              },
              {
                tax: {
                  rate: 0
                },
                amount: 0
              }
            ],
            recurrences: [
              {
                type: 'recurring',
                billing_period: 'weekly',
                unit_amount_gross: 12,
                unit_amount_net: 10,
                amount_subtotal: 20,
                amount_total: 24,
                amount_subtotal_decimal: '0.2',
                amount_total_decimal: '0.238',
                amount_tax: 4
              },
              {
                type: 'one_time',
                unit_amount_gross: 5555,
                unit_amount_net: 5555,
                amount_subtotal: 5555,
                amount_total: 5555,
                amount_subtotal_decimal: '55.55',
                amount_total_decimal: '55.55',
                amount_tax: 0
              }
            ],
            recurrencesByTax: [
              {
                type: 'recurring',
                billing_period: 'weekly',
                amount_total: 24,
                amount_subtotal: 20,
                amount_tax: 4,
                tax: {
                  tax: {
                    _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                    type: 'VAT',
                    rate: '19'
                  },
                  amount: 4
                }
              },
              {
                type: 'one_time',
                amount_total: 5555,
                amount_subtotal: 5555,
                amount_tax: 0,
                tax: {
                  tax: {
                    rate: 0
                  },
                  amount: 0
                }
              }
            ],
            cashbacks: []
          }
        },
        amount_subtotal_decimal: '55.75',
        amount_total_decimal: '55.788'
      }
    ],
    amount_subtotal: 30575,
    amount_total: 30579,
    total_details: {
      amount_tax: 4,
      breakdown: {
        taxes: [
          {
            tax: {
              rate: 0
            },
            amount: 0
          },
          {
            tax: {
              _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
              type: 'VAT',
              rate: '19'
            },
            amount: 4
          }
        ],
        recurrences: [
          {
            type: 'one_time',
            unit_amount_gross: 30555,
            unit_amount_net: 30555,
            amount_subtotal: 30555,
            amount_total: 30555,
            amount_subtotal_decimal: '305.55',
            amount_total_decimal: '305.55',
            amount_tax: 0
          },
          {
            type: 'recurring',
            billing_period: 'weekly',
            unit_amount_gross: 12,
            unit_amount_net: 10,
            amount_subtotal: 20,
            amount_total: 24,
            amount_subtotal_decimal: '0.2',
            amount_total_decimal: '0.238',
            amount_tax: 4
          }
        ],
        recurrencesByTax: [
          {
            type: 'one_time',
            amount_total: 30555,
            amount_subtotal: 30555,
            amount_tax: 0,
            tax: {
              tax: {
                rate: 0
              },
              amount: 0
            }
          },
          {
            type: 'recurring',
            billing_period: 'weekly',
            amount_total: 24,
            amount_subtotal: 20,
            amount_tax: 4,
            tax: {
              tax: {
                _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                type: 'VAT',
                rate: '19'
              },
              amount: 4
            }
          }
        ],
        cashbacks: []
      }
    },
    currency: 'EUR',
    amount_tax: 4,
    prices: {
      $relation: [
        {
          entity_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
          _schema: 'price',
          _tags: []
        },
        {
          entity_id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a',
          _schema: 'price',
          _tags: []
        },
        {
          entity_id: '8a837980-8d1c-4e1c-a92b-00f0feb94929',
          _schema: 'price',
          _tags: []
        },
        {
          entity_id: '4555625e-e408-476e-aa35-0392868ba372',
          _schema: 'price',
          _tags: []
        }
      ]
    },
    products: {
      $relation: [
        {
          entity_id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
          _schema: 'product',
          _tags: []
        },
        {
          entity_id: '5e42fccd-8f49-4a36-8459-14918d30cf61',
          _schema: 'product',
          _tags: []
        },
        {
          entity_id: 'e0ffaab9-3dae-4853-8753-8bf117c6d545',
          _schema: 'product',
          _tags: []
        },
        {
          entity_id: 'c9835627-2471-4a51-8344-bea082e7eab2',
          _schema: 'product',
          _tags: []
        }
      ]
    }
  },
  orders: [],
  meters: [],
  files: [
    {
      filename: 'Screenshot 2024-12-15 at 23.49.05.png',
      category: 'unknown',
      file_entity_id: '',
      access_control: 'private',
      type: 'image',
      public_url:
        'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/693cd2e7-87a0-4076-856c-464551b383b5/Screenshot%25202024-12-15%2520at%252023.49.05.png',
      size_bytes: 97737,
      readable_size: '95.45 KB',
      mime_type: 'image/png',
      s3ref: {
        bucket: 'epilot-dev-user-content',
        key: '739224/693cd2e7-87a0-4076-856c-464551b383b5/Screenshot%202024-12-15%20at%2023.49.05.png'
      },
      versions: [
        {
          filename: 'Screenshot 2024-12-15 at 23.49.05.png',
          category: 'unknown',
          file_entity_id: '',
          access_control: 'private',
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/693cd2e7-87a0-4076-856c-464551b383b5/Screenshot%25202024-12-15%2520at%252023.49.05.png',
          size_bytes: 97737,
          readable_size: '95.45 KB',
          mime_type: 'image/png',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/693cd2e7-87a0-4076-856c-464551b383b5/Screenshot%202024-12-15%20at%2023.49.05.png'
          }
        }
      ],
      _schema: 'file',
      _id: 'd4a7817d-c07b-4ffd-a770-9ca839cce652',
      _org: '739224',
      _created_at: '2024-12-16T03:12:26.725Z',
      _updated_at: '2024-12-16T03:13:01.840Z',
      _title: 'Screenshot 2024-12-15 at 23.49.05.png',
      shared_with_end_customer: true,
      width: 1188,
      height: 1476
    },
    {
      filename: 'Screenshot 2024-12-16 at 01.37.56.png',
      category: 'unknown',
      file_entity_id: '',
      access_control: 'private',
      type: 'image',
      public_url:
        'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/aec753bf-53de-4cbb-a021-084acb4e4f65/Screenshot%25202024-12-16%2520at%252001.37.56.png',
      size_bytes: 63359,
      readable_size: '61.87 KB',
      mime_type: 'image/png',
      s3ref: {
        bucket: 'epilot-dev-user-content',
        key: '739224/aec753bf-53de-4cbb-a021-084acb4e4f65/Screenshot%202024-12-16%20at%2001.37.56.png'
      },
      versions: [
        {
          filename: 'Screenshot 2024-12-16 at 01.37.56.png',
          category: 'unknown',
          file_entity_id: '',
          access_control: 'private',
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/aec753bf-53de-4cbb-a021-084acb4e4f65/Screenshot%25202024-12-16%2520at%252001.37.56.png',
          size_bytes: 63359,
          readable_size: '61.87 KB',
          mime_type: 'image/png',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/aec753bf-53de-4cbb-a021-084acb4e4f65/Screenshot%202024-12-16%20at%2001.37.56.png'
          }
        }
      ],
      _schema: 'file',
      _id: '45fbffd1-018a-4933-b5fd-2c9a0e6ba435',
      _org: '739224',
      _created_at: '2024-12-16T03:12:26.681Z',
      _updated_at: '2024-12-16T03:12:56.546Z',
      _title: 'Screenshot 2024-12-16 at 01.37.56.png',
      shared_with_end_customer: true,
      width: 810,
      height: 250
    },
    {
      filename: 'Screenshot 2024-12-16 at 01.37.36.png',
      category: 'unknown',
      file_entity_id: '',
      access_control: 'private',
      type: 'image',
      public_url:
        'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/805468bb-1159-4f99-8c42-90b04a9e9177/Screenshot%25202024-12-16%2520at%252001.37.36.png',
      size_bytes: 424820,
      readable_size: '414.86 KB',
      mime_type: 'image/png',
      s3ref: {
        bucket: 'epilot-dev-user-content',
        key: '739224/805468bb-1159-4f99-8c42-90b04a9e9177/Screenshot%202024-12-16%20at%2001.37.36.png'
      },
      versions: [
        {
          filename: 'Screenshot 2024-12-16 at 01.37.36.png',
          category: 'unknown',
          file_entity_id: '',
          access_control: 'private',
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/805468bb-1159-4f99-8c42-90b04a9e9177/Screenshot%25202024-12-16%2520at%252001.37.36.png',
          size_bytes: 424820,
          readable_size: '414.86 KB',
          mime_type: 'image/png',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/805468bb-1159-4f99-8c42-90b04a9e9177/Screenshot%202024-12-16%20at%2001.37.36.png'
          }
        }
      ],
      _schema: 'file',
      _id: 'c98b68b9-7303-4ed2-a23a-e236c7874d4d',
      _org: '739224',
      _created_at: '2024-12-16T03:12:26.485Z',
      _updated_at: '2024-12-16T03:12:48.950Z',
      _title: 'Screenshot 2024-12-16 at 01.37.36.png',
      shared_with_end_customer: true,
      width: 2710,
      height: 1476
    },
    {
      filename: 'Screenshot 2024-12-16 at 01.43.24.png',
      category: 'unknown',
      file_entity_id: '',
      access_control: 'private',
      type: 'image',
      public_url:
        'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a46c6b20-53b3-47a5-896e-4f8d2b950301/Screenshot%25202024-12-16%2520at%252001.43.24.png',
      size_bytes: 212567,
      readable_size: '207.58 KB',
      mime_type: 'image/png',
      s3ref: {
        bucket: 'epilot-dev-user-content',
        key: '739224/a46c6b20-53b3-47a5-896e-4f8d2b950301/Screenshot%202024-12-16%20at%2001.43.24.png'
      },
      versions: [
        {
          filename: 'Screenshot 2024-12-16 at 01.43.24.png',
          category: 'unknown',
          file_entity_id: '',
          access_control: 'private',
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a46c6b20-53b3-47a5-896e-4f8d2b950301/Screenshot%25202024-12-16%2520at%252001.43.24.png',
          size_bytes: 212567,
          readable_size: '207.58 KB',
          mime_type: 'image/png',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/a46c6b20-53b3-47a5-896e-4f8d2b950301/Screenshot%202024-12-16%20at%2001.43.24.png'
          }
        }
      ],
      _schema: 'file',
      _id: '27aabaf7-d12e-41ee-82fe-6c0cc9ce1200',
      _org: '739224',
      _created_at: '2024-12-16T03:12:24.781Z',
      _updated_at: '2024-12-16T03:12:41.093Z',
      _title: 'Screenshot 2024-12-16 at 01.43.24.png',
      shared_with_end_customer: true,
      width: 1460,
      height: 1584
    },
    {
      filename: 'Screenshot 2024-12-11 at 09.05.40.png',
      category: 'unknown',
      file_entity_id: '',
      access_control: 'private',
      type: 'image',
      public_url:
        'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b549203d-6856-48f1-910a-7c96b465d917/Screenshot%25202024-12-11%2520at%252009.05.40.png',
      size_bytes: 23032,
      readable_size: '22.49 KB',
      mime_type: 'image/png',
      s3ref: {
        bucket: 'epilot-dev-user-content',
        key: '739224/b549203d-6856-48f1-910a-7c96b465d917/Screenshot%202024-12-11%20at%2009.05.40.png'
      },
      versions: [
        {
          filename: 'Screenshot 2024-12-11 at 09.05.40.png',
          category: 'unknown',
          file_entity_id: '',
          access_control: 'private',
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b549203d-6856-48f1-910a-7c96b465d917/Screenshot%25202024-12-11%2520at%252009.05.40.png',
          size_bytes: 23032,
          readable_size: '22.49 KB',
          mime_type: 'image/png',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/b549203d-6856-48f1-910a-7c96b465d917/Screenshot%202024-12-11%20at%2009.05.40.png'
          }
        }
      ],
      _schema: 'file',
      _id: 'a9fdedbc-81bf-4c6f-aca1-e34e89ee9c5d',
      _org: '739224',
      _created_at: '2024-12-12T01:17:01.521Z',
      _updated_at: '2025-01-23T17:07:49.910Z',
      _title: 'Screenshot 2024-12-11 at 09.05.40.png',
      shared_with_end_customer: true,
      _tags: ['family-with-umlauts:nadeem', 'Tag2'],
      width: 566,
      height: 192
    }
  ],
  relations: [
    {
      _schema: 'contact',
      _title: 'Anna Spark',
      _id: '754dce41-8c6f-48e0-801b-673abf56f83e',
      _org: '739224',
      _created_at: '2023-04-04T08:40:56.478Z',
      files: [
        {
          filename: '3rd-party-api-flow (8).jpg',
          access_control: 'private',
          type: 'image',
          public_url: null,
          size_bytes: 2622909,
          readable_size: '2.5 MB',
          mime_type: 'image/jpeg',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/1d3aa910-27a6-4c54-8090-5791c706ad4f/3rd-party-api-flow%20(8).jpg'
          },
          versions: [
            {
              filename: '3rd-party-api-flow (8).jpg',
              access_control: 'private',
              type: 'image',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/1d3aa910-27a6-4c54-8090-5791c706ad4f/3rd-party-api-flow%2520(8).jpg',
              size_bytes: 2622909,
              readable_size: '2.5 MB',
              mime_type: 'image/jpeg',
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/1d3aa910-27a6-4c54-8090-5791c706ad4f/3rd-party-api-flow%20(8).jpg'
              }
            }
          ],
          _schema: 'file',
          _id: '1d7dc5d9-1393-4ef3-89c6-e930305cc42f',
          _org: '739224',
          _created_at: '2024-09-05T21:05:25.753Z',
          _updated_at: '2024-10-29T08:43:58.336Z',
          category: 'unknown',
          _title: '3rd-party-api-flow (8).jpg',
          shared_with_end_customer: true,
          custom_download_url:
            'https://intense-ag-develpoment.test02.apimanagement.eu10.hana.ondemand.com/file/twl/request/MTAwMDI5NzkxNC9VNC83ZjZjY2VkMS05NDMzLTQwY2MtOGRlMS0yMjlmZTNjMzc5MDU='
        },
        {
          filename: 'Bildschirmfoto_2024-09-04_um_09.57.16.png',
          access_control: 'private',
          shared_with_end_customer: true,
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/e66ee0b8-02a9-476d-8933-fb8ca3c6b546/Bildschirmfoto_2024-09-04_um_09.57.16.png',
          size_bytes: 131471,
          readable_size: '128.39 KB',
          mime_type: 'image/png',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/e66ee0b8-02a9-476d-8933-fb8ca3c6b546/Bildschirmfoto_2024-09-04_um_09.57.16.png'
          },
          versions: [
            {
              filename: 'Bildschirmfoto_2024-09-04_um_09.57.16.png',
              access_control: 'private',
              shared_with_end_customer: true,
              type: 'image',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/e66ee0b8-02a9-476d-8933-fb8ca3c6b546/Bildschirmfoto_2024-09-04_um_09.57.16.png',
              size_bytes: 131471,
              readable_size: '128.39 KB',
              mime_type: 'image/png',
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/e66ee0b8-02a9-476d-8933-fb8ca3c6b546/Bildschirmfoto_2024-09-04_um_09.57.16.png'
              }
            }
          ],
          _schema: 'file',
          _id: '68db2ef5-fce0-4b2b-bda1-448d10d3973d',
          _org: '739224',
          _created_at: '2024-09-04T22:54:21.791Z',
          _updated_at: '2024-09-04T22:54:21.791Z',
          category: 'unknown',
          _title: 'Bildschirmfoto_2024-09-04_um_09.57.16.png'
        },
        {
          filename: '26.06._Workshop_.pdf',
          access_control: 'private',
          shared_with_end_customer: true,
          type: 'document',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/5c3d7616-1256-4397-b0c0-d135a9584f87/26.06._Workshop_.pdf',
          size_bytes: 2126574,
          readable_size: '2.03 MB',
          mime_type: 'application/pdf',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/5c3d7616-1256-4397-b0c0-d135a9584f87/26.06._Workshop_.pdf'
          },
          versions: [
            {
              filename: '26.06._Workshop_.pdf',
              access_control: 'private',
              shared_with_end_customer: true,
              type: 'document',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/5c3d7616-1256-4397-b0c0-d135a9584f87/26.06._Workshop_.pdf',
              size_bytes: 2126574,
              readable_size: '2.03 MB',
              mime_type: 'application/pdf',
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/5c3d7616-1256-4397-b0c0-d135a9584f87/26.06._Workshop_.pdf'
              }
            }
          ],
          _schema: 'file',
          _id: '81a62b8c-fa36-4afa-b4c1-61c47baa849c',
          _org: '739224',
          _created_at: '2024-08-23T10:19:01.983Z',
          _updated_at: '2024-09-05T06:38:04.127Z',
          category: 'unknown',
          _title: '26.06._Workshop_.pdf',
          file_date: '2024-09-04T06:37:00.000Z'
        },
        {
          filename: 'external_file.jpg',
          access_control: 'public-read',
          shared_with_end_customer: true,
          type: 'image',
          public_url: null,
          size_bytes: 2622909,
          readable_size: '2.5 MB',
          mime_type: 'image/jpeg',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/dc045d25-1b81-4a04-ab4e-a7f6a188c2f0/3rd-party-api-flow_(7).jpg'
          },
          versions: [
            {
              filename: '3rd-party-api-flow_(7).jpg',
              access_control: 'private',
              shared_with_end_customer: true,
              type: 'image',
              public_url: '',
              size_bytes: 2622909,
              readable_size: '2.5 MB',
              mime_type: 'image/jpeg',
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/dc045d25-1b81-4a04-ab4e-a7f6a188c2f0/3rd-party-api-flow_(7).jpg'
              }
            }
          ],
          _schema: 'file',
          _id: '241fbb51-4644-44cc-8854-725f8c0422fc',
          _org: '739224',
          _created_at: '2024-09-03T11:30:02.099Z',
          _updated_at: '2024-09-05T21:06:04.431Z',
          category: 'unknown',
          _title: 'external_file.jpg'
        },
        {
          filename: 'Screenshot_2024-08-19_at_10.35.58.png',
          access_control: 'private',
          shared_with_end_customer: true,
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/12952cd7-f76d-4e93-b334-7f7caf07f5b0/Screenshot_2024-08-19_at_10.35.58.png',
          size_bytes: 1126,
          readable_size: '1.1 KB',
          mime_type: 'image/png',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/12952cd7-f76d-4e93-b334-7f7caf07f5b0/Screenshot_2024-08-19_at_10.35.58.png'
          },
          versions: [
            {
              filename: 'Screenshot_2024-08-19_at_10.35.58.png',
              access_control: 'private',
              shared_with_end_customer: true,
              type: 'image',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/12952cd7-f76d-4e93-b334-7f7caf07f5b0/Screenshot_2024-08-19_at_10.35.58.png',
              size_bytes: 1126,
              readable_size: '1.1 KB',
              mime_type: 'image/png',
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/12952cd7-f76d-4e93-b334-7f7caf07f5b0/Screenshot_2024-08-19_at_10.35.58.png'
              }
            }
          ],
          _schema: 'file',
          _id: 'cd001780-be68-4486-b5b0-a487a6d0de9c',
          _org: '739224',
          _created_at: '2024-08-23T09:15:54.806Z',
          _updated_at: '2024-08-23T09:15:54.806Z',
          category: 'unknown',
          _title: 'Screenshot_2024-08-19_at_10.35.58.png'
        },
        {
          filename: '26.06._Workshop__(7).pdf',
          access_control: 'private',
          shared_with_end_customer: true,
          type: 'document',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/de3906ef-c583-481a-a323-5c9096ef4885/26.06._Workshop__(7).pdf',
          size_bytes: 2126574,
          readable_size: '2.03 MB',
          mime_type: 'application/pdf',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/de3906ef-c583-481a-a323-5c9096ef4885/26.06._Workshop__(7).pdf'
          },
          versions: [
            {
              filename: '26.06._Workshop__(7).pdf',
              access_control: 'private',
              shared_with_end_customer: true,
              type: 'document',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/de3906ef-c583-481a-a323-5c9096ef4885/26.06._Workshop__(7).pdf',
              size_bytes: 2126574,
              readable_size: '2.03 MB',
              mime_type: 'application/pdf',
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/de3906ef-c583-481a-a323-5c9096ef4885/26.06._Workshop__(7).pdf'
              }
            }
          ],
          _schema: 'file',
          _id: '4b5fa769-85a2-4a8a-b481-f219dc386419',
          _org: '739224',
          _created_at: '2024-09-04T11:20:08.764Z',
          _updated_at: '2024-09-05T06:28:43.859Z',
          category: 'unknown',
          _title: '26.06._Workshop__(7).pdf',
          file_date: '2024-08-22T06:28:00.000Z'
        },
        {
          filename: '26.06._Workshop__(8).pdf',
          access_control: 'private',
          shared_with_end_customer: true,
          type: 'document',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/0998ecd1-7b1e-4a67-8d99-19e3e3ab0bfc/26.06._Workshop__(8).pdf',
          size_bytes: 2126574,
          readable_size: '2.03 MB',
          mime_type: 'application/pdf',
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/0998ecd1-7b1e-4a67-8d99-19e3e3ab0bfc/26.06._Workshop__(8).pdf'
          },
          versions: [
            {
              filename: '26.06._Workshop__(8).pdf',
              access_control: 'private',
              shared_with_end_customer: true,
              type: 'document',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/0998ecd1-7b1e-4a67-8d99-19e3e3ab0bfc/26.06._Workshop__(8).pdf',
              size_bytes: 2126574,
              readable_size: '2.03 MB',
              mime_type: 'application/pdf',
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/0998ecd1-7b1e-4a67-8d99-19e3e3ab0bfc/26.06._Workshop__(8).pdf'
              }
            }
          ],
          _schema: 'file',
          _id: '7baad485-3744-4398-962e-5b8cb3e336ae',
          _org: '739224',
          _created_at: '2024-09-04T11:20:24.355Z',
          _updated_at: '2024-09-04T14:18:03.748Z',
          category: 'unknown',
          _title: '26.06._Workshop__(8).pdf',
          file_date: '2024-08-01T14:09:00.000Z'
        }
      ],
      _acl_sync: '2024-11-21T13:04:31.626Z',
      file: {
        $relation: [
          {
            '0': null
          }
        ]
      },
      language: 'en',
      sequence_test: '24',
      _files: {
        $relation: [
          {
            '0': null
          },
          {
            '0': null
          },
          {
            '0': null
          },
          {
            '0': null
          },
          {
            entity_id: '4b5d19a2-caaa-4b22-8f00-63495b75a705'
          },
          {
            entity_id: 'ebac5018-6437-4b55-ace9-a7c2644d5cf4'
          },
          {
            entity_id: '8084c2c2-8455-4565-8251-135aa23d1f94'
          },
          {
            entity_id: '106f4ab7-f117-4cdf-98f6-d730afd605de'
          },
          {
            entity_id: '9b3e438d-8f91-4cb2-ae54-2e0883f36bdf'
          },
          {
            entity_id: 'cd84262e-6b35-4804-8156-23bcd0eb4c56'
          },
          {
            entity_id: '0bbb879e-2b69-4222-b1d8-50cd931a0d31'
          },
          {
            entity_id: 'a1464782-856b-45a9-9aa1-a068917a13fd'
          },
          {
            entity_id: 'cd001780-be68-4486-b5b0-a487a6d0de9c'
          },
          {
            entity_id: '81a62b8c-fa36-4afa-b4c1-61c47baa849c'
          },
          {
            entity_id: '48095fdd-4908-421b-bdff-4b99fd55c540'
          },
          {
            entity_id: '70cfc091-38cf-47c5-b9e7-db0d3aa3774f'
          },
          {
            entity_id: '64414958-2744-40af-8ccb-4a49111e25bd'
          },
          {
            entity_id: '1d7dc5d9-1393-4ef3-89c6-e930305cc42f'
          },
          {
            entity_id: '7c4298ed-6d9c-4620-bac7-c69f407c614d'
          },
          {
            entity_id: 'd757aced-9c5b-4871-8860-7eb66c9c8fec'
          },
          {
            entity_id: 'ec45346a-7aa0-4b40-8b10-ec427095896b'
          },
          {
            entity_id: 'f758824f-3f70-49d4-8ceb-d5ff3e79afbf'
          },
          {
            entity_id: '0da39ae4-47a7-47c1-bea2-c47275fd714d'
          },
          {
            entity_id: 'aa5fb6d2-f50f-49dd-a9b4-5129cfb914a8'
          },
          {
            entity_id: '68db2ef5-fce0-4b2b-bda1-448d10d3973d'
          },
          {
            entity_id: '7baad485-3744-4398-962e-5b8cb3e336ae'
          },
          {
            entity_id: '4b5fa769-85a2-4a8a-b481-f219dc386419'
          },
          {
            entity_id: '241fbb51-4644-44cc-8854-725f8c0422fc'
          },
          {
            entity_id: '68c061a5-cf93-476f-879b-c51ea521324b'
          }
        ]
      },
      _purpose: [
        'd9ddb956-9943-4fe4-9b84-ce2c99e26a1c',
        '00527ec7-4d82-4a2e-bade-6d4163b7e751',
        '05e0b4be-fc10-4408-9cd3-c92edc059a2d',
        '0c4f28bd-f567-4e4a-9b08-0e524fa0cd44',
        '1fda897b-c943-4178-a9c2-d9d7e73f2278',
        '09a4249d-4c94-4154-b361-a28f79a584d9',
        '0b2cda11-527c-4734-a687-c1f4b0b74153',
        '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
        '235971d6-8191-48a7-a0ef-a1bae4b636fa',
        '361ea610-da2e-488a-809d-12288d8ca28a',
        '40fb4332-076c-4fc3-a0ea-c19575ffe1ae',
        '42e5de9e-6d68-48ff-acfd-bac876a83e68',
        '48fe0ec9-87fb-4324-baa9-ad6bfd7059df',
        '49f5e6bc-137e-405c-a842-7f19dc260d59',
        '51a647cd-2c1c-4e92-9b32-3ebbff175415',
        '5653809f-b45a-4f88-b199-b58041967bc6',
        '6f94c105-1233-44f7-be82-daf641c20ca7',
        '718f492e-2336-40d2-8e5b-8d99c427d3ac',
        '7d922046-c37c-405c-963e-83be16064f59',
        '7ee98073-a772-4928-81df-012c11d5083d',
        'a95a8a79-c0bc-41ee-b70d-c75c72c2cbd8',
        'bd339552-d264-4a33-8c7a-77eee480fa51',
        'c603566f-5d6f-4750-9f7d-4c33d3e9cb3d',
        'cb92a48f-0b1b-49b3-af2d-73c54cadf186',
        'db9be2fb-f46d-421a-8a86-269d7fd71a05',
        'df3b5771-7b88-455b-b5e4-c48990578b68',
        'f052db25-3fc1-405d-b684-03abeb4277fc'
      ],
      new_date: '1991-10-09T23:00:00.000Z',
      journey_actions: [],
      contact: {
        $relation: [
          {
            '0': null
          }
        ]
      },
      relation_to_contact_attribute: {
        $relation: [
          {
            '0': null
          }
        ]
      },
      consent_email_marketing: {
        status: 'active',
        identifiers: [
          {
            identifier: 'z.solyom@epilot.cloud',
            status: 'active'
          },
          {
            identifier: 'epilot@test.clom',
            status: 'active'
          },
          {
            identifier: 'new@email.com',
            status: 'unknown'
          },
          {
            identifier: 'add_new@email.com',
            status: 'active'
          }
        ]
      },
      consent_sms_marketing: {
        status: 'unknown',
        identifiers: []
      },
      salutation: 'Mr.',
      title: 'Prof. Dr.',
      first_name: 'Spark',
      last_name: 'Anna',
      _pending_changes: {},
      customer_number: '1',
      birthdate: '2024-12-31T23:00:00.000Z',
      email: [
        {
          _tags: [],
          email: 'z.solyom@epilot.cloud',
          _id: 'Hxxsz2TOJ_tJ09qPQ02fF',
          id: 'p734mh0'
        },
        {
          '0': {
            _tags: [],
            email: 'z.solyom@epilot.cloud',
            _id: 'Hxxsz2TOJ_tJ09qPQ02fF',
            id: '3b3umul'
          },
          '1': {
            id: 'u0fujun',
            _tags: [],
            email: 'm.'
          },
          _id: 'GEVhhB_2WD_bIfWQELiVj',
          email: 'epilot@test.clom',
          id: 'vwo4dq0'
        },
        {
          _tags: [],
          email: 'new@email.com',
          _id: '_7iOSKPDnc8RBX_hm_cDV',
          id: 'fdfc1rq'
        },
        {
          _tags: [],
          email: 'add_new@email.com',
          _id: 'ehMuewhoRiTZlrmSrLHPz',
          id: 'bwjh7t3'
        }
      ],
      phone: [],
      communication_preference: 'portal',
      address: [
        {
          _tags: [],
          country: '',
          city: '',
          postal_code: '',
          street: 'ddasd',
          street_number: '1233',
          additional_info: '',
          _id: 'QTUaBZFFG_MYKExud8lrt',
          id: '7yjlunl'
        },
        {
          _tags: [],
          country: '',
          city: 'koln',
          postal_code: '',
          street: '',
          street_number: '',
          additional_info: '',
          _id: 'CY60xSlwN82yyMEeEHE21',
          id: '14qcf0l'
        }
      ],
      payment: [
        {
          _id: 'f17ecd8d-132a-4f61-b8d7-de13aa92a6c6',
          _tags: [],
          type: 'payment_sepa',
          data: {
            '0': null,
            fullname: 'Solyom Zsolt',
            iban: '',
            bank_name: '',
            bic_number: ''
          },
          id: 'mmqimwx'
        },
        {
          _id: '9d42e56f-f364-47fe-846c-bbddd68b55eb',
          _tags: [],
          type: 'payment_sepa',
          data: {
            bank_name: 'Landesbank Berlin - Berliner Sparkasse',
            iban: '',
            bic_number: 'BELADEBEXXX',
            fullname: '123123'
          },
          id: 'rq2fzmy'
        }
      ],
      account: {
        $relation: [
          {
            '0': null
          }
        ]
      },
      consent_phone_call: {
        status: 'unknown',
        identifiers: []
      },
      consent_print_marketing: {
        status: 'active',
        identifiers: [
          {
            identifier: '754dce41-8c6f-48e0-801b-673abf56f83e',
            status: 'unknown'
          }
        ]
      },
      undefined: {
        email: [
          {
            _tags: [],
            email: 'z.solyom@epilot.cloud',
            _id: 'Hxxsz2TOJ_tJ09qPQ02fF',
            id: '33mnya3'
          },
          {
            _tags: [],
            email: 'asd',
            _id: 'h6SaeXN9HUWU3cqoKZAKA',
            id: 'egl7rb8'
          },
          {
            '0': {
              _tags: [],
              email: 'z.solyom@epilot.cloud',
              _id: 'Hxxsz2TOJ_tJ09qPQ02fF',
              id: '3b3umul'
            },
            '1': {
              id: 'u0fujun',
              _tags: [],
              email: 'm.'
            },
            _id: 'GEVhhB_2WD_bIfWQELiVj',
            id: 'xfl91mg'
          },
          {
            '0': {
              _tags: [],
              email: 'z.solyom@epilot.cloud',
              _id: 'Hxxsz2TOJ_tJ09qPQ02fF',
              id: '3b3umul'
            },
            '1': {
              id: 'u0fujun',
              _tags: [],
              email: 'm.ashtaki@epilot.cloud'
            },
            '2': [
              {
                _tags: [],
                email: 'z.solyom@epilot.cloud',
                _id: 'Hxxsz2TOJ_tJ09qPQ02fF',
                id: '3b3umul'
              },
              {
                id: 'u0fujun',
                _tags: [],
                email: 'm.'
              }
            ],
            _id: '3DStMddWT8XVXF6KQ6Pjk',
            id: 'jzmuwxu'
          }
        ]
      },
      other_address: [
        {
          _id: 'CSdgJdjR0YJQjE_qkGbIS',
          _tags: [],
          street: 'street',
          zip: '',
          city: '',
          country: '',
          street_number: '1112'
        },
        {
          id: 'atfmq0f',
          _tags: [],
          country: '',
          city: '',
          postal_code: '',
          street: 'whaterv',
          street_number: '123312',
          additional_info: '',
          _id: 'GOC5AVRwcd2V9WliwwyCM'
        },
        {
          id: 'ttgnytk',
          _tags: [],
          country: '',
          city: 'london',
          postal_code: '',
          street: '',
          street_number: '',
          additional_info: '',
          _id: 'aSIJW44lVsxwC383A3h46'
        }
      ],
      first_ecp_login: '2024-09-26T07:20:52.996Z',
      whatever: 'some value',
      _updated_at: '2025-02-03T09:53:22.905Z'
    },
    {
      _schema: 'contact',
      _title: 'Max Spark',
      email: [
        {
          email: 'm.seabra@epilot.cloud',
          _id: '-Sptp21nMYZLV8JZoVYMa',
          id: 'mwo8al7'
        },
        {
          _id: '4YUhr3zYAWjIWeEfPGxdI',
          _tags: [],
          email: 'd.bot+alpha92@epilot.cloud',
          id: 'w7fcb3y'
        }
      ],
      first_name: 'Max',
      last_name: 'Spark',
      salutation: 'Mr.',
      _id: 'e407f7e4-e05a-4017-a4b6-4963c5a1551c',
      _org: '739224',
      _created_at: '2023-04-11T10:37:16.609Z',
      consent_email_marketing: {
        status: 'active',
        identifiers: [
          {
            identifier: 'm.seabra@epilot.cloud',
            status: 'active'
          },
          {
            identifier: 'd.bot+alpha92@epilot.cloud',
            status: 'active'
          }
        ]
      },
      phone: [
        {
          _tags: [],
          phone: '982123456',
          _id: 'rgoKYvfmCxHqTcoeHc6XG',
          id: 'i6ng2sl'
        }
      ],
      address: [
        {
          country: 'DE',
          city: 'Berlin',
          _tags: [],
          _id: 'G3xfKofwAbVEzZfJBglmk',
          postal_code: '12305',
          id: 'pxd8pa0'
        },
        {
          country: 'DE',
          city: 'Stuttgart',
          street: 'Schilfweg',
          street_number: '99',
          _id: 'Q3nzEbG150xzcYltUVCN9',
          postal_code: '70599',
          id: 'rt82pra'
        },
        {
          country: 'DE',
          city: 'Bochum',
          street: 'Herner Str.',
          street_number: '1',
          _id: 'Z5hYGacNOcsH9DmrBHZ-o',
          postal_code: '44787',
          id: 'dqzy6bn'
        },
        {
          country: 'DE',
          city: 'Bochum',
          street: 'Hermann-Hußmann-Str.',
          street_number: '44',
          _id: 'D_YZl9eOO3wU1u4Cywt4c',
          postal_code: '44787'
        },
        {
          country: 'DE',
          city: 'Bochum',
          street: 'Hermann-Hußmann-Str.',
          street_number: '11',
          _id: 'UW65pAxqS7EErwpkfMmTo',
          postal_code: '44787'
        },
        {
          country: 'DE',
          city: 'Bochum',
          street: 'Hermann-Hußmann-Str.',
          street_number: '22',
          _id: '_eSiTZVwGTDLS7FQbyul0',
          postal_code: '44787'
        },
        {
          _id: 'vt6oCGtsEEXyYHwh9Tstn',
          _tags: [],
          street: 'my street',
          zip: '',
          city: 'Koln',
          country: 'DE',
          postal_code: '',
          street_number: '123',
          plot_area: 'PAP',
          plot_of_land: 'PLP',
          additional_info: ''
        }
      ],
      payment: [
        {
          _id: 'qMIxbn6pWlU5XQS6_nhTg',
          _tags: [],
          type: 'payment_invoice',
          id: '3jcwiei'
        },
        {
          _id: '6242d695-cfe6-4824-8392-1bc2109318ff',
          _tags: [],
          type: 'payment_cash',
          id: 'dp2s5xv',
          data: {
            start_date: '2024-12-01',
            end_date: '2025-05-30'
          }
        }
      ],
      files: [
        {
          shared_with_end_customer: true,
          filename: 'energy-logo.png',
          access_control: 'private',
          size_bytes: 5934,
          mime_type: 'image/png',
          readable_size: '5.79 KB',
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/f9d2e9d9-1794-47ea-be29-8f633e5a7263/energy-logo.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA5I42YNG3X2WSLBE3%2F20240411%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20240411T151502Z&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEA8aDGV1LWNlbnRyYWwtMSJHMEUCIQDFidEMA0mrxsypnl%2BfEAYeXbljo5ZP6aM8Mzp4CTOMzQIgcnZn6DolVV2O4zmLdDt03ujh3SHQW1B%2BrH6RDkh9PCgqqAMISBADGgw5MTI0NjgyNDA4MjMiDD8DCDB9cwvfXZoFfyqFA5JrFp%2BOfjxb%2FIjZXzsi8dTm4LW1aFLteCh9BXErcQBhfWzXwzCDPCCpaRJXh2J7o1XA7rKp7uMYLyBY%2Bqr8oaCp%2B26K7dg%2Bib25Yp1PZZ%2BmfXR7ehsGwY9mpD4zLT3hlwXVDKxU%2FK0BzveBZyFDGUwr3Bnqxxt6FgezbQFqWgLZ%2BiJs%2FLk0m9jjYeETSyUAr7%2FOE7MTHfNrxPi1yMyKZ4kMcAbhNZn3OtV%2BN3pnC4EbPl4R4AzdjKC%2BPwYa1X41tuFmT9kjDvjm3fbKU8KwISYVVLw64c2dx6k4APoIg2Zx4XS3rmwjAauX2ZDIfG9sqDOffsQv0UaF7i7JrqEXqXQVN8qOcIdE6Fb7cOgvT2alRAE2Q90kwc3SLrH3uYF0dIiSSLLA0mQ%2FSjp0mzbyfd2jTiXirLkCtU6J2mCaqOhCCQTC4CbOKmbR7JDRmgrmFsGUPim27t8Uv%2FM96yRydR4JwYKM6nLjxr%2F%2FGWfy6GV1YMRzuQO2qaLe%2FF%2FHm6YYes1SkZBEMOn637AGOp0B%2FS7tmuAPcWOT0CzhWCOP%2F1BKivnjdDvYkmU85ptH9VKNxp4ekPSXMlVb8Fzmr9LbZHP0zmf9CUnCGSgsdWhLBe%2F%2F93%2B%2F2cWO%2BNZt5lN0NSTNh6naasLY%2BmxNgXWgGBEQ0wyRNbgE5jpyVIHNPx7QXew3YwKmaF7tLKrgamHQjXwvz9tWBk7AroVd2IJeMGRT0YCe3NUbvs3eLPfPgA%3D%3D&X-Amz-Signature=dbf382c81c6d255af3621afb7583b430da457e1da67f0d71acc1d62e9aece6b0&X-Amz-SignedHeaders=host&response-content-disposition=inline%3B%20filename%20%3D%22energy-logo.png%22',
          versions: [
            {
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/f9d2e9d9-1794-47ea-be29-8f633e5a7263/energy-logo.png'
              },
              shared_with_end_customer: true,
              filename: 'energy-logo.png',
              access_control: 'private',
              size_bytes: 5934,
              mime_type: 'image/png',
              readable_size: '5.79 KB',
              type: 'image',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/f9d2e9d9-1794-47ea-be29-8f633e5a7263/energy-logo.png'
            }
          ],
          _schema: 'file',
          _id: 'c86bb476-1689-42a4-ab09-9259cbe5ef36',
          _org: '739224',
          _created_at: '2023-08-16T10:55:26.064Z',
          _updated_at: '2023-08-18T07:50:21.195Z',
          category: 'unknown',
          _title: 'energy-logo.png'
        },
        {
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/be3b83e3-15fc-4967-92be-dae4b6fa954d/2024-02-07_contact_export.csv'
          },
          shared_with_end_customer: true,
          filename: '2024-02-07_contact_export.csv',
          access_control: 'private',
          size_bytes: 1378,
          mime_type: 'text/csv',
          readable_size: '1.35 KB',
          type: 'spreadsheet',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/be3b83e3-15fc-4967-92be-dae4b6fa954d/2024-02-07_contact_export.csv?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA5I42YNG3RKJSPZVW%2F20240411%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20240411T151501Z&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEA8aDGV1LWNlbnRyYWwtMSJHMEUCIQDm6qkXKCMx6EigMWpiHNGaSgd%2Bab%2FQfuVzJFdmTzF15gIgP3MF1mlAViqdFeHtPBE1VHFwKBXRokT5o7FO3VfH5RAqqAMISBADGgw5MTI0NjgyNDA4MjMiDOtBIhIlmsN48mhyOyqFA8oF88nsXJRzu2f3pu4CxdT5pvRhRzo5zd8sM9rmTLZd4der%2Fu6oaQIXK6I1szpWv4H7dh6hGjCIR0pphjcY4TiN3Vn9apSE2c0L85fmCevgxE27mu3dVNf3TBM9Stj%2FUMUfQXtZgflF0Br%2B7F%2FbXN9gHvD%2FCa3UP76QlwcdD3CK30rxero9Go5dHNut9HTJTlBUdRnOWh2UMIhUhGsvlvaXXxoeED%2FUj0kDt60eRtIrmk21GDhNDQkcbXkh%2FKQr7BJMMlL3BC0Z52E9kr8E00o6Hbd8Svsi3Olo7SVmaVHPUir3IYMjvlK1mtVFudqOZklM6L7wNndCtO0Yb2DZhwCSSGIYqP6K34c3%2B3EEU6UPoYgDvcrYYOpRz%2FZPs5WXnPXx5pXMO7fX0xCKGSVxa7gwHXDuk4zNFwVY1env5cN74XD9o5PqIjgYZIqZxqwhy6XVt0OqyhM97N4BMnNvmp7b64YSjgVSX15WESPjEeSB%2B9y3dVSgC4IN66U2hS7%2FAIaO0Q7TMOn637AGOp0BOsW9VHy3%2BniQq7pEUI3vJ4irXr3VDOo7VLu5j%2Bj1Sdh%2B2kua3yz6geCMCT4hsYyp2H4qN%2F4uek2GpthhPDN09C7TppqTHW%2FvLvS77TOif%2BbBMcAIuMkrcRGDNC%2FJO3G7JzLRw3q2TsRI5NZd0HxOIxeWLSiNUgQhb%2Fn9LlEh0m%2FGeUxOv6tuXZbWXO0pCw1fIEGdI%2BxPUI80eVvGOA%3D%3D&X-Amz-Signature=b8be6a4857528ab1d2831f9fe2013347f00b1c040cdf731b4ade8212c0fbb459&X-Amz-SignedHeaders=host&response-content-disposition=inline%3B%20filename%20%3D%222024-02-07_contact_export.csv%22',
          versions: [
            {
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/be3b83e3-15fc-4967-92be-dae4b6fa954d/2024-02-07_contact_export.csv'
              },
              shared_with_end_customer: true,
              filename: '2024-02-07_contact_export.csv',
              access_control: 'private',
              size_bytes: 1378,
              mime_type: 'text/csv',
              readable_size: '1.35 KB',
              type: 'spreadsheet',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/be3b83e3-15fc-4967-92be-dae4b6fa954d/2024-02-07_contact_export.csv'
            }
          ],
          _schema: 'file',
          _id: 'd9288ccf-7041-478c-8f0f-5e9d815913f0',
          _org: '739224',
          _created_at: '2024-03-25T11:41:41.193Z',
          _updated_at: '2024-03-25T11:41:41.193Z',
          category: 'unknown',
          _title: '2024-02-07_contact_export.csv'
        },
        {
          s3ref: {
            bucket: 'epilot-dev-user-content',
            key: '739224/72e7c695-18ff-4cb8-bce0-84df663087a9/2023-05-23_16h30_11%20(1).mp4'
          },
          shared_with_end_customer: true,
          filename: '2023-05-23_16h30_11 (1).mp4',
          access_control: 'private',
          size_bytes: 971248,
          mime_type: 'video/mp4',
          readable_size: '948.48 KB',
          type: 'video',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/72e7c695-18ff-4cb8-bce0-84df663087a9/2023-05-23_16h30_11%2520%281%29.mp4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA5I42YNG3557P54SJ%2F20240411%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20240411T151501Z&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEA8aDGV1LWNlbnRyYWwtMSJGMEQCIDavfC7yFnKqty8l6cTz2Bz9GroiZtJ3xJ29O3TFFdoQAiB9SMcFITMdaVON%2FI27zK71zZsZJnlqlBu5a%2BV%2FnWZm3yqoAwhIEAMaDDkxMjQ2ODI0MDgyMyIM8YZRSQkQNs6nyBqgKoUDoVpLeobuYkiiY0YgfnKZUPgo7fp9ireI%2BqYRTQbB3E%2BstCuvywJia9xf%2Flljdkn88e22K%2BLqcwoajJhXOlS4LvlNCtIr0Vd8Ka%2FyWYzByWDtVKdXjYe1UYcPkyE9xMEKY3H8WrqV7gXpfG2xocskLTx%2Fn3XiqLD21cn3FFJZ9mqxl3KxKN6rlUZw8yqOoJ9j%2BGuqihpZQJiqPxhcq9uWynEuCHD9W%2BGSsnX4rGQcMpe5RtnUSpXShKrxrvosj1MXrgBWWH4%2BPF9aokkyYpyuhI0r%2BMnIPOz2nhWTpDtvaIsWYosmfbZx7zvIUdguxZES1iEH4gdHFm1zn4oY6OE4LYxitMmc%2FtyBrR8O4EuszjvlhrvGExHqMqNCMytkzK6DTDGki1EWXrQ5q%2F5kBdT49nUVBvJWuei8b5opcm3rfFivNP%2BYr2LkFPp6UUR4BK5iH7pGheogRwuhJ4o1FU2BaqSWroyVtawwGdyEe5qiqSKWyK%2F6LBVcLiKY7c%2FmqOlT%2FXwp4low6frfsAY6ngHy61pszIh8rQdO5phrzYj4MrL%2BkvO9oz6UMYJ2eZpwCFtpjyWMuhry0SF70lpp9qZWkrJtk2QFdgDkAk%2FHwEErdT6gCg6eY%2BMYbtiqUUgguXiCBI9P6SKeiNiLo4YrjxWXirJIYONJ%2FEufG1PagZiTi8SChtiiZLBXpVInZ4zXUyQaOACjg7ewkUXfa2s18FXNxJbWj%2F%2F5zdn8%2BuD8wg%3D%3D&X-Amz-Signature=58cb73f7098be4bcbc338114c5d00b0d5315c6fcc261818fbcd499bd90560ff9&X-Amz-SignedHeaders=host&response-content-disposition=inline%3B%20filename%20%3D%222023-05-23_16h30_11%2520%281%29.mp4%22',
          versions: [
            {
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/72e7c695-18ff-4cb8-bce0-84df663087a9/2023-05-23_16h30_11%20(1).mp4'
              },
              shared_with_end_customer: true,
              filename: '2023-05-23_16h30_11 (1).mp4',
              access_control: 'private',
              size_bytes: 971248,
              mime_type: 'video/mp4',
              readable_size: '948.48 KB',
              type: 'video',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/72e7c695-18ff-4cb8-bce0-84df663087a9/2023-05-23_16h30_11%2520(1).mp4'
            }
          ],
          _schema: 'file',
          _id: 'c36d39eb-6a69-4c76-830a-1717aa81d213',
          _org: '739224',
          _created_at: '2024-03-25T11:10:57.615Z',
          _updated_at: '2024-03-25T11:10:57.615Z',
          category: 'unknown',
          _title: '2023-05-23_16h30_11 (1).mp4'
        },
        {
          category: 'unknown',
          filename: 'Bananas-1.svg',
          size_bytes: 13228,
          mime_type: 'image/svg+xml',
          readable_size: '12.92 KB',
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a9e48f36-2e10-4cbb-b80b-99fc601b29bb/Bananas.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA5I42YNG3X2WSLBE3%2F20240411%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20240411T151501Z&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEA8aDGV1LWNlbnRyYWwtMSJHMEUCIQDFidEMA0mrxsypnl%2BfEAYeXbljo5ZP6aM8Mzp4CTOMzQIgcnZn6DolVV2O4zmLdDt03ujh3SHQW1B%2BrH6RDkh9PCgqqAMISBADGgw5MTI0NjgyNDA4MjMiDD8DCDB9cwvfXZoFfyqFA5JrFp%2BOfjxb%2FIjZXzsi8dTm4LW1aFLteCh9BXErcQBhfWzXwzCDPCCpaRJXh2J7o1XA7rKp7uMYLyBY%2Bqr8oaCp%2B26K7dg%2Bib25Yp1PZZ%2BmfXR7ehsGwY9mpD4zLT3hlwXVDKxU%2FK0BzveBZyFDGUwr3Bnqxxt6FgezbQFqWgLZ%2BiJs%2FLk0m9jjYeETSyUAr7%2FOE7MTHfNrxPi1yMyKZ4kMcAbhNZn3OtV%2BN3pnC4EbPl4R4AzdjKC%2BPwYa1X41tuFmT9kjDvjm3fbKU8KwISYVVLw64c2dx6k4APoIg2Zx4XS3rmwjAauX2ZDIfG9sqDOffsQv0UaF7i7JrqEXqXQVN8qOcIdE6Fb7cOgvT2alRAE2Q90kwc3SLrH3uYF0dIiSSLLA0mQ%2FSjp0mzbyfd2jTiXirLkCtU6J2mCaqOhCCQTC4CbOKmbR7JDRmgrmFsGUPim27t8Uv%2FM96yRydR4JwYKM6nLjxr%2F%2FGWfy6GV1YMRzuQO2qaLe%2FF%2FHm6YYes1SkZBEMOn637AGOp0B%2FS7tmuAPcWOT0CzhWCOP%2F1BKivnjdDvYkmU85ptH9VKNxp4ekPSXMlVb8Fzmr9LbZHP0zmf9CUnCGSgsdWhLBe%2F%2F93%2B%2F2cWO%2BNZt5lN0NSTNh6naasLY%2BmxNgXWgGBEQ0wyRNbgE5jpyVIHNPx7QXew3YwKmaF7tLKrgamHQjXwvz9tWBk7AroVd2IJeMGRT0YCe3NUbvs3eLPfPgA%3D%3D&X-Amz-Signature=de99994625d3340a2cf781b9b997b0c7527cd38df6ea8a58d398062ce3b3b3a6&X-Amz-SignedHeaders=host&response-content-disposition=inline%3B%20filename%20%3D%22Bananas.svg%22',
          versions: [
            {
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/a9e48f36-2e10-4cbb-b80b-99fc601b29bb/Bananas.svg'
              },
              category: 'unknown',
              filename: 'Bananas.svg',
              size_bytes: 13228,
              mime_type: 'image/svg+xml',
              readable_size: '12.92 KB',
              type: 'image',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a9e48f36-2e10-4cbb-b80b-99fc601b29bb/Bananas.svg'
            }
          ],
          _schema: 'file',
          _id: '8ee14141-7635-43c6-8251-eb2d77763cd3',
          _org: '739224',
          _created_at: '2023-08-18T07:57:29.340Z',
          _updated_at: '2024-03-06T11:26:28.232Z',
          access_control: 'private',
          _title: 'Bananas-1.svg',
          shared_with_end_customer: true,
          file_date: '2024-03-27T11:05:00.000Z',
          language: 'de',
          mapped_entities: {
            $relation: [
              {
                entity_id: '465a2a6c-5096-46cd-9d97-2c09faa62f60',
                _tags: []
              },
              {
                entity_id: 'e0214640-e823-4cb0-a633-926379849e40',
                _tags: []
              },
              {
                entity_id: 'c30ce308-60f9-405e-99ab-e2734151a295',
                _tags: []
              },
              {
                entity_id: '4354ca01-bfbf-4d82-9e76-71bb33d9f095',
                _tags: []
              },
              {
                entity_id: '15f34fe2-5659-43c9-a625-c496f61fb2eb',
                _tags: []
              },
              {
                entity_id: '40004def-445c-4569-ad1b-f93b08cb907d',
                _tags: []
              },
              {
                entity_id: 'a3475d8e-8114-46f9-a578-5308aaa9853f',
                _tags: []
              },
              {
                entity_id: 'a7e2582c-4269-45e1-985a-9e248611a459',
                _tags: []
              },
              {
                entity_id: '2bce41a7-96cb-4057-8b2a-a7f3a85d17bf',
                _tags: []
              },
              {
                entity_id: 'f36408ad-806f-4628-9b8f-9a52dbf5eb9c',
                _tags: []
              },
              {
                entity_id: '6584cfcc-3f23-4e20-a5f2-d2b6cdb42de4',
                _tags: []
              },
              {
                entity_id: '2ae9e1fd-0f49-49ab-aced-de392c941dc7',
                _tags: []
              },
              {
                entity_id: '6dcecf58-ac4a-4ee7-9c77-f4aaedebd79e',
                _tags: []
              }
            ]
          }
        }
      ],
      language: 'de',
      full_name: 'Matheus Coelho ff',
      title: 'Dr.',
      customer_number: 'MC001',
      birthdate: '1995-04-18T09:30:00.000Z',
      test_number_attribute: '12',
      _files: {
        $relation: [
          {
            entity_id: 'c86bb476-1689-42a4-ab09-9259cbe5ef36'
          },
          {
            entity_id: '8ee14141-7635-43c6-8251-eb2d77763cd3',
            _tags: [''],
            added_by: {
              org_id: '739224',
              user_id: '10014532'
            }
          },
          {
            entity_id: 'c36d39eb-6a69-4c76-830a-1717aa81d213'
          },
          {
            entity_id: 'd9288ccf-7041-478c-8f0f-5e9d815913f0'
          },
          {
            entity_id: '14f43db9-7720-4cbf-821c-d859bb4fd951'
          },
          {
            entity_id: '9de462c4-69d4-493e-bed1-a3a54202424c'
          },
          {
            entity_id: '58dda62d-af56-4a3d-811f-692bfe1c0bf4'
          }
        ]
      },
      _acl_sync: '2023-10-25T14:16:15.295Z',
      file: {
        $relation: [
          {
            entity_id: 'cfd18f94-1ef1-4359-9599-cf5f668b6c2e'
          }
        ]
      },
      sequence_test: '22',
      _updated_at: '2024-12-11T07:25:56.091Z'
    },
    {
      type: 'product',
      _schema: 'product',
      _title: '[Flat Fee] - Composite Installation',
      name: '[Flat Fee] - Composite Installation',
      active: true,
      price_options: {
        $relation: [
          {
            entity_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
            _tags: []
          },
          {
            entity_id: '5dab027d-086d-412b-b245-17611592e588',
            _tags: []
          },
          {
            entity_id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
            _tags: []
          }
        ]
      },
      _id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
      _org: '739224',
      _created_at: '2023-03-29T11:05:24.738Z',
      _updated_at: '2024-10-21T08:33:56.976Z',
      _availability_files: {
        $relation: []
      },
      _files: {
        $relation: [
          {
            entity_id: '1389c64c-75c2-4a28-92e6-caeaf4e2be23',
            _tags: [''],
            added_by: {
              org_id: '739224',
              user_id: '11000622'
            }
          },
          {
            entity_id: 'ce44d150-fb66-43ac-b2dc-f0109fc606cb',
            _tags: [''],
            added_by: {
              org_id: '739224',
              user_id: '11000622'
            }
          },
          {
            entity_id: '38c70f5c-9bb5-407e-a72d-b225c7254803',
            _tags: [''],
            added_by: {
              org_id: '739224',
              user_id: '10009172'
            }
          },
          {
            entity_id: 'c1e7130e-fdf2-45e4-9694-ac49c3798f20',
            _tags: [''],
            added_by: {
              org_id: '739224',
              user_id: '10009172'
            }
          },
          {
            entity_id: '189f82e0-2385-480e-988a-190547998ac7',
            _tags: [''],
            added_by: {
              org_id: '739224',
              user_id: '10009172'
            }
          },
          {
            entity_id: '28d5049f-48c8-4513-97bf-e6bcfc14031f'
          }
        ]
      },
      internal_name: '[Flat Fee] - Composite Installation',
      _acl_sync: '2024-01-11T17:26:48.409Z',
      product_images: {
        $relation: [
          {
            entity_id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
            _tags: []
          }
        ]
      },
      order_number: 'PRODUCT-1039'
    },
    {
      active: true,
      feature: [],
      internal_name: '[Flat Fee] - Installation',
      name: '[Flat Fee] - Installation',
      price_options: {
        $relation: [
          {
            entity_id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a'
          }
        ]
      },
      type: 'product',
      _schema: 'product',
      _id: '5e42fccd-8f49-4a36-8459-14918d30cf61',
      _org: '739224',
      _created_at: '2024-07-26T14:06:11.533Z',
      _updated_at: '2024-07-26T14:06:11.533Z',
      order_number: 'PRODUCT-1067',
      _title: '[Flat Fee] - Installation'
    },
    {
      _purpose: [],
      _schema: 'product',
      _tags: [],
      active: true,
      feature: [],
      internal_name: '[Flat Fee] - Installation',
      name: '[Flat Fee] - Installation',
      price_options: {
        $relation: [
          {
            entity_id: '8a837980-8d1c-4e1c-a92b-00f0feb94929'
          }
        ]
      },
      type: 'product',
      _acl_sync: '2024-08-23T12:45:21.316Z',
      order_number: 'PRODUCT-1031',
      _id: 'e0ffaab9-3dae-4853-8753-8bf117c6d545',
      _org: '739224',
      _created_at: '2024-08-28T09:09:08.829Z',
      _updated_at: '2024-08-28T09:09:08.829Z',
      _title: '[Flat Fee] - Installation'
    },
    {
      _purpose: [],
      _schema: 'product',
      _tags: [],
      active: true,
      description: 'product description',
      feature: [],
      internal_name: '[Graduated] - Composite',
      name: '[Graduated] - Composite',
      price_options: {
        $relation: [
          {
            entity_id: '4555625e-e408-476e-aa35-0392868ba372'
          }
        ]
      },
      type: 'product',
      order_number: 'PRODUCT-1079',
      _id: 'c9835627-2471-4a51-8344-bea082e7eab2',
      _org: '739224',
      _created_at: '2024-08-27T14:24:56.167Z',
      _updated_at: '2024-08-27T14:24:56.167Z',
      _title: '[Graduated] - Composite'
    }
  ],
  workflow: [],
  journey_actions: [
    {
      journey_id: '4d596c30-e3b0-11ef-8191-3534fb26b0f6',
      action_label: {
        en: 'contract meter',
        de: 'contract meter'
      },
      slug: 'contract'
    },
    {
      journey_id: 'cac19350-b2a0-11ef-849e-ed9de792d714',
      action_label: {
        en: 'General Request',
        de: 'Allgemeine Anfrage'
      },
      slug: 'contract',
      rules: []
    }
  ]
}
