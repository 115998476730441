// import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { AxiosError, AxiosResponse } from 'axios'
import { ReactNode } from 'react'

const CACHE_TIME = window?.AppShell
  ? 1000 * 60 * 60 * 24 // 24 hours
  : 1000 * 60 * 5 // 5 minutes

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      select: (result: AxiosResponse<unknown>) => result.data,
      onError: (result: AxiosError) => result.response,
      retry: (failureCount: number, error: AxiosError) => {
        if (error?.response?.status === 404) return false

        return failureCount < 3
      },
      cacheTime: CACHE_TIME
    }
  }
})

// const persister = createSyncStoragePersister({
//   storage: window.localStorage
// })

export const QueryProvider = ({ children }: { children: ReactNode }) => {
  // TODO: uncomment this after fixing the issue with the persistQueryClientProvider causing issue in mobile app
  // if (window.AppShell) {
  //   return (
  //     <PersistQueryClientProvider
  //       client={queryClient}
  //       persistOptions={{ persister }}
  //     >
  //       {children}
  //     </PersistQueryClientProvider>
  //   )
  // }

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
