import {
  getClient,
  Components,
  type Client
} from '@epilot/customer-portal-client'

import AuthUtils from 'utils/AuthUtils'

import { getConfig } from '../EnvConfig'

let customerApiClient: Client | null = null

export const getCustomerApiClient = () => {
  if (customerApiClient) return customerApiClient

  customerApiClient = getClient()

  customerApiClient.defaults.baseURL = getConfig().CUSTOMER_PORTAL_API_URL

  if (customerApiClient.interceptors.request['handlers'].length > 3) {
    console.error(
      'Sanity check: This client already has more than 3 request interceptors, refusing to add more!',
      {
        baseURL: customerApiClient.defaults.baseURL,
        interceptors: customerApiClient.interceptors
      }
    )
  } else {
    customerApiClient.interceptors.request.use(async (request) => {
      const token: string = await AuthUtils.getAccessToken()

      const portalId: string = AuthUtils.getOrgId()

      const isNotPublicRequest = !request.url.includes('/public')

      if (portalId && isNotPublicRequest) {
        request.headers['x-portal-id'] = portalId
      }

      if (token && isNotPublicRequest) {
        request.headers['authorization'] = `Bearer ${token}`
      }

      return request
    })
  }

  return customerApiClient
}

export const PORTAL_TYPES = {
  endCustomerPortal: 'END_CUSTOMER_PORTAL',
  installerPortal: 'INSTALLER_PORTAL'
}

export type { Components }
