import { Schema } from '@epilot/customer-portal-client'

export const MOCK_SCHEMAS: { schemas: Schema[] } = {
  schemas: [
    {
      id: '00044968-b258-4587-85f9-b7b142a3f0ed',
      blueprint: '0fd09294-068d-4e4e-95f8-4d82b70b5c2c',
      name: 'Opportunity',
      plural: 'Opportunities',
      slug: 'opportunity',
      icon: 'opportunity',
      published: true,
      title_template:
        '{{#if opportunity_title}}{{opportunity_title}}{{else}}{{opportunity_number}}{{/if}}',
      ui_config: {
        table_view: {
          view_type: 'default',
          row_actions: ['view', 'edit', 'duplicate', 'share', 'delete'],
          bulk_actions: ['trigger_automation', 'export', 'send_email', 'delete']
        },
        create_view: {
          view_type: 'default'
        },
        edit_view: {
          view_type: 'default',
          summary_attributes: [
            'opportunity_number',
            '_created_at',
            'customer',
            'delivery_address',
            'billing_address',
            'status',
            'source',
            '_acl.view'
          ],
          ui_elements: []
        },
        list_item: {
          summary_attributes: [
            'opportunity_number',
            '_created_at',
            'customer',
            'delivery_address',
            'billing_address',
            'status'
          ]
        },
        sharing: {
          show_sharing_button: true
        }
      },
      group_settings: [
        {
          _manifest: [
            'f1ae7559-a517-46f8-994d-55de3dfe31ff',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: [],
          expanded: true,
          id: 'Opportunity Details',
          label: 'Opportunity Details',
          order: 10,
          settings_flag: []
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          expanded: false,
          id: 'Address Information',
          label: 'Address Information',
          order: 2,
          settings_flag: []
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            '571b2812-57d0-4cd1-875f-a6c553803ecf',
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '80da3109-c4cb-4de1-83f6-8f025e1465b6'
          ],
          expanded: false,
          id: 'Appointments & Dates',
          label: 'Appointments & Dates',
          order: 4,
          settings_flag: []
        },
        {
          id: 'Payment Details',
          label: 'Payment Details',
          expanded: false,
          order: 3
        },
        {
          id: 'ad81dbcf-ab58-4caf-b5bd-676146dd4c63',
          label: 'UWG',
          expanded: false,
          order: 4,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          order: 5,
          label: 'Consents',
          expanded: false,
          id: 'Consents',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          order: 6,
          label: 'Journey Data',
          expanded: false,
          id: 'Journey Data'
        },
        {
          id: '_ungrouped',
          label: '_ungrouped',
          expanded: false,
          order: 7
        },
        {
          id: '7393c1d1-b5a3-4e04-9d04-0540fefe3393',
          label: 'Zusatzfelder',
          expanded: false,
          order: 8,
          _purpose: ['1893847d-15b9-4109-8491-c3dcf1e14421']
        },
        {
          id: 'bf4e25d6-85b5-4682-8f46-46b8c6cff059',
          label: 'Bestellübersicht',
          expanded: false,
          order: 9
        },
        {
          order: 10,
          label: 'Quotes & Orders',
          id: 'Quotes & Orders'
        },
        {
          id: '9dae05f1-5879-41a4-8948-857ec22b5dee',
          label: 'Warenlager',
          expanded: false,
          order: 11
        },
        {
          _manifest: [
            '95ed0384-6290-4223-9c70-27a3e7d180af',
            '71090dbd-3869-4683-9469-648fbbbf5aed',
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            'e2e7f745-49d4-42d6-9f84-1a0dbc215f3a',
            '3aab6d49-705e-4577-941d-bd284ecd8d1b',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '72947807-0692-4abe-96d2-284469dd0b3a',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          expanded: false,
          label: 'Anmeldung EEG',
          order: 4,
          settings_flag: [],
          id: '0f33e678-c9db-4d0e-a4df-bc0c145d8992'
        },
        {
          _manifest: ['9d026299-e20d-4b40-8c9e-c9f4475a652e'],
          _purpose: [],
          expanded: true,
          id: '4c90fbbb-97e7-4f95-abb5-c7d1868e81dd',
          label: 'Customer Information',
          order: 20,
          settings_flag: []
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          expanded: false,
          id: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          label: 'EDL',
          order: 3,
          settings_flag: []
        },
        {
          id: 'c7c57ebf-c146-4e25-a6e2-96c2c28eb12b',
          label: 'Test',
          expanded: false
        },
        {
          id: '2cb6a4ae-69c4-44d8-a290-ac5a0d605dfc',
          label: 'New Group',
          expanded: false
        },
        {
          id: 'dc712a70-c157-4c89-82ac-493ac9915ea9',
          label: 'New Group With Gas',
          expanded: true,
          _purpose: ['20fe55e1-02ea-48eb-8c77-86fbbb6a182d']
        },
        {
          id: 'c4f5265f-c352-4dda-a723-de8693220513',
          label: 'New Empty group',
          expanded: true
        }
      ],
      attributes: [
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'ca0b993a-2d54-4faa-8812-0c1320af7d06',
            '64511106-1efd-4add-ae04-eb17fa4d641b'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Opportunity Details',
          hidden: false,
          label: 'Opportunity Title',
          layout: 'half_width',
          name: 'opportunity_title',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: false,
          sortable: true,
          type: 'string',
          id: '1b229f5b-4fc3-4fad-ac48-f2fe1d0b08c9'
        },
        {
          label: 'Opportunity Number',
          name: 'opportunity_number',
          type: 'sequence',
          show_in_table: true,
          group: 'Opportunity Details',
          layout: 'half_width',
          readonly: true,
          protected: true,
          prefix: 'PLW-',
          start_number: 100,
          order: 10,
          _purpose: [],
          id: 'ec309f9c-6d42-46b9-a47a-d64eaa8d0261'
        },
        {
          label: 'Due Date',
          name: 'due_date',
          type: 'date',
          show_in_table: false,
          group: 'Opportunity Details',
          layout: 'half_width',
          protected: true,
          order: 12,
          _purpose: [],
          id: 'dced4bbd-9e6d-4d08-a375-085b37b191fb'
        },
        {
          label: 'Assignee',
          name: 'assignee',
          type: 'relation_user',
          show_in_table: true,
          group: 'Opportunity Details',
          layout: 'full_width',
          protected: true,
          order: 0,
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            '1893847d-15b9-4109-8491-c3dcf1e14421',
            'e25ae831-6620-4f88-85df-f3a292aae224'
          ],
          id: '5b4d0682-5140-4c55-9758-3d9716325691'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'ca0b993a-2d54-4faa-8812-0c1320af7d06',
            '64511106-1efd-4add-ae04-eb17fa4d641b'
          ],
          default_value: {
            href: null,
            source_type: 'manual',
            title: 'manual'
          },
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Opportunity Details',
          hidden: false,
          label: 'Source',
          layout: 'one_third_width',
          name: 'source',
          protected: true,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'link',
          id: 'eb6b1dfd-47a6-4224-9ef7-12ee26c60d09'
        },
        {
          label: 'Shared With',
          name: '_acl.view',
          type: 'partner_organisation',
          show_in_table: true,
          group: 'Opportunity Details',
          layout: 'full_width',
          protected: true,
          sortable: false,
          id: 'd9861c01-6220-4033-87cd-154b709fcc1e',
          order: 15,
          _purpose: []
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Opportunity Details',
          hidden: false,
          label: 'Description',
          layout: 'full_width',
          multiline: true,
          name: 'description',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '2a39885c-b927-4d61-b19c-6314000e8d52'
        },
        {
          label: 'Status',
          name: 'status',
          type: 'status',
          options: [
            {
              value: 'open',
              title: 'Open'
            },
            {
              value: 'in_progress',
              title: 'In Progress'
            },
            {
              value: 'closed',
              title: 'Closed'
            },
            {
              value: 'cancelled',
              title: 'Cancelled'
            }
          ],
          show_in_table: true,
          group: 'Opportunity Details',
          layout: 'half_width',
          protected: true,
          order: 16,
          _purpose: [],
          id: '40bd114e-7134-40d2-88c7-a2c75a5f17f2'
        },
        {
          _manifest: ['6cb72012-c8cc-4108-a034-41194e5937a9'],
          _purpose: [],
          actions: [
            {
              action_type: 'create_new',
              new_entity_item: {
                _schema: 'contact'
              }
            },
            {
              action_type: 'create_new',
              new_entity_item: {
                _schema: 'account'
              }
            }
          ],
          allowedSchemas: ['contact', 'account'],
          deprecated: false,
          details_view_mode_enabled: false,
          enable_relation_picker: true,
          enable_relation_tags: true,
          entity_builder_disable_edit: false,
          group: '4c90fbbb-97e7-4f95-abb5-c7d1868e81dd',
          has_primary: true,
          hidden: false,
          label: 'Customer',
          layout: 'full_width',
          name: 'customer',
          protected: true,
          readonly: false,
          relation_type: 'has_many',
          repeatable: true,
          required: false,
          reverse_attributes: {},
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          summary_fields: [],
          type: 'relation',
          id: '4af29797-cd9f-452f-974e-12d856353fc7'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            '1893847d-15b9-4109-8491-c3dcf1e14421'
          ],
          constraints: {},
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Address Information',
          has_primary: false,
          hidden: false,
          label: 'Billing Address',
          layout: 'half_width',
          name: 'billing_address',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: false,
          sortable: true,
          type: 'relation_address',
          id: '2c082a73-fd95-41aa-a158-14e54807c265',
          order: 1,
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ]
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            '1893847d-15b9-4109-8491-c3dcf1e14421'
          ],
          constraints: {},
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Address Information',
          has_primary: false,
          hidden: false,
          label: 'Delivery Address',
          layout: 'half_width',
          name: 'delivery_address',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: false,
          sortable: true,
          type: 'relation_address',
          id: '0719d239-b17f-432d-a701-6f64c716269a',
          order: 2,
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ]
        },
        {
          _manifest: ['9d026299-e20d-4b40-8c9e-c9f4475a652e'],
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            '1893847d-15b9-4109-8491-c3dcf1e14421'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Address Information',
          has_primary: false,
          hidden: false,
          label: 'Additional Addresses',
          layout: 'half_width_stacked',
          name: 'address',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: false,
          sortable: true,
          type: 'relation_address',
          id: '1a765827-54f4-43bc-a2e8-cef919939ee1',
          order: 3,
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ]
        },
        {
          name: 'payment',
          label: 'Payment Details',
          group: 'Opportunity Details',
          type: 'relation_payment_method',
          layout: 'full_width',
          show_in_table: false,
          has_primary: false,
          protected: true,
          _purpose: [],
          id: '94dfb694-d22e-44df-8ad2-d6a5a7046841',
          order: 17
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          hide_label: true,
          label: 'Dates',
          layout: 'full_width',
          name: 'dates',
          order: 0,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: false,
          sortable: true,
          type: 'datetime',
          id: '0634d06a-ceb7-4633-b006-a590dd117aaf'
        },
        {
          _manifest: ['6cb72012-c8cc-4108-a034-41194e5937a9'],
          _purpose: [],
          actions: [],
          allowedSchemas: ['order'],
          deprecated: false,
          details_view_mode_enabled: false,
          enable_relation_picker: false,
          enable_relation_tags: false,
          entity_builder_disable_edit: false,
          group: '_ungrouped',
          hidden: true,
          label: 'Orders',
          name: 'items',
          protected: true,
          readonly: false,
          repeatable: true,
          required: false,
          reverse_attributes: {},
          settings_flag: [],
          show_in_table: false,
          sortable: true,
          summary_fields: [],
          type: 'relation',
          id: 'fdfc8c3c-bc1b-4d9c-a26c-452062cfe8c8'
        },
        {
          group: '9dae05f1-5879-41a4-8948-857ec22b5dee',
          name: 'seriennummer',
          type: 'string',
          label: 'Seriennummer',
          _purpose: [],
          id: '8611eb6f-be3b-4067-8c9b-6595e7ed6bb8'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: false,
          entity_builder_disable_edit: false,
          group: '_ungrouped',
          hidden: true,
          label: 'Products',
          name: 'products',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'relation',
          id: '6ffa2628-3e0e-458c-8fa4-8cd8ac3ed925'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          hidden: true,
          label: 'Purposes',
          name: '_purpose',
          parents: [],
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'purpose',
          id: '4a98df68-fa04-4057-b253-a1d78a6450dc'
        },
        {
          group: '9dae05f1-5879-41a4-8948-857ec22b5dee',
          name: 'versanddatum',
          type: 'date',
          label: 'Versanddatum',
          readonly: false,
          required: false,
          hidden: false,
          multiline: false,
          rows: 2,
          repeatable: false,
          has_primary: false,
          _purpose: [],
          id: '279cb3bf-f745-495d-9b93-4dfd587ceb4f'
        },
        {
          group: '9dae05f1-5879-41a4-8948-857ec22b5dee',
          name: 'menge',
          type: 'string',
          label: 'Menge',
          _purpose: [],
          id: 'c3a59feb-54b0-4133-9456-f0f52782fb4c'
        },
        {
          group: '7393c1d1-b5a3-4e04-9d04-0540fefe3393',
          name: 'sap_geschaeftspartner_carsharing',
          type: 'string',
          id: 'e71d91d7-6f81-4a05-adb4-d8c82576e6c5',
          label: 'SAP Geschäftspartner // Carsharing',
          _purpose: ['1893847d-15b9-4109-8491-c3dcf1e14421']
        },
        {
          group: '7393c1d1-b5a3-4e04-9d04-0540fefe3393',
          name: 'sapauftragsnummer_heizung',
          type: 'string',
          id: '768ea23c-7fd8-4387-b28c-9f5e88b53db5',
          label: 'SAP-Auftragsnummer // Heizung',
          _purpose: ['1893847d-15b9-4109-8491-c3dcf1e14421']
        },
        {
          group: '7393c1d1-b5a3-4e04-9d04-0540fefe3393',
          name: 'new_attribute_1686297679076',
          type: 'string',
          id: 'e84985e3-9034-4c7f-b93a-2b7b0a8bffa2',
          label: 'SAP-Geschäftspartnernummer // Pfalzwerke',
          _purpose: ['1893847d-15b9-4109-8491-c3dcf1e14421']
        },
        {
          group: '7393c1d1-b5a3-4e04-9d04-0540fefe3393',
          name: 'produkt',
          type: 'string',
          id: '9836866e-6ecf-4844-9271-1dd8bfd8d9fb',
          label: 'Produkt',
          _purpose: ['1893847d-15b9-4109-8491-c3dcf1e14421']
        },
        {
          group: '7393c1d1-b5a3-4e04-9d04-0540fefe3393',
          name: 'leistung_pv_anlage_kwp',
          type: 'string',
          id: 'e9ed87db-d7a2-408e-90c3-d5407b637096',
          label: 'Leistung PV Anlage KWp',
          _purpose: ['1893847d-15b9-4109-8491-c3dcf1e14421']
        },
        {
          group: '7393c1d1-b5a3-4e04-9d04-0540fefe3393',
          name: 'e_mail_fachpartner',
          type: 'string',
          id: 'df135b3d-c9ba-4cdd-9476-ef01cd14730d',
          label: 'E Mail Fachpartner',
          _purpose: ['1893847d-15b9-4109-8491-c3dcf1e14421']
        },
        {
          group: '7393c1d1-b5a3-4e04-9d04-0540fefe3393',
          name: 'fachpartner',
          type: 'string',
          id: '9ff11a04-682f-4308-b904-b4a5ea5f7caf',
          label: 'Fachpartner',
          _purpose: ['1893847d-15b9-4109-8491-c3dcf1e14421']
        },
        {
          group: '7393c1d1-b5a3-4e04-9d04-0540fefe3393',
          name: 'vertragslaufzeit_bis',
          type: 'string',
          id: '3d0d1d46-8542-40d9-8583-d9f041bf2c57',
          label: 'Vertragslaufzeit bis',
          _purpose: ['1893847d-15b9-4109-8491-c3dcf1e14421']
        },
        {
          group: '7393c1d1-b5a3-4e04-9d04-0540fefe3393',
          name: 'sapauftragsnummer',
          type: 'string',
          id: 'e08c3f68-90e1-4d23-9b2b-0b1a2a5f2304',
          label: 'SAP-Auftragsnummer',
          _purpose: ['1893847d-15b9-4109-8491-c3dcf1e14421']
        },
        {
          group: 'ad81dbcf-ab58-4caf-b5bd-676146dd4c63',
          name: 'optin_follow_up',
          id: '5f6f801b-1c02-4566-802c-f50b23e3bd45',
          label: 'Opt-In Follow up',
          order: 2,
          display_type: 'checkbox',
          readonly: false,
          required: true,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          default_value: true,
          has_primary: false,
          type: 'boolean',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'ad81dbcf-ab58-4caf-b5bd-676146dd4c63',
          name: 'opt_in_schriftlich',
          id: '5c24cc40-679f-4b06-a4ed-94ff55080173',
          label: 'Opt_In schriftlich',
          order: 4,
          display_type: 'checkbox',
          readonly: false,
          required: true,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          default_value: true,
          has_primary: false,
          type: 'boolean',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Opportunity Details',
          name: 'produktewaermepumpe',
          id: '47b6eb1c-b2a2-4571-aed6-1e5f3dd3cfb8',
          label: 'Produkte',
          order: 2,
          allow_extra_options: true,
          readonly: false,
          required: true,
          hidden: false,
          hintHelperText: '',
          type: 'multiselect',
          options: ['Vaillant', 'Viessmann', 'Elco', 'Tecalor'],
          layout: 'one_third_width',
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            'e25ae831-6620-4f88-85df-f3a292aae224'
          ]
        },
        {
          group: 'Opportunity Details',
          name: 'enddatum1',
          id: '8d939835-863f-4c83-aebd-568265c7d422',
          label: 'Enddatum',
          order: 7,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'datetime',
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            '1893847d-15b9-4109-8491-c3dcf1e14421',
            'e25ae831-6620-4f88-85df-f3a292aae224'
          ]
        },
        {
          group: 'Opportunity Details',
          name: 'startdatum2',
          id: '4c35824f-a88c-4be9-af66-a6092f53ce4f',
          label: 'Abrechnung Startdatum',
          order: 6,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'datetime',
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            '1893847d-15b9-4109-8491-c3dcf1e14421',
            'e25ae831-6620-4f88-85df-f3a292aae224'
          ]
        },
        {
          group: 'Opportunity Details',
          name: 'produkttyp',
          id: '6a944345-46ee-40cb-addf-53724433f6a4',
          label: 'Abrechnungstyp',
          order: 1,
          allow_extra_options: false,
          readonly: false,
          required: true,
          hidden: false,
          hintHelperText: '',
          type: 'multiselect',
          options: ['Direktkauf', 'Contracting'],
          layout: 'one_third_width',
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            'e25ae831-6620-4f88-85df-f3a292aae224'
          ]
        },
        {
          group: 'ad81dbcf-ab58-4caf-b5bd-676146dd4c63',
          name: 'optin_abgelehnt',
          id: '1e2740f4-6de4-41ee-b1af-51f3345bc4af',
          label: 'Opt-In abgelehnt',
          order: 6,
          display_type: 'checkbox',
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'boolean',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Opportunity Details',
          name: 'angebotsnummer',
          type: 'string',
          id: '53e9d2e8-73ec-42e9-946c-0a4e84f3db1a',
          label: 'Angebotsnummer',
          order: 4,
          layout: 'half_width',
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            'e25ae831-6620-4f88-85df-f3a292aae224'
          ]
        },
        {
          group: 'Opportunity Details',
          name: 'zeichen',
          type: 'string',
          id: '3f32c570-70d5-4a72-a5d0-ea76d6a9ca61',
          label: 'Kunden-Nr.',
          order: 5,
          required: true,
          layout: 'half_width',
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            'e25ae831-6620-4f88-85df-f3a292aae224'
          ]
        },
        {
          group: 'Appointments & Dates',
          name: '2_anruf_datum',
          id: '8534be7d-5599-4a26-97b1-2b309d32b6dc',
          label: '1. Anruf Datum',
          order: 4,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'date',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '2_anruf_notiz',
          type: 'string',
          id: '4af8a249-1ed4-493c-8fc0-02e9cb60e9b2',
          label: '1. Anruf Notiz',
          order: 6,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '3_anruf_datum',
          id: '7b9a4c7e-534d-4f87-83eb-9e22bf69a937',
          label: '2. Anruf Datum',
          order: 8,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'date',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '3_anruf_notiz',
          type: 'string',
          id: 'c631718e-1ecd-4ee4-b796-1348dc14cd7d',
          label: '2. Anruf Notiz',
          order: 10,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '4_anruf_datum',
          id: 'cd8db8a0-1f52-4397-b179-27a94416f5b8',
          label: '3. Anruf Datum',
          order: 12,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'date',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '4_anruf_notiz',
          type: 'string',
          id: '5906d319-0ea8-4a72-8593-b76c3fe2e168',
          label: '3. Anruf Notiz',
          order: 14,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '5_anruf_datum',
          id: 'bfdbb3db-8893-4986-a73c-2137079a14d1',
          label: '4. Anruf Datum',
          order: 16,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'date',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '5_anruf_notiz',
          type: 'string',
          id: 'be54d084-271e-4976-8735-a03e92f7fc8e',
          label: '4. Anruf Notiz',
          order: 18,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Opportunity Details',
          name: 'installationsdatum_waermepumpe',
          id: 'b91d3c48-7b73-46ac-a292-c21a37ab40c8',
          label: 'Installationsdatum Wärmepumpe',
          order: 8,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'date',
          _purpose: [
            'eb5b5331-09ec-4dac-ad05-cf02d2ef0206',
            '1893847d-15b9-4109-8491-c3dcf1e14421',
            'e25ae831-6620-4f88-85df-f3a292aae224'
          ]
        },
        {
          group: 'Appointments & Dates',
          name: '_anruf_datum5',
          id: 'd0ceddef-271a-43a1-9061-c3056c9529b0',
          label: '5. Anruf Datum',
          order: 20,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'date',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '5_anruf_notiz5',
          type: 'string',
          id: '3d974834-4c12-479d-b6b5-3e260b4736ab',
          label: '5. Anruf Notiz',
          order: 22,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '6_anruf_datum',
          id: 'a492301d-0c69-45f6-9ee2-1fd52be9c7cf',
          label: '6. Anruf Datum',
          order: 24,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'date',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '6_anruf_notiz',
          type: 'string',
          id: 'b2fad43e-e28f-4dd0-a662-9c7b7a344dc2',
          label: '6. Anruf Notiz',
          order: 26,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '7_anruf_datum',
          id: 'a22ff288-f8a3-4a69-bdc9-8067a2bdcf20',
          label: '7. Anruf Datum',
          order: 28,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'date',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '7_anruf_notiz',
          type: 'string',
          id: 'd8ade4de-02b9-49b2-8511-c5a9beda44ac',
          label: '7. Anruf Notiz',
          order: 30,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '8_anruf_datum',
          type: 'string',
          id: '09a93ec2-68d5-45e8-9da2-69df093763ea',
          label: '8. Anruf Datum',
          order: 32,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '8_anruf_notiz',
          type: 'string',
          id: '5e864e06-927e-458b-acb9-524b08da402f',
          label: '8. Anruf Notiz',
          order: 34,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '9_anruf_datum',
          id: '44876ed1-adaf-40e7-b4f0-946a2b2e2019',
          label: '9. Anruf Datum',
          order: 36,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'date',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '9_anruf_notiz',
          type: 'string',
          id: 'df0ac82b-38ec-443c-b96a-87b4c2343d80',
          label: '9. Anruf Notiz',
          order: 38,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '10_anruf_notiz',
          id: '2e0efe47-0a63-4355-95f7-77b427d62967',
          label: '10. Anruf Notiz',
          order: 40,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'date',
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Appointments & Dates',
          name: '10_anruf_notiz10',
          type: 'string',
          id: '19fa7316-f494-4be6-bc16-ffaf9da38698',
          label: '10. Anruf Notiz',
          order: 42,
          _purpose: ['eb5b5331-09ec-4dac-ad05-cf02d2ef0206']
        },
        {
          group: 'Opportunity Details',
          name: 'interessent',
          id: '1c1c2b33-1a05-440e-a8e6-77163f017b47',
          label: 'Interessent',
          order: 9,
          allow_extra_options: true,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          type: 'multiselect',
          options: ['Interessent', 'Kunde'],
          _purpose: ['e25ae831-6620-4f88-85df-f3a292aae224']
        },
        {
          group: 'Opportunity Details',
          name: 'preis',
          type: 'string',
          id: '11939a9a-420d-4353-a1a4-9b95ecac272b',
          label: 'Preis',
          order: 3,
          layout: 'one_third_width',
          _purpose: ['e25ae831-6620-4f88-85df-f3a292aae224']
        },
        {
          group: 'Opportunity Details',
          name: 'status2',
          id: 'f0f15635-ea2a-4a87-b37a-d7aa2bf4a3bf',
          label: 'Status2',
          order: 20,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'status'
        },
        {
          group: 'Opportunity Details',
          name: 'prozessphase_offengeschlossen',
          id: 'de0fb7d2-c404-4b76-8ad1-37d53e5befa8',
          label: 'Prozessphase offen/geschlossen',
          order: 22,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'status'
        },
        {
          group: 'Opportunity Details',
          name: 'prozessphase_2',
          id: '2bafa4db-7719-4898-885f-6215b3917262',
          label: 'Prozessphase 2',
          order: 24,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'status'
        },
        {
          group: 'Opportunity Details',
          name: 'reptest',
          type: 'string',
          id: '32030b03-5eea-4d1d-ac8d-b08683c4435d',
          label: 'rep-test',
          order: 26,
          repeatable: true
        },
        {
          _manifest: [
            '1b449ada-4afb-4144-924b-99d9ae071431',
            '6f85a07e-8e60-4e79-b6b0-4f3988d4a871',
            '5e2039da-2364-42d8-bf52-a8e6f1391a17',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Modulleistung 1 in Watt',
          name: 'modulleistung_in_watt',
          order: 13,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '9b353e64-26c8-4ea8-9bbb-6a5c7a85c811'
        },
        {
          _manifest: [
            'd2b07dc2-18d0-424a-a62e-7290327f4272',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            'f2df6585-27ee-4001-bb11-2fe434f75af9',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Typ',
          name: 'typ_generator',
          order: 39,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '533e7403-daf3-4b74-892c-52867061851a'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '72947807-0692-4abe-96d2-284469dd0b3a',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Anzahl Module 1',
          name: 'anzahl_module',
          order: 14,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'ee7b617f-0ed2-47b4-ab74-fc4a01e6e1a0'
        },
        {
          _manifest: [
            '95ed0384-6290-4223-9c70-27a3e7d180af',
            '71090dbd-3869-4683-9469-648fbbbf5aed',
            '02a18dcc-99bd-4bd2-9776-848d60210366',
            '6698f67b-92f6-46be-9713-c2e413063d17',
            'b38ab593-de57-413a-8d67-5341846fd2d3',
            '7f174858-59e8-44c6-a7dc-606ef115a550',
            'fdde66ab-bb02-4238-ac36-f304d39d461f',
            'f2df6585-27ee-4001-bb11-2fe434f75af9',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Scheinleistung Generator (kVA) 2',
          name: 'scheinleistung_in_kva_generator_2',
          order: 46,
          readonly: false,
          render_condition: 'weiterer_generator = "true"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'ee423a08-4673-437c-b80a-5f6946946e07'
        },
        {
          _manifest: [
            '016f0572-a1a9-43c4-b2de-c1056ebfcc09',
            '92c49a97-5a16-4eb5-aad9-cade502986ae',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            'fb691f2a-f9e2-4871-a9c8-6cfcd46da500',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Anzahl Wechselrichter 2',
          name: 'anzahl_wechselrichter_2',
          order: 31,
          readonly: false,
          render_condition: 'weitere_wechselrichter = "true"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'aee78fef-479a-4d6e-b29f-b49bce00feeb'
        },
        {
          _manifest: [
            '587a46b6-98f2-41da-84df-605dbe5e163a',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '4863581e-82ab-44e7-94b2-744f1bc8056f',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '8a109ffc-15b8-47fc-9841-1d1b52fe2bf6',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Vorhandene Leistung KWK (kVA)',
          name: 'vorhandene_leistung_kwk',
          order: 7,
          readonly: false,
          render_condition: 'weitere_anlagen_auf_dem_gruendstueck = "Ja"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'daa40bdd-bede-4e25-a227-713ce7614bbe'
        },
        {
          _manifest: [
            'd2b07dc2-18d0-424a-a62e-7290327f4272',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'fa15e862-ce12-4e48-a27a-702c85db55af',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Vorhandene Leistung PV (kWp)',
          layout: 'one_third_width',
          name: 'vorhandene_leistung',
          order: 6,
          readonly: false,
          render_condition: 'weitere_anlagen_auf_dem_gruendstueck = "Ja"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'de93c0df-f5e7-4f15-95c1-ba75e93dfb60'
        },
        {
          _manifest: [
            '016f0572-a1a9-43c4-b2de-c1056ebfcc09',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Leistungsaufnahme in kVA',
          name: 'leistungsaufnahme_in_kva_speicher',
          order: 53,
          readonly: false,
          render_condition: 'speicher_eeg_anmeldung = "Ja"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'dd035b22-4b12-4613-b78c-730fb1f81a28'
        },
        {
          _manifest: [
            '15bb4d3c-c19b-41d3-9f21-8fca347c4e61',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'fa15e862-ce12-4e48-a27a-702c85db55af',
            'c7d3c7d6-aafc-48bf-9100-2e45158eaff9',
            '72947807-0692-4abe-96d2-284469dd0b3a',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Anzahl Modul 2',
          name: 'anzahl_modul_2_pv',
          order: 19,
          readonly: false,
          render_condition: 'andere_modultypen_pv = "true"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'd8134d7e-98e7-4e4b-8f9b-2c173fc409cd'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'd2b07dc2-18d0-424a-a62e-7290327f4272',
            '84822596-7b3e-4f62-b9a3-362c2e19f5fa',
            '4fd19fb0-ae4c-490b-84d9-983bff86dca8',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            '6698f67b-92f6-46be-9713-c2e413063d17',
            '8e058ffe-b93b-4a21-90de-1632df0f1855',
            '7aab8de4-6e3e-4a8c-9ca6-dc6fa257fefa',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '2564e77b-1965-4e89-8781-946ce731a4d9',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Betreiber ist auch Grundstückseigentümer',
          name: 'betreiber_ist_auch_grundstueckseigentuemer',
          order: 1,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'boolean',
          id: 'e96902f6-d0fe-47d7-863f-168f9dd32942'
        },
        {
          _manifest: [
            '4bf50498-923b-4266-8a52-7b04b34615ee',
            '71090dbd-3869-4683-9469-648fbbbf5aed',
            'a63fc401-1c7a-413d-8dd7-e8d3476817d8',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '7f174858-59e8-44c6-a7dc-606ef115a550',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Wirkleistung Generator (kW)',
          name: 'leistung_in_kw_generator',
          order: 41,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '11cf3146-4357-4246-8f60-fd2f0cd2501a'
        },
        {
          _manifest: [
            '587a46b6-98f2-41da-84df-605dbe5e163a',
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '02a18dcc-99bd-4bd2-9776-848d60210366',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            'e2e7f745-49d4-42d6-9f84-1a0dbc215f3a',
            '15bb4d3c-c19b-41d3-9f21-8fca347c4e61',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '4863581e-82ab-44e7-94b2-744f1bc8056f',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'fb691f2a-f9e2-4871-a9c8-6cfcd46da500',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Phasig 2',
          name: 'phasig_2',
          options: ['1-Phasig', '3-Phasig'],
          order: 32,
          readonly: false,
          render_condition: 'weitere_wechselrichter = "true"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '16383ae5-5344-40f0-a551-73de22ef0076'
        },
        {
          _manifest: [
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '92c49a97-5a16-4eb5-aad9-cade502986ae',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '2564e77b-1965-4e89-8781-946ce731a4d9',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'fa15e862-ce12-4e48-a27a-702c85db55af',
            'c7d3c7d6-aafc-48bf-9100-2e45158eaff9',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Typ Modul 1',
          name: 'typ_modul_pv',
          order: 12,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'bd89b053-1cea-4570-891b-28c4a01c63f2'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '2564e77b-1965-4e89-8781-946ce731a4d9',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'fb691f2a-f9e2-4871-a9c8-6cfcd46da500',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Bonus',
          name: 'bonus',
          options: [
            'Bonus für die Volleinspeisung für das aktuelle Kalenderjahr ',
            'Bonus für die Volleinspeisung für den gesamten Vergütungszeitraum',
            'Bonus für die Volleinspeisung für bestimmte Kalenderjahre'
          ],
          order: 60,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'db40a5dd-46a3-49f9-bdc6-64eb7e05cbb1'
        },
        {
          _manifest: [
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            'e2e7f745-49d4-42d6-9f84-1a0dbc215f3a',
            '1048f6df-4684-4128-ab91-a4b1326eba43',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          info_helpers: {
            hint_text: 'Gesamtleistung Anlage in kWp'
          },
          label: 'Gesamtleistung PV-Anlage (kWp)',
          layout: 'full_width',
          name: 'gesamtleistung_pv_module',
          order: 20,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '0c313d04-30aa-4307-b0aa-c7d9f8301b91'
        },
        {
          _manifest: [
            '71090dbd-3869-4683-9469-648fbbbf5aed',
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Hersteller 2',
          name: 'hersteller_generator_2',
          order: 44,
          readonly: false,
          render_condition: 'weiterer_generator = "true"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '21cf356b-1420-4f72-9976-14d64ecacb2e'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'f28326e1-288d-4fe4-be77-01dcd662b428',
            'be36a3fc-dda9-4590-9e4f-309662a54e48',
            '6f85a07e-8e60-4e79-b6b0-4f3988d4a871',
            '3aab6d49-705e-4577-941d-bd284ecd8d1b',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Einspeisungsart',
          name: 'einspeisungsart',
          options: ['Volleinspeisung', 'Überschusseinspeisung'],
          order: 58,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'd17492ce-b145-4435-96bf-0bedc7f4a45b'
        },
        {
          _manifest: [
            'cf30181d-2ba1-4572-96da-972c0704a8f7',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Mieterstromzuschlag',
          name: 'mieterstromzuschlag',
          order: 36,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'boolean',
          id: '51d0164b-c38a-45b1-993e-b0ae02a80da6'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            '8e058ffe-b93b-4a21-90de-1632df0f1855',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '8a109ffc-15b8-47fc-9841-1d1b52fe2bf6',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Bauart 2',
          name: 'bauart_generator_2',
          options: ['Synchron', 'Asynchron'],
          order: 48,
          readonly: false,
          render_condition: 'weiterer_generator = "true"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '8cad3cab-8ecb-4a74-86c7-4b85f8f0fe2f'
        },
        {
          _manifest: [
            '84822596-7b3e-4f62-b9a3-362c2e19f5fa',
            'a63fc401-1c7a-413d-8dd7-e8d3476817d8',
            '9c391dee-05b2-4d44-82c5-af49561b489f',
            '15bb4d3c-c19b-41d3-9f21-8fca347c4e61',
            '5e2039da-2364-42d8-bf52-a8e6f1391a17',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Anlagentyp',
          name: 'anlagentyp',
          options: ['Photovoltaik-Anlage', 'KWK-Anlage'],
          order: 8,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'c0eddf4c-34f9-43b6-82ab-87145878bcce'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            'b8849410-f85f-4919-9028-7b3f57dddfd9',
            '1048f6df-4684-4128-ab91-a4b1326eba43',
            '5e2039da-2364-42d8-bf52-a8e6f1391a17',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '8a109ffc-15b8-47fc-9841-1d1b52fe2bf6',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'c7d3c7d6-aafc-48bf-9100-2e45158eaff9',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Hersteller Modul 2',
          name: 'hersteller_modul_2_pv',
          order: 16,
          readonly: false,
          render_condition: 'andere_modultypen_pv = "true"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '416cc161-e8a4-4256-aedd-977d498cdebf'
        },
        {
          _manifest: [
            'cf30181d-2ba1-4572-96da-972c0704a8f7',
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'e9f3bb0d-9c52-41d7-bc11-ebbe4880d598',
            '5342a214-a0de-42c8-aede-78aac35524ec',
            '50a60245-85f7-4f50-ba59-f0cb8b19792c',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '7aab8de4-6e3e-4a8c-9ca6-dc6fa257fefa',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          info_helpers: {},
          label: 'Scheinleistung Generator (kVA) ',
          name: 'scheinleistung_in_kva_generator',
          order: 40,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'e12b260b-3f0f-4d88-86a3-25254599c754'
        },
        {
          _manifest: [
            '71090dbd-3869-4683-9469-648fbbbf5aed',
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            '1b449ada-4afb-4144-924b-99d9ae071431',
            'e2e7f745-49d4-42d6-9f84-1a0dbc215f3a',
            '3aab6d49-705e-4577-941d-bd284ecd8d1b',
            'b38ab593-de57-413a-8d67-5341846fd2d3',
            'fdde66ab-bb02-4238-ac36-f304d39d461f',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '2564e77b-1965-4e89-8781-946ce731a4d9',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Speicher',
          name: 'speicher_eeg_anmeldung',
          options: ['Ja', 'Nein'],
          order: 50,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'ac8a6762-b07f-490f-b5c6-71992b10ae00'
        },
        {
          _manifest: [
            'cf30181d-2ba1-4572-96da-972c0704a8f7',
            '71090dbd-3869-4683-9469-648fbbbf5aed',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Kapazität in kWh',
          name: 'kapazitaet_in_kwh_speicher',
          order: 55,
          readonly: false,
          render_condition: 'speicher_eeg_anmeldung = "Ja"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'dc96f729-4391-4b80-a3d4-2f5f263ec157'
        },
        {
          _manifest: [
            '84822596-7b3e-4f62-b9a3-362c2e19f5fa',
            '02a18dcc-99bd-4bd2-9776-848d60210366',
            '6698f67b-92f6-46be-9713-c2e413063d17',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Anzahl',
          name: 'anzahl_speicher',
          order: 56,
          readonly: false,
          render_condition: 'speicher_eeg_anmeldung = "Ja"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '18cbb37c-ccf8-476c-9028-1cf7f13549a6'
        },
        {
          _manifest: [
            '5342a214-a0de-42c8-aede-78aac35524ec',
            '02a18dcc-99bd-4bd2-9776-848d60210366',
            '15bb4d3c-c19b-41d3-9f21-8fca347c4e61',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '8a109ffc-15b8-47fc-9841-1d1b52fe2bf6',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Typ 2',
          name: 'typ_generator_2',
          order: 45,
          readonly: false,
          render_condition: 'weiterer_generator = "true"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'e0803884-2f2d-479b-ab1c-c1c466abc120'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            '50a60245-85f7-4f50-ba59-f0cb8b19792c',
            'a63fc401-1c7a-413d-8dd7-e8d3476817d8',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            '3aab6d49-705e-4577-941d-bd284ecd8d1b',
            '7f174858-59e8-44c6-a7dc-606ef115a550',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Hersteller Modul 1',
          name: 'hersteller_modul_pv',
          order: 11,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'a621673c-144f-438c-b080-34a5a2558813'
        },
        {
          _manifest: [
            '4bf50498-923b-4266-8a52-7b04b34615ee',
            '71090dbd-3869-4683-9469-648fbbbf5aed',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '6698f67b-92f6-46be-9713-c2e413063d17',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Typ Wechselrichter PV 2',
          name: 'typ_wechselrichter_pv_2',
          order: 29,
          readonly: false,
          render_condition: 'weitere_wechselrichter = "true"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'fc1cef5f-206b-4767-b3e2-577b9abdffdb'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            '50a60245-85f7-4f50-ba59-f0cb8b19792c',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '3aab6d49-705e-4577-941d-bd284ecd8d1b',
            '5e2039da-2364-42d8-bf52-a8e6f1391a17',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '4863581e-82ab-44e7-94b2-744f1bc8056f',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'd09fa6ec-4635-4080-bf7a-93ce1ab0ddc1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Modulleistung 2 in Watt',
          name: 'modulleistung_2_in_watt_pv',
          order: 18,
          readonly: false,
          render_condition: 'andere_modultypen_pv = "true"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '865e63ad-60f5-470b-902a-01f9a045bd21'
        },
        {
          _manifest: [
            '95ed0384-6290-4223-9c70-27a3e7d180af',
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'be36a3fc-dda9-4590-9e4f-309662a54e48',
            '6f85a07e-8e60-4e79-b6b0-4f3988d4a871',
            '1048f6df-4684-4128-ab91-a4b1326eba43',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'fa15e862-ce12-4e48-a27a-702c85db55af',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Mieterstrom',
          name: 'mieterstrom',
          options: ['Ja', 'Nein'],
          order: 35,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'ef17cb65-1c7e-4651-8e81-780194abdfd2'
        },
        {
          _manifest: [
            '5342a214-a0de-42c8-aede-78aac35524ec',
            'f2df6585-27ee-4001-bb11-2fe434f75af9',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'weitere Modultypen',
          layout: 'full_width',
          name: 'andere_modultypen_pv',
          order: 15,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'boolean',
          id: '5abf4b55-40da-424b-8804-9ce2c4d0fa43'
        },
        {
          _manifest: [
            'f28326e1-288d-4fe4-be77-01dcd662b428',
            '4fd19fb0-ae4c-490b-84d9-983bff86dca8',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            'b8849410-f85f-4919-9028-7b3f57dddfd9',
            '3aab6d49-705e-4577-941d-bd284ecd8d1b',
            'b38ab593-de57-413a-8d67-5341846fd2d3',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '9ceb1e72-879d-47ae-929d-f696237eb146',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'fa15e862-ce12-4e48-a27a-702c85db55af',
            'c7d3c7d6-aafc-48bf-9100-2e45158eaff9',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Hersteller Wechselrichter PV 1',
          name: 'hersteller_wechselrichter_pv_1',
          order: 22,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '15170850-bba3-44c6-8690-49996653aff6',
          layout: 'one_third_width'
        },
        {
          _manifest: [
            'e9f3bb0d-9c52-41d7-bc11-ebbe4880d598',
            '1b449ada-4afb-4144-924b-99d9ae071431',
            'f28326e1-288d-4fe4-be77-01dcd662b428',
            'be36a3fc-dda9-4590-9e4f-309662a54e48',
            '6f85a07e-8e60-4e79-b6b0-4f3988d4a871',
            '9c391dee-05b2-4d44-82c5-af49561b489f',
            '7aab8de4-6e3e-4a8c-9ca6-dc6fa257fefa',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Erstzuordnung',
          name: 'erstzuordnung_pv',
          options: [
            'Erstzuordnung von Neuanlagen in die Einspeisevergütung',
            'Erstzuordnung von Neuanlagen in die Marktprämie oder sonstige geförderte Direktvermarktung'
          ],
          order: 34,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '32dc3cef-70fe-416e-ad5b-29019d9d89d2'
        },
        {
          _manifest: [
            '50a60245-85f7-4f50-ba59-f0cb8b19792c',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '4863581e-82ab-44e7-94b2-744f1bc8056f',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '72947807-0692-4abe-96d2-284469dd0b3a',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Bauart',
          name: 'bauart_generator',
          options: ['Synchron', 'Asynchron'],
          order: 42,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '57255bed-63c6-4430-9224-bb1aac3234cc'
        },
        {
          _manifest: [
            '95ed0384-6290-4223-9c70-27a3e7d180af',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            'd09fa6ec-4635-4080-bf7a-93ce1ab0ddc1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'weitere Wechselrichter',
          layout: 'full_width',
          name: 'weitere_wechselrichter',
          order: 27,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'boolean',
          id: '87cd1b3c-f5a1-4481-8097-17a590820ef7'
        },
        {
          _manifest: [
            '587a46b6-98f2-41da-84df-605dbe5e163a',
            '4fd19fb0-ae4c-490b-84d9-983bff86dca8',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            '6698f67b-92f6-46be-9713-c2e413063d17',
            '1048f6df-4684-4128-ab91-a4b1326eba43',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '72947807-0692-4abe-96d2-284469dd0b3a',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Typ Wechselrichter PV 1',
          name: 'typ_wechselrichter_pv_1',
          order: 23,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '88301dd8-1903-4185-8d56-e51567aeca6e',
          layout: 'one_third_width'
        },
        {
          _manifest: [
            'cf30181d-2ba1-4572-96da-972c0704a8f7',
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'f28326e1-288d-4fe4-be77-01dcd662b428',
            'b38ab593-de57-413a-8d67-5341846fd2d3',
            'fdde66ab-bb02-4238-ac36-f304d39d461f',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '72947807-0692-4abe-96d2-284469dd0b3a',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          allow_extra_options: false,
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Verbrauchsanlagen im Einsatz',
          name: 'verbrauchsanlagen_im_einsatz',
          options: ['Ladesäule', 'Wärmepumpe', 'Speicher'],
          order: 59,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'multiselect',
          id: 'bf0bd093-4f7a-44a7-b45d-5f0452b2ee8c'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            '1b449ada-4afb-4144-924b-99d9ae071431',
            'd2b07dc2-18d0-424a-a62e-7290327f4272',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '1f8bd771-4c12-4679-b039-c75eefe33594',
            '92c49a97-5a16-4eb5-aad9-cade502986ae',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Anzahl Wechselrichter 1',
          name: 'anzahl_wechselrichter_1',
          order: 25,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '2dfb94aa-2356-4ec5-827d-1868f63f8820'
        },
        {
          _manifest: [
            '4bf50498-923b-4266-8a52-7b04b34615ee',
            '587a46b6-98f2-41da-84df-605dbe5e163a',
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'b38ab593-de57-413a-8d67-5341846fd2d3',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '8a109ffc-15b8-47fc-9841-1d1b52fe2bf6',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Hersteller',
          name: 'hersteller_generator',
          order: 38,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'bc67dde8-11a1-486c-a1fe-56fbbe643f27'
        },
        {
          _manifest: [
            'cf30181d-2ba1-4572-96da-972c0704a8f7',
            '016f0572-a1a9-43c4-b2de-c1056ebfcc09',
            'b8849410-f85f-4919-9028-7b3f57dddfd9',
            '92c49a97-5a16-4eb5-aad9-cade502986ae',
            '7f174858-59e8-44c6-a7dc-606ef115a550',
            'f2df6585-27ee-4001-bb11-2fe434f75af9',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '72947807-0692-4abe-96d2-284469dd0b3a',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Hersteller',
          name: 'hersteller_speicher',
          order: 51,
          readonly: false,
          render_condition: 'speicher_eeg_anmeldung = "Ja"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '8baaf3ac-a9a3-4945-9423-59cc1a349fb0'
        },
        {
          _manifest: [
            '016f0572-a1a9-43c4-b2de-c1056ebfcc09',
            'e9f3bb0d-9c52-41d7-bc11-ebbe4880d598',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            'fdde66ab-bb02-4238-ac36-f304d39d461f',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'fa15e862-ce12-4e48-a27a-702c85db55af',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '72947807-0692-4abe-96d2-284469dd0b3a',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Weitere Anlagen auf dem Gründstück',
          layout: 'one_third_width',
          name: 'weitere_anlagen_auf_dem_gruendstueck',
          options: ['Ja', 'Nein'],
          order: 5,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '9e7c2ad3-d5ec-4562-aad2-8f6c43d3311b'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'f28326e1-288d-4fe4-be77-01dcd662b428',
            'a63fc401-1c7a-413d-8dd7-e8d3476817d8',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            '8e058ffe-b93b-4a21-90de-1632df0f1855',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Hersteller Wechselrichter PV 2',
          name: 'hersteller_wechselrichter_pv_2',
          order: 28,
          readonly: false,
          render_condition: 'weitere_wechselrichter = "true"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'c1c3537b-d84c-4c5a-bf0a-dac415854476'
        },
        {
          _manifest: [
            '4bf50498-923b-4266-8a52-7b04b34615ee',
            '016f0572-a1a9-43c4-b2de-c1056ebfcc09',
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Wirkleistung Generator (kW) 2',
          name: 'leistung_in_kw_generator_2',
          order: 47,
          readonly: false,
          render_condition: 'weiterer_generator = "true"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '4ed2f1d2-19d6-4018-97f0-f7840598380e'
        },
        {
          _manifest: [
            '95ed0384-6290-4223-9c70-27a3e7d180af',
            '4bf50498-923b-4266-8a52-7b04b34615ee',
            '71090dbd-3869-4683-9469-648fbbbf5aed',
            '50a60245-85f7-4f50-ba59-f0cb8b19792c',
            '92c49a97-5a16-4eb5-aad9-cade502986ae',
            'e2e7f745-49d4-42d6-9f84-1a0dbc215f3a',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '72947807-0692-4abe-96d2-284469dd0b3a',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Wechselrichterleistung 2',
          name: 'wechselrichterleistung_2',
          order: 30,
          readonly: false,
          render_condition: 'weitere_wechselrichter = "true"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'c7c7440d-8b66-4886-a4f8-ec055eda76ac'
        },
        {
          _manifest: [
            '95ed0384-6290-4223-9c70-27a3e7d180af',
            '71090dbd-3869-4683-9469-648fbbbf5aed',
            'e9f3bb0d-9c52-41d7-bc11-ebbe4880d598',
            '5342a214-a0de-42c8-aede-78aac35524ec',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            'fdde66ab-bb02-4238-ac36-f304d39d461f',
            '9c391dee-05b2-4d44-82c5-af49561b489f',
            '15bb4d3c-c19b-41d3-9f21-8fca347c4e61',
            '7aab8de4-6e3e-4a8c-9ca6-dc6fa257fefa',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'weiterer Generator',
          layout: 'full_width',
          name: 'weiterer_generator',
          order: 43,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'boolean',
          id: '24d240b0-6289-4504-9996-e898107d9d09'
        },
        {
          _manifest: [
            '1f8bd771-4c12-4679-b039-c75eefe33594',
            '9c391dee-05b2-4d44-82c5-af49561b489f',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'c7d3c7d6-aafc-48bf-9100-2e45158eaff9',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Phasig 1',
          name: 'phasig',
          options: ['1-Phasig', '3-Phasig'],
          order: 26,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'd2cbd865-b170-45b7-84e5-97635a85a1ca'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            '1b449ada-4afb-4144-924b-99d9ae071431',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '1048f6df-4684-4128-ab91-a4b1326eba43',
            '8e058ffe-b93b-4a21-90de-1632df0f1855',
            '5e2039da-2364-42d8-bf52-a8e6f1391a17',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Ort der Anlage',
          layout: 'one_third_width',
          name: 'ort_der_anlage',
          options: ['Gebäude', 'Freifläche'],
          order: 4,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '625e579c-b078-49f2-9eb9-650249b8c4a2'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            'e2e7f745-49d4-42d6-9f84-1a0dbc215f3a',
            '3aab6d49-705e-4577-941d-bd284ecd8d1b',
            'f2df6585-27ee-4001-bb11-2fe434f75af9',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '2564e77b-1965-4e89-8781-946ce731a4d9',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Typ',
          name: 'typ_speicher',
          order: 52,
          readonly: false,
          render_condition: 'speicher_eeg_anmeldung = "Ja"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '769d8f0e-71f0-4e02-8dc0-d912fd91095c'
        },
        {
          _manifest: [
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'd2b07dc2-18d0-424a-a62e-7290327f4272',
            'be36a3fc-dda9-4590-9e4f-309662a54e48',
            '3aab6d49-705e-4577-941d-bd284ecd8d1b',
            '9c391dee-05b2-4d44-82c5-af49561b489f',
            '7aab8de4-6e3e-4a8c-9ca6-dc6fa257fefa',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '8a109ffc-15b8-47fc-9841-1d1b52fe2bf6',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Erzeugungsleistung',
          name: 'erzeugungsleistung_einspeiseranlage_antrag',
          options: ['bis 25 KW', 'ab 25 KW'],
          order: 2,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '7a3e7793-5542-4f51-b128-9bf5aa640740'
        },
        {
          _manifest: [
            '587a46b6-98f2-41da-84df-605dbe5e163a',
            '4fd19fb0-ae4c-490b-84d9-983bff86dca8',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '25be5359-172b-43bd-a449-1225e36c1dea',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'fa15e862-ce12-4e48-a27a-702c85db55af',
            'cd826027-8e2f-4e56-abc1-2e2e93fdc388',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Typ Modul 2',
          name: 'typ_modul_2_pv',
          order: 17,
          readonly: false,
          render_condition: 'andere_modultypen_pv = "true"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '3ebb4135-6a45-4b79-b919-157743025c80'
        },
        {
          _manifest: [
            'be36a3fc-dda9-4590-9e4f-309662a54e48',
            'a91c27d8-7a67-4a7e-b001-dbd7f20ad335',
            'b8849410-f85f-4919-9028-7b3f57dddfd9',
            '3aab6d49-705e-4577-941d-bd284ecd8d1b',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            '9ceb1e72-879d-47ae-929d-f696237eb146',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Leistungsabgabe in kVA',
          name: 'leistungsabgabe_in_kva',
          order: 54,
          readonly: false,
          render_condition: 'speicher_eeg_anmeldung = "Ja"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '92b320a1-bf80-40a1-a888-90a09f3a0b4b'
        },
        {
          _manifest: [
            '016f0572-a1a9-43c4-b2de-c1056ebfcc09',
            'e9f3bb0d-9c52-41d7-bc11-ebbe4880d598',
            '1f8bd771-4c12-4679-b039-c75eefe33594',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '72947807-0692-4abe-96d2-284469dd0b3a',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Antrag von Anlagenbetreiber',
          name: 'antrag_von_anlagenbetreiber',
          order: 0,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'boolean',
          id: '61e67e6a-fe2b-4683-b832-9a32d760613f'
        },
        {
          _manifest: [
            'd2b07dc2-18d0-424a-a62e-7290327f4272',
            '84822596-7b3e-4f62-b9a3-362c2e19f5fa',
            '07d6a638-130d-4a17-a5ac-d4b0331bd4e3',
            '7f174858-59e8-44c6-a7dc-606ef115a550',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            '64f9daa9-307e-4bce-8129-5f62e8f677fb',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Bonusjahre',
          name: 'bonusjahre',
          order: 61,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'cb767a49-daf0-47de-b8a7-b35500e5ec58'
        },
        {
          _manifest: [
            '71090dbd-3869-4683-9469-648fbbbf5aed',
            'ed0bc1fe-b9b7-484f-bf42-e6bd1f14cbc2',
            'e9f3bb0d-9c52-41d7-bc11-ebbe4880d598',
            '84822596-7b3e-4f62-b9a3-362c2e19f5fa',
            '4fd19fb0-ae4c-490b-84d9-983bff86dca8',
            'a63fc401-1c7a-413d-8dd7-e8d3476817d8',
            'be36a3fc-dda9-4590-9e4f-309662a54e48',
            '1f8bd771-4c12-4679-b039-c75eefe33594',
            '02a18dcc-99bd-4bd2-9776-848d60210366',
            '3aab6d49-705e-4577-941d-bd284ecd8d1b',
            '7f174858-59e8-44c6-a7dc-606ef115a550',
            '6f6f6d3e-1739-44c6-b8d3-de6c58bfceb9',
            '72de2bb9-a67a-4427-9ccd-58ad18a116e7',
            '0d977146-d263-4ea9-8971-e3505d9b3d00',
            '5f3c0237-6a4b-4eee-854e-acb246ecde0f',
            '3dd57c13-250f-4579-86c0-f2f3f4f75585',
            '417e6eef-50f7-4cb1-80f4-27777cd44e7c',
            '21686782-a9d7-4f1d-8314-4e976ec07216',
            '09c44f20-66e9-41f5-bfde-9d8fd33609cc',
            '296d1998-6a99-4670-bef2-d714647b515d',
            '1c8fd3b7-2024-45ba-92f3-c1c9cd3cde37',
            'ccec1cca-9ce0-46d5-9b5d-99971b4f4b36',
            '80ded75e-8ecc-4c4e-97b4-dd3e56b1afc4',
            'c671887f-4719-4af0-af83-4d25999a6896',
            '26672517-9dd3-4d01-b2a9-e80c232a9c8b',
            'f4e01990-abb3-4f1e-9d66-16f40a7a790d',
            '177d6c1c-f39a-48aa-8844-a63c2ea331fc',
            '3ff8ed98-1de2-44b2-9030-3d7d0b1da4e9',
            '3006b97e-041f-4ee5-9554-141a98ae1eb1',
            '87e85058-21ca-47e4-8f71-195a9ac4e0f3',
            'e59f0573-31b2-4634-85c1-7f7a5cde1b40',
            '54896057-90ba-4a93-94bd-7d80d06e7055',
            'e61dd2d6-f4ee-4aa6-ab6f-6d2261f03ec3',
            'acb65a24-18ed-4e1a-9157-94b5263ba77e',
            'bbba87bc-a7c9-48a1-8915-42da4bbeb480',
            '844eeaa1-afd9-4ad1-8bc7-c48994de642d',
            '421dfe54-adee-4f78-9e64-11c80e9d99e3',
            '83aba8aa-4352-41fc-84b9-6d25f1c3bad5',
            'dd1b640d-b070-44f7-a3d8-bf0b3e2f5850',
            'b871812c-e12f-460f-b4a1-c38b3a1b84e3',
            'cd1753d3-9186-4e8a-8bca-85f0d93ec23f',
            '4bb33b9f-344f-43a5-8cfd-45cfdaf6fabe',
            '2abcb9f5-4eec-4f6e-bba4-15e287e3e4b1',
            '284c0827-e0e4-47b3-9f22-620510355452',
            '304eb46d-07e7-4180-9225-6154bb2d8200',
            'a5af6da9-5f90-4537-9f15-ba8686429f35',
            'fafbf21e-5d98-4850-9f51-c25e65fdfc31',
            '63c2a0dd-4d69-4093-b305-e758ca82dbf1',
            '804b92c9-1f9b-4434-bcba-e74ed6116087',
            'bbb6d8c9-d375-49e2-ab4b-8ee2d62682e1',
            '2bf73611-c461-45df-b6f7-6b77c4be618a',
            '9ed8297e-adcb-4f07-9978-f0ad7ce70605',
            'bfda5853-36c2-488e-9000-b75e89cf59d6',
            '5fd25e46-2023-45d3-b8e3-b794ada62069',
            'a655f0c3-9870-48ed-9568-57e53d56a588',
            'a49607c3-0316-40a8-b5b1-77708ce39b5e',
            'e6bd9656-b4c1-44ce-a2d8-d37f200ab277',
            '1d7a0861-548b-4e1d-a2da-69e6095e4e85',
            'a1a9d99a-81bf-4266-99c8-7f21075ba527',
            '5e3dd246-41f3-42a9-8262-97b0f4191f4d',
            '87e08c8f-a94f-4282-8bdf-65533fc8a078',
            '60d3e4ef-2dc8-4832-9ab8-aec6db1058e8',
            '0edc2fa4-43ce-47d6-83f5-420c3e01a49f',
            '3aa2571c-5ade-4b36-b493-02c3e10d79a9',
            'ec245459-dd82-418c-b162-7200973c9d48',
            '46309afc-9813-476e-89f7-4cc011a398e3',
            '937af0da-221c-4266-9a85-d076ff8f9ab6',
            '1611d93e-20b0-472a-9f05-ab15f1447bd7',
            '49e0cd01-06b5-4641-89d6-9d45fa5f6d74',
            '2571b3da-2386-47db-9f80-494b13e87574',
            '1058ba25-4a86-4734-9ef1-c90dc3e02e69',
            'bb50c390-a078-4704-9900-b265d4f495b6'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '0f33e678-c9db-4d0e-a4df-bc0c145d8992',
          hidden: false,
          label: 'Wechselrichterleistung 1',
          name: 'wechselrichterleistung_1',
          order: 24,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'c7e2ab80-958d-44b8-bc09-c17cdfbc7dc8'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung gefüllt.'
          },
          label: 'Datum Annahme Angebot',
          name: 'datum_annahme_angebot',
          order: 12,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '85dc193f-34ce-4504-b6c6-80c7c8a98204'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung gefüllt.'
          },
          label: 'Datum Übermittlung KVA',
          name: 'datum_uebermittlung_kva',
          order: 5,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '5b1325fd-4e51-40fc-99f6-d0a035c83850'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung gefüllt.'
          },
          label: 'Datum Ende Widerrufsfrist',
          name: 'datum_ende_widerrufsfrist',
          order: 14,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '260f2d92-7169-4568-8aa4-a1e150d0a142'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung gefüllt.'
          },
          label: 'Datum Übermittlung Angebot',
          name: 'datum_uebermittlung_angebot',
          order: 11,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: 'd4a58f75-9506-482b-8ce4-b66c8eb6fe90'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung gefüllt.'
          },
          label:
            'Datum Übermittlung Liefer- und Leistungsvertrag von Interessent:in',
          name: 'datum_uebermittlung_liefer_und_leistungsvertrag_von_interessent',
          order: 9,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '92f92983-c662-477b-81e4-d747c8d9b1df'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Terminbestätigung an Interessent:in wird automatisch versandt.'
          },
          label: 'Datum 2. Vor-Ort-Termin',
          name: 'datum_2_vororttermin',
          order: 3,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'datetime',
          id: '12c6dfcb-b4a1-4ab8-879a-2eba17f041c0'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung gefüllt.'
          },
          label: 'Datum Annahme KVA',
          name: 'datum_annahme_kva',
          order: 6,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: 'c316c593-bdbd-4086-a4ba-165d3f5de0db'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['80da3109-c4cb-4de1-83f6-8f025e1465b6'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          label: 'Umzug Auszugsdatum',
          name: 'umzug_auszugsdatum',
          order: 24,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '5f58fcf0-ccc2-4e19-8869-f46048728332'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Anzahl Etagen',
          name: 'anzahl_etagen',
          options: ['1', '2', '3', '4'],
          order: 34,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '21f3736d-b67b-43cc-b9f7-684bed7f1b04'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text:
              'Bitte tragen Sie hier das fixierte Datum des Baubeginns ein.'
          },
          label: 'Baubeginn (Partner)',
          name: 'baubeginn',
          order: 17,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: 'da44ed1d-0552-4dd2-aa89-f1804294c2f0'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Terminbestätigung an Interessent:in wird automatisch versandt.'
          },
          label: 'Datum 1. Vor-Ort-Termin',
          name: 'datum_1_vororttermin',
          order: 2,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'datetime',
          id: '873f0108-7041-4edb-bc0b-5d142bd43af8'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['80da3109-c4cb-4de1-83f6-8f025e1465b6'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          label: 'Umzug Einzugsdatum',
          name: 'umzug_einzugsdatum',
          order: 26,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: 'd843959a-97c5-499a-ae04-77a6fd01f6d7'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Gebäudetyp" ist gleich "Sonstiger Gebäudetyp".'
          },
          label: 'Sonstiger Gebäudetyp',
          name: 'sonstiger_gebaeudetyp',
          order: 8,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'dbb95507-ada5-4731-b1ee-f2d65744024f'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Einsatz der Anlage',
          name: 'einsatz_der_anlage',
          options: ['Neubau', 'Modernisierung'],
          order: 2,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'ec9b1d00-ead5-4f7b-8c53-df56c738e309'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['571b2812-57d0-4cd1-875f-a6c553803ecf'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          label: 'Datum Kündigung',
          name: 'datum_kuendigung',
          order: 20,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '621feb45-d692-4b1f-83db-849bbca3b3bf'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung gefüllt.'
          },
          label: 'Datum Beauftragung Partner',
          name: 'datum_beauftragung_partner',
          order: 15,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '43ca29be-fd9f-40a7-8991-0625506ef401'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Aktuelle Heiztechnik" ist gleich "Sonstige Heiztechnik".'
          },
          label: 'Sonstige Heiztechnik',
          name: 'sonstige_heiztechnik',
          order: 13,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'f6e138c0-9acc-42e7-86b5-991dfed55db8'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Anmerkungen Interessent:in in Nachqualifizierungs-Klickstrecke.'
          },
          label: 'Weitere Anmerkungen Interessent:in',
          layout: 'full_width',
          multiline: true,
          name: 'weitere_anmerkungen_interessent',
          order: 57,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '80f1859f-bba0-40b6-b4ff-6234599c7e04'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Art der Raumbeheizung',
          name: 'art_der_raumbeheizung',
          options: [
            'Rustikale Heizkörper',
            'Moderne Heizkörper',
            'Fußbodenheizung',
            'Kombination aus Heizkörpern & Fußbodenheizung'
          ],
          order: 45,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '2c1581d1-2221-43f6-8a99-51b9fd621fad'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text: 'Bitte tragen Sie hier das Datum der Fertigstellung ein.'
          },
          label: 'Fertigstellung (Partner)',
          name: 'fertigstellung',
          order: 18,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: 'f8eefb52-ae6f-422d-8e5b-b31e1ca0cd98'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['80da3109-c4cb-4de1-83f6-8f025e1465b6'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          label: 'Umzug Ablesedatum',
          name: 'umzug_ablesedatum',
          order: 22,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '6dde500e-6f86-49f6-b8d2-e731845e5e3b'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung gefüllt.'
          },
          label:
            'Datum Übermittlung Liefer- und Leistungsvertrag an Interessent:in',
          name: 'datum_uebermittlung_liefer_und_leistungsvertrag_an_interessent',
          order: 8,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '21f0e5cd-3d2a-4748-8fcb-5f1d6aa9efd1'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Aufstellungsort Heizungsanlage" ist gleich "Anderer Ort".'
          },
          label: 'Sonstiger Aufstellungsort Heizungsanlage',
          name: 'sonstiger_aufstellungsort_heizungsanlage',
          order: 43,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'cd2340f4-3d4d-4b6c-97ea-58be071ae7a1'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Appointments & Dates',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung gefüllt.'
          },
          label: 'Datum Beginn Widerrufsfrist',
          name: 'datum_beginn_widerrufsfrist',
          order: 13,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '3475f6d0-8757-42a6-bdc0-a493af8bcf38'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Aufstellungsort Heizungsanlage',
          name: 'aufstellungsort_heizungsanlage',
          options: [
            'Keller',
            'Erdgeschoss',
            'Obergeschoss',
            'Dachgeschoss',
            'Anderer Ort'
          ],
          order: 42,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'e485908b-7f22-45ee-bee7-b8f9d82101ee'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Anzahl Badewannen',
          name: 'anzahl_badewannen',
          order: 54,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '58a252f0-c983-4cab-83d2-d249a26b0080'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Übermittelt Interessent:in mit Übermittlung des Fördermittelbescheids. Achtung: Bitte im Fördermittelbescheid überprüfen, ob Wert korrekt!'
          },
          label: 'Status Fördermittelbescheid',
          name: 'status_foerdermittelbescheid',
          options: ['Genehmigt', 'Nicht genehmigt'],
          order: 63,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'edf61574-61a4-49ae-b6c5-b5b03cf514aa'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Aktuelle Heiztechnik" ist gleich "Gas".'
          },
          label: 'Gas Etagen- / oder Zentralheizung',
          name: 'gas_etagen_oder_zentralheizung',
          options: ['Gasetagenheizung', 'Zentralheizung'],
          order: 12,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '9916a9a0-f4f3-41e2-9eb7-8e962238cf82'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Gauben oder Dachfenster',
          name: 'gauben_oder_dachfenster',
          options: ['Ja', 'Nein'],
          order: 25,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'ffb7b2c1-bdfa-4fa9-9cd5-fffaa4b39825'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Art Ein- bzw. Zweifamilienhaus',
          name: 'art_ein_bzw_zweifamilienhaus',
          options: ['Freistehend', 'Doppelhaushälfte', 'Reihenhaus'],
          order: 9,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '328cef60-2489-4c6f-ba90-7f6c504be5d0'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Material Dach',
          name: 'dach_material',
          options: [
            'Ziegel',
            'Dachbahn/ Bitumen',
            'Stehfalzblech',
            'Grün- oder Kiesdach',
            'Vollständiges Mörteldach',
            'Sonstiges Material'
          ],
          order: 23,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '6310e0a8-d0df-4f3e-8691-69c142f58c7e'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Jahresverbrauch vor 2 Jahren (in kWh)',
          name: 'jahresverbrauch_vor_2_jahren_in_kwh',
          order: 51,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '904136d2-b8e2-42a7-b741-fd9951a83385'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Dachtyp" ist gleich "Sonstige Dachfläche".'
          },
          label: 'Sonstiger Dachtyp',
          name: 'sonstiger_dachtyp',
          order: 20,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'e81f8494-340b-4c3a-8a35-d5ac76181f40'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Abfrage Internetzugang',
          name: 'abfrage_internetzugang',
          options: ['Ja', 'Nein', 'Das weiß ich nicht.'],
          order: 44,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '13c3b77a-4e44-433f-9bfa-3fb20948d1b4'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Details Sanierungsmaßnahmen" ist gleich "Sonstiges".'
          },
          label: 'Details Sonstige Sanierungsmaßnahmen',
          name: 'details_sonstige_sanierungsmassnahmen',
          order: 41,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '2ab6cc61-3587-4eb8-b0ed-665021c1419f'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Aktuelle Heiztechnik',
          name: 'aktuelle_heiztechnik',
          options: ['Gas', 'Öl', 'Holz', 'Fernwärme', 'Sonstige Heiztechnik'],
          order: 11,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'f09b4276-b081-4c3c-813e-680a87e8006b'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn mehrere Dachflächen belegt werden sollen.'
          },
          label: '2. Dachfläche Länge (in Meter)',
          name: '2_dachflaeche_laenge_in_meter',
          order: 29,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '2d8e8ca8-13cd-450b-8f14-3eebfdea2b20'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Übermittelt Interessent:in mit Annahme des Angebots.'
          },
          label: 'Planung Förderung',
          name: 'planung_foerderung',
          options: ['Ja', 'Nein'],
          order: 62,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '946030fc-c8ef-4649-a76d-00090004ccf7'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Eigentümer',
          name: 'eigentuemer',
          options: ['Eigentümer:in', 'Mieter:in'],
          order: 1,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '5e8fa4c4-9839-46b0-94c1-c2dc787372f0'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung aktiviert.'
          },
          label: 'Bestätigung AGB',
          name: 'bestaetigung_agb',
          order: 59,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'boolean',
          id: '57aa045e-f36b-4688-9891-1ce6bf6d025b'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Einsatz der Wärmepumpe" ist gleich "Modiernisierung".'
          },
          label: 'Gebäudebaujahr',
          name: 'gebaeudebaujahr',
          options: [
            'vor 1918',
            '1919-1948',
            '1949-1978',
            '1979-2000',
            '2001-2008',
            'nach 2009'
          ],
          order: 4,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'd4fd8af1-387c-4e09-93bc-50a750f5e789'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Dachneigung',
          name: 'dachneigung',
          options: [
            'Flach (0°)',
            'Leicht (~15°)',
            'Normal (~30°)',
            'Stark (~45°)',
            'Sonstige Dachneigung'
          ],
          order: 21,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '81f3e453-6930-4a41-9867-b4bffd42d62a'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Beauftragung Gerüststellung',
          name: 'beauftragung_gerueststellung',
          options: ['Ja', 'Nein'],
          order: 36,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '961669b5-19f6-49b8-8db0-60e225c9cc6d'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Notizen Vor-Ort-Termin',
          layout: 'full_width',
          multiline: true,
          name: 'notizen',
          order: 56,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '1102982f-ea8d-449b-afda-56e993e8dec9'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn mehrere Dachflächen belegt werden sollen.'
          },
          label: '2. Dachfläche Breite (in Meter)',
          name: '2_dachflaeche_breite_in_meter',
          order: 30,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'b8723ef1-f60c-42f1-8405-c2a0f0aa6dcc'
        },
        {
          _manifest: [
            '532d20fb-eb6d-42e0-9c94-56a843620f12',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: '(durchschnittlicher) jährlicher Stromverbrauch (in kWh)',
          name: 'durchschnittlicher_jaehrlicher_stromverbrauch_in_kwh',
          order: 6,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'e9d6119e-7de6-400e-80fb-ad62afdc9555'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Gebäudehöhe (in Meter)',
          name: 'gebaeudehoehe_in_meter',
          order: 32,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'e3dfcbf8-6faf-45b6-ac9c-eb394c16a3dc'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Bitte beschreiben Sie hier kurz das geplante Sanierungsvorhaben.'
          },
          label: 'Beschreibung Sanierungsvorhaben (Partner)',
          layout: 'full_width',
          multiline: true,
          name: 'beschreibung_sanierungsvorhaben',
          order: 65,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '02f78b06-1313-4ed1-85ba-5ed90e159592'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Anmerkungen Interessent:in in Leadgenerierungs-Klickstrecke.'
          },
          label: 'Anmerkungen Interessent:in',
          layout: 'full_width',
          multiline: true,
          name: 'anmerkungen_interessent',
          order: 16,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '50b65ece-c05e-4471-9cdf-2e9bc6561a6c'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Anzahl Duschen',
          name: 'anzahl_duschen',
          order: 55,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '36a303cc-49f5-4d4c-896d-e1738390ba0a'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          allow_extra_options: false,
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Dachausrichtung',
          name: 'dachausrichtung',
          options: ['Westen', 'Norden', 'Osten', 'Süden'],
          order: 26,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'multiselect',
          id: 'f55d14a9-a202-4796-8f3e-93bb1081103c'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Übermittelt Kund:in nach Annahme des Angebots.'
          },
          label: 'Vertragsinhaber Stromvertrag - Nachname',
          name: 'vertragsinhaber_stromvertrag_nachname',
          order: 68,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '12c21d31-3615-43df-ad29-f77ce0540949'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          allow_extra_options: false,
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Dachtyp',
          name: 'dachtyp',
          options: [
            'Flachdach',
            'Walmdach',
            'Pultdach',
            'Satteldach',
            'Gaubendach',
            'Sonstige Dachfläche'
          ],
          order: 19,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'multiselect',
          id: '949ca7a1-ccda-4b83-be85-7088ca0d6d7f'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Einsatz der Anlage" ist gleich "Modernisierung".'
          },
          label: 'Denkmalschutz',
          name: 'denkmalschutz',
          options: ['Ja', 'Nein', 'Ich weiß es nicht.'],
          order: 14,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '8e6d5d08-8c2f-473f-bdfa-4d48b30df5bf'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Neubau bereits gebaut?',
          name: 'neubau_bereits_gebaut',
          options: ['Bereits gebaut', 'In Planung'],
          order: 3,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '59e6aab5-d168-4f90-b8ae-7baa783f54a1'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Höchster Stromverbrauch im Tagesverlauf',
          name: 'hoechster_stromverbrauch_im_tagesverlauf',
          options: [
            'Morgens und Abends',
            'Gleichmäßig über den Tag verteilt',
            'Unterschiedlich'
          ],
          order: 15,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '3b9aeee4-30bf-452f-877f-2e3887c26b4c'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Wohnhaft in Immobilie',
          name: 'wohnhaft_in_immobilie',
          options: ['Ja', 'Nein'],
          order: 5,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '28db85d8-837b-4200-972e-e59a56f7a454'
        },
        {
          _manifest: [
            '532d20fb-eb6d-42e0-9c94-56a843620f12',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Jahresverbrauch letztes Jahr (in kWh)',
          name: 'jahresverbrauch_letztes_jahr_in_kwh',
          order: 50,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '2b51e390-11c1-4ebf-b1f9-0bb387d06949'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Sanierungsmaßnahmen',
          name: 'sanierungsmassnahmen',
          options: ['Ja', 'Nein'],
          order: 38,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '12631c4b-8d6b-4511-a5ad-e4c7958be701'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: '1. Dachfläche Breite (in Meter)',
          name: '1_dachflaeche_breite_in_meter',
          order: 28,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '6237845d-3714-44db-9fab-b05ae725baa0'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Anzahl der Wohnungen',
          name: 'anzahl_der_wohnungen',
          order: 10,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'a13a2ce1-3840-4807-ae63-dd96304b3176'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Personen im Haushalt',
          name: 'personen_im_haushalt',
          order: 53,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'd9a6b1ce-2750-4abc-b546-27d745d6c7d3'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Wird durch eine Automatisierung aktiviert.'
          },
          label: 'Bestätigung Widerruf',
          name: 'bestaetigung_widerruf',
          order: 60,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'boolean',
          id: '17fdafff-96cc-4021-b27f-4ae8199d743a'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          allow_extra_options: true,
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Abfrage zusätzlicher Wärmeerzeuger" ist gleich "Ja".'
          },
          label: 'Wärmeerzeuger',
          name: 'waermeerzeuger',
          options: [
            'Gas',
            'Heizöl',
            'Flüssiggas',
            'Kachelofen',
            'Kaminofen',
            'Nacht-/ Niederstrom',
            'Braunkohle',
            'Kohle/ Steinkohle',
            'Holz',
            'Holzpellets',
            'Holzhackschnitzel',
            'Fernwärme (KWK 70%, KWK 35%, fossil)',
            'Nahwärme (KWK 70 %, KWK 35 %, ohne KWK)',
            'Sonstiger Wärmeerzeuger'
          ],
          order: 47,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'multiselect',
          id: 'da813442-9814-4752-a597-386259f7c551'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Gebäudebreite (in Meter)',
          name: 'gebaeudebreite_in_meter',
          order: 33,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '93e9b677-241d-4245-897d-29cd73c0c8e1'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Material Dach" ist gleich "Sonstiges Material".'
          },
          label: 'Sonstiges Material Dach',
          name: 'sonstiges_material_dach',
          order: 24,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'cada4e15-c40f-4200-8eb6-74cbb8933d58'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Beheizte Wohnfläche',
          name: 'beheizte_wohnflaeche',
          options: [
            '0m² - 60m²',
            '61m² - 100m²',
            '101m² - 150m²',
            '151m² - 200m²',
            'Mehr als 200m²'
          ],
          order: 37,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '7c32458c-fd1e-4094-af9b-7b8d9d7e3501'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: '1. Dachfläche Länge (in Meter)',
          name: '1_dachflaeche_laenge_in_meter',
          order: 27,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '79094271-83c4-4338-9c77-f10b3132dc9f'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Die BzA-Nummer (Bestätigung zum Auftrag) wird für die Beantragung der Fördermittel benötigt.'
          },
          label: 'BzA-Nummer (Partner)',
          name: 'bza_nummer',
          order: 64,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'e76666d9-8965-4fab-8da4-881662f21855'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Wärmeerzeuger" ist gleich "Sonstiger Wärmeerzeuger".'
          },
          label: 'Sonstiger Wärmeerzeuger',
          name: 'sonstiger_waermeerzeuger',
          order: 48,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '3eafe5bd-e9b7-40f1-bfef-c3839adf899e'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Dachneigung" ist gleich "Sonstige Dachneigung".'
          },
          label: 'Sonstige Dachneigung',
          name: 'sonstige_dachneigung',
          order: 22,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '102279e6-de0c-4583-bed5-6a38c70c4e01'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            'a07b08fb-c3db-4b9d-a522-f0636881d111',
            '59854dd7-8636-4568-a628-9859c07e3c57'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Gebäudetyp',
          name: 'gebaeudetyp',
          options: [
            'Ein- oder Zweifamilienhaus',
            'Mehrfamilienhaus',
            'Wohnung',
            'Sonstiger Gebäudetyp'
          ],
          order: 7,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '9fae942a-75a4-42b7-ab8e-c19cd729a59f'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Jahresverbrauch vor 3 Jahren (in kWh)',
          name: 'jahresverbrauch_vor_3_jahren_in_kwh',
          order: 52,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '1a475dd4-af36-4410-857d-336ef484c516'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text: 'Hinweis: Übermittelt Kund:in nach Annahme des Angebots.'
          },
          label: 'Vertragsinhaber Stromvertrag - Vorname',
          name: 'vertragsinhaber_stromvertrag_vorname',
          order: 67,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'f2f9c3bb-8ba0-49e9-a2c1-265badbd825d'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Abfrage zusätzliche Wärmeerzeuger',
          name: 'abfrage_zusaetzliche_waermeerzeuger',
          options: ['Ja', 'Nein'],
          order: 46,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'cbaa468b-e4bf-4890-b335-36f8e152f90e'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          label: 'Interesse Batteriespeicher',
          name: 'interesse_batteriespeicher',
          options: ['Ja', 'Nein', 'Unentschlossen'],
          order: 35,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '0aac0b14-1f41-49b2-8af1-a7a3e9fac2bb'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          allow_extra_options: true,
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Sanierungsmaßnahmen" ist gleich "Ja".'
          },
          label: 'Details Sanierungsmaßnahmen',
          name: 'details_sanierungsmassnahmen',
          options: [
            'Dachdämmung',
            'Dämmung der Geschossdecke',
            'Fenster',
            'Fassadendämmung',
            'Dämmung Kellerdecke',
            'PV-Anlage',
            'Sonstiges'
          ],
          order: 40,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'multiselect',
          id: '301cdcc3-6676-4d1e-8bfa-0bf3f4a8da84'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          hidden: false,
          info_helpers: {
            hint_text:
              'Hinweis: Nur gefüllt, wenn "Sanierungsmaßnahmen" ist gleich "Ja".'
          },
          label: 'Jahr Sanierungsmaßnahme',
          name: 'jahr_sanierungsmassnahme',
          order: 39,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: false,
          sortable: true,
          type: 'number',
          id: '8ba7d90c-3d05-421d-879c-ad1d88568e0e'
        },
        {
          group: 'c7c57ebf-c146-4e25-a6e2-96c2c28eb12b',
          name: 'to_change',
          id: 'b328e637-8bf1-4232-a2bf-3c50e73bac94',
          label: 'To Change',
          order: 2,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          multiline: false,
          rows: 2,
          rich_text: false,
          repeatable: false,
          has_primary: false,
          type: 'string'
        },
        {
          group: '2cb6a4ae-69c4-44d8-a290-ac5a0d605dfc',
          name: 'rich_text_attribute',
          type: 'string',
          id: 'a27e4027-3ee8-4b9d-8901-bc5a4a1159b8',
          label: 'Rich Text Attribute',
          order: 2,
          rich_text: true,
          layout: 'full_width'
        },
        {
          group: '2cb6a4ae-69c4-44d8-a290-ac5a0d605dfc',
          name: '100_absicherung',
          type: 'string',
          id: '6885ff90-e87b-42e2-96ab-a5d06f5d07ab',
          label: 'Breaking attribute',
          order: 4
        },
        {
          group: 'Address Information',
          name: 'super_tags',
          id: '915e4b77-766b-47c9-a085-fdbfe7a93dc1',
          label: 'Super Tags',
          order: 4,
          type: 'tags',
          layout: 'one_third_width'
        },
        {
          group: 'Address Information',
          name: 'input_for_long_text',
          type: 'string',
          id: 'bf245d14-f809-46f3-8641-b6875a39551a',
          label: 'Input for multiline text',
          order: 5,
          multiline: true,
          rows: '4'
        },
        {
          group: 'Address Information',
          name: 'long_long_label_name_long_long_label_namelong_long_label_namelong_long_label_namelong_long_label_namelong_long_label_namelong_long_label_name',
          type: 'string',
          id: 'f495a1ab-312f-4ac3-8e00-0c754c9fe94c',
          label:
            'Long long label name Long long label nameLong long label nameLong long label nameLong long label nameLong long label nameLong long label name',
          order: 6
        },
        {
          group: 'Address Information',
          name: 'new_binary_input_labelnew_binary_input_labelnew_binary_input_labelnew_binary_input_labelnew_binary_input_labelnew_binary_input_label',
          id: '592b3d17-2349-4fc5-ba25-2e07033c8cd0',
          label:
            'New binary input labelNew binary input labelNew binary input labelNew binary input labelNew binary input labelNew binary input label',
          order: 7,
          display_type: 'switch',
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'boolean',
          layout: 'full_width'
        },
        {
          group: 'dc712a70-c157-4c89-82ac-493ac9915ea9',
          name: 'new_attribute_1740043939005',
          type: 'string',
          id: 'fb3ba65d-a552-4c92-8cf4-b10b203ef438',
          label: 'New Attribute',
          order: 2,
          layout: 'half_width',
          _purpose: ['20fe55e1-02ea-48eb-8c77-86fbbb6a182d']
        },
        {
          group: 'ad81dbcf-ab58-4caf-b5bd-676146dd4c63',
          name: 'new_attribute_1740504416146',
          id: 'fd46816a-f61b-4413-95e4-df979e4013c7',
          label: 'New Attribute',
          order: 8,
          readonly: true,
          required: true,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          display_images_landscaped: true,
          enable_description: true,
          browser_upload: true,
          file_manager: true,
          template_manager: true,
          description_text: '',
          file_size: 5,
          file_size_unit: 'mb',
          files_upload_limit: 10,
          allowed_extensions: ['jpg', 'jpeg', 'png', 'gif'],
          type: 'image',
          layout: 'half_width'
        },
        {
          group: 'ad81dbcf-ab58-4caf-b5bd-676146dd4c63',
          name: 'new_attribute_1740504465360',
          id: '6936fa16-e039-4d53-ad48-71b02738f71e',
          label: 'New Attribute',
          order: 10,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          enable_description: false,
          browser_upload: true,
          file_manager: true,
          template_manager: true,
          description_text: '',
          allowed_extensions: [
            'docx',
            'doc',
            'pdf',
            'image',
            'jpeg',
            'png',
            'jpg'
          ],
          file_size: 5,
          file_size_unit: 'mb',
          files_upload_limit: 10,
          type: 'file',
          layout: 'half_width'
        }
      ],
      capabilities: [
        {
          name: 'attributes',
          title: 'Attributes',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Overview',
              component: 'EntityOverview',
              order: 0
            }
          ],
          id: '83df53b1-d109-4e07-bd01-8e496eb40859'
        },
        {
          name: 'relations',
          title: 'Relations',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Relations',
              component: 'EntityRelations',
              order: 10
            }
          ],
          id: 'a153b406-153c-4c87-badf-4fab3d6c4c9a'
        },
        {
          name: 'files',
          title: 'Files',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Files',
              component: 'EntityFiles',
              order: 50
            }
          ],
          id: '27bdc64a-92cf-487a-82f4-390f09fffb78'
        },
        {
          name: 'messages',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Messages',
              import: '@epilot360/messaging',
              order: 51,
              requiredPermission: {
                action: 'message:view'
              }
            },
            {
              icon: 'message',
              hook: 'SidePanel:Tab',
              route: 'emails',
              order: 51,
              import: '@epilot360/messaging',
              requiredPermission: {
                action: 'message:view'
              }
            },
            {
              hook: 'ActivityPilot:View',
              route: 'emails',
              order: 30,
              import: '@epilot360/messaging',
              requiredPermission: {
                action: 'message:view'
              }
            },
            {
              icon: 'message',
              hook: 'SidePanel:Tab',
              route: 'emails',
              order: 30,
              import: '@epilot360/messaging',
              requiredPermission: {
                action: 'message:view'
              }
            }
          ],
          id: '5a4df43c-87d6-4344-9a94-96f6b271c53d'
        },
        {
          name: 'activity_feed',
          title: 'Activity',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'activity_feed',
              order: 0
            },
            {
              icon: 'update',
              hook: 'SidePanel:Tab',
              route: 'activity_feed',
              order: 0
            },
            {
              icon: 'update',
              hook: 'SidePanel:Tab',
              route: 'activity_feed',
              order: 0
            }
          ],
          id: '7efc89d3-7038-4254-83a7-3c7366d16c5d'
        },
        {
          name: 'automation',
          title: 'Automation',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'automation',
              order: 30,
              import: '@epilot360/automation-capability'
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'play_circle_filled',
              route: '?activity_pilot=automation',
              order: 30,
              requiredPermission: {
                action: 'automation:trigger'
              }
            },
            {
              hook: 'SidePanel:Tab',
              route: 'automation',
              import: '@epilot360/automation-capability',
              title: 'Automation',
              icon: 'automation',
              order: 30
            },
            {
              hook: 'SidePanel:Tab',
              route: 'automation',
              import: '@epilot360/automation-capability',
              title: 'Automation',
              icon: 'automation'
            }
          ],
          id: '09e92518-71f0-4570-8b5d-44320e64c688'
        },
        {
          name: 'workflow',
          title: 'Workflow',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'workflow',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              header: true,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            },
            {
              icon: 'workflow',
              hook: 'SidePanel:Tab',
              route: 'workflow',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              header: true,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'custom_workflow_icon',
              route: '?activity_pilot=workflow',
              order: 20,
              requiredPermission: {
                action: 'workflow:execution:trigger'
              }
            },
            {
              icon: 'workflow',
              hook: 'SidePanel:Tab',
              route: 'workflow',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            },
            {
              hook: 'EntityDetailsV2:Header',
              title: 'Workflow Overview',
              component: 'WorkflowOverview'
            }
          ],
          id: 'a7cf8641-5357-470c-92d8-a7bbe3dc6069'
        },
        {
          name: 'quoting_relations',
          title: 'Quotes & Orders',
          ui_hooks: [
            {
              hook: 'EntityAttributes:Group',
              order: 0,
              title: 'Quotes & Orders',
              component: 'QuotingItems',
              settings: {
                display_grand_totals: true
              }
            }
          ],
          id: '41774169-f4b2-4595-923a-ba9f021c46f1'
        },
        {
          name: 'unmapped_attributes',
          title: 'Metadata',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              order: 500,
              title: 'Metadata',
              component: 'EntityUnmappedFields'
            }
          ],
          id: 'ea569f3f-700c-4326-93f6-389713058f3e'
        },
        {
          name: 'notes',
          title: 'Notes',
          attributes: [
            {
              name: 'notes',
              label: 'Notes',
              type: 'internal',
              id: 'dd811592-339e-428b-9ffb-2f16ee85671f'
            }
          ],
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'task',
              route: '?drawer=notes',
              order: 50,
              requiredPermission: {
                action: 'entity:create',
                resource: 'comment'
              }
            },
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Notes',
              import: '@epilot360/notes',
              order: 52,
              requiredPermission: {
                action: 'entity:view',
                resource: 'comment'
              }
            }
          ],
          id: '84b48d8e-280d-4035-9952-3955cdb55177'
        },
        {
          name: 'customer_messaging',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'email',
              route: '?drawer=messaging',
              order: 15,
              requiredPermission: {
                action: 'message:send'
              }
            }
          ],
          id: 'c6b5249f-c3fa-4112-b29e-9a821ed3fc8e'
        },
        {
          name: 'journey_data',
          title: 'Journey Data',
          ui_hooks: [
            {
              hook: 'EntityAttributes:Group',
              title: 'Journey Data',
              import: '@epilot360/journey-data',
              order: 60
            }
          ],
          id: 'a9de24ad-9651-46df-8227-977f3c2e3ac5'
        },
        {
          name: 'notes-v2',
          title: 'Notes V2',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20,
              header: true
            },
            {
              icon: 'note_stack',
              hook: 'SidePanel:Tab',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20
            },
            {
              hook: 'SidePanel:Tab',
              icon: 'note_stack',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20,
              header: true
            }
          ],
          id: '9c3cceef-0fcb-4abc-a9ff-190feb14265a'
        },
        {
          name: 'workflow-in-entities',
          title: 'Workflow in Entity Table',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: 'workflows.primary.task_duedate',
              label: 'Workflow Next Open Task',
              show_in_table: true,
              component: 'WorkflowCell',
              width: 450
            }
          ],
          id: 'd6af0d5e-8c8e-4307-884c-c3fe4a689b74'
        },
        {
          name: 'workflows-and-more-in-entities',
          title: 'Workflow in Entity Table',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: 'workflows.primary.name',
              label: 'Active Workflow',
              component: 'ActiveWorkflowCell',
              width: 450
            },
            {
              hook: 'EntityTable:Column',
              field: 'workflows.primary.phase_name',
              label: 'Active Phase',
              component: 'ActivePhaseCell',
              width: 450
            }
          ],
          id: '81b2f26c-acd1-4623-a58d-567e158cd444'
        },
        {
          name: 'consents',
          title: 'Consents',
          ui_hooks: [
            {
              hook: 'EntityAttributes:Group',
              title: 'Consents',
              component: 'LocalConsents',
              order: 40
            }
          ],
          _purpose: [],
          id: '18107239-8130-4484-9e53-a65ca671e0e7'
        },
        {
          name: 'unread',
          title: 'Unread',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: '_messages.unread_count',
              label: 'Unread',
              sortable: true,
              show_in_table: true,
              order: 1,
              component: 'ActivityCell',
              width: 80
            }
          ],
          id: 'b3713f24-46a8-46f5-b2ff-22b5e58532hy'
        }
      ],
      source: {
        id: 'e5fee37e-7818-481b-bab1-ae563592d696',
        type: 'blueprint'
      },
      draft: false,
      created_at: '2022-05-10T19:44:43.407Z',
      updated_at: '2025-02-26T12:14:54.862Z',
      version: 113,
      comment:
        'Add default address fields to address_information attributes in contract, opportunity and request',
      explicit_search_mappings: {
        journey_data: {
          type: 'flattened'
        },
        line_items: {
          type: 'flattened'
        },
        prices: {
          type: 'flattened'
        },
        pricing_items: {
          type: 'flattened'
        }
      },
      created_by: '11001053',
      layout_settings: {
        half_width_stacked: {
          maxWidth: '50%',
          gridTemplateColumns: '1fr'
        }
      },
      description:
        'Opportunities store details about sales leads or product inquiries usually made through journeys.',
      docs_url:
        'https://docs.epilot.io/docs/entities/schemas-list/#opportunity',
      category: 'sales',
      group_headlines: [
        {
          name: 'headline_1740428487720',
          type: 'headline',
          id: '435cdd7e-b984-45b3-b3c2-0239f63b45e2',
          label: 'Headline text',
          layout: 'one_third_width',
          order: 0,
          group: 'Address Information',
          enable_divider: true,
          divider: 'bottom_divider'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          enable_divider: false,
          group: 'Appointments & Dates',
          id: 'f3a3e5f5-bafe-4b2d-bf17-e4493a430e4e',
          label: 'Vor-Ort-Termine',
          layout: 'full_width',
          name: 'headline_1719405788558',
          order: 1,
          type: 'headline'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          enable_divider: true,
          group: 'Appointments & Dates',
          id: '7d0083a3-8d36-4250-bc0a-5169aaa08b42',
          label: 'Liefer- und Leistungsvertrag',
          layout: 'full_width',
          name: 'headline_1730297315168',
          order: 7,
          type: 'headline'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          enable_divider: true,
          group: 'Appointments & Dates',
          id: '1c2209e6-f802-47ad-98d3-7aef436abc85',
          label: 'Angebot und Beauftragung',
          layout: 'full_width',
          name: 'headline_1719406755168',
          order: 10,
          type: 'headline'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          enable_divider: true,
          group: 'Appointments & Dates',
          id: 'e5ffea4f-610f-4f8d-9ff0-57a2fedc5a78',
          label: 'KVA',
          layout: 'full_width',
          name: 'headline_1730292336614',
          order: 4,
          type: 'headline'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          enable_divider: true,
          group: 'Appointments & Dates',
          id: '2b6f5e87-c4dd-4c68-a54b-b784c561e174',
          label: 'Installation',
          layout: 'full_width',
          name: 'headline_1719414066456',
          order: 16,
          type: 'headline'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          enable_divider: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          id: 'a0aadd69-ddb6-41d8-8307-83e194d6c4ae',
          label: 'Dach',
          layout: 'full_width',
          name: 'headline_1719405882084',
          order: 18,
          type: 'headline'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          enable_divider: true,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          id: '166b42db-479f-4266-b11d-4435b433c255',
          label: 'Kundenantworten - Nachqualifizierung',
          layout: 'full_width',
          name: 'headline_1721142480388',
          order: 17,
          type: 'headline'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['a07b08fb-c3db-4b9d-a522-f0636881d111'],
          enable_divider: true,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          id: '3f8d9521-1515-44cb-b8e4-2a0864a96308',
          label: 'Vertragsinhaber Stromvertrag',
          layout: 'full_width',
          name: 'headline_1730413789799',
          order: 66,
          type: 'headline'
        },
        {
          _manifest: ['dde1c501-db79-4201-a10b-e7edbce1a212'],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          enable_divider: true,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          id: '73819b3b-d762-46b9-b534-3ca63a481fce',
          label: 'Förderung',
          layout: 'full_width',
          name: 'headline_1730297122035',
          order: 61,
          type: 'headline'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          enable_divider: true,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          id: '6b9739c0-f9d8-4dcc-bc9a-7a0c4c60ad6a',
          label: 'Angebotsannahme',
          layout: 'full_width',
          name: 'headline_1719477889546',
          order: 58,
          type: 'headline'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          enable_divider: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          id: 'fbb5fe60-6c0d-4c5e-a4d2-084e5183f8f9',
          label: 'Kundenantworten',
          layout: 'full_width',
          name: 'headline_1719405761932',
          order: 0,
          type: 'headline'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '59854dd7-8636-4568-a628-9859c07e3c57',
            'a07b08fb-c3db-4b9d-a522-f0636881d111'
          ],
          enable_divider: false,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          id: '6ca1915e-7d5a-4ae8-a40b-4b256b7e986a',
          label: 'Gebäudeinformationen',
          layout: 'full_width',
          name: 'headline_1721143027604',
          order: 31,
          type: 'headline'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: ['59854dd7-8636-4568-a628-9859c07e3c57'],
          enable_divider: true,
          group: '7074564e-7019-4878-a096-d0dfbbea9fd3',
          id: 'bd2ec7fe-19fa-4136-b62a-10a7d5b37c5a',
          label: 'Verbrauchsinformationen',
          layout: 'full_width',
          name: 'headline_1721143037953',
          order: 49,
          type: 'headline'
        }
      ]
    },
    {
      id: 'b6100349-7d48-402a-abaf-ec8b1fe7160a',
      blueprint: '0fd09294-068d-4e4e-95f8-4d82b70b5c2c',
      name: 'Order',
      plural: 'Orders',
      description:
        'Orders store details about requested products, pricing and past orders placed by customers.',
      docs_url: 'https://docs.epilot.io/docs/entities/schemas-list/#order',
      category: 'sales',
      slug: 'order',
      icon: 'order',
      published: true,
      title_template: '{{order_number}}',
      ui_config: {
        table_view: {
          view_type: 'default',
          row_actions: ['view', 'edit', 'duplicate', 'share', 'delete'],
          bulk_actions: ['trigger_automation', 'export', 'send_email', 'delete']
        },
        create_view: {
          view_type: 'default'
        },
        edit_view: {
          view_type: 'default',
          summary_attributes: [
            'order_number',
            '_created_at',
            'customer',
            'delivery_address',
            'billing_address',
            'status',
            'source',
            '_acl.view'
          ]
        },
        list_item: {
          summary_attributes: [
            'order_number',
            '_created_at',
            'customer',
            'delivery_address',
            'billing_address',
            'status'
          ],
          quick_actions: [
            {
              action: 'generate_document',
              label: 'Generate Document',
              icon: 'document'
            }
          ]
        },
        sharing: {
          show_sharing_button: true
        }
      },
      group_settings: [
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          expanded: true,
          id: 'Order Details',
          label: 'Order Details',
          order: 0,
          settings_flag: []
        },
        {
          _manifest: ['f4466916-5af1-454e-851f-e9089162a025'],
          _purpose: [],
          expanded: false,
          id: 'Customer Information',
          label: 'Customer Information',
          order: 0,
          settings_flag: []
        },
        {
          _manifest: ['f4466916-5af1-454e-851f-e9089162a025'],
          _purpose: [],
          expanded: false,
          id: 'Address Information',
          label: 'Address Information',
          order: 0,
          settings_flag: []
        },
        {
          id: 'Appointments & Dates',
          label: 'Appointments & Dates',
          expanded: false
        },
        {
          id: 'Advanced Options',
          label: 'Advanced Options',
          expanded: false
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            '07d73cba-82dc-4b91-b85b-369d924c3bbf',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [],
          expanded: false,
          id: 'Payment Details',
          label: 'Payment Details',
          order: 15,
          settings_flag: []
        },
        {
          id: 'Journey Data',
          label: 'Journey Data',
          expanded: false,
          order: 30
        },
        {
          id: 'Ordered Items',
          label: 'Ordered Items',
          expanded: true
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'e99e615c-005c-45b6-bf77-1c5ef9793f92'
          ],
          _purpose: ['94df2ffb-58ef-469c-ab85-8c04f450a493'],
          expanded: false,
          label: 'Address Information',
          order: 3,
          settings_flag: [],
          id: '68670023-3aa0-4ad2-b3ac-288bc1151d2f'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'e99e615c-005c-45b6-bf77-1c5ef9793f92'
          ],
          _purpose: [],
          expanded: false,
          label: 'Customer Information',
          order: 1,
          settings_flag: [],
          id: '7aa95c89-b296-45b0-975e-63d18d191dbd'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f'
          ],
          expanded: false,
          id: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          label: 'Commodity',
          order: 5,
          settings_flag: []
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          expanded: false,
          label: 'Zählerdaten',
          order: 4,
          settings_flag: [],
          id: 'd158a785-3fa0-4fcd-8f70-7d2c5e7bb600'
        },
        {
          id: '6da98448-0280-4de3-923f-164186a3f518',
          label: 'Neue Gruppe',
          expanded: false
        },
        {
          _manifest: ['5817d5f8-0495-4f34-8a91-e880e0ae49cd'],
          _purpose: [],
          expanded: false,
          label: 'Kontakt',
          order: 11,
          render_condition: 'opportunity_title= "z"',
          settings_flag: [],
          id: '9cbce11f-7c11-4c65-be68-46a3e7b3d3a9'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            '07d73cba-82dc-4b91-b85b-369d924c3bbf',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          expanded: false,
          label: 'Payment Details',
          order: 15,
          settings_flag: [],
          id: '90e35d0e-de12-4eb4-a34f-0b3675a6944a'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          expanded: false,
          label: 'Customer Information',
          order: 0,
          settings_flag: [],
          id: '5d212edb-af7d-4738-8117-df0eef7d3850'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          expanded: false,
          label: 'Address Information',
          order: 0,
          settings_flag: [],
          id: '21969ce9-04a8-4547-81f8-0ae1f97deced'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          expanded: true,
          label: 'Order Details',
          order: 0,
          settings_flag: [],
          id: '44a362d6-e988-437a-b29d-553c0e0f4c10'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            '07d73cba-82dc-4b91-b85b-369d924c3bbf',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          expanded: false,
          label: 'Payment Details',
          order: 15,
          settings_flag: [],
          id: '8338bf4f-4cd4-4b49-be3b-9ba156f064fa'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          expanded: false,
          label: 'Address Information',
          order: 0,
          settings_flag: [],
          id: '4ce1e871-54dc-413c-b04d-1f9a922689c1'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          expanded: false,
          label: 'Customer Information',
          order: 0,
          settings_flag: [],
          id: '3f663dc2-d2b0-4fea-9800-ace388b487f0'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          expanded: true,
          label: 'Order Details',
          order: 0,
          settings_flag: [],
          id: '301c1f24-4f4e-4556-a4cc-9a1fd44719ae'
        }
      ],
      explicit_search_mappings: {
        journey_data: {
          type: 'flattened'
        },
        pricing_items: {
          type: 'flattened'
        }
      },
      attributes: [
        {
          label: 'Order Number',
          name: 'order_number',
          type: 'sequence',
          show_in_table: true,
          group: 'Order Details',
          layout: 'half_width',
          readonly: true,
          protected: true,
          prefix: 'OR-',
          start_number: 100,
          id: '6839dc2e-ba96-4c28-b8c3-4cb724a5b3e4'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          default_value: 'draft',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Order Details',
          hidden: false,
          label: 'Status',
          layout: 'half_width',
          name: 'status',
          options: [
            {
              value: 'draft',
              title: 'Draft'
            },
            {
              value: 'quote',
              title: 'Open for Quote'
            },
            {
              value: 'placed',
              title: 'Placed'
            },
            {
              value: 'complete',
              title: 'Complete'
            },
            {
              value: 'cancelled',
              title: 'Cancelled'
            },
            {
              value: 'open_for_acceptance',
              title: 'Open for Acceptance'
            }
          ],
          protected: true,
          readonly: false,
          required: true,
          settings_flag: [],
          sortable: true,
          type: 'status',
          id: '732c6b91-40d2-4644-ad50-67e21bc916d7'
        },
        {
          label: 'Expiration Date',
          name: 'expires_at',
          type: 'date',
          group: 'Order Details',
          show_in_table: false,
          layout: 'half_width',
          render_condition: 'status="quote"',
          constraints: {
            disablePast: true
          },
          protected: true,
          id: 'a643515c-d546-4117-b5ed-59832d9ef22e'
        },
        {
          _manifest: ['f4466916-5af1-454e-851f-e9089162a025'],
          _purpose: ['eaee073d-8fbe-4e4d-9ec9-a814ac5cbcd1'],
          default_value: {
            href: null,
            source_type: 'manual',
            title: 'manual'
          },
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Order Details',
          hidden: false,
          label: 'Source',
          name: 'source',
          protected: true,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'link',
          id: '445401c0-0ba5-4dea-89d1-7a4bec79d835'
        },
        {
          label: 'Shared With',
          name: '_acl.view',
          type: 'partner_organisation',
          show_in_table: true,
          sortable: false,
          group: 'Order Details',
          layout: 'full_width',
          protected: true,
          id: 'f1a796f4-a57b-464e-b4f6-86582a6b4892'
        },
        {
          _manifest: ['5817d5f8-0495-4f34-8a91-e880e0ae49cd'],
          _purpose: ['14384d9c-2d94-40ae-93a9-b3f891e43d19'],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Payment Details',
          has_primary: true,
          hidden: false,
          label: 'Payment Details',
          layout: 'full_width',
          name: 'payment_method',
          order: 0,
          protected: true,
          readonly: false,
          repeatable: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'payment',
          id: '80470e6c-6fc8-493c-8fe1-b747c65dc8f2'
        },
        {
          name: 'customer',
          type: 'relation',
          relation_type: 'has_many',
          label: 'Customer',
          allowedSchemas: ['contact', 'account'],
          show_in_table: true,
          hide_label: false,
          group: 'Customer Information',
          layout: 'full_width',
          has_primary: true,
          enable_relation_picker: true,
          enable_relation_tags: true,
          protected: true,
          actions: [
            {
              action_type: 'create_new',
              new_entity_item: {
                _schema: 'contact'
              }
            },
            {
              action_type: 'create_new',
              new_entity_item: {
                _schema: 'account'
              }
            }
          ],
          id: 'd0158edd-ecb5-4b2a-8365-40571c3b9fd0'
        },
        {
          _manifest: ['f4466916-5af1-454e-851f-e9089162a025'],
          _purpose: [],
          actions: [],
          allowedSchemas: ['contact'],
          deprecated: false,
          details_view_mode_enabled: false,
          enable_relation_picker: true,
          enable_relation_tags: true,
          entity_builder_disable_edit: false,
          group: 'Customer Information',
          has_primary: true,
          hidden: false,
          hide_label: false,
          label: 'Billing Contact',
          layout: 'full_width',
          name: 'billing_contact',
          protected: true,
          readonly: false,
          relation_type: 'has_many',
          required: false,
          reverse_attributes: {},
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          summary_fields: [],
          type: 'relation',
          id: '12415fe6-44a7-4771-8ad6-dde8f92cd560'
        },
        {
          _manifest: ['f4466916-5af1-454e-851f-e9089162a025'],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Customer Information',
          hidden: false,
          label: 'First Name',
          layout: 'half_width',
          name: 'billing_first_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '10a3ccc6-6d3e-48bc-916c-03ba8fc24d53'
        },
        {
          _manifest: ['f4466916-5af1-454e-851f-e9089162a025'],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Customer Information',
          hidden: false,
          label: 'Last Name',
          layout: 'half_width',
          name: 'billing_last_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'b9e14781-bb09-4edd-abbd-1dacbd6232ed'
        },
        {
          _manifest: ['f4466916-5af1-454e-851f-e9089162a025'],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Customer Information',
          hidden: false,
          label: 'Email',
          layout: 'half_width',
          name: 'billing_email',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'string',
          id: '48ea042a-a081-4c3e-b278-310d346755b5'
        },
        {
          _manifest: ['f4466916-5af1-454e-851f-e9089162a025'],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Customer Information',
          hidden: false,
          label: 'Phone',
          layout: 'half_width',
          name: 'billing_phone',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'string',
          id: 'e4e99cae-bbae-4cf1-a606-798113492977'
        },
        {
          _manifest: ['f4466916-5af1-454e-851f-e9089162a025'],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Customer Information',
          hidden: false,
          label: 'Company name',
          layout: 'half_width',
          name: 'billing_company_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '7db287be-7adc-4a4f-9a6b-b6ccb2c67e69'
        },
        {
          _manifest: ['f4466916-5af1-454e-851f-e9089162a025'],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Customer Information',
          hidden: false,
          label: 'Tax ID',
          layout: 'half_width',
          name: 'billing_vat',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '6c9d7749-3d25-4bf3-8410-df7dba791fc8'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889'
          ],
          _purpose: [
            '94df2ffb-58ef-469c-ab85-8c04f450a493',
            '27212dc0-f90f-43aa-92b2-203a9d102994',
            '520620d4-0adb-425d-b7fb-0edfda94a3b6'
          ],
          constraints: {},
          default_address_fields: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Address Information',
          hidden: false,
          label: 'Billing Address',
          layout: 'full_width',
          name: 'billing_address',
          repeatable: 'true',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'address',
          id: '4a380eed-17f6-458f-bf58-64ad29ee59f2'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889'
          ],
          _purpose: [],
          constraints: {},
          default_address_fields: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Address Information',
          hidden: false,
          label: 'Delivery Address',
          layout: 'full_width',
          name: 'delivery_address',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          repeatable: 'true',
          type: 'address',
          id: '7990c01f-0d52-4cb2-946a-7a0386c5211d'
        },
        {
          label: 'Additional Addresses',
          name: 'additional_addresses',
          type: 'address',
          group: 'Address Information',
          layout: 'full_width',
          repeatable: true,
          has_primary: false,
          protected: true,
          id: '154ababb-9531-4a0d-883a-a4c0fa5fd23f',
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ]
        },
        {
          label: 'Dates',
          name: 'dates',
          type: 'datetime',
          show_in_table: true,
          hide_label: true,
          group: 'Appointments & Dates',
          layout: 'full_width',
          repeatable: true,
          protected: true,
          id: '81135d93-cdbd-47a8-9dfc-f2b6d407a47c'
        },
        {
          label: 'Memo',
          name: 'memo',
          type: 'string',
          group: 'Advanced Options',
          layout: 'full_width',
          multiline: true,
          protected: true,
          id: 'ed399b36-f6a9-49c3-b190-696285ce1403'
        },
        {
          label: 'Footer',
          name: 'footer_notes',
          type: 'string',
          group: 'Advanced Options',
          layout: 'full_width',
          multiline: true,
          protected: true,
          id: '55140fd3-70bf-49b5-ae04-3926540bff0f'
        },
        {
          label: 'Total Amount',
          name: 'total_amount',
          type: 'computed',
          show_in_table: true,
          hidden: true,
          amount_field: 'amount_total',
          currency_field: 'currency',
          computed: true,
          preview_value_formatter:
            '{{formatCurrencyAttribute entity attribute locale}}',
          value_formatter:
            '{{formatCurrencyAttribute entity attribute locale}}',
          protected: true,
          group: '_ungrouped',
          id: '481a4083-6faa-4511-afe0-4eade66a9189'
        },
        {
          label: 'Net Total',
          name: 'subtotal_amount',
          type: 'computed',
          show_in_table: true,
          hidden: true,
          amount_field: 'amount_subtotal',
          currency_field: 'currency',
          computed: true,
          protected: true,
          preview_value_formatter:
            '{{formatCurrencyAttribute entity attribute locale}}',
          value_formatter:
            '{{formatCurrencyAttribute entity attribute locale}}',
          group: '_ungrouped',
          id: '3f38edd3-5a3b-4d2e-b308-7774cff3d80e'
        },
        {
          label: 'Total Tax',
          name: 'total_tax',
          type: 'computed',
          show_in_table: true,
          hidden: true,
          amount_field: 'amount_tax',
          currency_field: 'currency',
          computed: true,
          preview_value_formatter:
            '{{formatCurrencyAttribute entity attribute locale}}',
          value_formatter:
            '{{formatCurrencyAttribute entity attribute locale}}',
          protected: true,
          group: '_ungrouped',
          id: 'be47d4ef-d2af-4c47-8649-270b588be185'
        },
        {
          label: 'Products',
          name: 'products',
          type: 'relation',
          show_in_table: true,
          hidden: true,
          allowedSchemas: ['product'],
          enable_relation_picker: false,
          enable_relation_tags: false,
          protected: true,
          group: '_ungrouped',
          id: 'a6439a88-1e2b-4a39-87cb-3dd747ae42c2'
        },
        {
          label: 'Prices',
          name: 'prices',
          type: 'relation',
          show_in_table: true,
          hidden: true,
          allowedSchemas: ['price'],
          enable_relation_picker: false,
          enable_relation_tags: false,
          protected: true,
          group: '_ungrouped',
          id: 'b3f367fc-994c-43cc-87ce-dc33f10f4a19'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          hidden: true,
          label: 'Purposes',
          name: '_purpose',
          parents: [],
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'purpose',
          id: 'ac456430-810d-4fdb-8ff7-3ffca20a6214'
        },
        {
          _manifest: ['3babbfe8-80a2-4e19-b9e6-3d6d4fa22730'],
          _purpose: ['40ac8cb1-7764-4ba7-bab6-451c99b75916'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Zählerstand NT (in kWh)',
          name: 'zaehlerstand_nt_in_kwh',
          order: 10,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '6a107633-174a-4599-b8aa-946d9eb57a59'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            '914c287f-6450-4006-86dd-98b47f2d264d',
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            '40ac8cb1-7764-4ba7-bab6-451c99b75916',
            '4458076f-5665-4722-8041-2b02908cc857'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Nächstmöglicher Kündigungstermin',
          name: 'naechstmoeglicher_kuendigungstermin',
          order: 14,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '9b476331-6dc8-4ce3-a662-44d63d580758'
        },
        {
          _manifest: ['3babbfe8-80a2-4e19-b9e6-3d6d4fa22730'],
          _purpose: ['40ac8cb1-7764-4ba7-bab6-451c99b75916'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Zählerstand HT (in kWh)',
          name: 'zaehlerstand_ht_in_kwh',
          order: 9,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '64c3d8e5-da99-4a23-811b-0ea22d311828'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            '914c287f-6450-4006-86dd-98b47f2d264d',
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f',
            '40ac8cb1-7764-4ba7-bab6-451c99b75916',
            '4458076f-5665-4722-8041-2b02908cc857'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Ablesedatum',
          name: 'ablesedatum',
          order: 11,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '0c5229d5-2ab2-4351-b124-0501a163b4ae'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            '914c287f-6450-4006-86dd-98b47f2d264d',
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f',
            '40ac8cb1-7764-4ba7-bab6-451c99b75916',
            '4458076f-5665-4722-8041-2b02908cc857',
            '98322bf0-1cde-4610-abc3-a61e27aa9d02',
            '03d009c2-7812-46ff-8c14-c869fadab79a'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Einzugsdatum',
          name: 'einzugsdatum',
          order: 18,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: 'd0765c26-a8a2-4b45-a85b-13a8da0bb5cc'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            '55e7b643-0c7e-4745-a52f-62b51f33e85a',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            '914c287f-6450-4006-86dd-98b47f2d264d',
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            '40ac8cb1-7764-4ba7-bab6-451c99b75916',
            '4458076f-5665-4722-8041-2b02908cc857'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Übernahme Kündigung',
          name: 'uebernahme_kuendigung',
          options: [
            'Ja, zum nächstmöglichen Kündigungstermin',
            'Ja, schnellstmöglich (Vertragslaufzeit unbekannt)',
            'Nein, ich habe bereits selbst gekündigt.'
          ],
          order: 13,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '3b73fcd5-4ac8-4368-8b9e-920bee0a4180'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            '3babbfe8-80a2-4e19-b9e6-3d6d4fa22730',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Wechsel Datum',
          name: 'wechsel_datum',
          order: 21,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '8d8a825a-8356-4a40-a956-112630883521'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            '914c287f-6450-4006-86dd-98b47f2d264d',
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            '40ac8cb1-7764-4ba7-bab6-451c99b75916',
            '4458076f-5665-4722-8041-2b02908cc857'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Bisheriger Lieferant',
          name: 'bisheriger_lieferant',
          order: 15,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '7c841c4a-c024-4092-9744-a90b84c10a61'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            '55e7b643-0c7e-4745-a52f-62b51f33e85a',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            '914c287f-6450-4006-86dd-98b47f2d264d',
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            '40ac8cb1-7764-4ba7-bab6-451c99b75916',
            '4458076f-5665-4722-8041-2b02908cc857'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Belieferung',
          name: 'belieferung',
          options: ['Versorgerwechsel', 'Umzug', 'Tarifwechsel'],
          order: 3,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'ee07f048-e718-4177-ba08-d4ba8a5f603c'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            '4458076f-5665-4722-8041-2b02908cc857'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Erwarteter Jahresverbrauch (in kWh)',
          name: 'erwarteter_jahresverbrauch_in_kwh',
          order: 0,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'b2b3420d-12f3-44ad-ba63-15bc09c6c51a'
        },
        {
          _manifest: ['3babbfe8-80a2-4e19-b9e6-3d6d4fa22730'],
          _purpose: ['40ac8cb1-7764-4ba7-bab6-451c99b75916'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Erwarteter Jahresverbrauch HT (in kWh)',
          name: 'erwarteter_jahresverbrauch_ht_in_kwh',
          order: 1,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '64c03fc5-969f-4999-8ccc-9f33bd301388'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            '914c287f-6450-4006-86dd-98b47f2d264d',
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f',
            '40ac8cb1-7764-4ba7-bab6-451c99b75916',
            '4458076f-5665-4722-8041-2b02908cc857',
            '98322bf0-1cde-4610-abc3-a61e27aa9d02',
            '03d009c2-7812-46ff-8c14-c869fadab79a'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Marktlokationsnummer',
          name: 'marktlokationsnummer',
          order: 6,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'c506dee3-4463-423c-b51d-ddd88905b67f'
        },
        {
          _manifest: ['3babbfe8-80a2-4e19-b9e6-3d6d4fa22730'],
          _purpose: ['40ac8cb1-7764-4ba7-bab6-451c99b75916'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Erwarteter Jahresverbrauch NT (in kWh)',
          name: 'erwarteter_jahresverbrauch_nt_in_kwh',
          order: 2,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '78e71741-2875-4b55-8335-239c82759981'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f',
            '40ac8cb1-7764-4ba7-bab6-451c99b75916',
            '4458076f-5665-4722-8041-2b02908cc857'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Zählernummer',
          name: 'zaehlernummer',
          order: 7,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '9802fd6a-3426-4aa3-8416-30261ad2eec0'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            '3babbfe8-80a2-4e19-b9e6-3d6d4fa22730',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            '914c287f-6450-4006-86dd-98b47f2d264d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Kundennummer aktueller Vertrag',
          name: 'kundennummer_aktueller_vertrag',
          order: 22,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '981931d3-a38e-4dbd-a93e-7422e86d19da'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            '55e7b643-0c7e-4745-a52f-62b51f33e85a',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            '4458076f-5665-4722-8041-2b02908cc857'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Zählerstand (in kWh)',
          name: 'zaehlerstand_in_kwh',
          order: 8,
          readonly: false,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: '8f2b6e83-0027-45c3-9b8c-7e3c83fb74c7'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f',
            '40ac8cb1-7764-4ba7-bab6-451c99b75916',
            '4458076f-5665-4722-8041-2b02908cc857'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Messeinrichtung',
          name: 'messeinrichtung',
          order: 16,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'e34e3e66-97ab-4abd-baae-6428570ae2db'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            '914c287f-6450-4006-86dd-98b47f2d264d',
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f',
            '40ac8cb1-7764-4ba7-bab6-451c99b75916',
            '4458076f-5665-4722-8041-2b02908cc857'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Bestätigter Kündigungstermin',
          name: 'bestaetigter_kuendigungstermin',
          order: 16,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '2a2751d6-54bd-4c8e-8975-a9457c25942f'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            '3babbfe8-80a2-4e19-b9e6-3d6d4fa22730',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            '914c287f-6450-4006-86dd-98b47f2d264d',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Aktueller Tarif',
          name: 'aktueller_tarif',
          order: 20,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'ca8a0497-1fed-4a64-b8fa-ba361a441e9a'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Bisheriger Tarif',
          name: 'bisheriger_tarif',
          options: ['KOMFORT VERIVOX', 'FLEXIBEL VERIVOX'],
          order: 10,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'eda9898c-51e3-42b1-a4b6-ef38750b8b72'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'd158a785-3fa0-4fcd-8f70-7d2c5e7bb600',
          hidden: false,
          label: 'Zählerstand HT',
          layout: 'half_width',
          name: 'zaehlerstand',
          order: 2,
          readonly: true,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'e907fa03-b94b-4113-8a54-e402be1487f9'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Kündigungstermin',
          name: 'kuendigungstermin',
          order: 6,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '74728c37-1580-41da-83b0-7e96ca5bb59b'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Situation',
          name: 'situation',
          options: [
            'Versorgerwechsel: Ich möchte unter meiner aktuellen Adresse meinen Versorger wechseln.',
            'Umzug: Ich ziehe neu ein/bin neu eingezogen und möchte an meiner neuen Adresse beliefert werden.'
          ],
          order: 2,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'c473fecc-4bd6-46b5-ae82-46713d4ab67e'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Kündigungsdatum',
          name: 'kuendigungsdatum',
          order: 5,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '2958a351-5165-424a-a35f-b8a48c398e21'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Bisheriger Anbieter',
          name: 'bisheriger_anbieter',
          order: 8,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'ec6487d0-3895-4468-80e5-c1aaf57c38ea'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Datum Tarifwechsel',
          name: 'datum_tarifwechsel',
          order: 11,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '2b04c07c-694f-40d8-b721-8cb16399e9ce'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Kundennummer alter Vertrag',
          name: 'kundennummer_alter_vertrag',
          order: 12,
          readonly: true,
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number',
          id: 'c8c2d6fb-5e2c-40d5-a8e4-597042f8ec27'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Kündigung',
          name: 'kuendigung',
          options: [
            'Ja, zum nächstmöglichen Kündigungstermin.',
            'Ja, schnellstmöglich (Vertragslaufzeit unbekannt).',
            'Nein, ich habe bereits selbst gekündigt.'
          ],
          order: 3,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'f8c0c187-5579-40ee-aacd-b17cf93e4924'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'BDEW Codenummer',
          name: 'bdew_codenummer',
          order: 9,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '6c90eb48-2d30-44d4-a434-8e8cd53ee971'
        },
        {
          _manifest: ['cdea4235-5b19-4780-9b5b-b6946d85321e'],
          _purpose: [
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'deeb8b23-bc9a-4c2f-8d14-d124df85ce13',
            '6b7862a9-6ce8-4a7f-beef-0e170260b768',
            'ee5a9aad-61a9-4284-b528-3b1a20c7789f'
          ],
          allow_extra_options: false,
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          hidden: false,
          label: 'Umfrage',
          name: 'umfrage',
          options: [],
          order: 13,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'multiselect',
          id: 'f54f1fd1-0d67-4a3f-ad3f-4c6b01a9664e'
        },
        {
          group: '6da98448-0280-4de3-923f-164186a3f518',
          name: 'new_attribute_1734444656176',
          id: '8932cb00-9a95-4d1b-9532-8c81f24d7d85',
          label: 'Neues Attribut',
          order: 2,
          relation_type: 'has_many',
          enable_relation_picker: false,
          enable_relation_tags: false,
          hidden: false,
          has_primary: false,
          type: 'relation',
          layout: 'full_width',
          reverse_attributes: {
            opportunity: 'items'
          },
          allowedSchemas: ['opportunity']
        },
        {
          group: 'Order Details',
          name: 'extra_charge',
          id: '12b572e4-e890-438f-840b-c78eb47ccbde',
          label: 'Extra Charge',
          order: 5,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          currency_selector_only: false,
          currency: [
            {
              code: 'EUR',
              description: 'Euro',
              symbol: '€',
              flag: '🇪🇺'
            },
            {
              code: 'USD',
              description: 'US Dollar',
              symbol: '$',
              flag: '🇺🇸'
            },
            {
              code: 'CHF',
              description: 'Swiss franc',
              symbol: 'fr',
              flag: '🇨🇭'
            }
          ],
          type: 'currency'
        },
        {
          group: '6da98448-0280-4de3-923f-164186a3f518',
          name: 'ph_address',
          id: '5edb023b-30a8-497e-80e5-559d6435a8af',
          label: 'PH Address',
          order: 4,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: true,
          has_primary: false,
          type: 'address'
        },
        {
          _manifest: [
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: ['14384d9c-2d94-40ae-93a9-b3f891e43d19'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '90e35d0e-de12-4eb4-a34f-0b3675a6944a',
          has_primary: true,
          hidden: false,
          label: 'Payment Details',
          layout: 'full_width',
          name: 'payment_method',
          order: 0,
          protected: true,
          readonly: false,
          repeatable: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'payment',
          id: '35c737f0-02f8-4b6d-83ef-5c7be6e3f79f'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5d212edb-af7d-4738-8117-df0eef7d3850',
          hidden: false,
          label: 'Email',
          layout: 'half_width',
          name: 'billing_email',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'string',
          id: '7faecc39-6288-40d5-a6fb-20c6c9b1d2cd'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          actions: [],
          allowedSchemas: ['contact'],
          deprecated: false,
          details_view_mode_enabled: false,
          enable_relation_picker: true,
          enable_relation_tags: true,
          entity_builder_disable_edit: false,
          group: '5d212edb-af7d-4738-8117-df0eef7d3850',
          has_primary: true,
          hidden: false,
          hide_label: false,
          label: 'Billing Contact',
          layout: 'full_width',
          name: 'billing_contact',
          protected: true,
          readonly: false,
          relation_type: 'has_many',
          repeatable: true,
          required: false,
          reverse_attributes: {},
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          summary_fields: [],
          type: 'relation',
          id: 'd14dcfec-3373-4b76-9ae5-564fa2b6b5ca'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5d212edb-af7d-4738-8117-df0eef7d3850',
          hidden: false,
          label: 'Tax ID',
          layout: 'half_width',
          name: 'billing_vat',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '9e239320-df7d-43b5-87bb-42725ddb8dde'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5d212edb-af7d-4738-8117-df0eef7d3850',
          hidden: false,
          label: 'First Name',
          layout: 'half_width',
          name: 'billing_first_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'a49c7bcb-3710-49f8-a5b6-6c9fdd612812'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5d212edb-af7d-4738-8117-df0eef7d3850',
          hidden: false,
          label: 'Company name',
          layout: 'half_width',
          name: 'billing_company_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '2cbf3469-01ec-441f-8022-88eea3844a9d'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5d212edb-af7d-4738-8117-df0eef7d3850',
          hidden: false,
          label: 'Last Name',
          layout: 'half_width',
          name: 'billing_last_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '49961f30-c6c8-4641-a29d-e25673890d93'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: ['eaee073d-8fbe-4e4d-9ec9-a814ac5cbcd1'],
          default_value: {
            href: null,
            source_type: 'manual',
            title: 'manual'
          },
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '44a362d6-e988-437a-b29d-553c0e0f4c10',
          hidden: false,
          label: 'Source',
          name: 'source',
          protected: true,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'link',
          id: '905c9761-9ba5-48dd-9890-6592752fa39c'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5d212edb-af7d-4738-8117-df0eef7d3850',
          hidden: false,
          label: 'Phone',
          layout: 'half_width',
          name: 'billing_phone',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'string',
          id: '8229b920-22ee-4100-8864-58854b4bd5a0'
        },
        {
          _manifest: [
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: ['14384d9c-2d94-40ae-93a9-b3f891e43d19'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '8338bf4f-4cd4-4b49-be3b-9ba156f064fa',
          has_primary: true,
          hidden: false,
          label: 'Payment Details',
          layout: 'full_width',
          name: 'payment_method',
          order: 0,
          protected: true,
          readonly: false,
          repeatable: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'payment',
          id: '09f129c9-f8bb-4c3e-98d0-15484325bcc9'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '3f663dc2-d2b0-4fea-9800-ace388b487f0',
          hidden: false,
          label: 'Phone',
          layout: 'half_width',
          name: 'billing_phone',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'string',
          id: '4cbf65ee-f697-4840-9fb3-fd9bdaed9233'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: ['eaee073d-8fbe-4e4d-9ec9-a814ac5cbcd1'],
          default_value: {
            href: null,
            source_type: 'manual',
            title: 'manual'
          },
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '301c1f24-4f4e-4556-a4cc-9a1fd44719ae',
          hidden: false,
          label: 'Source',
          name: 'source',
          protected: true,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'link',
          id: '9ca629bb-4a40-42e9-ac64-91116b382664'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '3f663dc2-d2b0-4fea-9800-ace388b487f0',
          hidden: false,
          label: 'Email',
          layout: 'half_width',
          name: 'billing_email',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'string',
          id: 'cd40bac5-18b3-4d3f-b72b-e82efde601ac'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '3f663dc2-d2b0-4fea-9800-ace388b487f0',
          hidden: false,
          label: 'First Name',
          layout: 'half_width',
          name: 'billing_first_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '2e2fcd28-d97a-4a64-b1cc-98904f064d10'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '3f663dc2-d2b0-4fea-9800-ace388b487f0',
          hidden: false,
          label: 'Tax ID',
          layout: 'half_width',
          name: 'billing_vat',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '895d44ca-63fa-4b0a-810e-537c2f84acbc'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '3f663dc2-d2b0-4fea-9800-ace388b487f0',
          hidden: false,
          label: 'Company name',
          layout: 'half_width',
          name: 'billing_company_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '3fd536ff-1d2b-4f79-aeac-d34b6f02bc21'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          actions: [],
          allowedSchemas: ['contact'],
          deprecated: false,
          details_view_mode_enabled: false,
          enable_relation_picker: true,
          enable_relation_tags: true,
          entity_builder_disable_edit: false,
          group: '3f663dc2-d2b0-4fea-9800-ace388b487f0',
          has_primary: true,
          hidden: false,
          hide_label: false,
          label: 'Billing Contact',
          layout: 'full_width',
          name: 'billing_contact',
          protected: true,
          readonly: false,
          relation_type: 'has_many',
          repeatable: true,
          required: false,
          reverse_attributes: {},
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          summary_fields: [],
          type: 'relation',
          id: 'aef0bb73-9eb1-490d-aee4-50e118bfd77f'
        },
        {
          _manifest: [
            'f4466916-5af1-454e-851f-e9089162a025',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '3f663dc2-d2b0-4fea-9800-ace388b487f0',
          hidden: false,
          label: 'Last Name',
          layout: 'half_width',
          name: 'billing_last_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '735c5c3b-4298-49d5-a1bd-842f2ead081f'
        }
      ],
      capabilities: [
        {
          name: 'attributes',
          title: 'Attributes',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Overview',
              component: 'EntityOverview',
              order: 0
            }
          ],
          id: '1b91af4f-27c0-4f15-a990-b11b4b7913a5'
        },
        {
          name: 'relations',
          title: 'Relations',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Relations',
              component: 'EntityRelations',
              order: 10
            }
          ],
          id: 'e5987050-6bd9-4b4f-92d3-206b83769fda'
        },
        {
          name: 'files',
          title: 'Files',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Files',
              component: 'EntityFiles',
              order: 50
            }
          ],
          id: 'f19b5365-1afe-403d-8c7a-ead77480abfe'
        },
        {
          name: 'messages',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Messages',
              import: '@epilot360/messaging',
              order: 51,
              requiredPermission: {
                action: 'message:view'
              }
            },
            {
              icon: 'message',
              hook: 'SidePanel:Tab',
              route: 'emails',
              order: 51,
              import: '@epilot360/messaging',
              requiredPermission: {
                action: 'message:view'
              }
            }
          ],
          id: '86fac33a-bf2a-4051-87d0-c7407b2c3c99'
        },
        {
          name: 'activity_feed',
          title: 'Activity',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'activity_feed',
              order: 0
            },
            {
              icon: 'update',
              hook: 'SidePanel:Tab',
              route: 'activity_feed',
              order: 0
            }
          ],
          id: '56536f2c-1dce-45d9-a9f0-2f987bbb36ac'
        },
        {
          name: 'order_items',
          title: 'Ordered Items',
          ui_hooks: [
            {
              hook: 'EntityAttributes:Group',
              order: 1,
              title: 'Ordered Items',
              component: 'PricingItems'
            }
          ],
          id: '5f79f9e4-5d67-41d0-b6d0-83aa30792428'
        },
        {
          name: 'unmapped_attributes',
          title: 'Metadata',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              order: 500,
              title: 'Metadata',
              component: 'EntityUnmappedFields'
            }
          ],
          id: '4767d98d-1da3-467f-922d-cc4524572183'
        },
        {
          name: 'automation',
          title: 'Automation',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'automation',
              order: 30,
              import: '@epilot360/automation-capability'
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'play_circle_filled',
              route: '?activity_pilot=automation',
              order: 30,
              requiredPermission: {
                action: 'automation:trigger'
              }
            },
            {
              hook: 'SidePanel:Tab',
              route: 'automation',
              import: '@epilot360/automation-capability',
              title: 'Automation',
              icon: 'automation',
              order: 30
            }
          ],
          id: '20a07c3d-5b38-4aea-8d36-24a4f04975f1'
        },
        {
          name: 'workflow',
          title: 'Workflow',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'workflow',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              header: true,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'custom_workflow_icon',
              route: '?activity_pilot=workflow',
              order: 20,
              requiredPermission: {
                action: 'workflow:execution:trigger'
              }
            },
            {
              hook: 'EntityDetailsV2:Header',
              title: 'Workflow Overview',
              component: 'WorkflowOverview'
            },
            {
              icon: 'workflow',
              hook: 'SidePanel:Tab',
              route: 'workflow',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            }
          ],
          id: 'bcb3d003-d043-429f-8e78-066ac8eeb069'
        },
        {
          name: 'notes',
          title: 'Notes',
          attributes: [
            {
              id: 'bcb3d003-d043-429f-8e78-066ac8eeb069',
              name: 'notes',
              label: 'Notes',
              type: 'internal'
            }
          ],
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'task',
              route: '?drawer=notes',
              order: 50,
              requiredPermission: {
                action: 'entity:create',
                resource: 'comment'
              }
            },
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Notes',
              import: '@epilot360/notes',
              order: 52,
              requiredPermission: {
                action: 'entity:view',
                resource: 'comment'
              }
            }
          ],
          id: '716ead2c-c820-4ac8-bc37-d3ca2396758b'
        },
        {
          name: 'customer_messaging',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'email',
              route: '?drawer=messaging',
              order: 15,
              requiredPermission: {
                action: 'message:send'
              }
            }
          ],
          id: 'b2fcb2bc-9843-416f-ac50-155ad2ce5720'
        },
        {
          name: 'notes-v2',
          title: 'Notes V2',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20,
              header: true
            },
            {
              icon: 'note_stack',
              hook: 'SidePanel:Tab',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20
            }
          ],
          id: '00c26668-aa2b-4590-8a44-dfdfeb3cd396'
        },
        {
          name: 'journey_data',
          title: 'Journey Data',
          ui_hooks: [
            {
              hook: 'EntityAttributes:Group',
              title: 'Journey Data',
              import: '@epilot360/journey-data',
              order: 30
            }
          ],
          id: '97c1cdfd-6cfb-458a-aab6-f931dce1d4b9'
        },
        {
          name: 'workflow-in-entities',
          title: 'Workflow in Entity Table',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: 'workflows.primary.task_duedate',
              label: 'Workflow Next Open Task',
              component: 'WorkflowCell',
              width: 450
            }
          ],
          id: '90e56c12-4f0b-4222-9095-beb4fc1c83d9'
        },
        {
          name: 'workflows-and-more-in-entities',
          title: 'Workflow in Entity Table',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: 'workflows.primary.name',
              label: 'Active Workflow',
              component: 'ActiveWorkflowCell',
              width: 450
            },
            {
              hook: 'EntityTable:Column',
              field: 'workflows.primary.phase_name',
              label: 'Active Phase',
              component: 'ActivePhaseCell',
              width: 450
            }
          ],
          id: 'c3e1dfab-54e9-490f-946c-721c7b514b64'
        },
        {
          name: 'consents',
          title: 'Consents',
          ui_hooks: [
            {
              hook: 'EntityAttributes:Group',
              title: 'Consents',
              component: 'LocalConsents',
              order: 80
            }
          ],
          id: '4459178c-d188-4435-8641-2c61eb8563d6'
        },
        {
          name: 'unread',
          title: 'Unread',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: 'unread',
              label: 'Unread',
              sortable: false,
              show_in_table: true,
              order: 1,
              component: 'ActivityCell',
              width: 80
            }
          ],
          id: 'b3713f24-46a8-46f5-b2ff-22b5e58532hy'
        }
      ],
      created_by: '11000894',
      comment: 'Add sidepanel tabs UI hooks to schemas',
      source: {
        id: 'e5fee37e-7818-481b-bab1-ae563592d696',
        type: 'blueprint'
      },
      updated_at: '2025-02-10T23:19:35.671Z',
      version: 350,
      created_at: '2024-11-13T04:48:17.670Z',
      draft: false,
      group_headlines: [
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f'
          ],
          enable_divider: true,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          id: '6239775a-2a9a-4ab4-bc0b-56f01e78b364',
          label: 'Angaben zum Zähler',
          layout: 'full_width',
          name: 'headline_1717186540566',
          order: 4,
          type: 'headline'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f'
          ],
          enable_divider: true,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          id: 'cf14682b-8861-448d-96dc-473a0489be3e',
          label: 'Umzug',
          layout: 'full_width',
          name: 'headline_1717186416978',
          order: 17,
          type: 'headline'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d'
          ],
          enable_divider: true,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          id: '25e831ad-d3b6-4054-8b6f-6666197fd867',
          label: 'Tarifwechsel',
          layout: 'full_width',
          name: 'headline_1717186426163',
          order: 19,
          type: 'headline'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            'f15029a6-f189-4530-bf6c-b4bf52b9ce7b',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'c31cf166-c2aa-49d5-927c-3a5a5cf2778f'
          ],
          enable_divider: true,
          group: '5a174206-e04b-49bb-96bd-75d2d1509c49',
          id: '5bbd18a3-6609-4f7f-bd39-07b341694fa4',
          label: 'Versorgerwechsel',
          layout: 'full_width',
          name: 'headline_1717186436057',
          order: 12,
          type: 'headline'
        }
      ]
    },
    {
      id: 'bd8b9ac5-fb83-436c-8717-fddd2a300324',
      name: 'Contact',
      plural: 'Contacts',
      slug: 'contact',
      icon: 'contact',
      published: true,
      title_template: '{{first_name}} {{last_name}}',
      ui_config: {
        table_view: {
          view_type: 'default',
          row_actions: ['view', 'edit', 'share', 'delete'],
          bulk_actions: ['trigger_automation', 'send_email', 'export', 'delete']
        },
        create_view: {
          view_type: 'default'
        },
        edit_view: {
          view_type: 'default',
          summary_attributes: [
            'last_name',
            'first_name',
            'phone',
            'email',
            'account',
            '_acl.view'
          ]
        },
        list_item: {
          summary_attributes: [
            'last_name',
            'first_name',
            'phone',
            'email',
            'account',
            '_acl.view'
          ]
        },
        sharing: {
          show_sharing_button: true
        }
      },
      group_settings: [
        {
          _manifest: ['d28dc993-b1ad-43ec-bfb5-3d3ba6b8adc4'],
          _purpose: [],
          expanded: true,
          id: 'Personal Details',
          label: 'Personal Details',
          order: 10,
          settings_flag: []
        },
        {
          _manifest: ['d28dc993-b1ad-43ec-bfb5-3d3ba6b8adc4'],
          _purpose: [],
          expanded: true,
          id: 'Contact Details',
          label: 'Contact Details',
          order: 20,
          settings_flag: []
        },
        {
          _manifest: ['6cb72012-c8cc-4108-a034-41194e5937a9'],
          _purpose: [],
          expanded: true,
          id: 'Company Details',
          label: 'Company Details',
          order: 30,
          settings_flag: []
        },
        {
          _manifest: [
            '532d20fb-eb6d-42e0-9c94-56a843620f12',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          expanded: true,
          id: 'Address Details',
          label: 'Address Details',
          order: 3,
          settings_flag: []
        },
        {
          id: 'Marketing Consent Management',
          label: 'Marketing Consent Management',
          expanded: false,
          order: 60
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: [],
          expanded: false,
          id: 'Payment Details',
          label: 'Payment Details',
          order: 4,
          settings_flag: []
        },
        {
          id: 'deeb48a6-7c46-4608-878d-96dc373e3ea0',
          label: 'New Group',
          expanded: false
        },
        {
          _manifest: ['5817d5f8-0495-4f34-8a91-e880e0ae49cd'],
          _purpose: [],
          expanded: true,
          label: 'zugeordnetes Unternehmen',
          order: 2,
          settings_flag: [],
          id: '0228c332-7f39-429f-ba2b-eae76a947df4'
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          expanded: false,
          label: 'Installateurportal',
          order: 1,
          settings_flag: [],
          id: '46a03d00-b56a-4fa5-abfd-b39d7ebf3662'
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          expanded: false,
          label: 'Installateur Strom',
          order: 2,
          render_condition: 'strom = "true"',
          settings_flag: [],
          id: 'f78ce1cd-af4d-49dd-890e-303b53ce499a'
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          expanded: false,
          label: 'Installateur Gas / Wasser',
          order: 3,
          render_condition: 'gas_wasser = "true"',
          settings_flag: [],
          id: 'cf11eed7-570d-440f-88be-84e71d927576'
        },
        {
          id: 'a72d2b7d-8fb4-45f0-86f9-78a1d0597b0d',
          label: 'New Group',
          expanded: false
        },
        {
          _manifest: [
            'd28dc993-b1ad-43ec-bfb5-3d3ba6b8adc4',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          expanded: true,
          label: 'Contact Details',
          order: 20,
          settings_flag: [],
          id: 'f877ab9e-6326-4b41-bedd-8eec51136ccb'
        },
        {
          _manifest: [
            '532d20fb-eb6d-42e0-9c94-56a843620f12',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          expanded: true,
          label: 'Address Details',
          order: 3,
          settings_flag: [],
          id: 'fa623fbe-ece8-49a5-8269-3c544927ae1c'
        },
        {
          _manifest: [
            'd28dc993-b1ad-43ec-bfb5-3d3ba6b8adc4',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          expanded: true,
          label: 'Personal Details',
          order: 10,
          settings_flag: [],
          id: 'e194949e-d6bb-4b28-96b1-f12247e79d96'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          expanded: false,
          label: 'Payment Details',
          order: 4,
          settings_flag: [],
          id: '13ed827a-0fdb-4937-9385-9b659ea79bd0'
        },
        {
          _manifest: [
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          expanded: true,
          label: 'Company Details',
          order: 30,
          settings_flag: [],
          id: '624b4073-fb66-4e66-b365-d38cebd03cc1'
        },
        {
          _manifest: [
            'be689a47-0be1-4d9d-820f-43964b4c332a',
            'f5393bbd-e507-4e3a-9046-42feb5684891',
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '50fb9921-3b0d-430a-9211-5b741e601008',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          expanded: false,
          label: 'Payment Details',
          order: 4,
          settings_flag: [],
          id: '2cd6dd83-8daf-41b0-b101-9f60d75b2d2c'
        },
        {
          _manifest: [
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          expanded: true,
          label: 'Company Details',
          order: 30,
          settings_flag: [],
          id: '681be3f6-3839-417d-960f-5abb8b414b12'
        },
        {
          _manifest: [
            'd28dc993-b1ad-43ec-bfb5-3d3ba6b8adc4',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          expanded: true,
          label: 'Personal Details',
          order: 10,
          settings_flag: [],
          id: '5096f1b0-38b1-4aab-95c7-0a73bd65449c'
        },
        {
          _manifest: [
            '532d20fb-eb6d-42e0-9c94-56a843620f12',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          expanded: true,
          label: 'Address Details',
          order: 3,
          settings_flag: [],
          id: 'a1cd0c1c-b040-4362-9e62-98401db13b0b'
        },
        {
          _manifest: [
            'd28dc993-b1ad-43ec-bfb5-3d3ba6b8adc4',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          expanded: true,
          label: 'Contact Details',
          order: 20,
          settings_flag: [],
          id: '73402cea-6b4e-4e91-a8ed-1b30ed69c1af'
        }
      ],
      attributes: [
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          default_value: '',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Salutation',
          layout: 'half_width',
          name: 'salutation',
          options: [
            null,
            'Mr.',
            'Ms. / Mrs.',
            'Company',
            'Contact Person',
            'Company/Contact Person',
            'Spouse',
            'Family',
            'Ownership',
            'Assembly',
            'Other'
          ],
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'e630fde2-8e88-4fd5-bc56-86d884870c6d',
          order: 1
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Title',
          layout: 'half_width',
          name: 'title',
          options: [null, 'Dr.', 'Prof.', 'Prof. Dr.'],
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'c8fd4b95-48f7-4cf5-a189-bed76c15eef8',
          order: 2
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'First Name',
          layout: 'half_width',
          name: 'first_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '9ed8da80-6303-43d3-975b-2090d57850a7',
          order: 3
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Last Name',
          layout: 'half_width',
          name: 'last_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'a89f4893-0b34-48e8-ac10-13c701030049',
          order: 4
        },
        {
          label: 'Customer Number',
          name: 'customer_number',
          type: 'string',
          show_in_table: true,
          group: 'Personal Details',
          layout: 'half_width',
          protected: true,
          id: 'fafae1ea-df98-43e3-9f7e-6f4bc92210fd',
          order: 5
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Date of Birth',
          layout: 'half_width',
          name: 'birthdate',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '24c5c4f5-e363-4ffe-a645-41ed221d459c',
          order: 6
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contact Details',
          has_primary: true,
          hidden: false,
          label: 'Email',
          layout: 'full_width',
          name: 'email',
          protected: true,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'email',
          id: 'ee79fcb9-fb26-47cf-a86e-0fd4aad08c0e'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contact Details',
          has_primary: true,
          hidden: false,
          label: 'Phone',
          layout: 'full_width',
          name: 'phone',
          protected: true,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'phone',
          id: '9e1a2798-2f13-424e-b39a-f13d8d1cad34'
        },
        {
          label: 'Shared With',
          name: '_acl.view',
          type: 'partner_organisation',
          show_in_table: true,
          sortable: false,
          group: 'Contact Details',
          layout: 'full_width',
          protected: true,
          id: '3d46049c-4490-49c1-8102-5228790bd474',
          _purpose: []
        },
        {
          label: 'Standard communication method',
          name: 'communication_preference',
          type: 'select',
          options: ['postal', 'portal'],
          show_in_table: true,
          group: 'Contact Details',
          layout: 'half_width',
          protected: true,
          id: '5d96a070-0cd7-4b0c-9f48-56aee83c8459',
          _purpose: []
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212'
          ],
          _purpose: [
            '05e0b4be-fc10-4408-9cd3-c92edc059a2d',
            '0c4f28bd-f567-4e4a-9b08-0e524fa0cd44',
            '062d0e37-da89-450f-94d9-69de9e99e4ec',
            '7ee98073-a772-4928-81df-012c11d5083d'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Address Details',
          has_primary: true,
          hidden: false,
          label: 'Address',
          layout: 'full_width',
          name: 'address',
          protected: true,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          sortable: true,
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ],
          type: 'address',
          id: '715bd3b8-9fb5-4ca9-b3b2-0766ee9616b1',
          order: 1
        },
        {
          _manifest: ['5817d5f8-0495-4f34-8a91-e880e0ae49cd'],
          _purpose: [
            'a317a17e-02e8-43e9-aa3a-c5f96f146244',
            '97aa1000-c465-4b0c-af3f-bde96d14d3dd',
            'ef127572-5a03-460f-8832-02405b60184e',
            '14384d9c-2d94-40ae-93a9-b3f891e43d19',
            '9ba855e8-67a5-4271-8938-8e0608d10655'
          ],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Payment Details',
          has_primary: true,
          hidden: false,
          label: 'Payment',
          layout: 'full_width',
          name: 'payment',
          protected: true,
          readonly: false,
          repeatable: true,
          required: true,
          settings_flag: [],
          sortable: true,
          type: 'payment',
          id: '4ad28852-e80c-4301-b6b1-5adbdb70beb7'
        },
        {
          _manifest: ['6cb72012-c8cc-4108-a034-41194e5937a9'],
          _purpose: [],
          actions: [],
          allowedSchemas: ['account'],
          deprecated: false,
          details_view_mode_enabled: false,
          enable_relation_picker: true,
          enable_relation_tags: true,
          entity_builder_disable_edit: false,
          group: 'Company Details',
          has_primary: true,
          hidden: false,
          hide_label: true,
          label: 'Account',
          layout: 'full_width',
          name: 'account',
          protected: true,
          readonly: false,
          relation_type: 'has_many',
          repeatable: true,
          required: false,
          reverse_attributes: {},
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          summary_fields: [],
          type: 'relation',
          id: '0dfa78c1-f042-4b7a-89be-147f37318cf1'
        },
        {
          name: 'marketing_permission',
          type: 'boolean',
          label: 'Marketing Permission',
          group: 'Contact Details',
          layout: 'half_width',
          deprecated: true,
          protected: true,
          id: '128e854f-fdb0-4c09-828c-1960e7bf7835',
          _purpose: []
        },
        {
          label: 'Contact Owner',
          name: 'contact_owner',
          type: 'relation_user',
          multiple: false,
          show_in_table: true,
          group: 'Contact Details',
          layout: 'full_width',
          protected: true,
          id: '83669be7-f600-4bf4-90fa-d6a4e4003f0e',
          _purpose: []
        },
        {
          label: 'Email Marketing',
          type: 'consent',
          show_in_table: true,
          name: 'consent_email_marketing',
          group: 'Marketing Consent Management',
          topic: 'EMAIL_MARKETING',
          identifiers: ['email[*].email'],
          protected: true,
          id: '8a168b5f-58e2-434b-abc8-da7c5ae07583'
        },
        {
          label: 'SMS Marketing',
          type: 'consent',
          name: 'consent_sms_marketing',
          group: 'Marketing Consent Management',
          topic: 'SMS_MARKETING',
          identifiers: ['phone[*].phone'],
          protected: true,
          id: '6f7882c2-e477-46e7-b5ec-accc7c2874fe'
        },
        {
          label: 'Direct Phone Marketing',
          type: 'consent',
          name: 'consent_phone_call',
          group: 'Marketing Consent Management',
          topic: 'PHONE_CALL_MARKETING',
          identifiers: ['phone[*].phone'],
          protected: true,
          id: '04aef77d-9167-4a86-b9d3-ab2cf7dce350'
        },
        {
          label: 'Print Marketing',
          type: 'consent',
          name: 'consent_print_marketing',
          group: 'Marketing Consent Management',
          topic: 'PRINT_MARKETING',
          protected: true,
          id: '4f84534d-512c-45d9-a84b-744f98508d4c'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          hidden: true,
          label: 'Purposes',
          name: '_purpose',
          parents: [],
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'purpose',
          id: '8893db63-1022-44f4-8562-1c10fd0bd6ea'
        },
        {
          label: 'Portal Users',
          hidden: true,
          show_in_table: true,
          name: 'portal_users',
          type: 'relation',
          relation_type: 'has_many',
          allowedSchemas: ['portal_user'],
          protected: true,
          reverse_attributes: {
            portal_user: 'mapped_contact'
          },
          settings_flag: [
            {
              name: 'end_customer_portal',
              enabled: true
            },
            {
              name: 'installer_portal',
              enabled: true
            }
          ],
          id: '96bf8723-04b0-48b8-a479-d175c6de3ea1'
        },
        {
          _manifest: ['bfc0cb46-b6aa-4b2d-83ed-43ef6d23bad1'],
          _purpose: [
            '5653809f-b45a-4f88-b199-b58041967bc6',
            '7d922046-c37c-405c-963e-83be16064f59'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Art der Eintragung',
          name: 'art_der_eintragung',
          options: ['Haupteintragung', 'Gasteintragung'],
          order: 7,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'd7fb873f-30dc-45ab-9eb4-f14f673c3fde'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: [
            'e7bb0998-6838-4d1c-af9c-569cadd0fc6e',
            'f5263d47-e132-4e44-8d7e-f9a881ee3b98',
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            '7b4d34f5-490f-4587-9b2f-af95dff91005',
            '9e7fc365-5d42-4fad-944c-f4c1bfd53e31'
          ],
          allow_extra_options: false,
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Sparte',
          name: 'sparte',
          options: ['Strom', 'Gas', 'Wasser', 'Fernwärme', 'Gas / Wasser'],
          order: 8,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'multiselect',
          id: 'fa405799-9ee5-4caa-82ae-7949a79df00f'
        },
        {
          _manifest: ['bfc0cb46-b6aa-4b2d-83ed-43ef6d23bad1'],
          _purpose: [
            '5653809f-b45a-4f88-b199-b58041967bc6',
            'db9be2fb-f46d-421a-8a86-269d7fd71a05',
            '20fe55e1-02ea-48eb-8c77-86fbbb6a182d',
            'bd339552-d264-4a33-8c7a-77eee480fa51',
            '235971d6-8191-48a7-a0ef-a1bae4b636fa'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Qualifikation Storm',
          name: 'qualifikation_storm',
          options: [],
          order: 11,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'b632873d-5686-4ade-90c9-6d9dbd471dac'
        },
        {
          _manifest: ['bfc0cb46-b6aa-4b2d-83ed-43ef6d23bad1'],
          _purpose: ['235971d6-8191-48a7-a0ef-a1bae4b636fa'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Qualifikation Gas/ Wasser',
          name: 'qualifikation_gas_wasser',
          options: [],
          order: 18,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '19b694af-3fbd-4dc6-ab6b-e6a71a8067d0'
        },
        {
          _manifest: ['bfc0cb46-b6aa-4b2d-83ed-43ef6d23bad1'],
          _purpose: ['235971d6-8191-48a7-a0ef-a1bae4b636fa'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Schweißqualifikation gültig bis',
          name: 'schweissqualifikation_gueltig_bis',
          order: 23,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '4cfa2bb5-2f2d-4c1a-bf22-dd63f57652d1'
        },
        {
          _manifest: ['bfc0cb46-b6aa-4b2d-83ed-43ef6d23bad1'],
          _purpose: ['235971d6-8191-48a7-a0ef-a1bae4b636fa'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Schweißqualifikation vorhanden?',
          name: 'schweissqualifikation_vorhanden',
          options: ['Ja', 'Nein'],
          order: 21,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '8498b799-18d2-494e-9769-39b0ca5e0efc'
        },
        {
          group: 'Contact Details',
          name: 'whatever',
          type: 'string',
          id: '66069326-1ea3-46d5-80c2-1dd8c4ab7da7',
          label: 'Whatever',
          order: 6,
          _purpose: ['b9598d50-54cc-4c73-b0c8-00a0105c55e9']
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: [
            'd6c77db1-aa76-44b2-b021-6e540a367d91',
            '7f29234d-f033-4720-80e6-8a6dc53e0832'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Ausweisnummer Strom',
          name: 'ausweisnummer_strom',
          order: 10,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'e9a5a121-86e1-4307-b1b8-fecbf8990e4e'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: ['7b4d34f5-490f-4587-9b2f-af95dff91005'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Ausweis Wasser gültig bis',
          name: 'ausweis_wasser_gueltig_bis',
          order: 19,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '72cff0af-84e3-4e8c-a5ba-5a29fe193745'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: ['20fe55e1-02ea-48eb-8c77-86fbbb6a182d'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Ausweis Gas gültig bis',
          name: 'ausweis_gas_gueltig_bis',
          order: 16,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '3a03aa62-9949-4b56-a2db-309e8260fa9d'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: ['7b4d34f5-490f-4587-9b2f-af95dff91005'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Ausweisnummer Wasser',
          name: 'ausweisnummer_wasser',
          order: 17,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '7e95870a-c6b1-4881-835f-70a7a36ab564'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: ['d6c77db1-aa76-44b2-b021-6e540a367d91'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Ausweis Strom gültig bis',
          name: 'ausweis_strom_gueltig_bis',
          order: 12,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: 'ed3cae88-ce3c-44a3-ae8d-2348fae0c875'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: ['20fe55e1-02ea-48eb-8c77-86fbbb6a182d'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Personal Details',
          hidden: false,
          label: 'Ausweisnummer Gas',
          name: 'ausweisnummer_gas',
          order: 14,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '9eb7afba-a1fe-44f3-b2b7-2d0438909185'
        },
        {
          group: 'deeb48a6-7c46-4608-878d-96dc373e3ea0',
          name: 'represents_contact',
          id: '846f830d-cb07-4bcc-8566-6b60c26671d6',
          label: 'Represents Contact',
          order: 0,
          relation_type: 'has_many',
          enable_relation_picker: true,
          enable_relation_tags: true,
          hidden: false,
          has_primary: false,
          type: 'relation',
          layout: 'full_width',
          allowedSchemas: ['contact'],
          _purpose: []
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '46a03d00-b56a-4fa5-abfd-b39d7ebf3662',
          hidden: false,
          label: 'Strom',
          layout: 'one_third_width',
          name: 'strom',
          order: 1,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'boolean',
          id: '1a108a30-6ab7-4a26-aee8-19ac1df38995'
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '46a03d00-b56a-4fa5-abfd-b39d7ebf3662',
          hidden: false,
          label: 'Gas / Wasser',
          layout: 'one_third_width',
          name: 'gas_wasser',
          order: 2,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'boolean',
          id: '0cc06c63-2b42-43bf-af3a-3d47167dd30d'
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '46a03d00-b56a-4fa5-abfd-b39d7ebf3662',
          hidden: false,
          label: 'Status Zugang',
          layout: 'one_third_width',
          name: 'status_zugang',
          options: ['Zugang gewährt', 'Eingangsprüfung erforderlich'],
          order: 0,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'status',
          id: '51de9114-280c-482b-a275-6e8b30971357'
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'f78ce1cd-af4d-49dd-890e-303b53ce499a',
          hidden: false,
          label: 'Netzbetreiber Eintragung (Strom)',
          layout: 'one_third_width',
          name: 'netzbetreiber_eintragung_strom',
          order: 2,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'b6b504f6-e59d-4d70-b83b-4cb010c67a3f'
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'f78ce1cd-af4d-49dd-890e-303b53ce499a',
          hidden: false,
          label: 'Ausweis gültig bis (Strom)',
          layout: 'one_third_width',
          name: 'ausweis_gueltig_bis_strom',
          order: 1,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '01581fc1-59e3-4ba5-b70a-1c15776b1167'
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'cf11eed7-570d-440f-88be-84e71d927576',
          hidden: false,
          label: 'Ausweis gültig bis (Gas/Wasser)',
          layout: 'one_third_width',
          name: 'ausweis_gueltig_bis_gas_wasser',
          order: 1,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '88cf3c5d-1492-477c-9b5a-f938f318c059'
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'cf11eed7-570d-440f-88be-84e71d927576',
          hidden: false,
          label: 'Ausweisnummer (Gas/Wasser)',
          layout: 'one_third_width',
          name: 'ausweisnummer_gas_wasser',
          order: 0,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '0a341719-96d5-4911-ac07-91e50de00a14'
        },
        {
          _manifest: [
            'db58002f-80c4-47db-9a65-12d96453b6fe',
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd'
          ],
          _purpose: ['7f29234d-f033-4720-80e6-8a6dc53e0832'],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'cf11eed7-570d-440f-88be-84e71d927576',
          hidden: false,
          label: 'Netzbetreiber Eintragung (Gas/Wasser)',
          layout: 'one_third_width',
          name: 'netzbetreiber_eintragung_gas_wasser',
          order: 2,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '6c6f01c2-7cd7-496e-8569-8af1dadf7de6'
        },
        {
          group: 'a72d2b7d-8fb4-45f0-86f9-78a1d0597b0d',
          name: 'contract',
          id: '007c30f2-1725-441c-ace0-3acff05fd5d2',
          label: 'Contract',
          order: 2,
          relation_type: 'has_many',
          enable_relation_picker: true,
          enable_relation_tags: true,
          hidden: false,
          has_primary: false,
          type: 'relation',
          layout: 'full_width',
          allowedSchemas: ['contract']
        },
        {
          group: 'deeb48a6-7c46-4608-878d-96dc373e3ea0',
          name: 'random_number',
          id: 'b2d8105f-e4e9-470c-a094-09aeac9b1f74',
          label: 'Random Number',
          order: 2,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          showSeparator: true,
          type: 'number'
        },
        {
          group: 'Address Details',
          name: 'test_checkbox',
          id: '88c6b10b-7adc-4ae4-80b9-d05326bcf2fd',
          label: 'Test Checkbox',
          order: 0,
          display_type: 'checkbox',
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'boolean',
          _purpose: []
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [
            '05e0b4be-fc10-4408-9cd3-c92edc059a2d',
            '0c4f28bd-f567-4e4a-9b08-0e524fa0cd44',
            '062d0e37-da89-450f-94d9-69de9e99e4ec',
            '7ee98073-a772-4928-81df-012c11d5083d'
          ],
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'fa623fbe-ece8-49a5-8269-3c544927ae1c',
          has_primary: true,
          hidden: false,
          label: 'Address',
          layout: 'full_width',
          name: 'address',
          order: 1,
          protected: true,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'address',
          id: 'ade6e4d1-f6f8-4d51-aa00-1b38b3342ec0'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'f877ab9e-6326-4b41-bedd-8eec51136ccb',
          has_primary: true,
          hidden: false,
          label: 'Phone',
          layout: 'full_width',
          name: 'phone',
          protected: true,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'phone',
          id: 'f0b8e659-ae40-48e6-a89a-2717a6109c44'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'e194949e-d6bb-4b28-96b1-f12247e79d96',
          hidden: false,
          label: 'Title',
          layout: 'half_width',
          name: 'title',
          options: [null, 'Dr.', 'Prof.', 'Prof. Dr.'],
          order: 2,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '9391d1a7-7bf7-4a68-8cf3-13af820aad01'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'f877ab9e-6326-4b41-bedd-8eec51136ccb',
          has_primary: true,
          hidden: false,
          label: 'Email',
          layout: 'full_width',
          name: 'email',
          protected: true,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'email',
          id: '7490f81c-a356-44d2-8703-0619a8ec6ebb'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          default_value: '',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'e194949e-d6bb-4b28-96b1-f12247e79d96',
          hidden: false,
          label: 'Salutation',
          layout: 'half_width',
          name: 'salutation',
          options: [
            null,
            'Mr.',
            'Ms. / Mrs.',
            'Company',
            'Contact Person',
            'Company/Contact Person',
            'Spouse',
            'Family',
            'Ownership',
            'Assembly',
            'Other'
          ],
          order: 1,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '7f341578-5864-44aa-8910-f8a4dc358fa8'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'e194949e-d6bb-4b28-96b1-f12247e79d96',
          hidden: false,
          label: 'Last Name',
          layout: 'half_width',
          name: 'last_name',
          order: 4,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'd41b43e7-df85-4a47-8b0e-b6212e4ab087'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'e194949e-d6bb-4b28-96b1-f12247e79d96',
          hidden: false,
          label: 'Date of Birth',
          layout: 'half_width',
          name: 'birthdate',
          order: 6,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '1c535586-4a59-47d2-9fa3-a628b14dff74'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'e194949e-d6bb-4b28-96b1-f12247e79d96',
          hidden: false,
          label: 'First Name',
          layout: 'half_width',
          name: 'first_name',
          order: 3,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '67bf6539-90cc-4cfa-b8e5-6fdaeccbd16c'
        },
        {
          _manifest: [
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [
            'a317a17e-02e8-43e9-aa3a-c5f96f146244',
            '97aa1000-c465-4b0c-af3f-bde96d14d3dd',
            'ef127572-5a03-460f-8832-02405b60184e',
            '14384d9c-2d94-40ae-93a9-b3f891e43d19',
            '9ba855e8-67a5-4271-8938-8e0608d10655'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '13ed827a-0fdb-4937-9385-9b659ea79bd0',
          has_primary: true,
          hidden: false,
          label: 'Payment',
          layout: 'full_width',
          name: 'payment',
          protected: true,
          readonly: false,
          repeatable: true,
          required: true,
          settings_flag: [],
          sortable: true,
          type: 'payment',
          id: '7c88a102-4f44-44ea-8c25-c381bb185983'
        },
        {
          _manifest: [
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          actions: [],
          allowedSchemas: ['account'],
          deprecated: false,
          details_view_mode_enabled: false,
          enable_relation_picker: true,
          enable_relation_tags: true,
          entity_builder_disable_edit: false,
          group: '624b4073-fb66-4e66-b365-d38cebd03cc1',
          has_primary: true,
          hidden: false,
          hide_label: true,
          label: 'Account',
          layout: 'full_width',
          name: 'account',
          protected: true,
          readonly: false,
          relation_type: 'has_many',
          repeatable: true,
          required: false,
          reverse_attributes: {},
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          summary_fields: [],
          type: 'relation',
          id: 'b43c53da-6fc5-4bb2-ad36-349091e318dc'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5096f1b0-38b1-4aab-95c7-0a73bd65449c',
          hidden: false,
          label: 'Date of Birth',
          layout: 'half_width',
          name: 'birthdate',
          order: 6,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date',
          id: '6b116589-96bb-48a8-a4ed-a35aa3f9e73c'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5096f1b0-38b1-4aab-95c7-0a73bd65449c',
          hidden: false,
          label: 'First Name',
          layout: 'half_width',
          name: 'first_name',
          order: 3,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: 'b763479e-d74d-48a1-8736-eb67ce5d0217'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5096f1b0-38b1-4aab-95c7-0a73bd65449c',
          hidden: false,
          label: 'Title',
          layout: 'half_width',
          name: 'title',
          options: [null, 'Dr.', 'Prof.', 'Prof. Dr.'],
          order: 2,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: 'b56c6653-450a-46e7-90d5-6fe47d741860'
        },
        {
          _manifest: [
            '5817d5f8-0495-4f34-8a91-e880e0ae49cd',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [
            'a317a17e-02e8-43e9-aa3a-c5f96f146244',
            '97aa1000-c465-4b0c-af3f-bde96d14d3dd',
            'ef127572-5a03-460f-8832-02405b60184e',
            '14384d9c-2d94-40ae-93a9-b3f891e43d19',
            '9ba855e8-67a5-4271-8938-8e0608d10655'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '2cd6dd83-8daf-41b0-b101-9f60d75b2d2c',
          has_primary: true,
          hidden: false,
          label: 'Payment',
          layout: 'full_width',
          name: 'payment',
          protected: true,
          readonly: false,
          repeatable: true,
          required: true,
          settings_flag: [],
          sortable: true,
          type: 'payment',
          id: 'a5077330-38dc-4ac8-8b01-126c6daa2361'
        },
        {
          _manifest: [
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          actions: [],
          allowedSchemas: ['account'],
          deprecated: false,
          details_view_mode_enabled: false,
          enable_relation_picker: true,
          enable_relation_tags: true,
          entity_builder_disable_edit: false,
          group: '681be3f6-3839-417d-960f-5abb8b414b12',
          has_primary: true,
          hidden: false,
          hide_label: true,
          label: 'Account',
          layout: 'full_width',
          name: 'account',
          protected: true,
          readonly: false,
          relation_type: 'has_many',
          repeatable: true,
          required: false,
          reverse_attributes: {},
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          summary_fields: [],
          type: 'relation',
          id: 'bdbb4f80-62ab-4928-b7fa-b7af1acef50b'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          default_value: '',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5096f1b0-38b1-4aab-95c7-0a73bd65449c',
          hidden: false,
          label: 'Salutation',
          layout: 'half_width',
          name: 'salutation',
          options: [
            null,
            'Mr.',
            'Ms. / Mrs.',
            'Company',
            'Contact Person',
            'Company/Contact Person',
            'Spouse',
            'Family',
            'Ownership',
            'Assembly',
            'Other'
          ],
          order: 1,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select',
          id: '2e127efa-15e1-4de5-a2bf-b9896e20a2ca'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '73402cea-6b4e-4e91-a8ed-1b30ed69c1af',
          has_primary: true,
          hidden: false,
          label: 'Email',
          layout: 'full_width',
          name: 'email',
          protected: true,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'email',
          id: 'e5518ec3-d391-4eef-ac49-b3fd5b30b891'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '73402cea-6b4e-4e91-a8ed-1b30ed69c1af',
          has_primary: true,
          hidden: false,
          label: 'Phone',
          layout: 'full_width',
          name: 'phone',
          protected: true,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'phone',
          id: '6f64df88-9eb7-49df-9560-8f01943161be'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: '5096f1b0-38b1-4aab-95c7-0a73bd65449c',
          hidden: false,
          label: 'Last Name',
          layout: 'half_width',
          name: 'last_name',
          order: 4,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string',
          id: '4a9f2188-4f79-4189-8310-662d12f7a558'
        },
        {
          _manifest: [
            'f8ebc0f7-a459-4a81-883e-0d151d7b4aed',
            'dde1c501-db79-4201-a10b-e7edbce1a212',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [
            '05e0b4be-fc10-4408-9cd3-c92edc059a2d',
            '0c4f28bd-f567-4e4a-9b08-0e524fa0cd44',
            '062d0e37-da89-450f-94d9-69de9e99e4ec',
            '7ee98073-a772-4928-81df-012c11d5083d'
          ],
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'a1cd0c1c-b040-4362-9e62-98401db13b0b',
          has_primary: true,
          hidden: false,
          label: 'Address',
          layout: 'full_width',
          name: 'address',
          order: 1,
          protected: true,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'address',
          id: '23fa0f59-0b24-4821-9761-65ef064f1d26'
        },
        {
          group: '46a03d00-b56a-4fa5-abfd-b39d7ebf3662',
          name: 'test_1',
          type: 'string',
          id: 'e61900d1-4b20-4bc8-9c39-9160fe1b3d7f',
          label: 'Test 1',
          order: 4,
          layout: 'one_third_width'
        },
        {
          group: '46a03d00-b56a-4fa5-abfd-b39d7ebf3662',
          name: 'test_2',
          type: 'string',
          id: '1f33e865-a213-4b77-995c-994cc0cb29f0',
          label: 'Test 2',
          order: 6,
          layout: 'half_width'
        },
        {
          group: '46a03d00-b56a-4fa5-abfd-b39d7ebf3662',
          name: 'test_3',
          type: 'string',
          id: '9e7dac38-6b29-4855-b52b-a462620033fd',
          label: 'Test 3',
          order: 8,
          layout: 'full_width'
        }
      ],
      capabilities: [
        {
          name: 'attributes',
          title: 'Attributes',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Overview',
              component: 'EntityOverview',
              order: 0
            }
          ],
          id: 'b7218fb4-9183-4a10-a3a5-88a79ce7d5ed'
        },
        {
          name: 'relations',
          title: 'Relations',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Relations',
              component: 'EntityRelations',
              order: 10
            }
          ],
          id: '04a2d6fa-8f59-4cd6-8c7d-52229029a1a4'
        },
        {
          name: 'files',
          title: 'Files',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Files',
              component: 'EntityFiles',
              order: 50
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'article',
              title: 'Generate document',
              route: '?file_action=generate_from_templates',
              order: 60,
              requiredPermission: {
                action: 'entity:create',
                resource: 'file'
              }
            }
          ],
          id: '6e801ad6-a988-4423-933a-4d9665abade9'
        },
        {
          name: 'messages',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Messages',
              import: '@epilot360/messaging',
              order: 51,
              requiredPermission: {
                action: 'message:view'
              }
            },
            {
              icon: 'message',
              hook: 'SidePanel:Tab',
              route: 'emails',
              order: 51,
              import: '@epilot360/messaging',
              requiredPermission: {
                action: 'message:view'
              }
            }
          ],
          id: 'ff639ecc-82d8-4945-a675-ba596473b4d4'
        },
        {
          name: 'activity_feed',
          title: 'Activity',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'activity_feed',
              order: 0
            },
            {
              icon: 'update',
              hook: 'SidePanel:Tab',
              route: 'activity_feed',
              order: 0
            }
          ],
          id: '526c29a6-9311-42ec-8799-62579bcd22f3'
        },
        {
          name: 'automation',
          title: 'Automation',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'automation',
              order: 30,
              import: '@epilot360/automation-capability'
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'play_circle_filled',
              route: '?activity_pilot=automation',
              order: 30,
              requiredPermission: {
                action: 'automation:trigger'
              }
            },
            {
              hook: 'SidePanel:Tab',
              route: 'automation',
              import: '@epilot360/automation-capability',
              title: 'Automation',
              icon: 'automation',
              order: 30
            }
          ],
          id: '5ca3f70f-d0d2-4a5a-81c4-4d8f9640cb09'
        },
        {
          name: 'workflow',
          title: 'Workflow',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'workflow',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              header: true,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'custom_workflow_icon',
              route: '?activity_pilot=workflow',
              order: 20,
              requiredPermission: {
                action: 'workflow:execution:trigger'
              }
            },
            {
              hook: 'EntityDetailsV2:Header',
              title: 'Workflow Overview',
              component: 'WorkflowOverview'
            },
            {
              icon: 'workflow',
              hook: 'SidePanel:Tab',
              route: 'workflow',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            }
          ],
          id: '77fc6d42-f0f9-48e9-94c0-d713a63c63b4'
        },
        {
          name: 'flow',
          title: 'Flow',
          feature_flag: 'flows-graph-view',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'flow',
              import: '@epilot360/flows-sidebar',
              order: 20,
              header: true,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            },
            {
              icon: 'workflow',
              hook: 'SidePanel:Tab',
              route: 'flow',
              import: '@epilot360/flows-sidebar',
              order: 20,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            }
          ],
          id: '77fc6d42-f0f9-48e9-94c0-d783a63c63b6'
        },
        {
          name: 'customer_messaging',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'email',
              route: '?drawer=messaging',
              order: 15,
              requiredPermission: {
                action: 'message:send'
              }
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'email',
              title: 'Send email template',
              route: '?drawer=messaging&insert-email-template=true',
              order: 16,
              requiredPermission: {
                action: 'message:send'
              }
            }
          ],
          id: '673d4e47-61ae-4343-a709-49f7f51eb7b5'
        },
        {
          name: 'notes',
          title: 'Notes',
          attributes: [
            {
              id: '673d4e47-61ae-4343-a709-49f7f51eb7b5',
              name: 'notes',
              label: 'Notes',
              type: 'internal'
            }
          ],
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'task',
              route: '?activity_pilot=notes&note-create=new',
              order: 50,
              requiredPermission: {
                action: 'entity:create',
                resource: 'comment'
              }
            },
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Notes',
              import: '@epilot360/notes',
              order: 52,
              requiredPermission: {
                action: 'entity:view',
                resource: 'comment'
              }
            }
          ],
          id: 'a73fed66-64d7-4feb-b6df-807444b3e372'
        },
        {
          name: 'metadata',
          title: 'Metadata',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              order: 500,
              title: 'Metadata',
              component: 'EntityUnmappedFields'
            }
          ],
          id: '9960ab80-c417-4986-b746-ca1870521993'
        },
        {
          name: 'notes-v2',
          title: 'Notes V2',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20,
              header: true
            },
            {
              icon: 'note_stack',
              hook: 'SidePanel:Tab',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20
            }
          ],
          id: '5b9c4a17-6015-4881-ae65-8d86916a4cec'
        },
        {
          name: 'workflow-in-entities',
          title: 'Workflow in Entity Table',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: 'workflows.primary.task_duedate',
              label: 'Workflow Next Open Task',
              component: 'WorkflowCell',
              width: 450
            }
          ],
          id: '143c8f72-4c89-47cb-8b41-e39e38327107'
        },
        {
          name: 'workflows-and-more-in-entities',
          title: 'Workflow in Entity Table',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: 'workflows.primary.name',
              label: 'Active Workflow',
              component: 'ActiveWorkflowCell',
              width: 450
            },
            {
              hook: 'EntityTable:Column',
              field: 'workflows.primary.phase_name',
              label: 'Active Phase',
              component: 'ActivePhaseCell',
              width: 450
            }
          ],
          id: '23af7553-6776-4853-be6a-811e7837f4e5'
        },
        {
          name: 'unread',
          title: 'Unread',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: 'unread',
              label: 'Unread',
              sortable: false,
              show_in_table: false,
              order: 1,
              component: 'ActivityCell',
              width: 80
            }
          ],
          id: 'b3713f24-46a8-46f5-b2ff-22b5e58532hy'
        }
      ],
      created_by: '11001053',
      comment: '',
      source: {
        id: '11001053',
        type: 'user'
      },
      updated_at: '2025-02-21T18:08:44.136Z',
      version: 563,
      created_at: '2024-10-16T12:23:28.032Z',
      draft: false,
      group_headlines: [
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: [],
          enable_divider: true,
          group: 'Personal Details',
          id: '2168840d-63f4-42fb-9835-93bbe2b0b3f4',
          label: 'Strom',
          layout: 'full_width',
          name: 'headline_1699969525697',
          order: 9,
          type: 'headline'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: [],
          enable_divider: true,
          group: 'Personal Details',
          id: '586cb7f3-27e3-4739-a227-7749b538a5e8',
          label: 'Fernwärme',
          layout: 'full_width',
          name: 'headline_1728635215261',
          order: 22,
          type: 'headline'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9'
          ],
          _purpose: [],
          enable_divider: true,
          group: 'Personal Details',
          id: 'f2f7a360-aee1-4033-8ced-6dbaaf975176',
          label: 'Gas/ Wasser',
          layout: 'full_width',
          name: 'headline_1699969573539',
          order: 13,
          type: 'headline'
        },
        {
          _manifest: [
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '6f7c3a57-d77f-49a2-88d4-b9a3f8762f60',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: ['e7bb0998-6838-4d1c-af9c-569cadd0fc6e'],
          enable_divider: false,
          group: 'Personal Details',
          id: 'eb3d5fb2-849b-4055-bf32-964ad7967f18',
          label: 'Gas',
          layout: 'full_width',
          name: 'headline_1699969573539',
          order: 15,
          type: 'headline'
        },
        {
          _manifest: [
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '6f7c3a57-d77f-49a2-88d4-b9a3f8762f60',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b'
          ],
          _purpose: ['e7bb0998-6838-4d1c-af9c-569cadd0fc6e'],
          enable_divider: false,
          group: 'Personal Details',
          id: '381a28c7-bf20-4344-be68-9bd1bdc99086',
          label: 'Wasser',
          layout: 'full_width',
          name: 'headline_1699969611796',
          order: 20,
          type: 'headline'
        },
        {
          _manifest: [
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '6f7c3a57-d77f-49a2-88d4-b9a3f8762f60',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: ['e7bb0998-6838-4d1c-af9c-569cadd0fc6e'],
          enable_divider: false,
          group: 'e194949e-d6bb-4b28-96b1-f12247e79d96',
          label: 'Gas',
          layout: 'full_width',
          name: 'headline_1699969573539',
          order: 15,
          type: 'headline',
          id: 'f9b31473-a765-4b1d-9e52-f93cfb67aa79'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          enable_divider: true,
          group: 'e194949e-d6bb-4b28-96b1-f12247e79d96',
          label: 'Strom',
          layout: 'full_width',
          name: 'headline_1699969525697',
          order: 9,
          type: 'headline',
          id: '8984f091-08bc-4285-b598-921b277d6695'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          enable_divider: true,
          group: 'e194949e-d6bb-4b28-96b1-f12247e79d96',
          label: 'Fernwärme',
          layout: 'full_width',
          name: 'headline_1728635215261',
          order: 22,
          type: 'headline',
          id: 'fa406cbe-507c-48ff-86ee-c350004b2602'
        },
        {
          _manifest: [
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '6f7c3a57-d77f-49a2-88d4-b9a3f8762f60',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: ['e7bb0998-6838-4d1c-af9c-569cadd0fc6e'],
          enable_divider: false,
          group: 'e194949e-d6bb-4b28-96b1-f12247e79d96',
          label: 'Wasser',
          layout: 'full_width',
          name: 'headline_1699969611796',
          order: 20,
          type: 'headline',
          id: '6649b59b-32fe-4320-8f13-154e7433faa5'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '33769301-28ed-4303-b2a9-2a006ecde155'
          ],
          _purpose: [],
          enable_divider: true,
          group: 'e194949e-d6bb-4b28-96b1-f12247e79d96',
          label: 'Gas/ Wasser',
          layout: 'full_width',
          name: 'headline_1699969573539',
          order: 13,
          type: 'headline',
          id: 'b30bfa9c-813f-48aa-bff7-554320ac44c5'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          enable_divider: true,
          group: '5096f1b0-38b1-4aab-95c7-0a73bd65449c',
          label: 'Strom',
          layout: 'full_width',
          name: 'headline_1699969525697',
          order: 9,
          type: 'headline',
          id: 'aa9e5d9c-354f-483c-8707-91baf414abd4'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          enable_divider: true,
          group: '5096f1b0-38b1-4aab-95c7-0a73bd65449c',
          label: 'Gas/ Wasser',
          layout: 'full_width',
          name: 'headline_1699969573539',
          order: 13,
          type: 'headline',
          id: 'e0e9a144-55d3-4267-8ebd-5c6eb3b2f3c2'
        },
        {
          _manifest: [
            'e202219d-d3ad-4e1a-a0b4-d11d27ed4737',
            '6cb72012-c8cc-4108-a034-41194e5937a9',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: [],
          enable_divider: true,
          group: '5096f1b0-38b1-4aab-95c7-0a73bd65449c',
          label: 'Fernwärme',
          layout: 'full_width',
          name: 'headline_1728635215261',
          order: 22,
          type: 'headline',
          id: '6b70dbe7-9af7-48bc-9045-2feb7a6b4504'
        },
        {
          _manifest: [
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '6f7c3a57-d77f-49a2-88d4-b9a3f8762f60',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: ['e7bb0998-6838-4d1c-af9c-569cadd0fc6e'],
          enable_divider: false,
          group: '5096f1b0-38b1-4aab-95c7-0a73bd65449c',
          label: 'Gas',
          layout: 'full_width',
          name: 'headline_1699969573539',
          order: 15,
          type: 'headline',
          id: '145b2e4a-9bd9-4d96-974b-60deb965b5c4'
        },
        {
          _manifest: [
            'd535e116-31e3-48f3-b31b-d15cf67ff889',
            '6f7c3a57-d77f-49a2-88d4-b9a3f8762f60',
            '3eadf1e8-439e-45bc-a8f2-abecf162279d',
            '807a3c05-50dc-497b-b720-53a3fc2fe21b',
            '1ea1f54e-07ae-4af0-a802-2ac21cc1441d'
          ],
          _purpose: ['e7bb0998-6838-4d1c-af9c-569cadd0fc6e'],
          enable_divider: false,
          group: '5096f1b0-38b1-4aab-95c7-0a73bd65449c',
          label: 'Wasser',
          layout: 'full_width',
          name: 'headline_1699969611796',
          order: 20,
          type: 'headline',
          id: '83dd94cc-b172-4b3c-abab-eecf6a215c81'
        }
      ],
      description:
        'Contacts store details about customers or individuals managed by the epilot organization.',
      docs_url: 'https://docs.epilot.io/docs/entities/schemas-list/#contact',
      category: 'customer_relations'
    },
    {
      id: '6022356d-02c1-4e64-a572-ca06a6e251f8',
      slug: 'contract',
      name: 'Contract',
      plural: 'Contracts',
      published: true,
      draft: false,
      icon: 'contract',
      title_template: '{{contract_name}}',
      ui_config: {
        table_view: {
          bulk_actions: [
            'trigger_automation',
            'send_email',
            'export',
            'delete'
          ],
          enable_thumbnails: false,
          navbar_actions: [],
          row_actions: ['view', 'edit', 'duplicate', 'delete'],
          view_type: 'default'
        },
        create_view: {
          view_type: 'default'
        },
        edit_view: {
          view_type: 'default',
          summary_attributes: [
            'contract_number',
            'account_number',
            'contract_name',
            'status',
            '_updated_at',
            'customer',
            'delivery_address',
            'billing_address'
          ]
        },
        list_item: {
          summary_attributes: [
            'contract_number',
            'account_number',
            'contract_name',
            'status',
            '_updated_at',
            'customer'
          ]
        },
        sharing: {
          show_sharing_button: false
        }
      },
      capabilities: [
        {
          id: '9fbb7ba3-cbb1-4d4d-927f-34ff6ebfc25b',
          name: 'attributes',
          title: 'Attributes',
          ui_hooks: [
            {
              component: 'EntityOverview',
              hook: 'EntityDetailsV2:Tab',
              order: 0,
              title: 'Overview'
            }
          ]
        },
        {
          id: 'c8a3cdc4-52a2-4aa2-ac67-db0fd5d84833',
          name: 'relations',
          title: 'Relations',
          ui_hooks: [
            {
              component: 'EntityRelations',
              hook: 'EntityDetailsV2:Tab',
              order: 10,
              title: 'Relations'
            }
          ]
        },
        {
          id: '8395adb3-d3aa-4847-a0b1-5cfa74c964b7',
          name: 'workflow',
          title: 'Workflow',
          ui_hooks: [
            {
              header: true,
              hook: 'ActivityPilot:View',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              requiredPermission: {
                action: 'workflow:execution:view'
              },
              route: 'workflow'
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'custom_workflow_icon',
              order: 30,
              requiredPermission: {
                action: 'workflow:execution:trigger'
              },
              route: '?activity_pilot=workflow'
            },
            {
              component: 'WorkflowOverview',
              hook: 'EntityDetailsV2:Header',
              title: 'Workflow Overview'
            },
            {
              icon: 'workflow',
              hook: 'SidePanel:Tab',
              route: 'workflow',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            }
          ]
        },
        {
          id: 'b9a3c69d-3059-466c-9858-3ff2bd4fa31c',
          name: 'billing_event',
          title: 'Billing Events',
          ui_hooks: [
            {
              component: 'BillingEvents',
              hook: 'EntityAttributes:Group',
              order: 120,
              title: 'Billing Events'
            }
          ]
        },
        {
          id: 'd9980f7d-494b-48d3-8e97-2cc85123c16e',
          name: 'files',
          title: 'Files',
          ui_hooks: [
            {
              component: 'EntityFiles',
              hook: 'EntityDetailsV2:Tab',
              order: 50,
              title: 'Files'
            }
          ]
        },
        {
          id: 'fd3ce5f4-c734-4e6d-9d60-5212595271de',
          name: 'messages',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              import: '@epilot360/messaging',
              order: 51,
              requiredPermission: {
                action: 'message:view'
              },
              title: 'Messages'
            },
            {
              icon: 'message',
              hook: 'SidePanel:Tab',
              route: 'emails',
              order: 51,
              import: '@epilot360/messaging',
              requiredPermission: {
                action: 'message:view'
              }
            }
          ]
        },
        {
          id: '75bf6ce0-196c-4772-ae8a-7feb4bb22ca9',
          name: 'activity_feed',
          title: 'Activity',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              order: 0,
              route: 'activity_feed'
            },
            {
              icon: 'update',
              hook: 'SidePanel:Tab',
              route: 'activity_feed',
              order: 0
            }
          ]
        },
        {
          id: 'a1107f74-fc09-4410-8bde-3e833bde7bcc',
          name: 'automation',
          title: 'Automation',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              import: '@epilot360/automation-capability',
              order: 30,
              route: 'automation'
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'play_circle_filled',
              order: 30,
              requiredPermission: {
                action: 'automation:trigger'
              },
              route: '?activity_pilot=automation'
            },
            {
              hook: 'SidePanel:Tab',
              route: 'automation',
              import: '@epilot360/automation-capability',
              title: 'Automation',
              icon: 'automation',
              order: 30
            }
          ]
        },
        {
          id: '48cbb80a-c60d-4342-b994-a34dffef17fe',
          name: 'customer_messaging',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'email',
              order: 10,
              requiredPermission: {
                action: 'message:send'
              },
              route: '?drawer=messaging'
            }
          ],
          feature_flag: 'ff_order_messaging_enabled'
        },
        {
          id: '6cd96064-60ef-443f-b762-c05f941f6605',
          name: 'notes',
          title: 'Notes',
          attributes: [
            {
              _manifest: [],
              _purpose: [],
              deprecated: false,
              entity_builder_disable_edit: false,
              hidden: false,
              id: '079ddb47-9959-4bf1-867a-fada44fe1424',
              label: 'Notes',
              name: 'notes',
              readonly: false,
              required: false,
              settings_flag: [],
              sortable: true,
              type: 'internal'
            }
          ],
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'task',
              order: 50,
              requiredPermission: {
                action: 'entity:create',
                resource: 'comment'
              },
              route: '?drawer=notes'
            },
            {
              hook: 'EntityDetailsV2:Tab',
              import: '@epilot360/notes',
              order: 52,
              requiredPermission: {
                action: 'entity:view',
                resource: 'comment'
              },
              title: 'Notes'
            }
          ]
        },
        {
          id: '09a486fd-8f95-4824-b96e-e9b83aa5e78d',
          name: 'product_items',
          title: 'Product Items',
          ui_hooks: [
            {
              component: 'PricingItems',
              hook: 'EntityAttributes:Group',
              order: 30,
              title: 'Product Items'
            }
          ]
        },
        {
          name: 'notes-v2',
          title: 'Notes V2',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20,
              header: true
            },
            {
              icon: 'note_stack',
              hook: 'SidePanel:Tab',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20
            }
          ],
          id: '71b1a7a8-0dd2-433f-a841-645790592055'
        },
        {
          id: '8f7618e4-cd89-40fa-bea2-536b90fa8425',
          name: 'metadata',
          title: 'Metadata',
          ui_hooks: [
            {
              component: 'EntityUnmappedFields',
              hook: 'EntityDetailsV2:Tab',
              order: 500,
              title: 'Metadata'
            }
          ]
        },
        {
          id: '1d49b2f1-0830-4a7a-b95d-ae565b271a92',
          name: 'workflow-in-entities',
          title: 'Workflow in Entity Table',
          ui_hooks: [
            {
              component: 'WorkflowCell',
              field: 'workflows.primary.task_duedate',
              hook: 'EntityTable:Column',
              label: 'Workflow Next Open Task',
              width: 450
            }
          ]
        },
        {
          id: '784a5d44-76c0-4453-87ee-a75cdfd2c894',
          name: 'workflows-and-more-in-entities',
          title: 'Workflow in Entity Table',
          ui_hooks: [
            {
              component: 'ActiveWorkflowCell',
              field: 'workflows.primary.name',
              hook: 'EntityTable:Column',
              label: 'Active Workflow',
              width: 450
            },
            {
              component: 'ActivePhaseCell',
              field: 'workflows.primary.phase_name',
              hook: 'EntityTable:Column',
              label: 'Active Phase',
              width: 450
            }
          ]
        },
        {
          name: 'unread',
          title: 'Unread',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: 'unread',
              label: 'Unread',
              sortable: false,
              show_in_table: false,
              order: 1,
              component: 'ActivityCell',
              width: 80
            }
          ],
          id: 'b3713f24-46a8-46f5-b2ff-22b5e58532hy'
        }
      ],
      group_settings: [
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'General Details',
          label: 'General Details',
          order: 0,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'Customer Information',
          label: 'Customer Information',
          order: 1,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: false,
          id: 'Product Items',
          label: 'Product Items',
          order: 2,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'Contract Conditions',
          label: 'Contract Conditions',
          order: 3,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'Address Information',
          label: 'Address Information',
          order: 4,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'Payment Details',
          label: 'Payment Details',
          order: 5,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'Installment Information',
          label: 'Installment Information',
          order: 6,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'Termination',
          label: 'Termination',
          order: 7,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: false,
          id: 'b9febd91-2b21-499b-ac86-e54b1dc6a016',
          label: 'Umzug/Auszug',
          order: 8,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'Commodity Information',
          label: 'Commodity Information',
          order: 9,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'Order',
          label: 'Order',
          order: 10,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'Meters',
          label: 'Meters',
          order: 11,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: false,
          id: '_ungrouped',
          label: '_ungrouped',
          order: 12,
          settings_flag: []
        },
        {
          _manifest: [],
          _purpose: [],
          expanded: true,
          id: 'Billing Events',
          label: 'Billing Events',
          order: 13,
          settings_flag: []
        },
        {
          id: 'db9e21aa-ad3d-49ba-b75f-557c2098e1ef',
          label: 'New Group',
          expanded: false
        }
      ],
      attributes: [
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'General Details',
          hidden: false,
          id: 'd6a9c849-3eb7-4786-af99-71cdbe2bb759',
          label: 'Contract Name',
          layout: 'half_width',
          name: 'contract_name',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'string'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'General Details',
          hidden: false,
          id: '7d22b539-ad4b-422c-8221-a7b3b4d5ff44',
          label: 'Contract Number',
          layout: 'half_width',
          name: 'contract_number',
          protected: true,
          readonly: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'General Details',
          hidden: false,
          id: '8ac239d1-10c4-41ae-b8c8-77e94f85aa09',
          label: 'Assignee',
          layout: 'half_width',
          multiple: false,
          name: 'assignee',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'relation_user'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'General Details',
          hidden: false,
          id: 'e4a8b136-d2fc-4496-99af-dd8736ff12a1',
          label: 'Shared With',
          layout: 'full_width',
          name: '_acl.view',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: false,
          type: 'partner_organisation'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Payment Details',
          has_primary: true,
          hidden: false,
          id: 'cd689df3-773a-4fde-af79-23595270b05f',
          label: 'Payment Details',
          layout: 'full_width',
          name: 'payment',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'relation_payment_method'
        },
        {
          _manifest: [],
          _purpose: [],
          default_value: 'draft',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'General Details',
          hidden: false,
          id: 'f5117d38-e546-4a03-9c15-f18d8b4ed255',
          label: 'Status',
          layout: 'half_width',
          name: 'status',
          options: [
            {
              value: 'draft',
              title: 'Draft'
            },
            {
              value: 'in_approval_process',
              title: 'In Approval Process'
            },
            {
              value: 'approved',
              title: 'Approved'
            },
            {
              value: 'active',
              title: 'Active'
            },
            {
              value: 'deactivated',
              title: 'Deactivated'
            },
            {
              value: 'revoked',
              title: 'Revoked'
            },
            {
              value: 'terminated',
              title: 'Terminated'
            },
            {
              value: 'expired',
              title: 'Expired'
            }
          ],
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'status'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'General Details',
          hidden: false,
          id: '22b55f68-9e69-4e03-bbc6-cd21e56b5252',
          label: 'Description',
          layout: 'half_width',
          name: 'description',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Commodity Information',
          hidden: false,
          id: '17d67709-08e7-4bf9-8b64-44ab9e7ab59e',
          label: 'Account Number',
          layout: 'half_width',
          name: 'account_number',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Commodity Information',
          hidden: false,
          id: '3b05f742-11c0-4efc-aaa1-9ca54068b588',
          label: 'Branch',
          layout: 'half_width',
          name: 'branch',
          options: [
            {
              title: 'Power',
              value: 'power'
            },
            {
              title: 'Gas',
              value: 'gas'
            },
            {
              title: 'Water',
              value: 'water'
            },
            {
              title: 'Waste water',
              value: 'waste_water'
            },
            {
              title: 'District heating',
              value: 'district_heating'
            }
          ],
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select'
        },
        {
          _manifest: [],
          _purpose: [],
          constraints: {},
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Address Information',
          has_primary: false,
          hidden: false,
          id: '6bb2954d-63ab-4113-b5bf-c67f56fa451e',
          label: 'Billing Address',
          layout: 'full_width',
          name: 'billing_address',
          order: 0,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'relation_address',
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ]
        },
        {
          label: 'Move In Date',
          name: 'move_in_date',
          type: 'date',
          group: 'Commodity Information',
          layout: 'half_width',
          protected: true,
          id: 'c94dc7ab-9348-4f98-90f1-1f91fb283e0e'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Address Information',
          has_primary: false,
          hidden: false,
          id: '3aa963c8-c40c-41b8-a162-c83057d3e0d8',
          label: 'Delivery Address',
          layout: 'full_width',
          name: 'delivery_address',
          order: 1,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'address',
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ]
        },
        {
          label: 'Move Out Date',
          name: 'move_out_date',
          type: 'date',
          group: 'Commodity Information',
          layout: 'half_width',
          protected: true,
          id: 'f644a3e5-2f6d-44c6-a03e-f24727954b20'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Address Information',
          has_primary: false,
          hidden: false,
          id: 'fffdbd85-0428-456a-bdd2-7fe04d33c718',
          label: 'Additional Addresses',
          layout: 'full_width',
          name: 'additional_addresses',
          order: 2,
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'relation_address',
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ]
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Termination',
          hidden: false,
          id: '849ed4ae-f93b-49be-a1bd-0404d7a37247',
          label: 'Date of Termination',
          layout: 'half_width',
          name: 'termination_date',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: false,
          sortable: true,
          type: 'date'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Termination',
          hidden: false,
          id: '0d4936b9-360a-4af9-ab0f-f96067882db4',
          label: 'Reason For Termination',
          layout: 'half_width',
          name: 'termination_reason',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Customer Information',
          has_primary: true,
          hidden: false,
          id: 'c434eefd-8a8f-46aa-b108-72c2d9ba5421',
          label: 'Customer',
          layout: 'full_width',
          name: 'customer',
          order: 20,
          protected: false,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'relation',
          allowedSchemas: ['contact'],
          relation_type: 'has_many'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'General Details',
          hidden: false,
          id: '77b178f4-3932-48f4-ad74-e64e912e4173',
          label: 'Start Date',
          layout: 'half_width',
          name: 'start_date',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'date'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'General Details',
          hidden: false,
          id: 'bc56f027-5792-4505-af43-62f87738c539',
          label: 'End Date',
          layout: 'half_width',
          name: 'end_date',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'date'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Order',
          has_primary: true,
          hidden: false,
          id: '033ec971-5bb2-4718-afd2-753a85b05745',
          label: 'Order',
          layout: 'full_width',
          name: 'order',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'relation'
        },
        {
          _manifest: [],
          _purpose: [],
          allow_any: false,
          default_value: 'one_time',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: 'bd8fa15c-e48f-420f-84a4-601c4655bf47',
          label: 'Type',
          layout: 'full_width',
          name: 'type',
          options: [
            {
              title: 'One Time',
              value: 'one_time'
            },
            {
              title: 'Recurring',
              value: 'recurring'
            }
          ],
          protected: true,
          readonly: false,
          render_condition: 'is_composite_price = "false"',
          required: true,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'select'
        },
        {
          _manifest: [],
          _purpose: [],
          allow_any: false,
          default_value: 'weekly',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: '349f9035-bb6d-4c9e-a633-bc4d96049d5b',
          label: 'Billing Period',
          layout: 'full_width',
          name: 'billing_period',
          options: [
            {
              title: 'Weekly',
              value: 'weekly'
            },
            {
              title: 'Monthly',
              value: 'monthly'
            },
            {
              title: 'Every Quarter',
              value: 'every_quarter'
            },
            {
              title: 'Every 6 months',
              value: 'every_6_months'
            },
            {
              title: 'Yearly',
              value: 'yearly'
            }
          ],
          protected: true,
          readonly: false,
          render_condition: 'type = "recurring"',
          required: true,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'select'
        },
        {
          _manifest: [],
          _purpose: [],
          constraints: {},
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: '0d4c0a2f-8f60-4a6b-b043-bd0eecd241e9',
          label: 'Billing Duration',
          layout: 'half_width_condensed',
          name: 'billing_duration_amount',
          protected: true,
          readonly: false,
          render_condition: 'type = "recurring"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number'
        },
        {
          _manifest: [],
          _purpose: [],
          allow_any: false,
          default_value: 'months',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: 'd464c075-7379-46e1-8c2a-57f92cf1caa4',
          label: 'Billing Duration Unit',
          layout: 'half_width_condensed',
          name: 'billing_duration_unit',
          options: [
            {
              title: 'Weeks',
              value: 'weeks'
            },
            {
              title: 'Months',
              value: 'months'
            },
            {
              title: 'Years',
              value: 'years'
            }
          ],
          protected: true,
          readonly: false,
          render_condition: 'type = "recurring"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select'
        },
        {
          _manifest: [],
          _purpose: [],
          constraints: {},
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: '9b3d2966-bc95-44b2-bea7-e94c7d060442',
          label: 'Notice Time',
          layout: 'half_width_condensed',
          name: 'notice_time_amount',
          protected: true,
          readonly: false,
          render_condition: 'type = "recurring"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number'
        },
        {
          _manifest: [],
          _purpose: [],
          allow_any: false,
          default_value: 'months',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: '6de5ee2b-ab71-48a3-ad63-9f28702289e7',
          label: 'Notice Time Unit',
          layout: 'half_width_condensed',
          name: 'notice_time_unit',
          options: [
            {
              title: 'Weeks',
              value: 'weeks'
            },
            {
              title: 'Months',
              value: 'months'
            },
            {
              title: 'Years',
              value: 'years'
            }
          ],
          protected: true,
          readonly: false,
          render_condition: 'type = "recurring"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select'
        },
        {
          _manifest: [],
          _purpose: [],
          constraints: {},
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: 'a91d3b9e-fb75-4f90-b98e-e09a2b55485b',
          label: 'Termination Time',
          layout: 'half_width_condensed',
          name: 'termination_time_amount',
          protected: true,
          readonly: false,
          render_condition: 'type = "recurring"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number'
        },
        {
          _manifest: [],
          _purpose: [],
          allow_any: false,
          default_value: 'months',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: '97084614-4be0-4a26-9f47-387a87f6669a',
          label: 'Termination Time Unit',
          layout: 'half_width_condensed',
          name: 'termination_time_unit',
          options: [
            {
              title: 'Weeks',
              value: 'weeks'
            },
            {
              title: 'Months',
              value: 'months'
            },
            {
              title: 'Years',
              value: 'years'
            }
          ],
          protected: true,
          readonly: false,
          render_condition: 'type = "recurring"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select'
        },
        {
          _manifest: [],
          _purpose: [],
          constraints: {},
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: '91ab8dee-2e05-4c6b-af48-fb6f8e2e3b7d',
          label: 'Renewal Duration',
          layout: 'half_width_condensed',
          name: 'renewal_duration_amount',
          protected: true,
          readonly: false,
          render_condition: 'type = "recurring"',
          required: false,
          settings_flag: [],
          show_separator: true,
          sortable: true,
          type: 'number'
        },
        {
          _manifest: [],
          _purpose: [],
          allow_any: false,
          default_value: 'months',
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: '3bf00736-176c-4eea-95fa-b4692aff9e06',
          label: 'Renewal Duration Unit',
          layout: 'half_width_condensed',
          name: 'renewal_duration_unit',
          options: [
            {
              title: 'Weeks',
              value: 'weeks'
            },
            {
              title: 'Months',
              value: 'months'
            },
            {
              title: 'Years',
              value: 'years'
            }
          ],
          protected: true,
          readonly: false,
          render_condition: 'type = "recurring"',
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Installment Information',
          hidden: false,
          id: '17423fcf-a08f-48fc-acd1-25ca33aebfb2',
          label: 'Billing Due Day',
          name: 'billing_due_day',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          show_separator: true,
          sortable: true,
          type: 'number'
        },
        {
          _manifest: [],
          _purpose: [],
          currency: [
            {
              code: 'EUR',
              description: 'Euro',
              symbol: '€',
              flag: '🇪🇺'
            }
          ],
          currency_selector_only: false,
          default_value: {
            _spread: {
              suffix_currency: 'EUR',
              suffix_decimal: '0.00',
              value: 0
            }
          },
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Installment Information',
          hidden: false,
          id: '8a31380d-6ba2-4eec-9404-c9e91865bb53',
          label: 'Installment Amount',
          layout: 'full_width',
          name: 'installment_amount',
          protected: true,
          readonly: false,
          required: true,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'currency'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: false,
          entity_builder_disable_edit: false,
          group: '_ungrouped',
          hidden: true,
          id: '5caf8768-2723-4b44-b6d7-d3611319bc29',
          label: 'Products',
          name: 'products',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'relation'
        },
        {
          _manifest: [],
          _purpose: [],
          currency: [
            {
              code: 'EUR',
              description: 'Euro',
              symbol: '€',
              flag: '🇪🇺'
            }
          ],
          currency_selector_only: false,
          default_value: {
            _spread: {
              suffix_currency: 'EUR',
              suffix_decimal: '0.00',
              value: 0
            }
          },
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Installment Information',
          hidden: false,
          id: '098be039-d582-4958-bbc5-36891df9646f',
          label: 'Balance',
          layout: 'full_width',
          name: 'balance',
          protected: true,
          readonly: true,
          required: true,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'currency'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          hidden: true,
          id: '78e3cb2c-3a2a-4490-9a55-529b9f158d75',
          label: 'Purposes',
          name: '_purpose',
          parents: [],
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'purpose'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          hidden: false,
          id: 'd607f9e0-ac51-4862-a9c5-34675ae51896',
          label: 'Last Sync At',
          layout: 'half_width',
          name: 'last_sync_at',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: false,
          sortable: true,
          type: 'date'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: false,
          entity_builder_disable_edit: false,
          group: '_ungrouped',
          hidden: true,
          id: '04ba8362-f5f1-466e-aa65-7a445796ea0d',
          label: 'Prices',
          name: 'prices',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'relation'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Meters',
          hidden: false,
          hide_label: true,
          id: '91a4aaa4-c63f-40e1-807d-6f7425c8233c',
          label: 'Meters',
          layout: 'full_width',
          name: 'meters',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'relation'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'b9febd91-2b21-499b-ac86-e54b1dc6a016',
          hidden: false,
          id: '5e86767e-6653-4800-8dbc-9b4795e074ec',
          label: 'Auszugsdatum',
          name: 'auszugsdatum',
          order: 2,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'b9febd91-2b21-499b-ac86-e54b1dc6a016',
          hidden: false,
          id: 'ccf7f0cc-0e05-48c2-a926-96ee0dc3a2aa',
          label: 'Einzugsdatum',
          name: 'einzugsdatum',
          order: 4,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'date'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'b9febd91-2b21-499b-ac86-e54b1dc6a016',
          has_primary: false,
          hidden: false,
          id: 'f9e8ca02-6fe2-4d3d-ab05-77d8685a88da',
          label: 'Anschrift Schlussabrechnung',
          name: 'anschrift_schlussabrechnung',
          order: 8,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'address'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'b9febd91-2b21-499b-ac86-e54b1dc6a016',
          hidden: false,
          id: '9a0ce6f4-9210-491c-b32f-2c0f8135c8b2',
          label: 'Name Nachmieter/Eigentümer',
          name: 'name_nachmietereigentuemer',
          order: 10,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'b9febd91-2b21-499b-ac86-e54b1dc6a016',
          hidden: false,
          id: 'ab1c6b0b-b944-4cc0-8667-9dd2b2c9729f',
          label: 'Vertragsmitnahme?',
          name: 'vertragsmitnahme',
          order: 12,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'select'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'b9febd91-2b21-499b-ac86-e54b1dc6a016',
          has_primary: false,
          hidden: false,
          id: '0bdcbb3c-79b6-474c-bbe4-4a3ea68afcd3',
          label: 'Neue Lieferadresse',
          name: 'neue_lieferadresse',
          order: 14,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'address'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: '73924e80-d87c-48b2-b5ae-9a2df73c4c66',
          label: 'Arbeitspreis Eintarif (Brutto)',
          layout: 'one_third_width',
          multiline: false,
          name: 'arbeitspreis_eintarif_brutto',
          order: 10,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: '3dd3acdc-68c5-4c42-a3ce-df6db6d5a079',
          label: 'Arbeitspreis Hochtarif (Brutto)',
          layout: 'one_third_width',
          name: 'arbeitspreis_hochtarif_brutto',
          order: 12,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: '2cfd4d3d-1e0c-41bf-b537-85cf6b4d73bd',
          label: 'Arbeitspreis Niedertarif (Brutto)',
          layout: 'one_third_width',
          name: 'arbeitspreis_niedertarif_brutto',
          order: 14,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'Contract Conditions',
          hidden: false,
          id: 'e9b80ffb-efcb-4eca-ab35-626154d4ecb1',
          label: 'Grundpreis (Brutto)',
          layout: 'one_third_width',
          name: 'grundpreis_brutto',
          order: 16,
          readonly: false,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'string'
        },
        {
          _manifest: [],
          _purpose: [],
          deprecated: false,
          enable_relation_picker: true,
          entity_builder_disable_edit: false,
          group: 'Address Information',
          has_primary: false,
          hidden: false,
          id: 'd12f95d5-bc86-4e95-8933-494fff018438',
          label: 'Abweichende Rechnungsadresse',
          name: 'abweichende_rechnungsadresse',
          order: 3,
          readonly: false,
          repeatable: true,
          required: false,
          settings_flag: [],
          sortable: true,
          type: 'address'
        },
        {
          group: 'db9e21aa-ad3d-49ba-b75f-557c2098e1ef',
          name: 'test_datetime',
          id: 'a8ca4d5f-8aee-45c6-95d6-18eebd9c4d5d',
          label: 'Test datetime',
          order: 2,
          readonly: false,
          required: false,
          hidden: false,
          hintHelperText: '',
          repeatable: false,
          has_primary: false,
          type: 'datetime'
        }
      ],
      explicit_search_mappings: {
        anlage: {
          fields: {
            keyword: {
              ignore_above: 256,
              normalizer: 'lowercase',
              type: 'keyword'
            }
          },
          index: true,
          type: 'text'
        }
      },
      updated_at: '2025-02-26T12:14:54.764Z',
      version: 154,
      created_at: '2024-10-22T12:16:21.538Z',
      source: {
        id: 'e5fee37e-7818-481b-bab1-ae563592d696',
        type: 'blueprint'
      },
      comment:
        'Add default address fields to address_information attributes in contract, opportunity and request',
      created_by: '11000949',
      description:
        'Contracts store details about service agreements, tariffs and other agreements between customers and the epilot organization.',
      docs_url: 'https://docs.epilot.io/docs/entities/schemas-list/#contract',
      category: 'contracts'
    },
    {
      id: '1b2a93e8-f22c-4c4a-9d6e-7f3ab53eef33',
      name: 'Meter',
      plural: 'Meters',
      slug: 'meter',
      icon: 'meter',
      title_template: 'Hello',
      published: true,
      ui_config: {
        table_view: {
          view_type: 'default',
          row_actions: ['view', 'edit', 'duplicate', 'share', 'delete'],
          bulk_actions: ['export', 'delete']
        },
        edit_view: {
          ui_elements: [],
          summary_attributes: ['meter_number', 'status', 'counters', 'customer']
        },
        list_item: {
          summary_attributes: ['meter_number', 'status', 'counters', 'customer']
        }
      },
      group_settings: [
        {
          _purpose: [],
          expanded: true,
          id: 'General Details',
          label: 'General Details',
          order: 10,
          settings_flag: []
        },
        {
          id: 'Address Details',
          label: 'Address Details',
          expanded: true,
          order: 20
        },
        {
          id: 'Contract Information',
          label: 'Contract Information',
          expanded: true,
          order: 30
        },
        {
          id: 'Customer Information',
          label: 'Customer Information',
          expanded: true,
          order: 40
        },
        {
          id: 'Counters',
          label: 'Counters',
          expanded: true,
          order: 50
        }
      ],
      attributes: [
        {
          _purpose: [],
          deprecated: false,
          entity_builder_disable_edit: false,
          group: 'General Details',
          hidden: false,
          id: '523cbc5c-81b9-48d1-aeeb-08c604bb39c6',
          label: 'MaLo-ID',
          layout: 'half_width',
          name: 'ma_lo_id',
          protected: true,
          readonly: false,
          required: false,
          settings_flag: [],
          show_in_table: true,
          sortable: true,
          type: 'string'
        },
        {
          label: 'Meter Type',
          name: 'meter_type',
          type: 'select',
          options: [
            'three-phase-meter',
            'bellow-gas-meter',
            'rotary-piston-meter',
            'smart-meter',
            'performance-meter',
            'maximum-meter',
            'turbine-gas-meter',
            'ultrasonic-gas-meter',
            'alternating-current-meter',
            'modern-metering-system',
            'intelligent-measuring-system',
            'electronic-meter'
          ],
          group: 'General Details',
          layout: 'half_width',
          protected: true,
          id: '00cb0dac-4a9e-4ba2-96ba-253ef7db6c28'
        },
        {
          label: 'Tariff Type',
          name: 'tariff_type',
          type: 'string',
          group: 'General Details',
          layout: 'half_width',
          protected: true,
          id: '668c66e7-e258-4ef0-ac9e-5b74442e6075'
        },
        {
          label: 'Meter Number',
          name: 'meter_number',
          type: 'string',
          group: 'General Details',
          layout: 'half_width',
          protected: true,
          id: '37a927ef-ac13-4115-9e61-ad641ba30336'
        },
        {
          label: 'Sector',
          name: 'sector',
          type: 'select',
          options: ['power', 'gas', 'water', 'district_heating', 'waste_water'],
          group: 'General Details',
          layout: 'half_width',
          protected: true,
          id: 'b4079873-13ec-4f0c-95b6-0bb367b00958'
        },
        {
          label: 'Used For',
          name: 'used_for',
          type: 'string',
          group: 'General Details',
          layout: 'half_width',
          show_in_table: false,
          protected: true,
          id: 'ee024710-d572-4794-a48d-822ceaaef6b8'
        },
        {
          label: 'Manufacturer',
          name: 'manufacturer',
          type: 'string',
          group: 'General Details',
          layout: 'half_width',
          show_in_table: false,
          protected: true,
          id: 'e14151b5-0c87-43e4-b121-44e8a147d3f3'
        },
        {
          label: 'Calibration Date',
          name: 'calibration_date',
          type: 'date',
          group: 'General Details',
          show_in_table: false,
          layout: 'half_width',
          protected: true,
          id: 'c6c3cd11-9403-4590-b19d-c21e335250cf'
        },
        {
          label: 'Location',
          name: 'location',
          type: 'string',
          group: 'General Details',
          show_in_table: false,
          layout: 'half_width',
          protected: true,
          id: 'bd917549-f338-40f8-8a82-2c8e5b1a8df2'
        },
        {
          label: 'Status',
          name: 'status',
          type: 'select',
          options: ['active', 'decommissioned'],
          group: 'General Details',
          show_in_table: false,
          layout: 'half_width',
          protected: true,
          id: '85ff8ecf-e75f-5b99-ab4d-f2209284183a'
        },
        {
          label: 'Address',
          name: 'address',
          type: 'address',
          group: 'Address Details',
          layout: 'full_width',
          repeatable: true,
          has_primary: false,
          constraints: {
            limit: 1
          },
          protected: true,
          id: 'a368aef9-c958-4839-b59e-9f2e1718af8a',
          default_address_fields: [
            'postal_code',
            'city',
            'street',
            'street_number'
          ]
        },
        {
          name: 'contract',
          type: 'relation',
          relation_type: 'has_one',
          label: 'Contract',
          allowedSchemas: ['contract'],
          show_in_table: true,
          group: 'Contract Information',
          layout: 'full_width',
          has_primary: true,
          enable_relation_picker: true,
          enable_relation_tags: true,
          protected: true,
          id: '60d2b445-f6bf-49b9-b98e-a0bf88225b32'
        },
        {
          name: 'customer',
          type: 'relation',
          relation_type: 'has_many',
          label: 'Customer',
          allowedSchemas: ['contact', 'account'],
          show_in_table: true,
          group: 'Customer Information',
          layout: 'full_width',
          has_primary: true,
          enable_relation_picker: true,
          enable_relation_tags: true,
          protected: true,
          id: 'b3140894-57c2-4aed-9c3a-c613dacab4cd'
        },
        {
          label: 'Counters',
          hide_label: true,
          name: 'counters',
          type: 'relation',
          relation_type: 'has_many',
          relation_affinity_mode: 'strong',
          allowedSchemas: ['meter_counter'],
          group: 'Counters',
          layout: 'full_width',
          enable_relation_picker: true,
          enable_relation_tags: true,
          protected: true,
          actions: [
            {
              action_type: 'create_new',
              default: true,
              label: 'entityrelation.add_counter'
            },
            {
              action_type: 'add_existing',
              label: 'entityrelation.add_existing'
            }
          ],
          id: '1d8a2a41-b7a6-423a-9274-ee8dafa02f36'
        },
        {
          label: 'Purposes',
          name: '_purpose',
          show_in_table: true,
          hidden: true,
          type: 'purpose',
          id: '0b905922-741f-4eb2-932c-82aa3b3d6589'
        }
      ],
      capabilities: [
        {
          name: 'attributes',
          title: 'Attributes',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Overview',
              component: 'EntityOverview',
              order: 0
            }
          ],
          id: '6aba646a-2d74-41e4-82bd-f09520c2bb02'
        },
        {
          name: 'meter_readings',
          title: 'Meter Readings',
          ui_hooks: [
            {
              hook: 'EntityAttributes:Group',
              order: 60,
              title: 'Meter Readings',
              component: 'MeterReadings'
            }
          ],
          id: '8ee91376-fa9e-4211-ae56-f40283a3f9f0'
        },
        {
          name: 'relations',
          title: 'Relations',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Relations',
              component: 'EntityRelations',
              order: 10
            }
          ],
          id: 'b6951c15-70f2-4b6e-bc23-96a100473a3d'
        },
        {
          name: 'customer_messaging',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'email',
              route: '?drawer=messaging',
              order: 15,
              requiredPermission: {
                action: 'message:send'
              }
            },
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Messages',
              import: '@epilot360/messaging',
              order: 60,
              requiredPermission: {
                action: 'message:view'
              }
            }
          ],
          id: '3db24a72-3a30-4ee2-8642-4f231b87094a'
        },
        {
          name: 'files',
          title: 'Files',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Files',
              component: 'EntityFiles',
              order: 50
            }
          ],
          id: 'c3146bca-ae13-4edb-a4a3-a4e95a6a8500'
        },
        {
          name: 'messages',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Messages',
              import: '@epilot360/messaging',
              order: 51,
              requiredPermission: {
                action: 'message:view'
              }
            },
            {
              icon: 'message',
              hook: 'SidePanel:Tab',
              route: 'emails',
              order: 51,
              import: '@epilot360/messaging',
              requiredPermission: {
                action: 'message:view'
              }
            }
          ],
          id: 'efd3d393-fb4a-43ac-ab51-bc71ec68d64c'
        },
        {
          name: 'workflow',
          title: 'Workflow',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'workflow',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              header: true,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'custom_workflow_icon',
              route: '?activity_pilot=workflow',
              order: 30,
              requiredPermission: {
                action: 'workflow:execution:trigger'
              }
            },
            {
              hook: 'EntityDetailsV2:Header',
              title: 'Workflow Overview',
              component: 'WorkflowOverview'
            },
            {
              icon: 'workflow',
              hook: 'SidePanel:Tab',
              route: 'workflow',
              import: '@epilot360/workflows-sidebar',
              order: 20,
              requiredPermission: {
                action: 'workflow:execution:view'
              }
            }
          ],
          id: 'c3b53d4c-b701-430a-8bb6-bdec247854cc'
        },
        {
          name: 'activity_feed',
          title: 'Activity',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'activity_feed',
              order: 0
            },
            {
              icon: 'update',
              hook: 'SidePanel:Tab',
              route: 'activity_feed',
              order: 0
            }
          ],
          id: 'dd4cb463-c1d7-40da-a8cf-ed6b72d13319'
        },
        {
          name: 'notes',
          title: 'Notes',
          attributes: [
            {
              id: 'a7b1b3b4-4b3b-4b3b-4b3b-4b3b4b3b4b3b',
              name: 'notes',
              label: 'Notes',
              type: 'internal'
            }
          ],
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'task',
              route: '?drawer=notes',
              order: 50,
              requiredPermission: {
                action: 'entity:create',
                resource: 'comment'
              }
            },
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Notes',
              import: '@epilot360/notes',
              order: 52,
              requiredPermission: {
                action: 'entity:view',
                resource: 'comment'
              }
            }
          ],
          id: '76d77d80-e3c6-4b11-b342-13e26b9ed028'
        },
        {
          name: 'automation',
          title: 'Automation',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'automation',
              order: 30,
              import: '@epilot360/automation-capability'
            },
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'play_circle_filled',
              route: '?activity_pilot=automation',
              order: 30,
              requiredPermission: {
                action: 'automation:trigger'
              }
            },
            {
              hook: 'SidePanel:Tab',
              route: 'automation',
              import: '@epilot360/automation-capability',
              title: 'Automation',
              icon: 'automation',
              order: 30
            }
          ],
          id: '58856da5-c8c1-4b4e-9df8-2eb4b8f20a78'
        },
        {
          name: 'notes-v2',
          title: 'Notes V2',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20,
              header: true
            },
            {
              icon: 'note_stack',
              hook: 'SidePanel:Tab',
              route: 'notes',
              import: '@epilot360/notes-capability',
              order: 20
            }
          ],
          id: '6cf108d1-a352-474a-bb5f-cb7e92e0a4da'
        },
        {
          name: 'metadata',
          title: 'Metadata',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              order: 500,
              title: 'Metadata',
              component: 'EntityUnmappedFields'
            }
          ],
          id: '2c575336-31f2-43f8-9c7d-e40ce205ebe2'
        },
        {
          name: 'unread',
          title: 'Unread',
          ui_hooks: [
            {
              hook: 'EntityTable:Column',
              field: 'unread',
              label: 'Unread',
              sortable: false,
              show_in_table: false,
              order: 1,
              component: 'ActivityCell',
              width: 80
            }
          ],
          id: 'b3713f24-46a8-46f5-b2ff-22b5e58532hy'
        }
      ],
      source: {
        id: 'e5fee37e-7818-481b-bab1-ae563592d696',
        type: 'blueprint'
      },
      comment: 'Add sidepanel tabs UI hooks to schemas',
      version: 45,
      created_at: '2024-08-05T08:29:53.013Z',
      updated_at: '2025-02-04T09:39:28.589Z',
      draft: false,
      group_headlines: [],
      created_by: '11000890',
      description:
        'Meters store details about power, gas or water or other devices used to meter consumption.',
      docs_url: 'https://docs.epilot.io/docs/entities/schemas-list/#meter',
      category: 'product_hub'
    },
    {
      id: 'af782e69-7fab-421d-a5a7-c1b91dbb4fb8',
      name: 'MeterCounter',
      plural: 'MeterCounters',
      slug: 'meter_counter',
      icon: 'meter_counter',
      published: true,
      title_template: 'Zählwerk',
      ui_config: {
        table_view: {
          view_type: 'default',
          row_actions: ['view', 'edit', 'duplicate', 'share', 'delete'],
          bulk_actions: ['export', 'delete']
        },
        list_item: {
          summary_attributes: [
            {
              label: 'OBIS Nr.',
              value: 'obis_number'
            },
            {
              label: 'Direction',
              value: 'direction'
            },
            {
              label: 'Tariff Type',
              value: 'tariff_type'
            }
          ]
        },
        edit_view: {
          ui_elements: []
        }
      },
      group_settings: [
        {
          id: 'General Details',
          label: 'General Details',
          expanded: true,
          order: 10
        },
        {
          id: 'Meter Information',
          label: 'Meter Information',
          expanded: true,
          order: 20
        }
      ],
      attributes: [
        {
          label: 'OBIS Number',
          name: 'obis_number',
          type: 'string',
          show_in_table: true,
          group: 'General Details',
          layout: 'half_width',
          protected: true,
          id: 'b763d0ee-5423-45f7-8555-206abc7772e6'
        },
        {
          label: 'Direction',
          name: 'direction',
          type: 'select',
          options: ['feed-in', 'feed-out'],
          group: 'General Details',
          layout: 'half_width',
          protected: true,
          default_value: 'feed-out',
          id: '32c67818-a6d4-4b1c-9dd1-809f4e1166e3'
        },
        {
          label: 'Tariff type',
          name: 'tariff_type',
          type: 'select',
          options: ['ht', 'nt', 'et'],
          group: 'General Details',
          layout: 'half_width',
          protected: true,
          id: 'c58b15f4-c2ab-4350-87cc-07135713d649'
        },
        {
          label: 'Transformer Ratio',
          name: 'transformer_ratio',
          type: 'number',
          group: 'General Details',
          layout: 'half_width',
          protected: true,
          id: 'fdb7ca3b-59c8-4fbc-b54b-136d8e90081a'
        },
        {
          label: 'Unit',
          name: 'unit',
          type: 'select',
          options: [
            'w',
            'wh',
            'kw',
            'kWh',
            'kvarh',
            'mw',
            'mWh',
            'unit',
            'cubic-meter',
            'hour',
            'day',
            'month',
            'year',
            'percentage'
          ],
          group: 'General Details',
          layout: 'half_width',
          protected: true,
          id: '9d29b605-e21c-4f99-8f6c-5a35a68f49a1'
        },
        {
          label: 'Conversion Factor',
          name: 'conversion_factor',
          type: 'number',
          group: 'General Details',
          layout: 'half_width',
          protected: true,
          id: 'a936effe-fb47-47f6-966c-a934de4b112c'
        },
        {
          name: 'meter',
          type: 'relation',
          relation_type: 'has_one',
          label: 'Meter',
          allowedSchemas: ['meter'],
          show_in_table: true,
          group: 'Meter Information',
          layout: 'full_width',
          has_primary: true,
          enable_relation_picker: true,
          enable_relation_tags: true,
          protected: true,
          id: '8b28492a-a6eb-4845-a1a8-57f0ac54c5b2',
          reverse_attributes: {
            meter: 'counters'
          }
        },
        {
          label: 'Purposes',
          name: '_purpose',
          show_in_table: true,
          hidden: true,
          type: 'purpose',
          id: 'e4fb62f3-8aa3-4934-a58b-44b21d4304fb'
        }
      ],
      capabilities: [
        {
          name: 'attributes',
          title: 'Attributes',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Overview',
              component: 'EntityOverview',
              order: 0
            }
          ],
          id: 'b90fd6af-91ff-459e-be2f-0a6b70191b26'
        },
        {
          name: 'relations',
          title: 'Relations',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Relations',
              component: 'EntityRelations',
              order: 10
            }
          ],
          id: '50d0918b-8045-45d3-bd52-688c1df8de10'
        },
        {
          name: 'files',
          title: 'Files',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Files',
              component: 'EntityFiles',
              order: 50
            }
          ],
          id: 'd596c4bb-0dec-414a-bcb4-17161551ea88'
        },
        {
          name: 'messages',
          title: 'Messages',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Messages',
              import: '@epilot360/messaging',
              order: 51,
              requiredPermission: {
                action: 'message:view'
              }
            },
            {
              icon: 'message',
              hook: 'SidePanel:Tab',
              route: 'emails',
              order: 51,
              import: '@epilot360/messaging',
              requiredPermission: {
                action: 'message:view'
              }
            }
          ],
          id: 'e118a8eb-fb93-47b3-956c-1a29757b0fcf'
        },
        {
          name: 'activity_feed',
          title: 'Activity',
          ui_hooks: [
            {
              hook: 'ActivityPilot:View',
              route: 'activity_feed',
              order: 0
            },
            {
              icon: 'update',
              hook: 'SidePanel:Tab',
              route: 'activity_feed',
              order: 0
            }
          ],
          id: '8e0fe7b0-06c6-4b7b-91a0-8b4dd33f6aa4'
        },
        {
          name: 'notes',
          title: 'Notes',
          attributes: [
            {
              id: 'a7b1b3b4-4b3b-4b3b-4b3b-4b3b4b3b4b3b',
              name: 'notes',
              label: 'Notes',
              type: 'internal'
            }
          ],
          ui_hooks: [
            {
              hook: 'ActivityPilot:QuickAction',
              icon: 'task',
              route: '?drawer=notes',
              order: 50,
              requiredPermission: {
                action: 'entity:create',
                resource: 'comment'
              }
            },
            {
              hook: 'EntityDetailsV2:Tab',
              title: 'Notes',
              import: '@epilot360/notes',
              order: 52,
              requiredPermission: {
                action: 'entity:view',
                resource: 'comment'
              }
            }
          ],
          id: 'd603c88f-9938-4af5-9ed2-7fcef66b3089'
        },
        {
          name: 'metadata',
          title: 'Metadata',
          ui_hooks: [
            {
              hook: 'EntityDetailsV2:Tab',
              order: 500,
              title: 'Metadata',
              component: 'EntityUnmappedFields'
            }
          ],
          id: '1b4bdc5a-9d71-43a2-a250-e0f960bd65a1'
        }
      ],
      source: {
        id: 'e5fee37e-7818-481b-bab1-ae563592d696',
        type: 'blueprint'
      },
      comment: 'Add sidepanel tabs UI hooks to schemas',
      version: 32,
      created_at: '2024-08-05T08:29:53.053Z',
      updated_at: '2025-02-04T09:39:28.629Z',
      draft: false,
      group_headlines: [],
      description:
        'Meter counters represent the counters used in meter devices, allowing separate tracking for different consumption types or purposes, such as peak and off-peak energy usage.',
      docs_url:
        'https://docs.epilot.io/docs/entities/schemas-list/#meter_counter',
      category: 'product_hub'
    }
  ]
}
