import { DefaultEpilotTheme, EpilotTheme } from '@epilot/base-elements'
import merge from 'lodash/fp/merge'

import { type Meter } from 'api/MeteringApiClient'
import { ExternalLink } from 'context/PortalConfigContext'
import { Contract } from 'utils/ContractUtils'

import { BillingEvent, EntityItem, FileItem, Order } from '../api/ApiTypes'
import { Opportunity } from '../utils/OpportunityUtils'

export const MOCK_ORDER: EntityItem = {
  _id: 'id',
  metadata: null,
  _tags: [],
  status: 'active',
  order_number: 'Order 323',
  billing_first_name: 'John',
  billing_last_name: 'Deo',
  billing_email: 'john@mail.com',
  billing_phone: '9837747474',
  billing_address: [
    {
      street: 'East Str.',
      street_number: '120',
      city: 'Cologne',
      postal_code: '12323',
      country: 'Germany',
      _tags: []
    }
  ],
  delivery_address: [
    {
      street: 'West Str.',
      street_number: '102',
      city: 'Cologne',
      postal_code: '12323',
      country: 'Germany',
      _tags: []
    }
  ],
  payment_method: [
    {
      type: 'PayPal'
    }
  ],
  currency: 'EUR',
  line_items: [],
  amount_subtotal: 12300,
  amount_total: 13000,
  _schema: 'contract',
  _org: 'org',
  _created_at: '2021-10-31T01:30:00.000-05:00',
  _updated_at: '2021-11-31T01:30:00.000-05:00',
  _title: 'Order Nr.12020',
  billing_contact: {
    $relation: [
      {
        entity_id: '12',
        relationText: '',
        _tags: [],
        _schema: 'contract'
      }
    ]
  },
  org: {
    id: 'org_id'
  }
}

export const MOCK_OPPORTUNITY: Opportunity = {
  _id: '96b94f5b-f99b-452d-93a8-f6f1303a74c4',
  status: 'lead',
  billing_address: [
    {
      _id: '96b94f5b-f99b-452d-93a8-f6f1303a74c4',
      street: 'Frank Syna',
      street_number: '25262',
      city: 'Frankenberg/Sa.',
      postal_code: '09669',
      country: 'de',
      _tags: []
    }
  ],
  delivery_address: [
    {
      _id: '96b94f5b-f99b-452d-93a8-f6f1303a74c4',
      street: 'Frank Syna',
      street_number: '25262',
      city: 'Frankenberg/Sa.',
      postal_code: '09669',
      country: 'de',
      _tags: []
    }
  ],
  items: [],
  opportunity_number: 'OPZU7iDeF',
  _schema: 'opportunity',
  _org: '739224',
  _created_at: '2022-02-04T10:24:29.288Z',
  _updated_at: '2022-02-04T12:05:03.073Z',
  _title: 'Opportunity 23',
  journey_data: {
    'Zahlung/color': 'red'
  },
  customer: null,
  opportunity_title: 'Opportunity 23',
  latestQuote: MOCK_ORDER,
  _tags: null,
  _files: [],
  files: [],
  org: null,
  currency: 'EUR',
  _purpose: []
}

export const MOCK_OPPORTUNITIES = [
  {
    _id: '1',
    _title: 'Opportunity 1',
    status: 'in_progress',
    _schema: 'opportunity',
    _created_at: '2021-01-01',
    _updated_at: '2021-01-01'
  },
  {
    _id: '2',
    _title: 'Opportunity 2',
    status: 'active',
    _created_at: '2021-01-01',
    _schema: 'opportunity',
    _updated_at: '2021-01-01'
  },
  {
    _id: '3',
    _title: 'Opportunity 3',
    status: 'in_progress',
    _created_at: '2021-01-01',
    _schema: 'opportunity',
    _updated_at: '2021-01-01'
  },
  {
    _id: '4',
    _title: 'Opportunity 4',
    status: 'in_progress',
    _schema: 'opportunity',
    _created_at: '2021-01-01',
    _updated_at: '2021-01-01'
  }
] as Opportunity[]

export const MOCK_ORDERS = [
  {
    _id: '1',
    _title: 'Order 1',
    status: 'quote',
    _schema: 'order',
    _created_at: '2023-01-01',
    _updated_at: '2023-01-01'
  },
  {
    _id: '2',
    _title: 'Order 2',
    status: 'placed',
    _schema: 'order',
    _created_at: '2023-01-01',
    _updated_at: '2021-01-01'
  },
  {
    _id: '3',
    _title: 'Order 3',
    status: 'quote',
    _schema: 'order',
    _created_at: '2023-01-01',
    _updated_at: '2023-01-01'
  },
  {
    _id: '4',
    _title: 'Order 4',
    status: 'placed',
    _schema: 'order',
    _created_at: '2023-01-01',
    _updated_at: '2023-01-01'
  }
] as Order[]

export const MOCK_EXTERNAL_LINKS = [
  {
    id: '1',
    label: { en: 'Action 1', de: 'Aktion 1' },
    link: 'https://www.google.com'
  },
  {
    id: '2',
    label: { en: 'Action 2', de: 'Aktion 2' },
    link: 'https://www.google.com'
  },
  {
    id: '3',
    label: { en: 'Action 3', de: 'Aktion 3' },
    link: 'https://www.google.com'
  },
  {
    id: '4',
    label: { en: 'Action 4', de: 'Aktion 4' },
    link: 'https://www.google.com'
  }
] as ExternalLink[]

export const MOCK_CONTRACTS = [
  {
    _id: '1',
    _org: '123',
    _title: 'Contract 1',
    status: 'active',
    _schema: 'contract',
    _created_at: '2023-03-06',
    _updated_at: '2023-03-06'
  },
  {
    _id: '2',
    _org: '123',
    _title: 'Contract 2',
    status: 'active',
    _schema: 'contract',
    _created_at: '2023-04-14',
    _updated_at: '2023-03-06'
  },
  {
    _id: '3',
    _org: '123',
    _title: 'Contract 3',
    status: 'active',
    _schema: 'contract',
    _created_at: '2023-04-03',
    _updated_at: '2023-03-06'
  },
  {
    _id: '4',
    _org: '123',
    _title: 'Contract 4',
    status: 'active',
    _schema: 'contract',
    _created_at: '2023-07-09',
    _updated_at: '2023-03-06'
  }
] as Contract[]

export const MOCK_METERS = [
  {
    _id: '1',
    _title: 'Meter 1',
    sector: 'power'
  },
  {
    _id: '2',
    _title: 'Meter 2',
    sector: 'power'
  },
  {
    _id: '3',
    _title: 'Meter 3',
    sector: 'gas'
  },
  {
    _id: '4',
    _title: 'Meter 4',
    sector: 'gas'
  }
] as Meter[]

export const MOCK_DOCUMENTS: { results: FileItem[]; hits: number } = {
  results: [
    {
      _id: '1',
      filename: 'File 1',
      public_url: 'https://www.google.com',
      _created_at: '2021-01-01',
      _updated_at: '2021-01-01',
      _title: 'test1',
      _org: '123',
      _schema: 'file'
    },
    {
      _id: '2',
      filename: 'File 2',
      public_url: 'https://www.google.com',
      _created_at: '2021-01-01',
      _updated_at: '2021-01-01',
      _title: 'test1',
      _org: '123',
      _schema: 'file'
    },
    {
      _id: '3',
      filename: 'File 3',
      public_url: 'https://www.google.com',
      _created_at: '2021-01-01',
      _updated_at: '2021-01-01',
      _title: 'test1',
      _org: '123',
      _schema: 'file'
    },
    {
      _id: '4',
      filename: 'File 4',
      public_url: 'https://www.google.com',
      _created_at: '2021-01-01',
      _updated_at: '2021-01-01',
      _title: 'test1',
      _org: '123',
      _schema: 'file'
    }
  ],
  hits: 5
}

export const MOCK_BILLING_EVENTS: BillingEvent[] = [
  {
    type: 'installment',
    _id: '3ef5073d-bd7c-4577-a9f1-28f70516b755',
    _created_at: '2022-01-02T00:00:00.000Z',
    _updated_at: '2022-01-02T00:00:00.000Z',
    billing_amount: -10000,
    _title: 'Billing Event 1',
    _org: '123',
    billing_amount_decimal: '100.00',
    billing_currency: 'EUR',
    due_date: '2023-11-01',
    contract: {
      $relation: [
        {
          entity_id: 'ef957d2b-9cb4-467f-81c6-af874a34b1b6'
        }
      ]
    },
    external_id: 'external-id-1'
  },
  {
    type: 'installment',
    _id: '3ef5073d-bd7c-4577-a9f1-28f70516b755',
    _created_at: '2022-01-02T00:00:00.000Z',
    _updated_at: '2022-01-02T00:00:00.000Z',
    billing_amount: -10000,
    _title: 'Billing Event 2',
    _org: '123',
    billing_amount_decimal: '100.00',
    billing_currency: 'EUR',
    due_date: '2033-11-01',
    contract: {
      $relation: [
        {
          entity_id: 'ef957d2b-9cb4-467f-81c6-af874a34b1b6'
        }
      ]
    },
    external_id: 'external-id-2'
  },
  {
    type: 'installment',
    _id: '3ef5073d-bd7c-4577-a9f1-28f70516b755',
    _created_at: '2022-01-02T00:00:00.000Z',
    _updated_at: '2022-01-02T00:00:00.000Z',
    billing_amount: -10000,
    _title: 'Billing Event 3',
    _org: '123',
    billing_amount_decimal: '100.00',
    billing_currency: 'EUR',
    due_date: '2023-11-01',
    paid_date: '2023-10-01',
    contract: {
      $relation: [
        {
          entity_id: 'ef957d2b-9cb4-467f-81c6-af874a34b1b6'
        }
      ]
    },
    external_id: 'external-id-3'
  },
  {
    type: 'installment',
    _id: '3ef5073d-bd7c-4577-a9f1-28f70516b755',
    _created_at: '2022-01-02T00:00:00.000Z',
    _updated_at: '2022-01-02T00:00:00.000Z',
    billing_amount: -10000,
    _title: 'Billing Event 4',
    _org: '123',
    billing_amount_decimal: '100.00',
    billing_currency: 'EUR',
    due_date: '2023-11-01',
    paid_date: '2023-09-01',
    contract: {
      $relation: [
        {
          entity_id: 'ef957d2b-9cb4-467f-81c6-af874a34b1b6'
        }
      ]
    },
    external_id: 'external-id-4'
  }
]

export const ECP_THEME_MOCK: Required<EpilotTheme> = merge(DefaultEpilotTheme, {
  muiOverrides: { MuiPaper: { root: { backgroundColor: '#fff' } } },
  palette: {
    background: {
      default: '#f5f7fa'
    }
  }
}) as Required<EpilotTheme>
