import {
  EntityItem,
  JourneyActions,
  Meter
} from '@epilot/customer-portal-client'

export const MOCK_METER_DETAILS: {
  data: {
    entity: Meter
    relations: EntityItem[]
    journey_actions: JourneyActions[]
  }
} = {
  data: {
    entity: {
      _org: '739224',
      _schema: 'meter',
      ma_lo_id: '9876754',
      meter_type: 'performance-meter',
      tariff_type: 'ht',
      manufacturer: 'TEST',
      _id: '566df5e1-d88d-428b-9d0d-5c29cfcf8498',
      _created_at: '2024-10-15T08:00:23.255Z',
      _updated_at: '2025-02-11T09:25:17.907Z',
      _title: 'Hello',
      contract: {
        $relation: [
          {
            entity_id: '3a6bb275-b704-4a8e-9a10-81c487f206bb',
            _tags: []
          }
        ]
      },
      status: 'active',
      counters: {
        $relation: [
          {
            entity_id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
            _tags: []
          }
        ]
      },
      meter_number: '68886457',
      address: [
        {
          _id: 'rPf1f5JG6S4CSvesoC1AW',
          _tags: [],
          street: 'Street',
          zip: '',
          city: 'Willich',
          country: 'SK',
          postal_code: '47877',
          street_number: '90'
        }
      ],
      _files: {
        $relation: [
          {
            entity_id: 'c0390034-2233-4222-979d-9b55657fa69a'
          },
          {
            entity_id: 'b3ba2374-9eb3-4f2d-b186-9516b8e060bc'
          },
          {
            entity_id: '665eb1f7-c014-4aad-8213-97c038b56e6f'
          },
          {
            entity_id: 'fa5a7fb0-89c4-4a75-9a1a-523cd558d011'
          },
          {
            entity_id: '598640b6-b42d-4605-a058-e6e0ccf8990c'
          },
          {
            entity_id: 'e3804976-4412-4685-8570-30f9e238e070'
          },
          {
            entity_id: '5048fbec-104c-41cd-8776-cf5e4d01091e'
          },
          {
            entity_id: 'f5229b31-5338-4e0c-9e71-cc7d96bfa6f2'
          },
          {
            entity_id: 'ed3c7440-170f-4144-8032-d51a2d3252aa'
          },
          {
            entity_id: '3e0e794f-df07-4f2a-8269-af4034eee55f'
          },
          {
            entity_id: 'f2c0aaba-a855-4511-a2c9-1cd3799b578e'
          },
          {
            entity_id: 'f4e86825-7c3c-42e4-8203-81c71a7f53b0'
          },
          {
            entity_id: '2ece4cb8-add9-4a66-9323-204f52521b82'
          },
          {
            entity_id: '3e61a29e-9fa0-48c0-9d47-34b188f55df7'
          },
          {
            entity_id: 'fe9448d3-1976-421a-9efc-4db1906c005c'
          },
          {
            entity_id: '66f5db8b-6664-442f-b76a-0ee1546489ce'
          },
          {
            entity_id: '96f8a358-e82c-4f8f-81a5-dfdc4fde609a'
          },
          {
            entity_id: '78894497-c96d-40ba-86ba-c1fec43bc564'
          },
          {
            entity_id: '29f2701d-577a-4b0b-8deb-cadef2293d8c'
          },
          {
            entity_id: '4e172316-3b75-4a8f-a0da-a0fb0a4e6d19'
          },
          {
            entity_id: '061cc6b2-5dee-46ff-9d23-3f5484dff7ba'
          },
          {
            entity_id: '4f831f86-1ec5-4e5e-a537-66b9c9765cc1'
          },
          {
            entity_id: '1845aec0-d814-4c0f-b21a-4c76a6837fa5'
          },
          {
            entity_id: '76a959f0-9649-4000-bbd8-a413746e539a'
          },
          {
            entity_id: 'e3e09a92-a88d-48f8-9dac-d38615719ca1'
          },
          {
            entity_id: '42772f39-ee1f-4987-b768-baa9fdcb9b60'
          },
          {
            entity_id: '3ba0da66-9ef2-47a1-b192-00c3142ed0f4'
          },
          {
            entity_id: 'ba194af5-d84d-4f74-8cd3-8e909f753add'
          },
          {
            entity_id: 'cd51e404-02f5-471f-aadd-cc7cf480de5b'
          },
          {
            entity_id: '79cfd8ff-281a-4810-9da2-10ec39c49eae'
          },
          {
            entity_id: '5b25ca64-af31-4eb8-a830-a21ec50bf2a8'
          },
          {
            entity_id: '291e9398-542f-47cc-8719-1257dd2fec8d'
          },
          {
            entity_id: '63a2f44e-0ecc-42f7-aa8c-010a72a4b68e'
          },
          {
            entity_id: 'b7ed36e4-2865-4209-8f2c-5527e5da1bdb'
          },
          {
            entity_id: 'fb1a68f8-219d-4f4f-97ce-ead0d36464bb'
          },
          {
            entity_id: 'e6c5ad4b-adfc-4855-b5c2-7935c952dd88'
          },
          {
            entity_id: '49cd1fd3-28f0-47c8-8752-34ce226c20cd'
          },
          {
            entity_id: 'a60ba627-604a-42ed-ad56-2e68041be670'
          },
          {
            entity_id: '414d6dcc-c392-4585-a05e-bf4b1020ba5e'
          },
          {
            entity_id: '1758b5af-1969-475a-860e-8b541b78db79'
          },
          {
            entity_id: '5152dad9-1c0f-4959-b06f-bd98cca9358f'
          },
          {
            entity_id: '1543f416-79fb-4a3e-8a2c-bd96c78b5707'
          },
          {
            entity_id: 'dcd1c9c2-58e0-4ebf-848d-9ef01ef46e8e'
          },
          {
            entity_id: 'aa0a2bae-7a9e-471f-af82-43ab4aba89b8'
          },
          {
            entity_id: 'b5927870-ff11-443d-a629-91fba0b41a84'
          },
          {
            entity_id: '98044073-4fa8-43fe-a503-a2f1aee8ebde'
          },
          {
            entity_id: '5f221674-c1f1-4dd9-bbfd-27f11f501faf'
          },
          {
            entity_id: '3331b7b2-b585-4c19-b6ee-4ace5a59452d'
          },
          {
            entity_id: 'bfc85bc0-8a35-4680-8683-b8bdc53cc7c5'
          },
          {
            entity_id: '46ca5170-2fba-4912-9f27-29ada7071093'
          },
          {
            entity_id: '746d4eba-316a-4cf0-b0f9-ed212e79ef47'
          }
        ]
      },
      sector: 'water'
    },
    relations: [
      {
        filename: 'Reading_2025-02-04T10_01_13+01_00.jpg',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/9413eda0-53cc-4eb9-b68e-3e025c4d50dc/Reading_2025-02-04T10_01_13%252B01_00.jpg',
        size_bytes: 1636026,
        readable_size: '1.56 MB',
        mime_type: 'image/jpeg',
        width: 1046,
        height: 980,
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/9413eda0-53cc-4eb9-b68e-3e025c4d50dc/Reading_2025-02-04T10_01_13%2B01_00.jpg'
        },
        versions: [
          {
            filename: 'Reading_2025-02-04T10_01_13+01_00.jpg',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/9413eda0-53cc-4eb9-b68e-3e025c4d50dc/Reading_2025-02-04T10_01_13%252B01_00.jpg',
            size_bytes: 1636026,
            readable_size: '1.56 MB',
            mime_type: 'image/jpeg',
            width: 1046,
            height: 980,
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/9413eda0-53cc-4eb9-b68e-3e025c4d50dc/Reading_2025-02-04T10_01_13%2B01_00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'c0390034-2233-4222-979d-9b55657fa69a',
        _org: '739224',
        _created_at: '2025-02-04T09:01:19.779Z',
        _updated_at: '2025-02-04T09:01:19.779Z',
        category: 'unknown',
        _title: 'Reading_2025-02-04T10_01_13+01_00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T05:51:05+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/9d6cfb88-5a1b-47e6-8edc-1f9fce0c562c/Reading%25202025-02-06T05%253A51%253A05%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/png',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/9d6cfb88-5a1b-47e6-8edc-1f9fce0c562c/Reading%202025-02-06T05%3A51%3A05%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:51:05+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/9d6cfb88-5a1b-47e6-8edc-1f9fce0c562c/Reading%25202025-02-06T05%253A51%253A05%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/png',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/9d6cfb88-5a1b-47e6-8edc-1f9fce0c562c/Reading%202025-02-06T05%3A51%3A05%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '061cc6b2-5dee-46ff-9d23-3f5484dff7ba',
        _org: '739224',
        _created_at: '2025-02-06T04:51:09.955Z',
        _updated_at: '2025-02-06T04:51:09.955Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:51:05+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/03dbbe2c-6833-4d6f-a3c1-3e1973baa3aa/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/03dbbe2c-6833-4d6f-a3c1-3e1973baa3aa/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/03dbbe2c-6833-4d6f-a3c1-3e1973baa3aa/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/03dbbe2c-6833-4d6f-a3c1-3e1973baa3aa/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '2ece4cb8-add9-4a66-9323-204f52521b82',
        _org: '739224',
        _created_at: '2025-02-06T04:03:17.305Z',
        _updated_at: '2025-02-06T04:03:17.305Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T04:45:44+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T05:55:03+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/1cf7c841-d5e2-4c6d-93ee-e8be0e20cc10/Reading%25202025-02-06T05%253A55%253A03%252B01%253A00.jpg',
        size_bytes: 36307,
        readable_size: '35.46 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/1cf7c841-d5e2-4c6d-93ee-e8be0e20cc10/Reading%202025-02-06T05%3A55%3A03%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:55:03+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/1cf7c841-d5e2-4c6d-93ee-e8be0e20cc10/Reading%25202025-02-06T05%253A55%253A03%252B01%253A00.jpg',
            size_bytes: 36307,
            readable_size: '35.46 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/1cf7c841-d5e2-4c6d-93ee-e8be0e20cc10/Reading%202025-02-06T05%3A55%3A03%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '1845aec0-d814-4c0f-b21a-4c76a6837fa5',
        _org: '739224',
        _created_at: '2025-02-06T04:55:04.056Z',
        _updated_at: '2025-02-06T04:55:04.056Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:55:03+01:00.jpg'
      },
      {
        obis_number: '7SLB5500095093',
        direction: 'feed-out',
        tariff_type: 'et',
        unit: 'kWh',
        _tags: [],
        _schema: 'meter_counter',
        _id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
        _org: '739224',
        _created_at: '2023-11-28T10:49:42.914Z',
        _updated_at: '2024-09-27T13:10:45.658Z',
        _title: 'Zählwerk'
      },
      {
        filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/5392c25d-2ecb-44f0-af96-2c1bf33cbe4c/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/5392c25d-2ecb-44f0-af96-2c1bf33cbe4c/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/5392c25d-2ecb-44f0-af96-2c1bf33cbe4c/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/5392c25d-2ecb-44f0-af96-2c1bf33cbe4c/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '78894497-c96d-40ba-86ba-c1fec43bc564',
        _org: '739224',
        _created_at: '2025-02-06T04:27:26.156Z',
        _updated_at: '2025-02-06T04:27:26.156Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:15:03+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/aa5f764b-9f67-4dc8-a18b-0f5f699acfd4/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/aa5f764b-9f67-4dc8-a18b-0f5f699acfd4/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/aa5f764b-9f67-4dc8-a18b-0f5f699acfd4/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/aa5f764b-9f67-4dc8-a18b-0f5f699acfd4/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '96f8a358-e82c-4f8f-81a5-dfdc4fde609a',
        _org: '739224',
        _created_at: '2025-02-06T04:26:44.625Z',
        _updated_at: '2025-02-06T04:26:44.625Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:15:03+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/6eafd4fc-4ce6-4ceb-bf7b-b7b00d03e11f/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/6eafd4fc-4ce6-4ceb-bf7b-b7b00d03e11f/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/6eafd4fc-4ce6-4ceb-bf7b-b7b00d03e11f/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/6eafd4fc-4ce6-4ceb-bf7b-b7b00d03e11f/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '4e172316-3b75-4a8f-a0da-a0fb0a4e6d19',
        _org: '739224',
        _created_at: '2025-02-06T04:31:54.424Z',
        _updated_at: '2025-02-06T04:31:54.424Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:15:03+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/4aaadbc9-e306-4954-89bb-4bfeb1f6219b/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/4aaadbc9-e306-4954-89bb-4bfeb1f6219b/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/4aaadbc9-e306-4954-89bb-4bfeb1f6219b/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/4aaadbc9-e306-4954-89bb-4bfeb1f6219b/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '29f2701d-577a-4b0b-8deb-cadef2293d8c',
        _org: '739224',
        _created_at: '2025-02-06T04:28:12.605Z',
        _updated_at: '2025-02-06T04:28:12.605Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:15:03+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T04:32:04+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a1daccdb-04ae-465f-ad9f-0bc7a6e19abd/Reading%25202025-02-06T04%253A32%253A04%252B01%253A00.jpg',
        size_bytes: 36307,
        readable_size: '35.46 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/a1daccdb-04ae-465f-ad9f-0bc7a6e19abd/Reading%202025-02-06T04%3A32%3A04%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T04:32:04+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a1daccdb-04ae-465f-ad9f-0bc7a6e19abd/Reading%25202025-02-06T04%253A32%253A04%252B01%253A00.jpg',
            size_bytes: 36307,
            readable_size: '35.46 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/a1daccdb-04ae-465f-ad9f-0bc7a6e19abd/Reading%202025-02-06T04%3A32%3A04%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '5048fbec-104c-41cd-8776-cf5e4d01091e',
        _org: '739224',
        _created_at: '2025-02-06T03:35:38.848Z',
        _updated_at: '2025-02-06T03:35:38.848Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T04:32:04+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T05:54:17+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/9b0c97b0-da02-4c03-9422-40a2bc2159a8/Reading%25202025-02-06T05%253A54%253A17%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/png',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/9b0c97b0-da02-4c03-9422-40a2bc2159a8/Reading%202025-02-06T05%3A54%3A17%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:54:17+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/9b0c97b0-da02-4c03-9422-40a2bc2159a8/Reading%25202025-02-06T05%253A54%253A17%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/png',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/9b0c97b0-da02-4c03-9422-40a2bc2159a8/Reading%202025-02-06T05%3A54%3A17%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '4f831f86-1ec5-4e5e-a537-66b9c9765cc1',
        _org: '739224',
        _created_at: '2025-02-06T04:54:19.714Z',
        _updated_at: '2025-02-06T04:54:19.714Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:54:17+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T03:49:56+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/e18117fc-3ec7-4ea3-bc1e-f42893437717/Reading%25202025-02-06T03%253A49%253A56%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/e18117fc-3ec7-4ea3-bc1e-f42893437717/Reading%202025-02-06T03%3A49%3A56%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T03:49:56+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/e18117fc-3ec7-4ea3-bc1e-f42893437717/Reading%25202025-02-06T03%253A49%253A56%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/e18117fc-3ec7-4ea3-bc1e-f42893437717/Reading%202025-02-06T03%3A49%3A56%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '665eb1f7-c014-4aad-8213-97c038b56e6f',
        _org: '739224',
        _created_at: '2025-02-06T03:13:32.774Z',
        _updated_at: '2025-02-06T03:13:32.774Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T03:49:56+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/2c659539-faa0-45d7-9c71-c834fc43ef6c/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/2c659539-faa0-45d7-9c71-c834fc43ef6c/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/2c659539-faa0-45d7-9c71-c834fc43ef6c/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/2c659539-faa0-45d7-9c71-c834fc43ef6c/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '66f5db8b-6664-442f-b76a-0ee1546489ce',
        _org: '739224',
        _created_at: '2025-02-06T04:25:25.159Z',
        _updated_at: '2025-02-06T04:25:25.159Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:15:03+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T04:32:04+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a74aeee3-9257-4de2-9e59-a1c91ac0a5eb/Reading%25202025-02-06T04%253A32%253A04%252B01%253A00.jpg',
        size_bytes: 36307,
        readable_size: '35.46 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/a74aeee3-9257-4de2-9e59-a1c91ac0a5eb/Reading%202025-02-06T04%3A32%3A04%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T04:32:04+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a74aeee3-9257-4de2-9e59-a1c91ac0a5eb/Reading%25202025-02-06T04%253A32%253A04%252B01%253A00.jpg',
            size_bytes: 36307,
            readable_size: '35.46 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/a74aeee3-9257-4de2-9e59-a1c91ac0a5eb/Reading%202025-02-06T04%3A32%3A04%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'e3804976-4412-4685-8570-30f9e238e070',
        _org: '739224',
        _created_at: '2025-02-06T03:32:07.232Z',
        _updated_at: '2025-02-06T03:32:07.232Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T04:32:04+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T03:49:56+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/7c8d714f-b0a9-44a3-a503-d96b843e58da/Reading%25202025-02-06T03%253A49%253A56%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/7c8d714f-b0a9-44a3-a503-d96b843e58da/Reading%202025-02-06T03%3A49%3A56%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T03:49:56+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/7c8d714f-b0a9-44a3-a503-d96b843e58da/Reading%25202025-02-06T03%253A49%253A56%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/7c8d714f-b0a9-44a3-a503-d96b843e58da/Reading%202025-02-06T03%3A49%3A56%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '598640b6-b42d-4605-a058-e6e0ccf8990c',
        _org: '739224',
        _created_at: '2025-02-06T03:25:25.862Z',
        _updated_at: '2025-02-06T03:25:25.862Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T03:49:56+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/bc14d262-15d2-4780-b5c4-2e2a66ecf3af/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/bc14d262-15d2-4780-b5c4-2e2a66ecf3af/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/bc14d262-15d2-4780-b5c4-2e2a66ecf3af/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/bc14d262-15d2-4780-b5c4-2e2a66ecf3af/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '3e61a29e-9fa0-48c0-9d47-34b188f55df7',
        _org: '739224',
        _created_at: '2025-02-06T04:15:09.438Z',
        _updated_at: '2025-02-06T04:15:09.438Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:15:03+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/975d0dde-a9aa-45a4-b2ef-0450a38c2512/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/975d0dde-a9aa-45a4-b2ef-0450a38c2512/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/975d0dde-a9aa-45a4-b2ef-0450a38c2512/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/975d0dde-a9aa-45a4-b2ef-0450a38c2512/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '3e0e794f-df07-4f2a-8269-af4034eee55f',
        _org: '739224',
        _created_at: '2025-02-06T03:48:35.538Z',
        _updated_at: '2025-02-06T03:48:35.538Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T04:45:44+01:00.jpg'
      },
      {
        contract_name: '750464353-3-W-1',
        customer: {
          $relation: [
            {
              entity_id: '2b38291e-1a3e-487a-8136-e11d53f86c8a',
              _tags: []
            },
            {
              entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              _schema: 'contact',
              _tags: []
            },
            {
              entity_id: '754dce41-8c6f-48e0-801b-673abf56f83e',
              _schema: 'contact',
              _tags: []
            }
          ]
        },
        type: 'recurring',
        billing_period: 'monthly',
        billing_duration_amount: null,
        billing_duration_unit: null,
        notice_time_amount: null,
        notice_time_unit: null,
        termination_time_amount: null,
        termination_time_unit: null,
        renewal_duration_amount: null,
        renewal_duration_unit: null,
        installment_amount: 26500,
        installment_amount_currency: 'EUR',
        installment_amount_decimal: '265',
        balance: 100,
        balance_currency: 'EUR',
        balance_decimal: '1.00',
        _schema: 'contract',
        status: 'active',
        _id: '3a6bb275-b704-4a8e-9a10-81c487f206bb',
        _org: '739224',
        _created_at: '2024-09-26T16:46:54.024Z',
        _updated_at: '2025-01-30T11:11:41.844Z',
        _title: '750464353-3-W-1',
        contract_number: '1234',
        start_date: '2024-12-10',
        end_date: '2025-12-10',
        delivery_address: [
          {
            _id: 'pqPLwvDo6eqZ97gemnrRr',
            _tags: [],
            street: 'Am Fronhof',
            zip: '',
            city: 'Willich',
            country: 'DE',
            postal_code: '47877',
            street_number: '123'
          }
        ],
        _files: {
          $relation: [
            {
              entity_id: 'a9fdedbc-81bf-4c6f-aca1-e34e89ee9c5d',
              _tags: [''],
              added_by: {
                org_id: '739224',
                user_id: '11000949'
              }
            },
            {
              entity_id: '27aabaf7-d12e-41ee-82fe-6c0cc9ce1200',
              _tags: [''],
              added_by: {
                org_id: '739224',
                user_id: '11000949'
              }
            },
            {
              entity_id: 'c98b68b9-7303-4ed2-a23a-e236c7874d4d',
              _tags: [''],
              added_by: {
                org_id: '739224',
                user_id: '11000949'
              }
            },
            {
              entity_id: '45fbffd1-018a-4933-b5fd-2c9a0e6ba435',
              _tags: [''],
              added_by: {
                org_id: '739224',
                user_id: '11000949'
              }
            },
            {
              entity_id: 'd4a7817d-c07b-4ffd-a770-9ca839cce652',
              _tags: [''],
              added_by: {
                org_id: '739224',
                user_id: '11000949'
              }
            }
          ]
        },
        branch: 'water',
        billing_address: {
          $relation_ref: [
            {
              entity_id: '2b38291e-1a3e-487a-8136-e11d53f86c8a',
              path: 'address',
              _id: 'Yx8KGpO1pu91iinZYTl_T'
            }
          ]
        },
        line_items: [
          {
            price_id: '5dab027d-086d-412b-b245-17611592e588',
            product_id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
            quantity: 1,
            description: 'test composite',
            unit_amount: 0,
            unit_amount_decimal: '0.00',
            pricing_model: 'per_unit',
            item_components: [
              {
                price_id: '667a518f-4b3c-4623-aab4-c63c7f2bdb97',
                product_id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
                quantity: 1,
                description: 'testest',
                unit_amount: 0,
                unit_amount_decimal: '0.00',
                pricing_model: 'tiered_flatfee',
                _price: {
                  _id: '667a518f-4b3c-4623-aab4-c63c7f2bdb97',
                  pricing_model: 'tiered_flatfee',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: true,
                  price_display_in_journeys: 'show_price',
                  active: true,
                  type: 'recurring',
                  billing_period: 'monthly',
                  billing_duration_unit: 'years',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _title: 'testest',
                  description: 'testest',
                  variable_price: true,
                  tiers: [
                    {
                      up_to: 90.555555,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 10000,
                      flat_fee_amount_decimal: '100'
                    },
                    {
                      up_to: 300,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 20000,
                      flat_fee_amount_decimal: '200',
                      display_mode: 'on_request'
                    },
                    {
                      up_to: 600,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 30000,
                      flat_fee_amount_decimal: '300',
                      display_mode: 'on_request'
                    },
                    {
                      up_to: null,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 50000,
                      flat_fee_amount_decimal: '500'
                    }
                  ],
                  internal_description: 'testest',
                  billing_duration_amount: '3'
                },
                _product: {
                  type: 'product',
                  _title: '[Flat Fee] - Composite Installation',
                  name: '[Flat Fee] - Composite Installation',
                  active: true,
                  price_options: [
                    {
                      _id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                      _schema: 'price',
                      _title: 'flat fee installation',
                      pricing_model: 'tiered_volume',
                      variable_price: true,
                      description: 'flat fee installation',
                      tiers: [
                        {
                          up_to: 90.555555,
                          unit_amount: 1000,
                          unit_amount_decimal: '10',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: 300,
                          unit_amount: 2500,
                          unit_amount_decimal: '25',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        },
                        {
                          up_to: 600,
                          unit_amount: 6000,
                          unit_amount_decimal: '60',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        },
                        {
                          up_to: null,
                          unit_amount: 10000,
                          unit_amount_decimal: '100',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        }
                      ],
                      type: 'one_time',
                      price_display_in_journeys: 'show_as_starting_price',
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-29T12:34:47.681Z',
                      unit_amount: 0,
                      is_tax_inclusive: true,
                      active: true,
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      _updated_at: '2024-10-21T08:33:56.979Z',
                      internal_description: 'flat fee instalar',
                      unit: 'm',
                      _acl_sync: '2024-01-11T17:26:48.410Z',
                      unit_amount_decimal: '0.00',
                      unit_amount_currency: 'EUR',
                      _files: [],
                      $relation: {
                        entity_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                        _tags: []
                      }
                    },
                    {
                      _id: '5dab027d-086d-412b-b245-17611592e588',
                      pricing_model: 'per_unit',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      price_display_in_journeys: 'show_price',
                      active: true,
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      is_composite_price: true,
                      _tags: ['composite'],
                      _schema: 'price',
                      _title: 'test composite',
                      description: 'test composite',
                      price_components: [],
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-29T15:54:36.174Z',
                      _updated_at: '2024-10-24T08:57:46.274Z',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      internal_description: 'test composite',
                      $relation: {
                        entity_id: '5dab027d-086d-412b-b245-17611592e588',
                        _tags: []
                      }
                    },
                    {
                      _id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                      pricing_model: 'tiered_graduated',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      price_display_in_journeys: 'show_price',
                      active: true,
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      _tags: [],
                      _schema: 'price',
                      _title: 'graduated test',
                      description: 'graduated test',
                      variable_price: true,
                      tiers: [
                        {
                          up_to: 90.555555,
                          unit_amount: 1000,
                          unit_amount_decimal: '10',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: 300,
                          unit_amount: 5000,
                          unit_amount_decimal: '50',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: null,
                          unit_amount: 10000,
                          unit_amount_decimal: '100',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        }
                      ],
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-30T08:42:31.011Z',
                      _updated_at: '2024-01-11T17:26:48.500Z',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      internal_description: 'graduated test',
                      _acl_sync: '2024-01-11T17:26:48.405Z',
                      $relation: {
                        entity_id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                        _tags: []
                      }
                    }
                  ],
                  _id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
                  _availability_files: [],
                  internal_name: '[Flat Fee] - Composite Installation',
                  product_images: [
                    {
                      _id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                      s3ref: {
                        bucket: 'epilot-dev-user-content',
                        key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                      },
                      category: 'unknown',
                      filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                      access_control: 'public-read',
                      type: 'image',
                      public_url:
                        'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                      size_bytes: 332081,
                      readable_size: '324.3 KB',
                      mime_type: 'image/png',
                      versions: [
                        {
                          s3ref: {
                            bucket: 'epilot-dev-user-content',
                            key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                          },
                          category: 'unknown',
                          filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                          access_control: 'public-read',
                          type: 'image',
                          public_url:
                            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                          size_bytes: 332081,
                          readable_size: '324.3 KB',
                          mime_type: 'image/png'
                        }
                      ],
                      _schema: 'file',
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '11000656'
                        }
                      ],
                      _created_at: '2024-06-27T07:13:31.962Z',
                      _updated_at: '2024-09-24T19:44:09.352Z',
                      _title: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      source_url:
                        'https://file.dev.sls.epilot.io/v1/files/public/links/739224-jo2Rg-vdKgFxyqpjT9lla/Screenshot%202023-01-16%20at%205.24.48%20PM.png',
                      $relation: {
                        entity_id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                        _tags: []
                      }
                    }
                  ],
                  order_number: 'PRODUCT-1039'
                },
                taxes: [
                  {
                    rate: 'nontaxable',
                    rateValue: 0,
                    amount: 0
                  }
                ],
                type: 'recurring',
                billing_period: 'monthly',
                tiers_details: [
                  {
                    quantity: 0,
                    unit_amount: 10000,
                    unit_amount_decimal: '100',
                    unit_amount_net: 10000,
                    unit_amount_gross: 10000,
                    amount_subtotal: 10000,
                    amount_total: 10000,
                    amount_tax: 0
                  }
                ],
                unit_amount_gross: 10000,
                unit_amount_net: 10000,
                amount_subtotal: 10000,
                amount_total: 10000,
                amount_tax: 0,
                currency: 'EUR',
                is_tax_inclusive: true,
                unit_amount_net_decimal: '100',
                unit_amount_gross_decimal: '100',
                amount_subtotal_decimal: '100',
                amount_total_decimal: '100'
              },
              {
                price_id: '67ea3c64-3193-4650-b194-322047d65e80',
                product_id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
                quantity: 1,
                description: 'Winter Lease',
                unit_amount: 12055,
                unit_amount_decimal: '120.55',
                pricing_model: 'per_unit',
                _price: {
                  _id: '67ea3c64-3193-4650-b194-322047d65e80',
                  unit_amount: 12055,
                  type: 'recurring',
                  billing_period: 'monthly',
                  billing_duration_amount: '2',
                  billing_duration_unit: 'years',
                  notice_time_amount: '1',
                  notice_time_unit: 'months',
                  termination_time_amount: '2',
                  termination_time_unit: 'weeks',
                  renewal_duration_amount: '1',
                  renewal_duration_unit: 'years',
                  active: true,
                  sales_tax: 'reduced',
                  tax_behavior: 'exclusive',
                  description: 'Winter Lease',
                  billing_scheme: 'Per Unit',
                  tiers_mode: 'Standard',
                  _title: 'Winter Lease',
                  unit_amount_decimal: '120.55',
                  unit_amount_currency: 'EUR',
                  pricing_model: 'per_unit',
                  is_tax_inclusive: true,
                  price_display_in_journeys: 'show_price',
                  internal_description: 'Winter Lease'
                },
                _product: {
                  type: 'product',
                  _title: '[Flat Fee] - Composite Installation',
                  name: '[Flat Fee] - Composite Installation',
                  active: true,
                  price_options: [
                    {
                      _id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                      _schema: 'price',
                      _title: 'flat fee installation',
                      pricing_model: 'tiered_volume',
                      variable_price: true,
                      description: 'flat fee installation',
                      tiers: [
                        {
                          up_to: 90.555555,
                          unit_amount: 1000,
                          unit_amount_decimal: '10',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: 300,
                          unit_amount: 2500,
                          unit_amount_decimal: '25',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        },
                        {
                          up_to: 600,
                          unit_amount: 6000,
                          unit_amount_decimal: '60',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        },
                        {
                          up_to: null,
                          unit_amount: 10000,
                          unit_amount_decimal: '100',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        }
                      ],
                      type: 'one_time',
                      price_display_in_journeys: 'show_as_starting_price',
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-29T12:34:47.681Z',
                      unit_amount: 0,
                      is_tax_inclusive: true,
                      active: true,
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      _updated_at: '2024-10-21T08:33:56.979Z',
                      internal_description: 'flat fee instalar',
                      unit: 'm',
                      _acl_sync: '2024-01-11T17:26:48.410Z',
                      unit_amount_decimal: '0.00',
                      unit_amount_currency: 'EUR',
                      _files: [],
                      $relation: {
                        entity_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                        _tags: []
                      }
                    },
                    {
                      _id: '5dab027d-086d-412b-b245-17611592e588',
                      pricing_model: 'per_unit',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      price_display_in_journeys: 'show_price',
                      active: true,
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      is_composite_price: true,
                      _tags: ['composite'],
                      _schema: 'price',
                      _title: 'test composite',
                      description: 'test composite',
                      price_components: [],
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-29T15:54:36.174Z',
                      _updated_at: '2024-10-24T08:57:46.274Z',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      internal_description: 'test composite',
                      $relation: {
                        entity_id: '5dab027d-086d-412b-b245-17611592e588',
                        _tags: []
                      }
                    },
                    {
                      _id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                      pricing_model: 'tiered_graduated',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      price_display_in_journeys: 'show_price',
                      active: true,
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      _tags: [],
                      _schema: 'price',
                      _title: 'graduated test',
                      description: 'graduated test',
                      variable_price: true,
                      tiers: [
                        {
                          up_to: 90.555555,
                          unit_amount: 1000,
                          unit_amount_decimal: '10',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: 300,
                          unit_amount: 5000,
                          unit_amount_decimal: '50',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: null,
                          unit_amount: 10000,
                          unit_amount_decimal: '100',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        }
                      ],
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-30T08:42:31.011Z',
                      _updated_at: '2024-01-11T17:26:48.500Z',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      internal_description: 'graduated test',
                      _acl_sync: '2024-01-11T17:26:48.405Z',
                      $relation: {
                        entity_id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                        _tags: []
                      }
                    }
                  ],
                  _id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
                  _availability_files: [],
                  internal_name: '[Flat Fee] - Composite Installation',
                  product_images: [
                    {
                      _id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                      s3ref: {
                        bucket: 'epilot-dev-user-content',
                        key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                      },
                      category: 'unknown',
                      filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                      access_control: 'public-read',
                      type: 'image',
                      public_url:
                        'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                      size_bytes: 332081,
                      readable_size: '324.3 KB',
                      mime_type: 'image/png',
                      versions: [
                        {
                          s3ref: {
                            bucket: 'epilot-dev-user-content',
                            key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                          },
                          category: 'unknown',
                          filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                          access_control: 'public-read',
                          type: 'image',
                          public_url:
                            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                          size_bytes: 332081,
                          readable_size: '324.3 KB',
                          mime_type: 'image/png'
                        }
                      ],
                      _schema: 'file',
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '11000656'
                        }
                      ],
                      _created_at: '2024-06-27T07:13:31.962Z',
                      _updated_at: '2024-09-24T19:44:09.352Z',
                      _title: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      source_url:
                        'https://file.dev.sls.epilot.io/v1/files/public/links/739224-jo2Rg-vdKgFxyqpjT9lla/Screenshot%202023-01-16%20at%205.24.48%20PM.png',
                      $relation: {
                        entity_id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                        _tags: []
                      }
                    }
                  ],
                  order_number: 'PRODUCT-1039'
                },
                taxes: [
                  {
                    rate: 'nontaxable',
                    rateValue: 0,
                    amount: 0
                  }
                ],
                type: 'recurring',
                billing_period: 'monthly',
                unit_amount_net: 12055,
                unit_amount_net_decimal: '120.55',
                unit_amount_gross: 12055,
                unit_amount_gross_decimal: '120.55',
                amount_subtotal: 12055,
                amount_total: 12055,
                amount_tax: 0,
                currency: 'EUR',
                is_tax_inclusive: true,
                amount_subtotal_decimal: '120.55',
                amount_total_decimal: '120.55'
              },
              {
                price_id: '295d3f9b-e6d0-4f44-8083-76ccf2c04591',
                product_id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
                quantity: 1,
                description: 'New One',
                unit_amount: 1000,
                unit_amount_decimal: '10.00',
                pricing_model: 'per_unit',
                _price: {
                  _id: '295d3f9b-e6d0-4f44-8083-76ccf2c04591',
                  _persistence_status: 'transient',
                  unit_amount: 1000,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '10.00',
                  sales_tax: 'standard',
                  active: true,
                  price_display_in_journeys: 'show_price',
                  type: 'one_time',
                  _title: 'New One',
                  description: 'New One',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  internal_description: 'New One',
                  pricing_model: 'per_unit',
                  is_tax_inclusive: true
                },
                _product: {
                  type: 'product',
                  _title: '[Flat Fee] - Composite Installation',
                  name: '[Flat Fee] - Composite Installation',
                  active: true,
                  price_options: [
                    {
                      _id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                      _schema: 'price',
                      _title: 'flat fee installation',
                      pricing_model: 'tiered_volume',
                      variable_price: true,
                      description: 'flat fee installation',
                      tiers: [
                        {
                          up_to: 90.555555,
                          unit_amount: 1000,
                          unit_amount_decimal: '10',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: 300,
                          unit_amount: 2500,
                          unit_amount_decimal: '25',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        },
                        {
                          up_to: 600,
                          unit_amount: 6000,
                          unit_amount_decimal: '60',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        },
                        {
                          up_to: null,
                          unit_amount: 10000,
                          unit_amount_decimal: '100',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        }
                      ],
                      type: 'one_time',
                      price_display_in_journeys: 'show_as_starting_price',
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-29T12:34:47.681Z',
                      unit_amount: 0,
                      is_tax_inclusive: true,
                      active: true,
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      _updated_at: '2024-10-21T08:33:56.979Z',
                      internal_description: 'flat fee instalar',
                      unit: 'm',
                      _acl_sync: '2024-01-11T17:26:48.410Z',
                      unit_amount_decimal: '0.00',
                      unit_amount_currency: 'EUR',
                      _files: [],
                      $relation: {
                        entity_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                        _tags: []
                      }
                    },
                    {
                      _id: '5dab027d-086d-412b-b245-17611592e588',
                      pricing_model: 'per_unit',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      price_display_in_journeys: 'show_price',
                      active: true,
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      is_composite_price: true,
                      _tags: ['composite'],
                      _schema: 'price',
                      _title: 'test composite',
                      description: 'test composite',
                      price_components: [],
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-29T15:54:36.174Z',
                      _updated_at: '2024-10-24T08:57:46.274Z',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      internal_description: 'test composite',
                      $relation: {
                        entity_id: '5dab027d-086d-412b-b245-17611592e588',
                        _tags: []
                      }
                    },
                    {
                      _id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                      pricing_model: 'tiered_graduated',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      price_display_in_journeys: 'show_price',
                      active: true,
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      _tags: [],
                      _schema: 'price',
                      _title: 'graduated test',
                      description: 'graduated test',
                      variable_price: true,
                      tiers: [
                        {
                          up_to: 90.555555,
                          unit_amount: 1000,
                          unit_amount_decimal: '10',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: 300,
                          unit_amount: 5000,
                          unit_amount_decimal: '50',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: null,
                          unit_amount: 10000,
                          unit_amount_decimal: '100',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        }
                      ],
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-30T08:42:31.011Z',
                      _updated_at: '2024-01-11T17:26:48.500Z',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      internal_description: 'graduated test',
                      _acl_sync: '2024-01-11T17:26:48.405Z',
                      $relation: {
                        entity_id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                        _tags: []
                      }
                    }
                  ],
                  _id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
                  _availability_files: [],
                  internal_name: '[Flat Fee] - Composite Installation',
                  product_images: [
                    {
                      _id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                      s3ref: {
                        bucket: 'epilot-dev-user-content',
                        key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                      },
                      category: 'unknown',
                      filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                      access_control: 'public-read',
                      type: 'image',
                      public_url:
                        'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                      size_bytes: 332081,
                      readable_size: '324.3 KB',
                      mime_type: 'image/png',
                      versions: [
                        {
                          s3ref: {
                            bucket: 'epilot-dev-user-content',
                            key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                          },
                          category: 'unknown',
                          filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                          access_control: 'public-read',
                          type: 'image',
                          public_url:
                            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                          size_bytes: 332081,
                          readable_size: '324.3 KB',
                          mime_type: 'image/png'
                        }
                      ],
                      _schema: 'file',
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '11000656'
                        }
                      ],
                      _created_at: '2024-06-27T07:13:31.962Z',
                      _updated_at: '2024-09-24T19:44:09.352Z',
                      _title: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      source_url:
                        'https://file.dev.sls.epilot.io/v1/files/public/links/739224-jo2Rg-vdKgFxyqpjT9lla/Screenshot%202023-01-16%20at%205.24.48%20PM.png',
                      $relation: {
                        entity_id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                        _tags: []
                      }
                    }
                  ],
                  order_number: 'PRODUCT-1039'
                },
                taxes: [
                  {
                    rate: 'nontaxable',
                    rateValue: 0,
                    amount: 0
                  }
                ],
                type: 'one_time',
                unit_amount_net: 1000,
                unit_amount_net_decimal: '10',
                unit_amount_gross: 1000,
                unit_amount_gross_decimal: '10',
                amount_subtotal: 1000,
                amount_total: 1000,
                amount_tax: 0,
                currency: 'EUR',
                is_tax_inclusive: true,
                amount_subtotal_decimal: '10',
                amount_total_decimal: '10'
              }
            ],
            _price: {
              _id: '5dab027d-086d-412b-b245-17611592e588',
              pricing_model: 'per_unit',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0.00',
              is_tax_inclusive: true,
              price_display_in_journeys: 'show_price',
              active: true,
              type: 'one_time',
              billing_period: 'weekly',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              is_composite_price: true,
              _tags: ['composite'],
              _title: 'test composite',
              description: 'test composite',
              price_components: [
                {
                  _id: '667a518f-4b3c-4623-aab4-c63c7f2bdb97',
                  pricing_model: 'tiered_flatfee',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: true,
                  price_display_in_journeys: 'show_price',
                  active: true,
                  type: 'recurring',
                  billing_period: 'monthly',
                  billing_duration_unit: 'years',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _title: 'testest',
                  description: 'testest',
                  variable_price: true,
                  tiers: [
                    {
                      up_to: 90.555555,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 10000,
                      flat_fee_amount_decimal: '100'
                    },
                    {
                      up_to: 300,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 20000,
                      flat_fee_amount_decimal: '200',
                      display_mode: 'on_request'
                    },
                    {
                      up_to: 600,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 30000,
                      flat_fee_amount_decimal: '300',
                      display_mode: 'on_request'
                    },
                    {
                      up_to: null,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 50000,
                      flat_fee_amount_decimal: '500'
                    }
                  ],
                  internal_description: 'testest',
                  billing_duration_amount: '3'
                },
                {
                  _id: '67ea3c64-3193-4650-b194-322047d65e80',
                  unit_amount: 12055,
                  type: 'recurring',
                  billing_period: 'monthly',
                  billing_duration_amount: '2',
                  billing_duration_unit: 'years',
                  notice_time_amount: '1',
                  notice_time_unit: 'months',
                  termination_time_amount: '2',
                  termination_time_unit: 'weeks',
                  renewal_duration_amount: '1',
                  renewal_duration_unit: 'years',
                  active: true,
                  sales_tax: 'reduced',
                  tax_behavior: 'exclusive',
                  description: 'Winter Lease',
                  billing_scheme: 'Per Unit',
                  tiers_mode: 'Standard',
                  _title: 'Winter Lease',
                  unit_amount_decimal: '120.55',
                  unit_amount_currency: 'EUR',
                  pricing_model: 'per_unit',
                  is_tax_inclusive: true,
                  price_display_in_journeys: 'show_price',
                  internal_description: 'Winter Lease'
                },
                {
                  _id: '295d3f9b-e6d0-4f44-8083-76ccf2c04591',
                  _persistence_status: 'transient',
                  unit_amount: 1000,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '10.00',
                  sales_tax: 'standard',
                  active: true,
                  price_display_in_journeys: 'show_price',
                  type: 'one_time',
                  _title: 'New One',
                  description: 'New One',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  internal_description: 'New One',
                  pricing_model: 'per_unit',
                  is_tax_inclusive: true
                }
              ],
              internal_description: 'test composite'
            },
            _product: {
              type: 'product',
              _title: '[Flat Fee] - Composite Installation',
              name: '[Flat Fee] - Composite Installation',
              active: true,
              price_options: [
                {
                  _id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                  _schema: 'price',
                  _title: 'flat fee installation',
                  pricing_model: 'tiered_volume',
                  variable_price: true,
                  description: 'flat fee installation',
                  tiers: [
                    {
                      up_to: 90.555555,
                      unit_amount: 1000,
                      unit_amount_decimal: '10',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0'
                    },
                    {
                      up_to: 300,
                      unit_amount: 2500,
                      unit_amount_decimal: '25',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0',
                      display_mode: 'on_request'
                    },
                    {
                      up_to: 600,
                      unit_amount: 6000,
                      unit_amount_decimal: '60',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0',
                      display_mode: 'on_request'
                    },
                    {
                      up_to: null,
                      unit_amount: 10000,
                      unit_amount_decimal: '100',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0'
                    }
                  ],
                  type: 'one_time',
                  price_display_in_journeys: 'show_as_starting_price',
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '10009172'
                    }
                  ],
                  _created_at: '2023-05-29T12:34:47.681Z',
                  unit_amount: 0,
                  is_tax_inclusive: true,
                  active: true,
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  _updated_at: '2024-10-21T08:33:56.979Z',
                  internal_description: 'flat fee instalar',
                  unit: 'm',
                  _acl_sync: '2024-01-11T17:26:48.410Z',
                  unit_amount_decimal: '0.00',
                  unit_amount_currency: 'EUR',
                  _files: [],
                  $relation: {
                    entity_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                    _tags: []
                  }
                },
                {
                  _id: '5dab027d-086d-412b-b245-17611592e588',
                  pricing_model: 'per_unit',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: true,
                  price_display_in_journeys: 'show_price',
                  active: true,
                  type: 'one_time',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  is_composite_price: true,
                  _tags: ['composite'],
                  _schema: 'price',
                  _title: 'test composite',
                  description: 'test composite',
                  price_components: [],
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '10009172'
                    }
                  ],
                  _created_at: '2023-05-29T15:54:36.174Z',
                  _updated_at: '2024-10-24T08:57:46.274Z',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  internal_description: 'test composite',
                  $relation: {
                    entity_id: '5dab027d-086d-412b-b245-17611592e588',
                    _tags: []
                  }
                },
                {
                  _id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                  pricing_model: 'tiered_graduated',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: true,
                  price_display_in_journeys: 'show_price',
                  active: true,
                  type: 'one_time',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _schema: 'price',
                  _title: 'graduated test',
                  description: 'graduated test',
                  variable_price: true,
                  tiers: [
                    {
                      up_to: 90.555555,
                      unit_amount: 1000,
                      unit_amount_decimal: '10',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0'
                    },
                    {
                      up_to: 300,
                      unit_amount: 5000,
                      unit_amount_decimal: '50',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0'
                    },
                    {
                      up_to: null,
                      unit_amount: 10000,
                      unit_amount_decimal: '100',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0'
                    }
                  ],
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '10009172'
                    }
                  ],
                  _created_at: '2023-05-30T08:42:31.011Z',
                  _updated_at: '2024-01-11T17:26:48.500Z',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  internal_description: 'graduated test',
                  _acl_sync: '2024-01-11T17:26:48.405Z',
                  $relation: {
                    entity_id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                    _tags: []
                  }
                }
              ],
              _id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
              _availability_files: [],
              internal_name: '[Flat Fee] - Composite Installation',
              product_images: [
                {
                  _id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                  s3ref: {
                    bucket: 'epilot-dev-user-content',
                    key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                  },
                  category: 'unknown',
                  filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                  access_control: 'public-read',
                  type: 'image',
                  public_url:
                    'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                  size_bytes: 332081,
                  readable_size: '324.3 KB',
                  mime_type: 'image/png',
                  versions: [
                    {
                      s3ref: {
                        bucket: 'epilot-dev-user-content',
                        key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                      },
                      category: 'unknown',
                      filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                      access_control: 'public-read',
                      type: 'image',
                      public_url:
                        'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                      size_bytes: 332081,
                      readable_size: '324.3 KB',
                      mime_type: 'image/png'
                    }
                  ],
                  _schema: 'file',
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '11000656'
                    }
                  ],
                  _created_at: '2024-06-27T07:13:31.962Z',
                  _updated_at: '2024-09-24T19:44:09.352Z',
                  _title: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  source_url:
                    'https://file.dev.sls.epilot.io/v1/files/public/links/739224-jo2Rg-vdKgFxyqpjT9lla/Screenshot%202023-01-16%20at%205.24.48%20PM.png',
                  $relation: {
                    entity_id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                    _tags: []
                  }
                }
              ],
              order_number: 'PRODUCT-1039'
            },
            price_mappings: [
              {
                price_id: '667a518f-4b3c-4623-aab4-c63c7f2bdb97',
                value: 0
              }
            ],
            currency: 'EUR',
            amount_subtotal: 23055,
            amount_total: 23055,
            total_details: {
              amount_tax: 0,
              breakdown: {
                taxes: [
                  {
                    tax: {
                      rate: 0
                    },
                    amount: 0
                  }
                ],
                recurrences: [
                  {
                    type: 'recurring',
                    billing_period: 'monthly',
                    unit_amount_gross: 22055,
                    unit_amount_net: 22055,
                    amount_subtotal: 22055,
                    amount_total: 22055,
                    amount_subtotal_decimal: '220.55',
                    amount_total_decimal: '220.55',
                    amount_tax: 0
                  },
                  {
                    type: 'one_time',
                    unit_amount_gross: 1000,
                    unit_amount_net: 1000,
                    amount_subtotal: 1000,
                    amount_total: 1000,
                    amount_subtotal_decimal: '10',
                    amount_total_decimal: '10',
                    amount_tax: 0
                  }
                ],
                recurrencesByTax: [
                  {
                    type: 'recurring',
                    billing_period: 'monthly',
                    amount_total: 22055,
                    amount_subtotal: 22055,
                    amount_tax: 0,
                    tax: {
                      tax: {
                        rate: 0
                      },
                      amount: 0
                    }
                  },
                  {
                    type: 'one_time',
                    amount_total: 1000,
                    amount_subtotal: 1000,
                    amount_tax: 0,
                    tax: {
                      tax: {
                        rate: 0
                      },
                      amount: 0
                    }
                  }
                ],
                cashbacks: []
              }
            },
            amount_tax: 0,
            amount_subtotal_decimal: '230.55',
            amount_total_decimal: '230.55'
          },
          {
            price_id: '57ced6a0-223f-4636-9389-90a1335c69d8',
            product_id: '64d52abe-bc47-4a49-8008-08927284590a',
            quantity: 1,
            description: '[Flat Fee] - Installation',
            unit_amount: 0,
            unit_amount_decimal: '0',
            pricing_model: 'tiered_flatfee',
            _price: {
              _id: '57ced6a0-223f-4636-9389-90a1335c69d8',
              _tags: [],
              active: true,
              billing_duration_unit: 'months',
              description: '[Flat Fee] - Installation',
              is_tax_inclusive: false,
              notice_time_unit: 'months',
              price_display_in_journeys: 'show_as_starting_price',
              pricing_model: 'tiered_flatfee',
              renewal_duration_unit: 'months',
              termination_time_unit: 'months',
              tiers: [
                {
                  flat_fee_amount: 5000,
                  flat_fee_amount_decimal: '50',
                  unit_amount: 0,
                  unit_amount_decimal: '0',
                  up_to: 5
                },
                {
                  flat_fee_amount: 7500,
                  flat_fee_amount_decimal: '75',
                  unit_amount: 0,
                  unit_amount_decimal: '0',
                  up_to: 10
                },
                {
                  flat_fee_amount: 10000,
                  flat_fee_amount_decimal: '100',
                  unit_amount: 0,
                  unit_amount_decimal: '0',
                  up_to: 15
                },
                {
                  flat_fee_amount: 12000,
                  flat_fee_amount_decimal: '120',
                  unit_amount: 0,
                  unit_amount_decimal: '0'
                }
              ],
              type: 'one_time',
              unit: 'kwh',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0',
              variable_price: true,
              billing_period: 'weekly',
              internal_description: '[Flat Fee] - Installation',
              _title: '[Flat Fee] - Installation'
            },
            _product: {
              _purpose: [],
              _tags: [],
              active: true,
              feature: [],
              internal_name: '[Flat Fee] - Installation',
              name: '[Flat Fee] - Installation',
              price_options: [
                {
                  _id: '57ced6a0-223f-4636-9389-90a1335c69d8',
                  _schema: 'price',
                  _tags: [],
                  active: true,
                  billing_duration_unit: 'months',
                  description: '[Flat Fee] - Installation',
                  is_tax_inclusive: false,
                  notice_time_unit: 'months',
                  price_display_in_journeys: 'show_as_starting_price',
                  pricing_model: 'tiered_flatfee',
                  renewal_duration_unit: 'months',
                  termination_time_unit: 'months',
                  tiers: [
                    {
                      flat_fee_amount: 5000,
                      flat_fee_amount_decimal: '50',
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      up_to: 5
                    },
                    {
                      flat_fee_amount: 7500,
                      flat_fee_amount_decimal: '75',
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      up_to: 10
                    },
                    {
                      flat_fee_amount: 10000,
                      flat_fee_amount_decimal: '100',
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      up_to: 15
                    },
                    {
                      flat_fee_amount: 12000,
                      flat_fee_amount_decimal: '120',
                      unit_amount: 0,
                      unit_amount_decimal: '0'
                    }
                  ],
                  type: 'one_time',
                  unit: 'kwh',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0',
                  variable_price: true,
                  _acl_sync: '2024-08-23T12:45:21.316Z',
                  billing_period: 'weekly',
                  internal_description: '[Flat Fee] - Installation',
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: 'api_bcyn5xLgUYEJZSs8LdACs'
                    }
                  ],
                  _created_at: '2024-08-29T14:59:42.190Z',
                  _updated_at: '2024-08-29T14:59:42.190Z',
                  _title: '[Flat Fee] - Installation',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  $relation: {
                    entity_id: '57ced6a0-223f-4636-9389-90a1335c69d8'
                  }
                }
              ],
              type: 'product',
              order_number: 'PRODUCT-1031',
              _id: '64d52abe-bc47-4a49-8008-08927284590a',
              _title: '[Flat Fee] - Installation'
            },
            taxes: [
              {
                tax: {
                  active: true,
                  description: 'test 19% tax',
                  rate: '19',
                  region: 'DE',
                  type: 'VAT',
                  _schema: 'tax',
                  _id: '812bba85-147a-4f6c-bd94-5a852abd335c',
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: 'api_bcyn5xLgUYEJZSs8LdACs'
                    }
                  ],
                  _created_at: '2024-07-26T14:06:09.046Z',
                  _updated_at: '2025-01-16T18:34:47.522Z',
                  _title: 'test 19% tax',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  _manifest: [
                    'd3af0cf1-399d-4975-8e50-931b7b714ed9',
                    'f4466916-5af1-454e-851f-e9089162a025'
                  ],
                  behavior: 'Inclusive',
                  file: null,
                  workflows: {
                    i9P35ewx: [
                      {
                        assignees: [],
                        definition_id: 'i9P35ewx',
                        id: '1ampltsttyz',
                        last_update_time: '2024-06-28T07:46:40.456Z',
                        name: 'Neuer Preis hinzugefügt',
                        status: 'STARTED',
                        task_assignees: ['112006'],
                        task_execution_type: 'MANUAL',
                        task_id: 'tkv00dynxjx',
                        task_name:
                          'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                        task_status: 'ASSIGNED'
                      },
                      {
                        assignees: [],
                        definition_id: 'i9P35ewx',
                        id: 'ullc8yrng1b',
                        last_update_time: '2024-07-03T09:15:25.342Z',
                        name: 'Neuer Preis hinzugefügt',
                        status: 'STARTED',
                        task_assignees: ['112006'],
                        task_execution_type: 'MANUAL',
                        task_id: '4bgx55cbi8o',
                        task_name:
                          'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                        task_status: 'ASSIGNED'
                      },
                      {
                        assignees: [],
                        definition_id: 'i9P35ewx',
                        id: 'l6bq243meh0',
                        last_update_time: '2024-07-03T09:15:27.444Z',
                        name: 'Neuer Preis hinzugefügt',
                        status: 'STARTED',
                        task_assignees: ['112006'],
                        task_execution_type: 'MANUAL',
                        task_id: 'qcknwz8lxnf',
                        task_name:
                          'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                        task_status: 'ASSIGNED'
                      },
                      {
                        assignees: [],
                        definition_id: 'i9P35ewx',
                        id: 'ukii16rrght',
                        last_update_time: '2024-07-04T13:46:55.071Z',
                        name: 'Neuer Preis hinzugefügt',
                        status: 'STARTED',
                        task_assignees: ['112006'],
                        task_execution_type: 'MANUAL',
                        task_id: 'nqolrvvw097',
                        task_name:
                          'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                        task_status: 'ASSIGNED'
                      },
                      {
                        assignees: [],
                        definition_id: 'i9P35ewx',
                        id: '8r1pk0xt0k5',
                        last_update_time: '2024-07-04T13:46:57.383Z',
                        name: 'Neuer Preis hinzugefügt',
                        status: 'STARTED',
                        task_assignees: ['112006'],
                        task_execution_type: 'MANUAL',
                        task_id: '4fcz1s7bd20',
                        task_name:
                          'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                        task_status: 'ASSIGNED'
                      }
                    ],
                    primary: {
                      assignees: [],
                      definition_id: 'i9P35ewx',
                      id: '8r1pk0xt0k5',
                      last_update_time: '2024-07-04T13:46:57.383Z',
                      name: 'Neuer Preis hinzugefügt',
                      status: 'STARTED',
                      task_assignees: ['112006'],
                      task_execution_type: 'MANUAL',
                      task_id: '4fcz1s7bd20',
                      task_name:
                        'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                      task_status: 'ASSIGNED'
                    }
                  }
                },
                amount: 950
              }
            ],
            price_mappings: [
              {
                price_id: '57ced6a0-223f-4636-9389-90a1335c69d8',
                value: 0
              }
            ],
            tiers_details: [
              {
                quantity: 0,
                unit_amount: 5000,
                unit_amount_decimal: '50',
                unit_amount_net: 5000,
                unit_amount_gross: 5950,
                amount_subtotal: 5000,
                amount_total: 5950,
                amount_tax: 950
              }
            ],
            unit_amount_gross: 5950,
            unit_amount_net: 5000,
            amount_subtotal: 5000,
            amount_total: 5950,
            amount_tax: 950,
            currency: 'EUR',
            is_tax_inclusive: false,
            unit_amount_net_decimal: '50',
            unit_amount_gross_decimal: '59.5',
            amount_subtotal_decimal: '50',
            amount_total_decimal: '59.5'
          },
          {
            price_id: '98c9872e-660b-4d2f-90e9-a0b4c7245d6d',
            product_id: '216c4a5a-3392-4395-9b20-6f2e0348466d',
            quantity: 1,
            description: 'Composite Price',
            unit_amount: 0,
            unit_amount_decimal: '0.00',
            pricing_model: 'per_unit',
            item_components: [
              {
                price_id: '061f8783-b1b4-4de3-a220-ca51d221c0a0',
                product_id: '216c4a5a-3392-4395-9b20-6f2e0348466d',
                quantity: 1,
                description: 'Comp 2',
                unit_amount: 100,
                unit_amount_decimal: '1',
                pricing_model: 'per_unit',
                _price: {
                  _id: '061f8783-b1b4-4de3-a220-ca51d221c0a0',
                  pricing_model: 'per_unit',
                  unit_amount: 100,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '1',
                  is_tax_inclusive: true,
                  active: true,
                  price_display_in_journeys: 'show_price',
                  type: 'one_time',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _title: 'Comp 2',
                  description: 'Comp 2',
                  tax: null,
                  internal_description: 'Comp 2'
                },
                _product: {
                  type: 'product',
                  _title: 'Tiered Power Tariff',
                  name: 'Tiered Power Tariff',
                  active: true,
                  _id: '216c4a5a-3392-4395-9b20-6f2e0348466d',
                  price_options: [
                    {
                      _id: '158c604b-ce0b-4f26-810c-053e65dc051d',
                      pricing_model: 'per_unit',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      active: true,
                      price_display_in_journeys: 'show_price',
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      is_composite_price: true,
                      _tags: ['composite'],
                      _schema: 'price',
                      _title: 'Tariff - 1',
                      description: 'Tariff - 1',
                      _org: '739224',
                      _created_at: '2023-02-22T17:41:03.139Z',
                      _updated_at: '2023-02-28T12:16:28.333Z',
                      price_components: [],
                      internal_description: 'Tariff - 1',
                      _owners: [
                        {
                          org_id: '739224'
                        }
                      ],
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      $relation: {
                        entity_id: '158c604b-ce0b-4f26-810c-053e65dc051d',
                        _schema: 'price',
                        _tags: []
                      }
                    },
                    {
                      _id: '98c9872e-660b-4d2f-90e9-a0b4c7245d6d',
                      pricing_model: 'per_unit',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      active: true,
                      price_display_in_journeys: 'show_price',
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      is_composite_price: true,
                      _tags: ['composite'],
                      _schema: 'price',
                      _title: 'Composite Price',
                      description: 'Composite Price',
                      price_components: [],
                      _org: '739224',
                      _created_at: '2023-02-23T13:58:40.351Z',
                      _updated_at: '2023-02-24T13:55:01.249Z',
                      internal_description: 'Composite Price',
                      _owners: [
                        {
                          org_id: '739224'
                        }
                      ],
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      $relation: {
                        entity_id: '98c9872e-660b-4d2f-90e9-a0b4c7245d6d',
                        _schema: 'price',
                        _tags: []
                      }
                    }
                  ],
                  internal_name: 'Tiered Power Tariff'
                },
                taxes: [
                  {
                    tax: {
                      _manifest: [
                        '956e9f44-0d8e-4d50-af85-e528fb130d64',
                        '3babbfe8-80a2-4e19-b9e6-3d6d4fa22730'
                      ],
                      _schema: 'tax',
                      active: true,
                      description: '19%',
                      rate: '19',
                      region: 'DE',
                      type: 'VAT',
                      _id: '4e16391f-47a5-4020-bdd5-6c157f607836',
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '11000037'
                        }
                      ],
                      _created_at: '2024-11-27T07:17:34.967Z',
                      _updated_at: '2024-11-27T07:17:34.967Z',
                      _title: '19%',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      }
                    },
                    amount: 16
                  }
                ],
                type: 'one_time',
                unit_amount_net: 84,
                unit_amount_net_decimal: '0.840336134454',
                unit_amount_gross: 100,
                unit_amount_gross_decimal: '1',
                amount_subtotal: 84,
                amount_total: 100,
                amount_tax: 16,
                currency: 'EUR',
                is_tax_inclusive: true,
                amount_subtotal_decimal: '0.840336134454',
                amount_total_decimal: '1'
              },
              {
                price_id: '083747e3-cc16-42d5-8727-152df995b86d',
                product_id: '216c4a5a-3392-4395-9b20-6f2e0348466d',
                quantity: 1,
                description: 'Comp 3 - trans',
                unit_amount: 1500,
                unit_amount_decimal: '15.00',
                pricing_model: 'per_unit',
                _price: {
                  _id: '083747e3-cc16-42d5-8727-152df995b86d',
                  pricing_model: 'per_unit',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '15.00',
                  is_tax_inclusive: true,
                  active: true,
                  price_display_in_journeys: 'show_price',
                  type: 'recurring',
                  billing_period: 'monthly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _title: 'Comp 3 - trans',
                  description: 'Comp 3 - trans',
                  tax: null,
                  internal_description: 'Comp 3 - trans'
                },
                _product: {
                  type: 'product',
                  _title: 'Tiered Power Tariff',
                  name: 'Tiered Power Tariff',
                  active: true,
                  _id: '216c4a5a-3392-4395-9b20-6f2e0348466d',
                  price_options: [
                    {
                      _id: '158c604b-ce0b-4f26-810c-053e65dc051d',
                      pricing_model: 'per_unit',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      active: true,
                      price_display_in_journeys: 'show_price',
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      is_composite_price: true,
                      _tags: ['composite'],
                      _schema: 'price',
                      _title: 'Tariff - 1',
                      description: 'Tariff - 1',
                      _org: '739224',
                      _created_at: '2023-02-22T17:41:03.139Z',
                      _updated_at: '2023-02-28T12:16:28.333Z',
                      price_components: [],
                      internal_description: 'Tariff - 1',
                      _owners: [
                        {
                          org_id: '739224'
                        }
                      ],
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      $relation: {
                        entity_id: '158c604b-ce0b-4f26-810c-053e65dc051d',
                        _schema: 'price',
                        _tags: []
                      }
                    },
                    {
                      _id: '98c9872e-660b-4d2f-90e9-a0b4c7245d6d',
                      pricing_model: 'per_unit',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      active: true,
                      price_display_in_journeys: 'show_price',
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      is_composite_price: true,
                      _tags: ['composite'],
                      _schema: 'price',
                      _title: 'Composite Price',
                      description: 'Composite Price',
                      price_components: [],
                      _org: '739224',
                      _created_at: '2023-02-23T13:58:40.351Z',
                      _updated_at: '2023-02-24T13:55:01.249Z',
                      internal_description: 'Composite Price',
                      _owners: [
                        {
                          org_id: '739224'
                        }
                      ],
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      $relation: {
                        entity_id: '98c9872e-660b-4d2f-90e9-a0b4c7245d6d',
                        _schema: 'price',
                        _tags: []
                      }
                    }
                  ],
                  internal_name: 'Tiered Power Tariff'
                },
                taxes: [
                  {
                    tax: {
                      active: true,
                      description: 'test 19% tax',
                      rate: '19',
                      region: 'DE',
                      type: 'VAT',
                      _schema: 'tax',
                      _id: '812bba85-147a-4f6c-bd94-5a852abd335c',
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: 'api_bcyn5xLgUYEJZSs8LdACs'
                        }
                      ],
                      _created_at: '2024-07-26T14:06:09.046Z',
                      _updated_at: '2025-01-16T18:34:47.522Z',
                      _title: 'test 19% tax',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      _manifest: [
                        'd3af0cf1-399d-4975-8e50-931b7b714ed9',
                        'f4466916-5af1-454e-851f-e9089162a025'
                      ],
                      behavior: 'Inclusive',
                      file: null,
                      workflows: {
                        i9P35ewx: [
                          {
                            assignees: [],
                            definition_id: 'i9P35ewx',
                            id: '1ampltsttyz',
                            last_update_time: '2024-06-28T07:46:40.456Z',
                            name: 'Neuer Preis hinzugefügt',
                            status: 'STARTED',
                            task_assignees: ['112006'],
                            task_execution_type: 'MANUAL',
                            task_id: 'tkv00dynxjx',
                            task_name:
                              'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                            task_status: 'ASSIGNED'
                          },
                          {
                            assignees: [],
                            definition_id: 'i9P35ewx',
                            id: 'ullc8yrng1b',
                            last_update_time: '2024-07-03T09:15:25.342Z',
                            name: 'Neuer Preis hinzugefügt',
                            status: 'STARTED',
                            task_assignees: ['112006'],
                            task_execution_type: 'MANUAL',
                            task_id: '4bgx55cbi8o',
                            task_name:
                              'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                            task_status: 'ASSIGNED'
                          },
                          {
                            assignees: [],
                            definition_id: 'i9P35ewx',
                            id: 'l6bq243meh0',
                            last_update_time: '2024-07-03T09:15:27.444Z',
                            name: 'Neuer Preis hinzugefügt',
                            status: 'STARTED',
                            task_assignees: ['112006'],
                            task_execution_type: 'MANUAL',
                            task_id: 'qcknwz8lxnf',
                            task_name:
                              'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                            task_status: 'ASSIGNED'
                          },
                          {
                            assignees: [],
                            definition_id: 'i9P35ewx',
                            id: 'ukii16rrght',
                            last_update_time: '2024-07-04T13:46:55.071Z',
                            name: 'Neuer Preis hinzugefügt',
                            status: 'STARTED',
                            task_assignees: ['112006'],
                            task_execution_type: 'MANUAL',
                            task_id: 'nqolrvvw097',
                            task_name:
                              'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                            task_status: 'ASSIGNED'
                          },
                          {
                            assignees: [],
                            definition_id: 'i9P35ewx',
                            id: '8r1pk0xt0k5',
                            last_update_time: '2024-07-04T13:46:57.383Z',
                            name: 'Neuer Preis hinzugefügt',
                            status: 'STARTED',
                            task_assignees: ['112006'],
                            task_execution_type: 'MANUAL',
                            task_id: '4fcz1s7bd20',
                            task_name:
                              'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                            task_status: 'ASSIGNED'
                          }
                        ],
                        primary: {
                          assignees: [],
                          definition_id: 'i9P35ewx',
                          id: '8r1pk0xt0k5',
                          last_update_time: '2024-07-04T13:46:57.383Z',
                          name: 'Neuer Preis hinzugefügt',
                          status: 'STARTED',
                          task_assignees: ['112006'],
                          task_execution_type: 'MANUAL',
                          task_id: '4fcz1s7bd20',
                          task_name:
                            'Mindestmarge/strategischen Vorgaben wurden eingehalten',
                          task_status: 'ASSIGNED'
                        }
                      }
                    },
                    amount: 239
                  }
                ],
                type: 'one_time',
                unit_amount_net: 1261,
                unit_amount_net_decimal: '12.605042016807',
                unit_amount_gross: 1500,
                unit_amount_gross_decimal: '15',
                amount_subtotal: 1261,
                amount_total: 1500,
                amount_tax: 239,
                currency: 'EUR',
                is_tax_inclusive: true,
                amount_subtotal_decimal: '12.605042016807',
                amount_total_decimal: '15'
              }
            ],
            _price: {
              _id: '98c9872e-660b-4d2f-90e9-a0b4c7245d6d',
              pricing_model: 'per_unit',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0.00',
              is_tax_inclusive: true,
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'one_time',
              billing_period: 'weekly',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              is_composite_price: true,
              _tags: ['composite'],
              _title: 'Composite Price',
              description: 'Composite Price',
              price_components: [
                {
                  _id: '061f8783-b1b4-4de3-a220-ca51d221c0a0',
                  pricing_model: 'per_unit',
                  unit_amount: 100,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '1',
                  is_tax_inclusive: true,
                  active: true,
                  price_display_in_journeys: 'show_price',
                  type: 'one_time',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _title: 'Comp 2',
                  description: 'Comp 2',
                  tax: null,
                  internal_description: 'Comp 2'
                },
                {
                  _id: '083747e3-cc16-42d5-8727-152df995b86d',
                  pricing_model: 'per_unit',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: true,
                  active: true,
                  price_display_in_journeys: 'show_price',
                  type: 'one_time',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _title: 'Comp 3 - trans',
                  description: 'Comp 3 - trans',
                  tax: null,
                  internal_description: 'Comp 3 - trans'
                }
              ],
              internal_description: 'Composite Price'
            },
            _product: {
              type: 'product',
              _title: 'Tiered Power Tariff',
              name: 'Tiered Power Tariff',
              active: true,
              _id: '216c4a5a-3392-4395-9b20-6f2e0348466d',
              price_options: [
                {
                  _id: '158c604b-ce0b-4f26-810c-053e65dc051d',
                  pricing_model: 'per_unit',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: true,
                  active: true,
                  price_display_in_journeys: 'show_price',
                  type: 'one_time',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  is_composite_price: true,
                  _tags: ['composite'],
                  _schema: 'price',
                  _title: 'Tariff - 1',
                  description: 'Tariff - 1',
                  _org: '739224',
                  _created_at: '2023-02-22T17:41:03.139Z',
                  _updated_at: '2023-02-28T12:16:28.333Z',
                  price_components: [],
                  internal_description: 'Tariff - 1',
                  _owners: [
                    {
                      org_id: '739224'
                    }
                  ],
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  $relation: {
                    entity_id: '158c604b-ce0b-4f26-810c-053e65dc051d',
                    _schema: 'price',
                    _tags: []
                  }
                },
                {
                  _id: '98c9872e-660b-4d2f-90e9-a0b4c7245d6d',
                  pricing_model: 'per_unit',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: true,
                  active: true,
                  price_display_in_journeys: 'show_price',
                  type: 'one_time',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  is_composite_price: true,
                  _tags: ['composite'],
                  _schema: 'price',
                  _title: 'Composite Price',
                  description: 'Composite Price',
                  price_components: [],
                  _org: '739224',
                  _created_at: '2023-02-23T13:58:40.351Z',
                  _updated_at: '2023-02-24T13:55:01.249Z',
                  internal_description: 'Composite Price',
                  _owners: [
                    {
                      org_id: '739224'
                    }
                  ],
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  $relation: {
                    entity_id: '98c9872e-660b-4d2f-90e9-a0b4c7245d6d',
                    _schema: 'price',
                    _tags: []
                  }
                }
              ],
              internal_name: 'Tiered Power Tariff'
            },
            currency: 'EUR',
            amount_subtotal: 1345,
            amount_total: 1600,
            total_details: {
              amount_tax: 255,
              breakdown: {
                taxes: [
                  {
                    tax: {
                      _id: '4e16391f-47a5-4020-bdd5-6c157f607836',
                      type: 'VAT',
                      rate: '19'
                    },
                    amount: 255
                  }
                ],
                recurrences: [
                  {
                    type: 'one_time',
                    unit_amount_gross: 100,
                    unit_amount_net: 84,
                    amount_subtotal: 84,
                    amount_total: 100,
                    amount_subtotal_decimal: '0.840336134454',
                    amount_total_decimal: '1',
                    amount_tax: 16
                  },
                  {
                    type: 'recurring',
                    billing_period: 'monthly',
                    unit_amount_gross: 1500,
                    unit_amount_net: 1261,
                    amount_subtotal: 1261,
                    amount_total: 1500,
                    amount_subtotal_decimal: '12.605042016807',
                    amount_total_decimal: '15',
                    amount_tax: 239
                  }
                ],
                recurrencesByTax: [
                  {
                    type: 'one_time',
                    amount_total: 100,
                    amount_subtotal: 84,
                    amount_tax: 16,
                    tax: {
                      tax: {
                        _id: '4e16391f-47a5-4020-bdd5-6c157f607836',
                        type: 'VAT',
                        rate: '19'
                      },
                      amount: 255
                    }
                  },
                  {
                    type: 'recurring',
                    billing_period: 'monthly',
                    amount_total: 1500,
                    amount_subtotal: 1261,
                    amount_tax: 239,
                    tax: {
                      tax: {
                        _id: '4e16391f-47a5-4020-bdd5-6c157f607836',
                        type: 'VAT',
                        rate: '19'
                      },
                      amount: 255
                    }
                  }
                ],
                cashbacks: []
              }
            },
            amount_tax: 255,
            amount_subtotal_decimal: '13.445378151261',
            amount_total_decimal: '16'
          },
          {
            price_id: '8d5da070-94a4-4809-a2e8-9cbafba791fb',
            product_id: '47cbbba4-326f-4586-8c94-790df3a02f45',
            quantity: 1,
            description: 'Flat Fee price parent - Demo 01/06',
            unit_amount: 0,
            unit_amount_decimal: '0.00',
            pricing_model: 'per_unit',
            item_components: [
              {
                price_id: 'bad5d471-dd91-4324-973f-14e01dd45e9c',
                product_id: '47cbbba4-326f-4586-8c94-790df3a02f45',
                quantity: 1,
                description: 'Flat Fee price - Demo 31/05',
                unit_amount: 0,
                unit_amount_decimal: '0.00',
                pricing_model: 'tiered_flatfee',
                _price: {
                  _id: 'bad5d471-dd91-4324-973f-14e01dd45e9c',
                  pricing_model: 'tiered_flatfee',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: false,
                  price_display_in_journeys: 'show_price',
                  active: true,
                  type: 'recurring',
                  billing_period: 'yearly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _title: 'Flat Fee price - Demo 31/05',
                  description: 'Flat Fee price - Demo 31/05',
                  variable_price: true,
                  unit: 'kwh',
                  tiers: [
                    {
                      up_to: 100,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 10000,
                      flat_fee_amount_decimal: '100'
                    },
                    {
                      up_to: 300,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 25000,
                      flat_fee_amount_decimal: '250'
                    },
                    {
                      up_to: 600,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 50000,
                      flat_fee_amount_decimal: '500'
                    },
                    {
                      up_to: null,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 100000,
                      flat_fee_amount_decimal: '1000'
                    }
                  ],
                  internal_description: 'Flat Fee price - Demo 01/06',
                  tax: {
                    $relation: [
                      {
                        entity_id: 'e7a008ae-0eea-4530-8f20-c73e4046b1ff'
                      }
                    ]
                  }
                },
                _product: {
                  type: 'product',
                  active: true,
                  _title: 'Tiered Product Demo 01/06',
                  name: 'Tiered Product Demo 01/06',
                  price_options: [
                    {
                      _id: '292836cf-b893-4214-ae50-7e5f227f13d5',
                      pricing_model: 'tiered_volume',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      price_display_in_journeys: 'show_price',
                      active: true,
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      _tags: [],
                      _schema: 'price',
                      _title: 'Volume price - Demo 01/06',
                      description: 'Volume price - Demo 01/06',
                      variable_price: true,
                      unit: 'kwh',
                      tiers: [
                        {
                          up_to: 100,
                          unit_amount: 1000,
                          unit_amount_decimal: '10',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: 300,
                          unit_amount: 2500,
                          unit_amount_decimal: '25',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        },
                        {
                          up_to: 600,
                          unit_amount: 5000,
                          unit_amount_decimal: '50',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        },
                        {
                          up_to: null,
                          unit_amount: 10000,
                          unit_amount_decimal: '100',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        }
                      ],
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-31T19:08:24.739Z',
                      _updated_at: '2023-09-19T10:14:17.467Z',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      _acl_sync: '2023-09-19T10:14:17.176Z',
                      internal_description: 'Volume price - Demo 01/06',
                      $relation: {
                        entity_id: '292836cf-b893-4214-ae50-7e5f227f13d5',
                        _tags: []
                      }
                    },
                    {
                      _id: '8d5da070-94a4-4809-a2e8-9cbafba791fb',
                      pricing_model: 'per_unit',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      price_display_in_journeys: 'show_price',
                      active: true,
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      is_composite_price: true,
                      _tags: ['composite'],
                      _schema: 'price',
                      _title: 'Flat Fee price parent - Demo 01/06',
                      description: 'Flat Fee price parent - Demo 01/06',
                      price_components: [],
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-31T19:10:12.454Z',
                      _updated_at: '2024-07-25T08:17:39.583Z',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      internal_description:
                        'Flat Fee price parent - Demo 01/06',
                      $relation: {
                        entity_id: '8d5da070-94a4-4809-a2e8-9cbafba791fb',
                        _tags: []
                      }
                    },
                    {
                      _id: '2904f1c7-8c80-4bb7-95a0-fbf617cb03cc',
                      pricing_model: 'tiered_graduated',
                      unit_amount: 0,
                      unit_amount_currency: 'EUR',
                      unit_amount_decimal: '0.00',
                      is_tax_inclusive: true,
                      price_display_in_journeys: 'show_price',
                      active: true,
                      type: 'one_time',
                      billing_period: 'weekly',
                      billing_duration_unit: 'months',
                      notice_time_unit: 'months',
                      termination_time_unit: 'months',
                      renewal_duration_unit: 'months',
                      _tags: [],
                      _schema: 'price',
                      _title: 'Cumulative price - Demo 01/06',
                      description: 'Cumulative price - Demo 01/06',
                      variable_price: true,
                      unit: 'kwh',
                      _org: '739224',
                      _owners: [
                        {
                          org_id: '739224',
                          user_id: '10009172'
                        }
                      ],
                      _created_at: '2023-05-31T19:10:38.293Z',
                      _updated_at: '2023-09-19T10:14:17.354Z',
                      _acl: {
                        view: ['org_739224'],
                        edit: ['org_739224'],
                        delete: ['org_739224']
                      },
                      tiers: [
                        {
                          up_to: 100,
                          unit_amount: 10000,
                          unit_amount_decimal: '100',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0'
                        },
                        {
                          up_to: 300,
                          unit_amount: 5000,
                          unit_amount_decimal: '50',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        },
                        {
                          up_to: 600,
                          unit_amount: 2500,
                          unit_amount_decimal: '25',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        },
                        {
                          up_to: null,
                          unit_amount: 1000,
                          unit_amount_decimal: '10',
                          flat_fee_amount: 0,
                          flat_fee_amount_decimal: '0',
                          display_mode: 'on_request'
                        }
                      ],
                      _acl_sync: '2023-09-19T10:14:17.187Z',
                      internal_description: 'Cumulative price - Demo 01/06',
                      $relation: {
                        entity_id: '2904f1c7-8c80-4bb7-95a0-fbf617cb03cc',
                        _tags: []
                      }
                    }
                  ],
                  _id: '47cbbba4-326f-4586-8c94-790df3a02f45',
                  internal_name: 'Tiered Product Demo 01/06'
                },
                taxes: [
                  {
                    rate: 'nontaxable',
                    rateValue: 0,
                    amount: 0
                  }
                ],
                type: 'recurring',
                billing_period: 'yearly',
                tiers_details: [
                  {
                    quantity: 0,
                    unit_amount: 10000,
                    unit_amount_decimal: '100',
                    unit_amount_net: 10000,
                    unit_amount_gross: 10000,
                    amount_subtotal: 10000,
                    amount_total: 10000,
                    amount_tax: 0
                  }
                ],
                unit_amount_gross: 10000,
                unit_amount_net: 10000,
                amount_subtotal: 10000,
                amount_total: 10000,
                amount_tax: 0,
                currency: 'EUR',
                is_tax_inclusive: false,
                unit_amount_net_decimal: '100',
                unit_amount_gross_decimal: '100',
                amount_subtotal_decimal: '100',
                amount_total_decimal: '100'
              }
            ],
            _price: {
              _id: '8d5da070-94a4-4809-a2e8-9cbafba791fb',
              pricing_model: 'per_unit',
              unit_amount: 0,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0.00',
              is_tax_inclusive: true,
              price_display_in_journeys: 'show_price',
              active: true,
              type: 'one_time',
              billing_period: 'weekly',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              is_composite_price: true,
              _tags: ['composite'],
              _title: 'Flat Fee price parent - Demo 01/06',
              description: 'Flat Fee price parent - Demo 01/06',
              price_components: [
                {
                  _id: 'bad5d471-dd91-4324-973f-14e01dd45e9c',
                  pricing_model: 'tiered_flatfee',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: false,
                  price_display_in_journeys: 'show_price',
                  active: true,
                  type: 'recurring',
                  billing_period: 'yearly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _title: 'Flat Fee price - Demo 31/05',
                  description: 'Flat Fee price - Demo 31/05',
                  variable_price: true,
                  unit: 'kwh',
                  tiers: [
                    {
                      up_to: 100,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 10000,
                      flat_fee_amount_decimal: '100'
                    },
                    {
                      up_to: 300,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 25000,
                      flat_fee_amount_decimal: '250'
                    },
                    {
                      up_to: 600,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 50000,
                      flat_fee_amount_decimal: '500'
                    },
                    {
                      up_to: null,
                      unit_amount: 0,
                      unit_amount_decimal: '0',
                      flat_fee_amount: 100000,
                      flat_fee_amount_decimal: '1000'
                    }
                  ],
                  internal_description: 'Flat Fee price - Demo 01/06',
                  tax: {
                    $relation: [
                      {
                        entity_id: 'e7a008ae-0eea-4530-8f20-c73e4046b1ff'
                      }
                    ]
                  }
                }
              ],
              internal_description: 'Flat Fee price parent - Demo 01/06'
            },
            _product: {
              type: 'product',
              active: true,
              _title: 'Tiered Product Demo 01/06',
              name: 'Tiered Product Demo 01/06',
              price_options: [
                {
                  _id: '292836cf-b893-4214-ae50-7e5f227f13d5',
                  pricing_model: 'tiered_volume',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: true,
                  price_display_in_journeys: 'show_price',
                  active: true,
                  type: 'one_time',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _schema: 'price',
                  _title: 'Volume price - Demo 01/06',
                  description: 'Volume price - Demo 01/06',
                  variable_price: true,
                  unit: 'kwh',
                  tiers: [
                    {
                      up_to: 100,
                      unit_amount: 1000,
                      unit_amount_decimal: '10',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0'
                    },
                    {
                      up_to: 300,
                      unit_amount: 2500,
                      unit_amount_decimal: '25',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0',
                      display_mode: 'on_request'
                    },
                    {
                      up_to: 600,
                      unit_amount: 5000,
                      unit_amount_decimal: '50',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0',
                      display_mode: 'on_request'
                    },
                    {
                      up_to: null,
                      unit_amount: 10000,
                      unit_amount_decimal: '100',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0'
                    }
                  ],
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '10009172'
                    }
                  ],
                  _created_at: '2023-05-31T19:08:24.739Z',
                  _updated_at: '2023-09-19T10:14:17.467Z',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  _acl_sync: '2023-09-19T10:14:17.176Z',
                  internal_description: 'Volume price - Demo 01/06',
                  $relation: {
                    entity_id: '292836cf-b893-4214-ae50-7e5f227f13d5',
                    _tags: []
                  }
                },
                {
                  _id: '8d5da070-94a4-4809-a2e8-9cbafba791fb',
                  pricing_model: 'per_unit',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: true,
                  price_display_in_journeys: 'show_price',
                  active: true,
                  type: 'one_time',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  is_composite_price: true,
                  _tags: ['composite'],
                  _schema: 'price',
                  _title: 'Flat Fee price parent - Demo 01/06',
                  description: 'Flat Fee price parent - Demo 01/06',
                  price_components: [],
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '10009172'
                    }
                  ],
                  _created_at: '2023-05-31T19:10:12.454Z',
                  _updated_at: '2024-07-25T08:17:39.583Z',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  internal_description: 'Flat Fee price parent - Demo 01/06',
                  $relation: {
                    entity_id: '8d5da070-94a4-4809-a2e8-9cbafba791fb',
                    _tags: []
                  }
                },
                {
                  _id: '2904f1c7-8c80-4bb7-95a0-fbf617cb03cc',
                  pricing_model: 'tiered_graduated',
                  unit_amount: 0,
                  unit_amount_currency: 'EUR',
                  unit_amount_decimal: '0.00',
                  is_tax_inclusive: true,
                  price_display_in_journeys: 'show_price',
                  active: true,
                  type: 'one_time',
                  billing_period: 'weekly',
                  billing_duration_unit: 'months',
                  notice_time_unit: 'months',
                  termination_time_unit: 'months',
                  renewal_duration_unit: 'months',
                  _tags: [],
                  _schema: 'price',
                  _title: 'Cumulative price - Demo 01/06',
                  description: 'Cumulative price - Demo 01/06',
                  variable_price: true,
                  unit: 'kwh',
                  _org: '739224',
                  _owners: [
                    {
                      org_id: '739224',
                      user_id: '10009172'
                    }
                  ],
                  _created_at: '2023-05-31T19:10:38.293Z',
                  _updated_at: '2023-09-19T10:14:17.354Z',
                  _acl: {
                    view: ['org_739224'],
                    edit: ['org_739224'],
                    delete: ['org_739224']
                  },
                  tiers: [
                    {
                      up_to: 100,
                      unit_amount: 10000,
                      unit_amount_decimal: '100',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0'
                    },
                    {
                      up_to: 300,
                      unit_amount: 5000,
                      unit_amount_decimal: '50',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0',
                      display_mode: 'on_request'
                    },
                    {
                      up_to: 600,
                      unit_amount: 2500,
                      unit_amount_decimal: '25',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0',
                      display_mode: 'on_request'
                    },
                    {
                      up_to: null,
                      unit_amount: 1000,
                      unit_amount_decimal: '10',
                      flat_fee_amount: 0,
                      flat_fee_amount_decimal: '0',
                      display_mode: 'on_request'
                    }
                  ],
                  _acl_sync: '2023-09-19T10:14:17.187Z',
                  internal_description: 'Cumulative price - Demo 01/06',
                  $relation: {
                    entity_id: '2904f1c7-8c80-4bb7-95a0-fbf617cb03cc',
                    _tags: []
                  }
                }
              ],
              _id: '47cbbba4-326f-4586-8c94-790df3a02f45',
              internal_name: 'Tiered Product Demo 01/06'
            },
            price_mappings: [
              {
                price_id: 'bad5d471-dd91-4324-973f-14e01dd45e9c',
                value: 0
              }
            ],
            currency: 'EUR',
            amount_subtotal: 10000,
            amount_total: 10000,
            total_details: {
              amount_tax: 0,
              breakdown: {
                taxes: [
                  {
                    tax: {
                      rate: 0
                    },
                    amount: 0
                  }
                ],
                recurrences: [
                  {
                    type: 'recurring',
                    billing_period: 'yearly',
                    unit_amount_gross: 10000,
                    unit_amount_net: 10000,
                    amount_subtotal: 10000,
                    amount_total: 10000,
                    amount_subtotal_decimal: '100',
                    amount_total_decimal: '100',
                    amount_tax: 0
                  }
                ],
                recurrencesByTax: [
                  {
                    type: 'recurring',
                    billing_period: 'yearly',
                    amount_total: 10000,
                    amount_subtotal: 10000,
                    amount_tax: 0,
                    tax: {
                      tax: {
                        rate: 0
                      },
                      amount: 0
                    }
                  }
                ],
                cashbacks: []
              }
            },
            amount_tax: 0,
            amount_subtotal_decimal: '100',
            amount_total_decimal: '100'
          }
        ],
        amount_subtotal: 39400,
        amount_total: 40605,
        total_details: {
          amount_tax: 1205,
          breakdown: {
            taxes: [
              {
                tax: {
                  rate: 0
                },
                amount: 0
              },
              {
                tax: {
                  _id: '812bba85-147a-4f6c-bd94-5a852abd335c',
                  type: 'VAT',
                  rate: '19'
                },
                amount: 1205
              }
            ],
            recurrences: [
              {
                type: 'recurring',
                billing_period: 'monthly',
                unit_amount_gross: 23555,
                unit_amount_net: 23316,
                amount_subtotal: 23316,
                amount_total: 23555,
                amount_subtotal_decimal: '233.155042016807',
                amount_total_decimal: '235.55',
                amount_tax: 239
              },
              {
                type: 'one_time',
                unit_amount_gross: 7050,
                unit_amount_net: 6084,
                amount_subtotal: 6084,
                amount_total: 7050,
                amount_subtotal_decimal: '60.840336134454',
                amount_total_decimal: '70.5',
                amount_tax: 966
              },
              {
                type: 'recurring',
                billing_period: 'yearly',
                unit_amount_gross: 10000,
                unit_amount_net: 10000,
                amount_subtotal: 10000,
                amount_total: 10000,
                amount_subtotal_decimal: '100',
                amount_total_decimal: '100',
                amount_tax: 0
              }
            ],
            recurrencesByTax: [
              {
                type: 'recurring',
                billing_period: 'monthly',
                amount_total: 22055,
                amount_subtotal: 22055,
                amount_tax: 0,
                tax: {
                  tax: {
                    rate: 0
                  },
                  amount: 0
                }
              },
              {
                type: 'one_time',
                amount_total: 1000,
                amount_subtotal: 1000,
                amount_tax: 0,
                tax: {
                  tax: {
                    rate: 0
                  },
                  amount: 0
                }
              },
              {
                type: 'one_time',
                amount_total: 6050,
                amount_subtotal: 5084,
                amount_tax: 966,
                tax: {
                  tax: {
                    _id: '812bba85-147a-4f6c-bd94-5a852abd335c',
                    type: 'VAT',
                    rate: '19'
                  },
                  amount: 1205
                }
              },
              {
                type: 'recurring',
                billing_period: 'monthly',
                amount_total: 1500,
                amount_subtotal: 1261,
                amount_tax: 239,
                tax: {
                  tax: {
                    _id: '812bba85-147a-4f6c-bd94-5a852abd335c',
                    type: 'VAT',
                    rate: '19'
                  },
                  amount: 1205
                }
              },
              {
                type: 'recurring',
                billing_period: 'yearly',
                amount_total: 10000,
                amount_subtotal: 10000,
                amount_tax: 0,
                tax: {
                  tax: {
                    rate: 0
                  },
                  amount: 0
                }
              }
            ],
            cashbacks: []
          }
        },
        currency: 'EUR',
        amount_tax: 1205,
        prices: {
          $relation: [
            {
              entity_id: '5dab027d-086d-412b-b245-17611592e588',
              _schema: 'price',
              _tags: []
            },
            {
              entity_id: '57ced6a0-223f-4636-9389-90a1335c69d8',
              _schema: 'price',
              _tags: []
            },
            {
              entity_id: '98c9872e-660b-4d2f-90e9-a0b4c7245d6d',
              _schema: 'price',
              _tags: []
            },
            {
              entity_id: '8d5da070-94a4-4809-a2e8-9cbafba791fb',
              _schema: 'price',
              _tags: []
            }
          ]
        },
        products: {
          $relation: [
            {
              entity_id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
              _schema: 'product',
              _tags: []
            },
            {
              entity_id: '64d52abe-bc47-4a49-8008-08927284590a',
              _schema: 'product',
              _tags: []
            },
            {
              entity_id: '216c4a5a-3392-4395-9b20-6f2e0348466d',
              _schema: 'product',
              _tags: []
            },
            {
              entity_id: '47cbbba4-326f-4586-8c94-790df3a02f45',
              _schema: 'product',
              _tags: []
            }
          ]
        }
      },
      {
        filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/cc103630-9a37-48f2-bfc2-f09dd9594c76/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/cc103630-9a37-48f2-bfc2-f09dd9594c76/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:15:03+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/cc103630-9a37-48f2-bfc2-f09dd9594c76/Reading%25202025-02-06T05%253A15%253A03%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/cc103630-9a37-48f2-bfc2-f09dd9594c76/Reading%202025-02-06T05%3A15%3A03%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'fe9448d3-1976-421a-9efc-4db1906c005c',
        _org: '739224',
        _created_at: '2025-02-06T04:17:08.066Z',
        _updated_at: '2025-02-06T04:17:08.066Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:15:03+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/5da1341c-ff23-4521-9c15-12336f0bc93e/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/5da1341c-ff23-4521-9c15-12336f0bc93e/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/5da1341c-ff23-4521-9c15-12336f0bc93e/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/5da1341c-ff23-4521-9c15-12336f0bc93e/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'f4e86825-7c3c-42e4-8203-81c71a7f53b0',
        _org: '739224',
        _created_at: '2025-02-06T03:57:50.787Z',
        _updated_at: '2025-02-06T03:57:50.787Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T04:45:44+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/e9588421-058c-42f3-93b7-aa036a791ff5/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/e9588421-058c-42f3-93b7-aa036a791ff5/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/e9588421-058c-42f3-93b7-aa036a791ff5/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/e9588421-058c-42f3-93b7-aa036a791ff5/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'f2c0aaba-a855-4511-a2c9-1cd3799b578e',
        _org: '739224',
        _created_at: '2025-02-06T03:49:58.753Z',
        _updated_at: '2025-02-06T03:49:58.753Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T04:45:44+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/6bc2249d-3799-45d0-add6-3b142ea21265/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/6bc2249d-3799-45d0-add6-3b142ea21265/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/6bc2249d-3799-45d0-add6-3b142ea21265/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/6bc2249d-3799-45d0-add6-3b142ea21265/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'ed3c7440-170f-4144-8032-d51a2d3252aa',
        _org: '739224',
        _created_at: '2025-02-06T03:47:31.488Z',
        _updated_at: '2025-02-06T03:47:31.488Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T04:45:44+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/8ca1f870-b676-4ccd-97e0-05a793815fe3/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/8ca1f870-b676-4ccd-97e0-05a793815fe3/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T04:45:44+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/8ca1f870-b676-4ccd-97e0-05a793815fe3/Reading%25202025-02-06T04%253A45%253A44%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/8ca1f870-b676-4ccd-97e0-05a793815fe3/Reading%202025-02-06T04%3A45%3A44%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'f5229b31-5338-4e0c-9e71-cc7d96bfa6f2',
        _org: '739224',
        _created_at: '2025-02-06T03:45:48.778Z',
        _updated_at: '2025-02-06T03:45:48.778Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T04:45:44+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T03:49:56+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/0f6762c0-5b2b-4c76-ba2a-aa4edf21cef1/Reading%25202025-02-06T03%253A49%253A56%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/0f6762c0-5b2b-4c76-ba2a-aa4edf21cef1/Reading%202025-02-06T03%3A49%3A56%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T03:49:56+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/0f6762c0-5b2b-4c76-ba2a-aa4edf21cef1/Reading%25202025-02-06T03%253A49%253A56%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/0f6762c0-5b2b-4c76-ba2a-aa4edf21cef1/Reading%202025-02-06T03%3A49%3A56%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'fa5a7fb0-89c4-4a75-9a1a-523cd558d011',
        _org: '739224',
        _created_at: '2025-02-06T03:23:47.349Z',
        _updated_at: '2025-02-06T03:23:47.349Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T03:49:56+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T03:49:56+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/40637a6f-c529-43b6-aa24-d8fc61a7b36e/Reading%25202025-02-06T03%253A49%253A56%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/40637a6f-c529-43b6-aa24-d8fc61a7b36e/Reading%202025-02-06T03%3A49%3A56%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T03:49:56+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/40637a6f-c529-43b6-aa24-d8fc61a7b36e/Reading%25202025-02-06T03%253A49%253A56%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/40637a6f-c529-43b6-aa24-d8fc61a7b36e/Reading%202025-02-06T03%3A49%3A56%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'b3ba2374-9eb3-4f2d-b186-9516b8e060bc',
        _org: '739224',
        _created_at: '2025-02-06T03:06:20.431Z',
        _updated_at: '2025-02-06T03:06:20.431Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T03:49:56+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:31:36+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/7420a54b-53c3-43ea-9a01-51a6f6dd0891/Reading%25202025-02-06T06%253A31%253A36%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/png',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/7420a54b-53c3-43ea-9a01-51a6f6dd0891/Reading%202025-02-06T06%3A31%3A36%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:31:36+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/7420a54b-53c3-43ea-9a01-51a6f6dd0891/Reading%25202025-02-06T06%253A31%253A36%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/png',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/7420a54b-53c3-43ea-9a01-51a6f6dd0891/Reading%202025-02-06T06%3A31%3A36%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'ba194af5-d84d-4f74-8cd3-8e909f753add',
        _org: '739224',
        _created_at: '2025-02-06T05:31:37.818Z',
        _updated_at: '2025-02-06T05:31:37.818Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:31:36+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-10T06:31:32+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/fdf0e420-8043-4045-afc5-3fcefa699db6/Reading%25202025-02-10T06%253A31%253A32%252B01%253A00.jpg',
        size_bytes: 79371,
        readable_size: '77.51 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/fdf0e420-8043-4045-afc5-3fcefa699db6/Reading%202025-02-10T06%3A31%3A32%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-10T06:31:32+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/fdf0e420-8043-4045-afc5-3fcefa699db6/Reading%25202025-02-10T06%253A31%253A32%252B01%253A00.jpg',
            size_bytes: 79371,
            readable_size: '77.51 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/fdf0e420-8043-4045-afc5-3fcefa699db6/Reading%202025-02-10T06%3A31%3A32%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '3331b7b2-b585-4c19-b6ee-4ace5a59452d',
        _org: '739224',
        _created_at: '2025-02-10T05:31:33.236Z',
        _updated_at: '2025-02-10T05:31:33.236Z',
        category: 'unknown',
        _title: 'Reading 2025-02-10T06:31:32+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:48:43+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/5f69a62b-9758-4874-87d4-ffdc823a7515/Reading%25202025-02-06T06%253A48%253A43%252B01%253A00.jpg',
        size_bytes: 35395,
        readable_size: '34.57 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/5f69a62b-9758-4874-87d4-ffdc823a7515/Reading%202025-02-06T06%3A48%3A43%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:48:43+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/5f69a62b-9758-4874-87d4-ffdc823a7515/Reading%25202025-02-06T06%253A48%253A43%252B01%253A00.jpg',
            size_bytes: 35395,
            readable_size: '34.57 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/5f69a62b-9758-4874-87d4-ffdc823a7515/Reading%202025-02-06T06%3A48%3A43%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'e6c5ad4b-adfc-4855-b5c2-7935c952dd88',
        _org: '739224',
        _created_at: '2025-02-06T05:48:44.099Z',
        _updated_at: '2025-02-06T05:48:44.099Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:48:43+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-10T05:21:47+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/99083963-8b03-4140-ac90-a6703fe99515/Reading%25202025-02-10T05%253A21%253A47%252B01%253A00.jpg',
        size_bytes: 51499,
        readable_size: '50.29 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/99083963-8b03-4140-ac90-a6703fe99515/Reading%202025-02-10T05%3A21%3A47%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-10T05:21:47+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/99083963-8b03-4140-ac90-a6703fe99515/Reading%25202025-02-10T05%253A21%253A47%252B01%253A00.jpg',
            size_bytes: 51499,
            readable_size: '50.29 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/99083963-8b03-4140-ac90-a6703fe99515/Reading%202025-02-10T05%3A21%3A47%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '1543f416-79fb-4a3e-8a2c-bd96c78b5707',
        _org: '739224',
        _created_at: '2025-02-10T04:21:59.253Z',
        _updated_at: '2025-02-10T04:21:59.253Z',
        category: 'unknown',
        _title: 'Reading 2025-02-10T05:21:47+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-10T04:51:59+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/c914536c-d8d4-46b9-ac2a-ec3a84273342/Reading%25202025-02-10T04%253A51%253A59%252B01%253A00.jpg',
        size_bytes: 65911,
        readable_size: '64.37 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/c914536c-d8d4-46b9-ac2a-ec3a84273342/Reading%202025-02-10T04%3A51%3A59%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-10T04:51:59+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/c914536c-d8d4-46b9-ac2a-ec3a84273342/Reading%25202025-02-10T04%253A51%253A59%252B01%253A00.jpg',
            size_bytes: 65911,
            readable_size: '64.37 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/c914536c-d8d4-46b9-ac2a-ec3a84273342/Reading%202025-02-10T04%3A51%3A59%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '1758b5af-1969-475a-860e-8b541b78db79',
        _org: '739224',
        _created_at: '2025-02-10T03:52:00.769Z',
        _updated_at: '2025-02-10T03:52:00.769Z',
        category: 'unknown',
        _title: 'Reading 2025-02-10T04:51:59+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:01:12+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/6719de42-3827-413f-b0cb-da4bdcd0adce/Reading%25202025-02-06T06%253A01%253A12%252B01%253A00.jpg',
        size_bytes: 36307,
        readable_size: '35.46 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/6719de42-3827-413f-b0cb-da4bdcd0adce/Reading%202025-02-06T06%3A01%3A12%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:01:12+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/6719de42-3827-413f-b0cb-da4bdcd0adce/Reading%25202025-02-06T06%253A01%253A12%252B01%253A00.jpg',
            size_bytes: 36307,
            readable_size: '35.46 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/6719de42-3827-413f-b0cb-da4bdcd0adce/Reading%202025-02-06T06%3A01%3A12%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'e3e09a92-a88d-48f8-9dac-d38615719ca1',
        _org: '739224',
        _created_at: '2025-02-06T05:01:14.010Z',
        _updated_at: '2025-02-06T05:01:14.010Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:01:12+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:34:02+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/54517e4d-ce0d-48dc-a265-9c19002e6d32/Reading%25202025-02-06T06%253A34%253A02%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/png',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/54517e4d-ce0d-48dc-a265-9c19002e6d32/Reading%202025-02-06T06%3A34%3A02%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:34:02+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/54517e4d-ce0d-48dc-a265-9c19002e6d32/Reading%25202025-02-06T06%253A34%253A02%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/png',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/54517e4d-ce0d-48dc-a265-9c19002e6d32/Reading%202025-02-06T06%3A34%3A02%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '79cfd8ff-281a-4810-9da2-10ec39c49eae',
        _org: '739224',
        _created_at: '2025-02-06T05:34:03.443Z',
        _updated_at: '2025-02-06T05:34:03.443Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:34:02+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T05:58:30+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/c7c3b742-5833-475e-b68f-f45c7f780d6c/Reading%25202025-02-06T05%253A58%253A30%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/png',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/c7c3b742-5833-475e-b68f-f45c7f780d6c/Reading%202025-02-06T05%3A58%3A30%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T05:58:30+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/c7c3b742-5833-475e-b68f-f45c7f780d6c/Reading%25202025-02-06T05%253A58%253A30%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/png',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/c7c3b742-5833-475e-b68f-f45c7f780d6c/Reading%202025-02-06T05%3A58%3A30%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '76a959f0-9649-4000-bbd8-a413746e539a',
        _org: '739224',
        _created_at: '2025-02-06T04:58:34.074Z',
        _updated_at: '2025-02-06T04:58:34.074Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T05:58:30+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-10T06:25:25+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/cb0f612a-ba16-4a96-b25b-7f45dfc23a17/Reading%25202025-02-10T06%253A25%253A25%252B01%253A00.jpg',
        size_bytes: 79371,
        readable_size: '77.51 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/cb0f612a-ba16-4a96-b25b-7f45dfc23a17/Reading%202025-02-10T06%3A25%3A25%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-10T06:25:25+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/cb0f612a-ba16-4a96-b25b-7f45dfc23a17/Reading%25202025-02-10T06%253A25%253A25%252B01%253A00.jpg',
            size_bytes: 79371,
            readable_size: '77.51 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/cb0f612a-ba16-4a96-b25b-7f45dfc23a17/Reading%202025-02-10T06%3A25%3A25%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '98044073-4fa8-43fe-a503-a2f1aee8ebde',
        _org: '739224',
        _created_at: '2025-02-10T05:25:31.187Z',
        _updated_at: '2025-02-10T05:25:31.187Z',
        category: 'unknown',
        _title: 'Reading 2025-02-10T06:25:25+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:34:53+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/536ab611-a4d2-42fc-aa9f-588ae9242a86/Reading%25202025-02-06T06%253A34%253A53%252B01%253A00.jpg',
        size_bytes: 36307,
        readable_size: '35.46 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/536ab611-a4d2-42fc-aa9f-588ae9242a86/Reading%202025-02-06T06%3A34%3A53%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:34:53+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/536ab611-a4d2-42fc-aa9f-588ae9242a86/Reading%25202025-02-06T06%253A34%253A53%252B01%253A00.jpg',
            size_bytes: 36307,
            readable_size: '35.46 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/536ab611-a4d2-42fc-aa9f-588ae9242a86/Reading%202025-02-06T06%3A34%3A53%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '291e9398-542f-47cc-8719-1257dd2fec8d',
        _org: '739224',
        _created_at: '2025-02-06T05:34:53.743Z',
        _updated_at: '2025-02-06T05:34:53.743Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:34:53+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-10T06:30:28+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/92790289-cb2f-416c-bf62-3613dd9f0f74/Reading%25202025-02-10T06%253A30%253A28%252B01%253A00.jpg',
        size_bytes: 79371,
        readable_size: '77.51 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/92790289-cb2f-416c-bf62-3613dd9f0f74/Reading%202025-02-10T06%3A30%3A28%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-10T06:30:28+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/92790289-cb2f-416c-bf62-3613dd9f0f74/Reading%25202025-02-10T06%253A30%253A28%252B01%253A00.jpg',
            size_bytes: 79371,
            readable_size: '77.51 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/92790289-cb2f-416c-bf62-3613dd9f0f74/Reading%202025-02-10T06%3A30%3A28%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '5f221674-c1f1-4dd9-bbfd-27f11f501faf',
        _org: '739224',
        _created_at: '2025-02-10T05:30:30.806Z',
        _updated_at: '2025-02-10T05:30:30.806Z',
        category: 'unknown',
        _title: 'Reading 2025-02-10T06:30:28+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:35:22+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/7c641af9-4559-4ced-b67d-cd9cc09ba7b2/Reading%25202025-02-06T06%253A35%253A22%252B01%253A00.jpg',
        size_bytes: 36307,
        readable_size: '35.46 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/7c641af9-4559-4ced-b67d-cd9cc09ba7b2/Reading%202025-02-06T06%3A35%3A22%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:35:22+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/7c641af9-4559-4ced-b67d-cd9cc09ba7b2/Reading%25202025-02-06T06%253A35%253A22%252B01%253A00.jpg',
            size_bytes: 36307,
            readable_size: '35.46 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/7c641af9-4559-4ced-b67d-cd9cc09ba7b2/Reading%202025-02-06T06%3A35%3A22%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '63a2f44e-0ecc-42f7-aa8c-010a72a4b68e',
        _org: '739224',
        _created_at: '2025-02-06T05:35:23.218Z',
        _updated_at: '2025-02-06T05:35:23.218Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:35:22+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-10T05:07:57+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a20f8cd6-591f-4b1f-91d8-7923102179f0/Reading%25202025-02-10T05%253A07%253A57%252B01%253A00.jpg',
        size_bytes: 46439,
        readable_size: '45.35 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/a20f8cd6-591f-4b1f-91d8-7923102179f0/Reading%202025-02-10T05%3A07%3A57%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-10T05:07:57+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a20f8cd6-591f-4b1f-91d8-7923102179f0/Reading%25202025-02-10T05%253A07%253A57%252B01%253A00.jpg',
            size_bytes: 46439,
            readable_size: '45.35 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/a20f8cd6-591f-4b1f-91d8-7923102179f0/Reading%202025-02-10T05%3A07%3A57%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '5152dad9-1c0f-4959-b06f-bd98cca9358f',
        _org: '739224',
        _created_at: '2025-02-10T04:08:02.872Z',
        _updated_at: '2025-02-10T04:08:02.872Z',
        category: 'unknown',
        _title: 'Reading 2025-02-10T05:07:57+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-10T06:07:35+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/d75939e7-c293-464a-9958-300f2dd902b4/Reading%25202025-02-10T06%253A07%253A35%252B01%253A00.jpg',
        size_bytes: 48843,
        readable_size: '47.7 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/d75939e7-c293-464a-9958-300f2dd902b4/Reading%202025-02-10T06%3A07%3A35%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-10T06:07:35+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/d75939e7-c293-464a-9958-300f2dd902b4/Reading%25202025-02-10T06%253A07%253A35%252B01%253A00.jpg',
            size_bytes: 48843,
            readable_size: '47.7 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/d75939e7-c293-464a-9958-300f2dd902b4/Reading%202025-02-10T06%3A07%3A35%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'dcd1c9c2-58e0-4ebf-848d-9ef01ef46e8e',
        _org: '739224',
        _created_at: '2025-02-10T05:07:41.737Z',
        _updated_at: '2025-02-10T05:07:41.737Z',
        category: 'unknown',
        _title: 'Reading 2025-02-10T06:07:35+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:33:48+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/c16776b1-ccc5-44aa-a01e-4a2ac43197b7/Reading%25202025-02-06T06%253A33%253A48%252B01%253A00.jpg',
        size_bytes: 36307,
        readable_size: '35.46 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/c16776b1-ccc5-44aa-a01e-4a2ac43197b7/Reading%202025-02-06T06%3A33%3A48%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:33:48+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/c16776b1-ccc5-44aa-a01e-4a2ac43197b7/Reading%25202025-02-06T06%253A33%253A48%252B01%253A00.jpg',
            size_bytes: 36307,
            readable_size: '35.46 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/c16776b1-ccc5-44aa-a01e-4a2ac43197b7/Reading%202025-02-06T06%3A33%3A48%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'cd51e404-02f5-471f-aadd-cc7cf480de5b',
        _org: '739224',
        _created_at: '2025-02-06T05:33:49.472Z',
        _updated_at: '2025-02-06T05:33:49.472Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:33:48+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:34:30+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a62d17d6-ee5f-4e53-b00d-1b49e24499bb/Reading%25202025-02-06T06%253A34%253A30%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/png',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/a62d17d6-ee5f-4e53-b00d-1b49e24499bb/Reading%202025-02-06T06%3A34%3A30%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:34:30+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/a62d17d6-ee5f-4e53-b00d-1b49e24499bb/Reading%25202025-02-06T06%253A34%253A30%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/png',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/a62d17d6-ee5f-4e53-b00d-1b49e24499bb/Reading%202025-02-06T06%3A34%3A30%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '5b25ca64-af31-4eb8-a830-a21ec50bf2a8',
        _org: '739224',
        _created_at: '2025-02-06T05:34:31.848Z',
        _updated_at: '2025-02-06T05:34:31.848Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:34:30+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:29:00+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/bcb9c946-9221-475b-91bf-6e28338f73c7/Reading%25202025-02-06T06%253A29%253A00%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/png',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/bcb9c946-9221-475b-91bf-6e28338f73c7/Reading%202025-02-06T06%3A29%3A00%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:29:00+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/bcb9c946-9221-475b-91bf-6e28338f73c7/Reading%25202025-02-06T06%253A29%253A00%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/png',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/bcb9c946-9221-475b-91bf-6e28338f73c7/Reading%202025-02-06T06%3A29%3A00%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '42772f39-ee1f-4987-b768-baa9fdcb9b60',
        _org: '739224',
        _created_at: '2025-02-06T05:29:05.377Z',
        _updated_at: '2025-02-06T05:29:05.377Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:29:00+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-10T04:49:35+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/c390e480-2eb0-4776-9287-f65a2a36b49e/Reading%25202025-02-10T04%253A49%253A35%252B01%253A00.jpg',
        size_bytes: 59347,
        readable_size: '57.96 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/c390e480-2eb0-4776-9287-f65a2a36b49e/Reading%202025-02-10T04%3A49%3A35%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-10T04:49:35+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/c390e480-2eb0-4776-9287-f65a2a36b49e/Reading%25202025-02-10T04%253A49%253A35%252B01%253A00.jpg',
            size_bytes: 59347,
            readable_size: '57.96 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/c390e480-2eb0-4776-9287-f65a2a36b49e/Reading%202025-02-10T04%3A49%3A35%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '414d6dcc-c392-4585-a05e-bf4b1020ba5e',
        _org: '739224',
        _created_at: '2025-02-10T03:49:41.389Z',
        _updated_at: '2025-02-10T03:49:41.389Z',
        category: 'unknown',
        _title: 'Reading 2025-02-10T04:49:35+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:49:12+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/24292a7d-fdfc-46c9-aa32-213c79673b45/Reading%25202025-02-06T06%253A49%253A12%252B01%253A00.jpg',
        size_bytes: 36587,
        readable_size: '35.73 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/24292a7d-fdfc-46c9-aa32-213c79673b45/Reading%202025-02-06T06%3A49%3A12%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:49:12+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/24292a7d-fdfc-46c9-aa32-213c79673b45/Reading%25202025-02-06T06%253A49%253A12%252B01%253A00.jpg',
            size_bytes: 36587,
            readable_size: '35.73 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/24292a7d-fdfc-46c9-aa32-213c79673b45/Reading%202025-02-06T06%3A49%3A12%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '49cd1fd3-28f0-47c8-8752-34ce226c20cd',
        _org: '739224',
        _created_at: '2025-02-06T05:49:13.189Z',
        _updated_at: '2025-02-06T05:49:13.189Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:49:12+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:31:20+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/12fd9061-5591-40c0-ada9-00cda25800fc/Reading%25202025-02-06T06%253A31%253A20%252B01%253A00.jpg',
        size_bytes: 36307,
        readable_size: '35.46 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/12fd9061-5591-40c0-ada9-00cda25800fc/Reading%202025-02-06T06%3A31%3A20%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:31:20+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/12fd9061-5591-40c0-ada9-00cda25800fc/Reading%25202025-02-06T06%253A31%253A20%252B01%253A00.jpg',
            size_bytes: 36307,
            readable_size: '35.46 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/12fd9061-5591-40c0-ada9-00cda25800fc/Reading%202025-02-06T06%3A31%3A20%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '3ba0da66-9ef2-47a1-b192-00c3142ed0f4',
        _org: '739224',
        _created_at: '2025-02-06T05:31:21.715Z',
        _updated_at: '2025-02-06T05:31:21.715Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:31:20+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-10T06:08:16+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/364cfa26-c1d7-4551-9abb-ad9ff8a4a163/Reading%25202025-02-10T06%253A08%253A16%252B01%253A00.jpg',
        size_bytes: 50831,
        readable_size: '49.64 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/364cfa26-c1d7-4551-9abb-ad9ff8a4a163/Reading%202025-02-10T06%3A08%3A16%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-10T06:08:16+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/364cfa26-c1d7-4551-9abb-ad9ff8a4a163/Reading%25202025-02-10T06%253A08%253A16%252B01%253A00.jpg',
            size_bytes: 50831,
            readable_size: '49.64 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/364cfa26-c1d7-4551-9abb-ad9ff8a4a163/Reading%202025-02-10T06%3A08%3A16%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'aa0a2bae-7a9e-471f-af82-43ab4aba89b8',
        _org: '739224',
        _created_at: '2025-02-10T05:08:17.681Z',
        _updated_at: '2025-02-10T05:08:17.681Z',
        category: 'unknown',
        _title: 'Reading 2025-02-10T06:08:16+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:44:58+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/6e5f3ae5-e4d4-4baf-b051-14e259c61e69/Reading%25202025-02-06T06%253A44%253A58%252B01%253A00.jpg',
        size_bytes: 2181390,
        readable_size: '2.08 MB',
        mime_type: 'image/png',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/6e5f3ae5-e4d4-4baf-b051-14e259c61e69/Reading%202025-02-06T06%3A44%3A58%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:44:58+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/6e5f3ae5-e4d4-4baf-b051-14e259c61e69/Reading%25202025-02-06T06%253A44%253A58%252B01%253A00.jpg',
            size_bytes: 2181390,
            readable_size: '2.08 MB',
            mime_type: 'image/png',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/6e5f3ae5-e4d4-4baf-b051-14e259c61e69/Reading%202025-02-06T06%3A44%3A58%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'b7ed36e4-2865-4209-8f2c-5527e5da1bdb',
        _org: '739224',
        _created_at: '2025-02-06T05:45:04.041Z',
        _updated_at: '2025-02-06T05:45:04.041Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:44:58+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-10T06:09:00+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/29359f46-663b-44f9-bf4e-5630757bebfd/Reading%25202025-02-10T06%253A09%253A00%252B01%253A00.jpg',
        size_bytes: 48067,
        readable_size: '46.94 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/29359f46-663b-44f9-bf4e-5630757bebfd/Reading%202025-02-10T06%3A09%3A00%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-10T06:09:00+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/29359f46-663b-44f9-bf4e-5630757bebfd/Reading%25202025-02-10T06%253A09%253A00%252B01%253A00.jpg',
            size_bytes: 48067,
            readable_size: '46.94 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/29359f46-663b-44f9-bf4e-5630757bebfd/Reading%202025-02-10T06%3A09%3A00%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'b5927870-ff11-443d-a629-91fba0b41a84',
        _org: '739224',
        _created_at: '2025-02-10T05:09:01.933Z',
        _updated_at: '2025-02-10T05:09:01.933Z',
        category: 'unknown',
        _title: 'Reading 2025-02-10T06:09:00+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-06T06:50:37+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/2d403eff-e641-4184-a178-2b69c5ea43fd/Reading%25202025-02-06T06%253A50%253A37%252B01%253A00.jpg',
        size_bytes: 37299,
        readable_size: '36.42 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/2d403eff-e641-4184-a178-2b69c5ea43fd/Reading%202025-02-06T06%3A50%3A37%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:50:37+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/2d403eff-e641-4184-a178-2b69c5ea43fd/Reading%25202025-02-06T06%253A50%253A37%252B01%253A00.jpg',
            size_bytes: 37299,
            readable_size: '36.42 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/2d403eff-e641-4184-a178-2b69c5ea43fd/Reading%202025-02-06T06%3A50%3A37%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'a60ba627-604a-42ed-ad56-2e68041be670',
        _org: '739224',
        _created_at: '2025-02-06T05:50:38.896Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:50:37+01:00.jpg',
        _tags: ['message_attachment'],
        _updated_at: '2025-02-06T07:02:41.949Z'
      },
      {
        filename: 'Reading 2025-02-06T06:45:09+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/7b4a62f8-b59f-4dba-92df-04eb44eb28af/Reading%25202025-02-06T06%253A45%253A09%252B01%253A00.jpg',
        size_bytes: 36307,
        readable_size: '35.46 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/7b4a62f8-b59f-4dba-92df-04eb44eb28af/Reading%202025-02-06T06%3A45%3A09%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-06T06:45:09+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/7b4a62f8-b59f-4dba-92df-04eb44eb28af/Reading%25202025-02-06T06%253A45%253A09%252B01%253A00.jpg',
            size_bytes: 36307,
            readable_size: '35.46 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/7b4a62f8-b59f-4dba-92df-04eb44eb28af/Reading%202025-02-06T06%3A45%3A09%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'fb1a68f8-219d-4f4f-97ce-ead0d36464bb',
        _org: '739224',
        _created_at: '2025-02-06T05:45:10.134Z',
        _updated_at: '2025-02-06T05:45:10.134Z',
        category: 'unknown',
        _title: 'Reading 2025-02-06T06:45:09+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-11T10:13:13+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/e86753a9-cecf-4098-a1a3-a358df940b3c/Reading%25202025-02-11T10%253A13%253A13%252B01%253A00.jpg',
        size_bytes: 59524,
        readable_size: '58.13 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/e86753a9-cecf-4098-a1a3-a358df940b3c/Reading%202025-02-11T10%3A13%3A13%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-11T10:13:13+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/e86753a9-cecf-4098-a1a3-a358df940b3c/Reading%25202025-02-11T10%253A13%253A13%252B01%253A00.jpg',
            size_bytes: 59524,
            readable_size: '58.13 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/e86753a9-cecf-4098-a1a3-a358df940b3c/Reading%202025-02-11T10%3A13%3A13%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: 'bfc85bc0-8a35-4680-8683-b8bdc53cc7c5',
        _org: '739224',
        _created_at: '2025-02-11T09:13:16.073Z',
        _updated_at: '2025-02-11T09:13:16.073Z',
        category: 'unknown',
        _title: 'Reading 2025-02-11T10:13:13+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-11T10:25:13+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/99078a8d-40ec-46c2-ab07-438011a02f75/Reading%25202025-02-11T10%253A25%253A13%252B01%253A00.jpg',
        size_bytes: 79348,
        readable_size: '77.49 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/99078a8d-40ec-46c2-ab07-438011a02f75/Reading%202025-02-11T10%3A25%3A13%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-11T10:25:13+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/99078a8d-40ec-46c2-ab07-438011a02f75/Reading%25202025-02-11T10%253A25%253A13%252B01%253A00.jpg',
            size_bytes: 79348,
            readable_size: '77.49 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/99078a8d-40ec-46c2-ab07-438011a02f75/Reading%202025-02-11T10%3A25%3A13%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '746d4eba-316a-4cf0-b0f9-ed212e79ef47',
        _org: '739224',
        _created_at: '2025-02-11T09:25:17.365Z',
        _updated_at: '2025-02-11T09:25:17.365Z',
        category: 'unknown',
        _title: 'Reading 2025-02-11T10:25:13+01:00.jpg'
      },
      {
        filename: 'Reading 2025-02-11T10:15:32+01:00.jpg',
        document_type: 'image',
        access_control: 'private',
        shared_with_end_customer: true,
        type: 'image',
        public_url:
          'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/ee244fb3-3d16-44af-a770-f18722875778/Reading%25202025-02-11T10%253A15%253A32%252B01%253A00.jpg',
        size_bytes: 716708,
        readable_size: '699.91 KB',
        mime_type: 'image/jpeg',
        s3ref: {
          bucket: 'epilot-dev-user-content',
          key: '739224/ee244fb3-3d16-44af-a770-f18722875778/Reading%202025-02-11T10%3A15%3A32%2B01%3A00.jpg'
        },
        versions: [
          {
            filename: 'Reading 2025-02-11T10:15:32+01:00.jpg',
            document_type: 'image',
            access_control: 'private',
            shared_with_end_customer: true,
            type: 'image',
            public_url:
              'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/ee244fb3-3d16-44af-a770-f18722875778/Reading%25202025-02-11T10%253A15%253A32%252B01%253A00.jpg',
            size_bytes: 716708,
            readable_size: '699.91 KB',
            mime_type: 'image/jpeg',
            s3ref: {
              bucket: 'epilot-dev-user-content',
              key: '739224/ee244fb3-3d16-44af-a770-f18722875778/Reading%202025-02-11T10%3A15%3A32%2B01%3A00.jpg'
            }
          }
        ],
        _schema: 'file',
        _id: '46ca5170-2fba-4912-9f27-29ada7071093',
        _org: '739224',
        _created_at: '2025-02-11T09:15:37.179Z',
        _updated_at: '2025-02-11T09:15:37.179Z',
        category: 'unknown',
        _title: 'Reading 2025-02-11T10:15:32+01:00.jpg'
      }
    ],
    journey_actions: []
  }
}
