import { PortalConfig } from '@epilot/customer-portal-client'

import { CUSTOMER_QUERY_IDS } from 'api/Queries'
import { DesignJSON } from 'utils/DesignUtils'
import { queryClient } from 'utils/QueryUtils'

import { DUMMY_PORTAL_MESSAGE_EVENT_TYPE } from './events'
import DUMMY_MOCKS from './query-mocks'

let currentConfig: PortalConfig

let currentDesign: DesignJSON

export const embeddedListener = (event: MessageEvent) => {
  if (
    event.data.type === DUMMY_PORTAL_MESSAGE_EVENT_TYPE.PORTAL_DESIGN_RECEIVED
  ) {
    const design = event.data.noDesignAvailable
      ? currentDesign
      : event.data.design

    window.postMessage(
      { type: DUMMY_PORTAL_MESSAGE_EVENT_TYPE.PORTAL_DESIGN_UPDATE, design },
      '*'
    )

    currentDesign = design
  }
  if (
    event.data.type === DUMMY_PORTAL_MESSAGE_EVENT_TYPE.PORTAL_CONFIG_RECEIVED
  ) {
    const config = event.data.noConfigAvailable
      ? currentConfig
      : typeof event.data.config === 'string'
      ? JSON.parse(event.data.config)
      : event.data.config

    currentConfig = config

    DUMMY_MOCKS[CUSTOMER_QUERY_IDS.GET_PORTAL_CONFIG] = config
    queryClient.invalidateQueries([CUSTOMER_QUERY_IDS.GET_PORTAL_CONFIG])
  }
  if (
    event.data.type === DUMMY_PORTAL_MESSAGE_EVENT_TYPE.PORTAL_CONFIG_UPDATE
  ) {
    const config = event.data.noConfigAvailable
      ? currentConfig
      : typeof event.data.config === 'string'
      ? JSON.parse(event.data.config)
      : event.data.config

    currentConfig = config

    DUMMY_MOCKS[CUSTOMER_QUERY_IDS.GET_PORTAL_CONFIG] = config
    queryClient.invalidateQueries([CUSTOMER_QUERY_IDS.GET_PORTAL_CONFIG])
  }
}

const requestPortalConfig = () => {
  window.parent.postMessage(
    { type: DUMMY_PORTAL_MESSAGE_EVENT_TYPE.GET_PORTAL_CONFIG },
    '*'
  )
}

const requestPortalDesign = () => {
  window.parent.postMessage(
    { type: DUMMY_PORTAL_MESSAGE_EVENT_TYPE.GET_PORTAL_DESIGN },
    '*'
  )
}

const COMMUNICATION_TIMEOUT = 60 * 1000

export const getConfig = async (
  defaultConfig?: PortalConfig
): Promise<PortalConfig> => {
  return new Promise<PortalConfig>((resolve, reject) => {
    const messageListener = (event: MessageEvent) => {
      if (
        event.data.type === DUMMY_PORTAL_MESSAGE_EVENT_TYPE.PORTAL_CONFIG_UPDATE
      ) {
        cleanup()

        const config = event.data.noConfigAvailable
          ? currentConfig || defaultConfig
          : typeof event.data.config === 'string'
          ? JSON.parse(event.data.config)
          : event.data.config

        currentConfig = config

        DUMMY_MOCKS[CUSTOMER_QUERY_IDS.GET_PORTAL_CONFIG] = config
        queryClient.invalidateQueries([CUSTOMER_QUERY_IDS.GET_PORTAL_CONFIG])

        resolve(config)
      }
    }

    const timeout = setTimeout(() => {
      cleanup()

      reject(new Error('Timeout waiting for config'))
    }, COMMUNICATION_TIMEOUT)

    function cleanup() {
      clearTimeout(timeout)
      window.removeEventListener('message', messageListener)
    }

    try {
      window.addEventListener('message', messageListener)

      requestPortalConfig()
    } catch (e) {
      console.error('Error posting message to parent:', e)

      cleanup()

      reject(e)
    }
  })
}

export const getDesign = async (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const messageListener = (event: MessageEvent) => {
      if (
        event.data.type ===
        DUMMY_PORTAL_MESSAGE_EVENT_TYPE.PORTAL_DESIGN_RECEIVED
      ) {
        cleanup()

        const design = event.data.noDesignAvailable
          ? currentDesign
          : event.data.design

        currentDesign = design

        resolve()
      }
    }

    const timeout = setTimeout(() => {
      cleanup()

      reject(new Error('Timeout waiting for design'))
    }, COMMUNICATION_TIMEOUT)

    function cleanup() {
      clearTimeout(timeout)
      window.removeEventListener('message', messageListener)
    }

    try {
      window.addEventListener('message', messageListener)

      requestPortalDesign()
    } catch (e) {
      console.error('Error posting message to parent:', e)

      cleanup()

      reject(e)
    }
  })
}
