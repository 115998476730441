const config = {
  dev: {
    DEPLOYED_ECP_URL: 'https://end-customer-portal.ecp.dev.epilot.io',
    STAGE: 'dev',
    UNLEASH_URL: 'https://epilot360-feature-flag.dev.sls.epilot.io',
    UNLEASH_URI: '/proxy',
    UNLEASH_INSTANCE_ID: '6Rcynrp3-YBthyQuf4Us',
    UNLEASH_AUTHORIZATION: 'epilot360',
    CUSTOMER_PORTAL_API_URL: 'https://customer-portal-api.dev.sls.epilot.io',
    DESIGN_BUILDER_API_URL: 'https://design-builder-api.dev.sls.epilot.io/v1',
    METERING_API_URL: 'https://metering.dev.sls.epilot.io',
    ENTITY_WEB_SOCKET_URL: 'wss://ws-entity.dev.sls.epilot.io',
    FILE_API_URL: 'https://file.dev.sls.epilot.io',
    IBAN_API_URL:
      'https://m1ijr3w9q8.execute-api.eu-central-1.amazonaws.com/dev/v1/integration/iban?iban=',
    AMPLIFY_REGION: 'eu-central-1',
    DATADOG_CLIENT_TOKEN: '01a74178-cb3c-4a5c-bbfb-5c855ee4214c',
    FULLSTORY_ORG_ID: 'o-1336-eu1',
    ANALYTICS_API_BASE_URL: 'https://analytics-api.dev.sls.epilot.io'
  },
  staging: {
    DEPLOYED_ECP_URL: 'https://end-customer-portal.ecp.staging.epilot.io/login',
    STAGE: 'staging',
    UNLEASH_URL: 'https://epilot360-feature-flag.staging.sls.epilot.io',
    UNLEASH_URI: '/proxy',
    UNLEASH_INSTANCE_ID: '6Rcynrp3-YBthyQuf4Us',
    UNLEASH_AUTHORIZATION: 'epilot360',
    CUSTOMER_PORTAL_API_URL:
      'https://customer-portal-api.staging.sls.epilot.io',
    DESIGN_BUILDER_API_URL:
      'https://design-builder-api.staging.sls.epilot.io/v1',
    METERING_API_URL: 'https://metering.staging.sls.epilot.io',
    ENTITY_WEB_SOCKET_URL: 'wss://ws-entity.staging.sls.epilot.io',
    FILE_API_URL: 'https://file.staging.sls.epilot.io',
    IBAN_API_URL:
      'https://svc-integration-iban-api.staging.sls.epilot.io/v1/integration/iban?iban=',
    AMPLIFY_REGION: 'eu-central-1',
    DATADOG_CLIENT_TOKEN: '01a74178-cb3c-4a5c-bbfb-5c855ee4214c',
    FULLSTORY_ORG_ID: 'o-1336-eu1v',
    ANALYTICS_API_BASE_URL: 'https://analytics-api.staging.sls.epilot.io'
  },
  prod: {
    DEPLOYED_ECP_URL: 'https://end-customer-portal.ecp.epilot.io/login',
    STAGE: 'prod',
    UNLEASH_URL: 'https://epilot360-feature-flag.sls.epilot.io',
    UNLEASH_URI: '/proxy',
    UNLEASH_INSTANCE_ID: '6Rcynrp3-YBthyQuf4Us',
    UNLEASH_AUTHORIZATION: 'epilot360',
    CUSTOMER_PORTAL_API_URL: 'https://customer-portal-api.sls.epilot.io',
    DESIGN_BUILDER_API_URL: 'https://design-builder-api.sls.epilot.io/v1',
    METERING_API_URL: 'https://metering.sls.epilot.io',
    ENTITY_WEB_SOCKET_URL: 'wss://ws-entity.sls.epilot.io',
    FILE_API_URL: 'https://file.sls.epilot.io',
    IBAN_API_URL:
      'https://svc-integration-iban-api.sls.epilot.io/v1/integration/iban?iban=',
    AMPLIFY_REGION: 'eu-central-1',
    DATADOG_CLIENT_TOKEN: 'pub84494e3e0dbc760d4bdcfe5cf8dea518',
    FULLSTORY_ORG_ID: 'o-1336-eu1',
    ANALYTICS_API_BASE_URL: 'https://analytics-api.sls.epilot.io'
  }
}

export const getConfig = () => {
  const environment = document.body.dataset.environment

  const desiredConfig = config[environment]

  if (desiredConfig) {
    return desiredConfig
  }

  // Fall back to dev config in local dev mode
  const parsedConfig = import.meta.env.DEV ? config.dev : config.prod

  return {
    ...parsedConfig,

    // Allowed overrides (local development only)
    ...Object.keys(import.meta.env).reduce((acc, key) => {
      if (key.startsWith('VITE_FEATURE_SETTING_OVERRIDE_')) {
        const feature = key.slice(30).toUpperCase()

        acc[`FEATURE_SETTING_OVERRIDE_${feature}`] =
          import.meta.env[key] === 'true'
      } else if (key.startsWith('VITE_')) {
        acc[key.slice(5)] = import.meta.env[key]
      }

      return acc
    }, {})
  }
}
