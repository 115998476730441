import { Contract } from 'api/ApiTypes'

export const MOCK_CONTRACT_LIST: { data: Contract[] } = {
  data: [
    {
      contract_name: 'WER',
      contract_number: 'QWEQWE-123',
      payment: [
        {
          _id: '33e44294-768e-4750-a14f-1c10c90143a1',
          _tags: [],
          type: 'payment_sepa',
          data: {
            bank_name: 'Landesbank Berlin - Berliner Sparkasse',
            iban: '',
            bic_number: 'BELADEBEXXX',
            fullname: 'Nadi Manta'
          },
          id: 'x79bo66',
          $relation_ref: {
            entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
            path: 'payment',
            _id: '33e44294-768e-4750-a14f-1c10c90143a1'
          }
        }
      ],
      customer: {
        $relation: [
          {
            entity_id: 'e407f7e4-e05a-4017-a4b6-4963c5a1551c',
            _tags: [],
            _schema: 'contact'
          },
          {
            entity_id: '754dce41-8c6f-48e0-801b-673abf56f83e',
            _tags: []
          }
        ]
      },
      type: 'recurring',
      billing_period: 'monthly',
      billing_duration_amount: null,
      billing_duration_unit: null,
      notice_time_amount: null,
      notice_time_unit: null,
      termination_time_amount: null,
      termination_time_unit: null,
      renewal_duration_amount: null,
      renewal_duration_unit: null,
      _schema: 'contract',
      status: 'active',
      _id: '681f2c18-bc80-4ade-8b18-a6e64240e19b',
      _org: '739224',
      _created_at: '2024-01-09T07:40:46.111Z',
      installment_amount: 13200,
      balance: 10000,
      sequence_attr: '168',
      _title: 'WER',
      installment_amount_currency: 'EUR',
      installment_amount_decimal: '132',
      other_address: [
        {
          _id: 'bJws8YEyYj3ci_Y-K55YC',
          _tags: [],
          street: 'greece street',
          street_number: '78',
          postal_code: '34444',
          city: 'Athens 23',
          country: 'GR',
          additional_info: '',
          $relation_ref: {
            entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
            path: 'address',
            _id: 'bJws8YEyYj3ci_Y-K55YC'
          }
        },
        {
          _id: 'vKn8EvUccnOuZZhwxvMer',
          country: 'DE',
          city: 'Bochum',
          street: 'Hermann-Hußmann-Str.',
          street_number: '11',
          postal_code: '44787',
          $relation_ref: {
            entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
            path: 'address',
            _id: 'vKn8EvUccnOuZZhwxvMer'
          }
        }
      ],
      balance_currency: 'EUR',
      balance_decimal: '100.00',
      _updated_at: '2025-01-29T09:56:48.783Z',
      line_items: [
        {
          price_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
          product_id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
          quantity: 1,
          description: 'flat fee installation',
          unit_amount: 0,
          unit_amount_decimal: '0.00',
          pricing_model: 'tiered_volume',
          _price: {
            _id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
            _title: 'flat fee installation',
            pricing_model: 'tiered_volume',
            variable_price: true,
            description: 'flat fee installation',
            tiers: [
              {
                up_to: 90.555555,
                unit_amount: 1000,
                unit_amount_decimal: '10',
                flat_fee_amount: 0,
                flat_fee_amount_decimal: '0'
              },
              {
                up_to: 300,
                unit_amount: 2500,
                unit_amount_decimal: '25',
                flat_fee_amount: 0,
                flat_fee_amount_decimal: '0',
                display_mode: 'on_request'
              },
              {
                up_to: 600,
                unit_amount: 6000,
                unit_amount_decimal: '60',
                flat_fee_amount: 0,
                flat_fee_amount_decimal: '0',
                display_mode: 'on_request'
              },
              {
                up_to: null,
                unit_amount: 10000,
                unit_amount_decimal: '100',
                flat_fee_amount: 0,
                flat_fee_amount_decimal: '0'
              }
            ],
            type: 'one_time',
            price_display_in_journeys: 'show_as_starting_price',
            unit_amount: 0,
            is_tax_inclusive: true,
            active: true,
            billing_period: 'weekly',
            billing_duration_unit: 'months',
            notice_time_unit: 'months',
            termination_time_unit: 'months',
            renewal_duration_unit: 'months',
            internal_description: 'flat fee instalar',
            unit: 'm',
            unit_amount_decimal: '0.00',
            unit_amount_currency: 'EUR'
          },
          _product: {
            type: 'product',
            _title: '[Flat Fee] - Composite Installation',
            name: '[Flat Fee] - Composite Installation',
            active: true,
            price_options: [
              {
                _id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                _schema: 'price',
                _title: 'flat fee installation',
                pricing_model: 'tiered_volume',
                variable_price: true,
                description: 'flat fee installation',
                tiers: [
                  {
                    up_to: 90.555555,
                    unit_amount: 1000,
                    unit_amount_decimal: '10',
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0'
                  },
                  {
                    up_to: 300,
                    unit_amount: 2500,
                    unit_amount_decimal: '25',
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0',
                    display_mode: 'on_request'
                  },
                  {
                    up_to: 600,
                    unit_amount: 6000,
                    unit_amount_decimal: '60',
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0',
                    display_mode: 'on_request'
                  },
                  {
                    up_to: null,
                    unit_amount: 10000,
                    unit_amount_decimal: '100',
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0'
                  }
                ],
                type: 'one_time',
                price_display_in_journeys: 'show_as_starting_price',
                _org: '739224',
                _owners: [
                  {
                    org_id: '739224',
                    user_id: '10009172'
                  }
                ],
                _created_at: '2023-05-29T12:34:47.681Z',
                unit_amount: 0,
                is_tax_inclusive: true,
                active: true,
                billing_period: 'weekly',
                billing_duration_unit: 'months',
                notice_time_unit: 'months',
                termination_time_unit: 'months',
                renewal_duration_unit: 'months',
                _acl: {
                  view: ['org_739224'],
                  edit: ['org_739224'],
                  delete: ['org_739224']
                },
                _updated_at: '2024-10-21T08:33:56.979Z',
                internal_description: 'flat fee instalar',
                unit: 'm',
                _acl_sync: '2024-01-11T17:26:48.410Z',
                unit_amount_decimal: '0.00',
                unit_amount_currency: 'EUR',
                _files: [],
                $relation: {
                  entity_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
                  _tags: []
                }
              },
              {
                _id: '5dab027d-086d-412b-b245-17611592e588',
                pricing_model: 'per_unit',
                unit_amount: 0,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '0.00',
                is_tax_inclusive: true,
                price_display_in_journeys: 'show_price',
                active: true,
                type: 'one_time',
                billing_period: 'weekly',
                billing_duration_unit: 'months',
                notice_time_unit: 'months',
                termination_time_unit: 'months',
                renewal_duration_unit: 'months',
                is_composite_price: true,
                _tags: ['composite'],
                _schema: 'price',
                _title: 'test composite',
                description: 'test composite',
                price_components: [],
                _org: '739224',
                _owners: [
                  {
                    org_id: '739224',
                    user_id: '10009172'
                  }
                ],
                _created_at: '2023-05-29T15:54:36.174Z',
                _updated_at: '2024-10-24T08:57:46.274Z',
                _acl: {
                  view: ['org_739224'],
                  edit: ['org_739224'],
                  delete: ['org_739224']
                },
                internal_description: 'test composite',
                $relation: {
                  entity_id: '5dab027d-086d-412b-b245-17611592e588',
                  _tags: []
                }
              },
              {
                _id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                pricing_model: 'tiered_graduated',
                unit_amount: 0,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '0.00',
                is_tax_inclusive: true,
                price_display_in_journeys: 'show_price',
                active: true,
                type: 'one_time',
                billing_period: 'weekly',
                billing_duration_unit: 'months',
                notice_time_unit: 'months',
                termination_time_unit: 'months',
                renewal_duration_unit: 'months',
                _tags: [],
                _schema: 'price',
                _title: 'graduated test',
                description: 'graduated test',
                variable_price: true,
                tiers: [
                  {
                    up_to: 90.555555,
                    unit_amount: 1000,
                    unit_amount_decimal: '10',
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0'
                  },
                  {
                    up_to: 300,
                    unit_amount: 5000,
                    unit_amount_decimal: '50',
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0'
                  },
                  {
                    up_to: null,
                    unit_amount: 10000,
                    unit_amount_decimal: '100',
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0'
                  }
                ],
                _org: '739224',
                _owners: [
                  {
                    org_id: '739224',
                    user_id: '10009172'
                  }
                ],
                _created_at: '2023-05-30T08:42:31.011Z',
                _updated_at: '2024-01-11T17:26:48.500Z',
                _acl: {
                  view: ['org_739224'],
                  edit: ['org_739224'],
                  delete: ['org_739224']
                },
                internal_description: 'graduated test',
                _acl_sync: '2024-01-11T17:26:48.405Z',
                $relation: {
                  entity_id: 'e3eff902-566d-401d-ad6c-9682794b09eb',
                  _tags: []
                }
              }
            ],
            _id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
            _availability_files: [],
            internal_name: '[Flat Fee] - Composite Installation',
            product_images: [
              {
                _id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                s3ref: {
                  bucket: 'epilot-dev-user-content',
                  key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                },
                category: 'unknown',
                filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                access_control: 'public-read',
                type: 'image',
                public_url:
                  'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                size_bytes: 332081,
                readable_size: '324.3 KB',
                mime_type: 'image/png',
                versions: [
                  {
                    s3ref: {
                      bucket: 'epilot-dev-user-content',
                      key: '739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%202023-01-16%20at%205.24.48%20PM.png'
                    },
                    category: 'unknown',
                    filename: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                    access_control: 'public-read',
                    type: 'image',
                    public_url:
                      'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/b4e316cf-5998-4aee-a8fb-8dac06fd8558/Screenshot%25202023-01-16%2520at%25205.24.48%2520PM.png',
                    size_bytes: 332081,
                    readable_size: '324.3 KB',
                    mime_type: 'image/png'
                  }
                ],
                _schema: 'file',
                _org: '739224',
                _owners: [
                  {
                    org_id: '739224',
                    user_id: '11000656'
                  }
                ],
                _created_at: '2024-06-27T07:13:31.962Z',
                _updated_at: '2024-09-24T19:44:09.352Z',
                _title: 'Screenshot 2023-01-16 at 5.24.48 PM.png',
                _acl: {
                  view: ['org_739224'],
                  edit: ['org_739224'],
                  delete: ['org_739224']
                },
                source_url:
                  'https://file.dev.sls.epilot.io/v1/files/public/links/739224-jo2Rg-vdKgFxyqpjT9lla/Screenshot%202023-01-16%20at%205.24.48%20PM.png',
                $relation: {
                  entity_id: 'ab8cb022-7b93-43fb-a77e-2feccdd25168',
                  _tags: []
                }
              }
            ],
            order_number: 'PRODUCT-1039'
          },
          taxes: [
            {
              rate: 'nontaxable',
              rateValue: 0,
              amount: 0
            }
          ],
          price_mappings: [
            {
              price_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
              value: 1
            }
          ],
          currency: 'EUR',
          unit_amount_net: 1000,
          unit_amount_gross: 1000,
          amount_subtotal: 1000,
          amount_total: 1000,
          amount_tax: 0,
          tiers_details: [
            {
              quantity: 1,
              unit_amount: 1000,
              unit_amount_decimal: '10',
              unit_amount_gross: 1000,
              unit_amount_net: 1000,
              amount_subtotal: 1000,
              amount_total: 1000,
              amount_tax: 0
            }
          ],
          is_tax_inclusive: true,
          unit_amount_net_decimal: '10',
          unit_amount_gross_decimal: '10',
          amount_subtotal_decimal: '10',
          amount_total_decimal: '10'
        },
        {
          price_id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a',
          product_id: '5e42fccd-8f49-4a36-8459-14918d30cf61',
          quantity: 1,
          description: '[Flat Fee] - Installation',
          unit_amount: 0,
          unit_amount_decimal: '0.00',
          pricing_model: 'tiered_flatfee',
          _price: {
            _id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a',
            active: true,
            billing_duration_unit: 'months',
            description: '[Flat Fee] - Installation',
            is_tax_inclusive: false,
            notice_time_unit: 'months',
            price_display_in_journeys: 'show_as_starting_price',
            pricing_model: 'tiered_flatfee',
            renewal_duration_unit: 'months',
            termination_time_unit: 'months',
            tiers: [
              {
                flat_fee_amount: 5000,
                flat_fee_amount_decimal: '50',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5
              },
              {
                flat_fee_amount: 7500,
                flat_fee_amount_decimal: '75',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10
              },
              {
                flat_fee_amount: 10000,
                flat_fee_amount_decimal: '100',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15
              },
              {
                flat_fee_amount: 12000,
                flat_fee_amount_decimal: '120',
                unit_amount: 0,
                unit_amount_decimal: '0'
              }
            ],
            type: 'one_time',
            unit: 'kwh',
            unit_amount: 0,
            unit_amount_currency: 'EUR',
            unit_amount_decimal: '0.00',
            variable_price: true,
            internal_description: '[Flat Fee] - Installation',
            billing_period: 'weekly',
            _title: '[Flat Fee] - Installation'
          },
          _product: {
            active: true,
            feature: [],
            internal_name: '[Flat Fee] - Installation',
            name: '[Flat Fee] - Installation',
            price_options: [
              {
                _id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a',
                active: true,
                billing_duration_unit: 'months',
                description: '[Flat Fee] - Installation',
                is_tax_inclusive: false,
                notice_time_unit: 'months',
                price_display_in_journeys: 'show_as_starting_price',
                pricing_model: 'tiered_flatfee',
                renewal_duration_unit: 'months',
                termination_time_unit: 'months',
                tiers: [
                  {
                    flat_fee_amount: 5000,
                    flat_fee_amount_decimal: '50',
                    unit_amount: 0,
                    unit_amount_decimal: '0',
                    up_to: 5
                  },
                  {
                    flat_fee_amount: 7500,
                    flat_fee_amount_decimal: '75',
                    unit_amount: 0,
                    unit_amount_decimal: '0',
                    up_to: 10
                  },
                  {
                    flat_fee_amount: 10000,
                    flat_fee_amount_decimal: '100',
                    unit_amount: 0,
                    unit_amount_decimal: '0',
                    up_to: 15
                  },
                  {
                    flat_fee_amount: 12000,
                    flat_fee_amount_decimal: '120',
                    unit_amount: 0,
                    unit_amount_decimal: '0'
                  }
                ],
                type: 'one_time',
                unit: 'kwh',
                unit_amount: 0,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '0.00',
                variable_price: true,
                _schema: 'price',
                _org: '739224',
                _owners: [
                  {
                    org_id: '739224',
                    user_id: 'api_bcyn5xLgUYEJZSs8LdACs'
                  }
                ],
                _created_at: '2024-07-26T14:06:10.296Z',
                _updated_at: '2024-07-26T14:06:10.296Z',
                internal_description: '[Flat Fee] - Installation',
                billing_period: 'weekly',
                _title: '[Flat Fee] - Installation',
                _acl: {
                  view: ['org_739224'],
                  edit: ['org_739224'],
                  delete: ['org_739224']
                },
                $relation: {
                  entity_id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a'
                }
              }
            ],
            type: 'product',
            _id: '5e42fccd-8f49-4a36-8459-14918d30cf61',
            order_number: 'PRODUCT-1067',
            _title: '[Flat Fee] - Installation'
          },
          taxes: [
            {
              rate: 'nontaxable',
              rateValue: 0,
              amount: 0
            }
          ],
          price_mappings: [
            {
              price_id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a',
              value: 123
            }
          ],
          currency: 'EUR',
          unit_amount_net: 12000,
          unit_amount_gross: 12000,
          amount_subtotal: 12000,
          amount_total: 12000,
          amount_tax: 0,
          tiers_details: [
            {
              quantity: 123,
              unit_amount: 12000,
              unit_amount_decimal: '120',
              unit_amount_gross: 12000,
              unit_amount_net: 12000,
              amount_subtotal: 12000,
              amount_total: 12000,
              amount_tax: 0
            }
          ],
          is_tax_inclusive: false,
          unit_amount_net_decimal: '120',
          unit_amount_gross_decimal: '120',
          amount_subtotal_decimal: '120',
          amount_total_decimal: '120'
        },
        {
          price_id: '8a837980-8d1c-4e1c-a92b-00f0feb94929',
          product_id: 'e0ffaab9-3dae-4853-8753-8bf117c6d545',
          quantity: 1,
          description: '[Flat Fee] - Installation',
          unit_amount: 0,
          unit_amount_decimal: '0',
          pricing_model: 'tiered_flatfee',
          _price: {
            _id: '8a837980-8d1c-4e1c-a92b-00f0feb94929',
            _tags: [],
            active: true,
            billing_duration_unit: 'months',
            description: '[Flat Fee] - Installation',
            is_tax_inclusive: false,
            notice_time_unit: 'months',
            price_display_in_journeys: 'show_as_starting_price',
            pricing_model: 'tiered_flatfee',
            renewal_duration_unit: 'months',
            termination_time_unit: 'months',
            tiers: [
              {
                flat_fee_amount: 5000,
                flat_fee_amount_decimal: '50',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5
              },
              {
                flat_fee_amount: 7500,
                flat_fee_amount_decimal: '75',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10
              },
              {
                flat_fee_amount: 10000,
                flat_fee_amount_decimal: '100',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15
              },
              {
                flat_fee_amount: 12000,
                flat_fee_amount_decimal: '120',
                unit_amount: 0,
                unit_amount_decimal: '0'
              }
            ],
            type: 'one_time',
            unit: 'kwh',
            unit_amount: 0,
            unit_amount_currency: 'EUR',
            unit_amount_decimal: '0',
            variable_price: true,
            billing_period: 'weekly',
            internal_description: '[Flat Fee] - Installation',
            _title: '[Flat Fee] - Installation'
          },
          _product: {
            _purpose: [],
            _tags: [],
            active: true,
            feature: [],
            internal_name: '[Flat Fee] - Installation',
            name: '[Flat Fee] - Installation',
            price_options: [
              {
                _id: '8a837980-8d1c-4e1c-a92b-00f0feb94929',
                _schema: 'price',
                _tags: [],
                active: true,
                billing_duration_unit: 'months',
                description: '[Flat Fee] - Installation',
                is_tax_inclusive: false,
                notice_time_unit: 'months',
                price_display_in_journeys: 'show_as_starting_price',
                pricing_model: 'tiered_flatfee',
                renewal_duration_unit: 'months',
                termination_time_unit: 'months',
                tiers: [
                  {
                    flat_fee_amount: 5000,
                    flat_fee_amount_decimal: '50',
                    unit_amount: 0,
                    unit_amount_decimal: '0',
                    up_to: 5
                  },
                  {
                    flat_fee_amount: 7500,
                    flat_fee_amount_decimal: '75',
                    unit_amount: 0,
                    unit_amount_decimal: '0',
                    up_to: 10
                  },
                  {
                    flat_fee_amount: 10000,
                    flat_fee_amount_decimal: '100',
                    unit_amount: 0,
                    unit_amount_decimal: '0',
                    up_to: 15
                  },
                  {
                    flat_fee_amount: 12000,
                    flat_fee_amount_decimal: '120',
                    unit_amount: 0,
                    unit_amount_decimal: '0'
                  }
                ],
                type: 'one_time',
                unit: 'kwh',
                unit_amount: 0,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '0',
                variable_price: true,
                _acl_sync: '2024-08-23T12:45:21.316Z',
                billing_period: 'weekly',
                internal_description: '[Flat Fee] - Installation',
                _org: '739224',
                _owners: [
                  {
                    org_id: '739224',
                    user_id: '11000933'
                  }
                ],
                _created_at: '2024-08-28T09:09:07.557Z',
                _updated_at: '2024-08-28T09:09:07.557Z',
                _title: '[Flat Fee] - Installation',
                _acl: {
                  view: ['org_739224'],
                  edit: ['org_739224'],
                  delete: ['org_739224']
                },
                $relation: {
                  entity_id: '8a837980-8d1c-4e1c-a92b-00f0feb94929'
                }
              }
            ],
            type: 'product',
            order_number: 'PRODUCT-1031',
            _id: 'e0ffaab9-3dae-4853-8753-8bf117c6d545',
            _title: '[Flat Fee] - Installation'
          },
          taxes: [
            {
              rate: 'nontaxable',
              rateValue: 0,
              amount: 0
            }
          ],
          price_mappings: [
            {
              price_id: '8a837980-8d1c-4e1c-a92b-00f0feb94929',
              value: 123
            }
          ],
          currency: 'EUR',
          unit_amount_net: 12000,
          unit_amount_gross: 12000,
          amount_subtotal: 12000,
          amount_total: 12000,
          amount_tax: 0,
          tiers_details: [
            {
              quantity: 123,
              unit_amount: 12000,
              unit_amount_decimal: '120',
              unit_amount_gross: 12000,
              unit_amount_net: 12000,
              amount_subtotal: 12000,
              amount_total: 12000,
              amount_tax: 0
            }
          ],
          is_tax_inclusive: false,
          unit_amount_net_decimal: '120',
          unit_amount_gross_decimal: '120',
          amount_subtotal_decimal: '120',
          amount_total_decimal: '120'
        },
        {
          price_id: '4555625e-e408-476e-aa35-0392868ba372',
          product_id: 'c9835627-2471-4a51-8344-bea082e7eab2',
          quantity: 1,
          description: '[Graduated] - Composite',
          unit_amount: 0,
          unit_amount_decimal: '0',
          pricing_model: 'per_unit',
          item_components: [
            {
              price_id: 'ecf5315a-2632-4c89-ad7d-3eca1a9592d1',
              product_id: 'c9835627-2471-4a51-8344-bea082e7eab2',
              quantity: 1,
              description: '[Graduated] - Consumption II',
              unit_amount: 0,
              unit_amount_decimal: '0',
              pricing_model: 'tiered_graduated',
              _price: {
                _id: 'ecf5315a-2632-4c89-ad7d-3eca1a9592d1',
                _tags: [],
                active: true,
                billing_duration_unit: 'months',
                description: '[Graduated] - Consumption II',
                is_tax_inclusive: false,
                long_description: 'component description',
                notice_time_unit: 'months',
                price_display_in_journeys: 'show_price',
                pricing_model: 'tiered_graduated',
                renewal_duration_unit: 'months',
                tax: [
                  {
                    _schema: 'tax',
                    active: true,
                    description: 'test 19% tax',
                    rate: '19',
                    region: 'DE',
                    type: 'VAT',
                    region_label: 'DE',
                    _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                    _org: '739224',
                    _owners: [
                      {
                        org_id: '739224',
                        user_id: '11000933'
                      }
                    ],
                    _created_at: '2024-08-27T14:24:53.739Z',
                    _updated_at: '2024-08-27T14:24:53.739Z',
                    _title: 'test 19% tax',
                    _acl: {
                      view: ['org_739224'],
                      edit: ['org_739224'],
                      delete: ['org_739224']
                    }
                  }
                ],
                termination_time_unit: 'months',
                tiers: [
                  {
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0',
                    unit_amount: 10,
                    unit_amount_decimal: '0.1',
                    up_to: 1000
                  },
                  {
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0',
                    unit_amount: 5,
                    unit_amount_decimal: '0.054',
                    up_to: 2000
                  },
                  {
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0',
                    unit_amount: 5,
                    unit_amount_decimal: '0.053',
                    up_to: 3000
                  },
                  {
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0',
                    unit_amount: 5,
                    unit_amount_decimal: '0.05'
                  }
                ],
                type: 'recurring',
                unit: 'kwh',
                unit_amount: 0,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '0',
                variable_price: true,
                billing_period: 'weekly',
                internal_description: '[Graduated] - Consumption II',
                _title: '[Graduated] - Consumption II'
              },
              _product: {
                _purpose: [],
                _tags: [],
                active: true,
                description: 'product description',
                feature: [],
                internal_name: '[Graduated] - Composite',
                name: '[Graduated] - Composite',
                price_options: [
                  {
                    _id: '4555625e-e408-476e-aa35-0392868ba372',
                    _schema: 'price',
                    _tags: [],
                    active: true,
                    billing_duration_unit: 'months',
                    description: '[Graduated] - Composite',
                    is_composite_price: true,
                    is_tax_inclusive: true,
                    long_description: 'price option description',
                    notice_time_unit: 'months',
                    price_components: [],
                    price_display_in_journeys: 'show_price',
                    pricing_model: 'per_unit',
                    renewal_duration_unit: 'months',
                    termination_time_unit: 'months',
                    tiers: [],
                    type: 'one_time',
                    unit_amount: 0,
                    unit_amount_currency: 'EUR',
                    unit_amount_decimal: '0',
                    variable_price: false,
                    billing_period: 'weekly',
                    internal_description: '[Graduated] - Composite',
                    _org: '739224',
                    _owners: [
                      {
                        org_id: '739224',
                        user_id: '11000933'
                      }
                    ],
                    _created_at: '2024-08-27T14:24:55.184Z',
                    _updated_at: '2024-08-27T14:24:55.184Z',
                    _title: '[Graduated] - Composite',
                    _acl: {
                      view: ['org_739224'],
                      edit: ['org_739224'],
                      delete: ['org_739224']
                    },
                    $relation: {
                      entity_id: '4555625e-e408-476e-aa35-0392868ba372'
                    }
                  }
                ],
                type: 'product',
                order_number: 'PRODUCT-1079',
                _id: 'c9835627-2471-4a51-8344-bea082e7eab2',
                _title: '[Graduated] - Composite'
              },
              taxes: [
                {
                  tax: {
                    _schema: 'tax',
                    active: true,
                    description: 'test 19% tax',
                    rate: '19',
                    region: 'DE',
                    type: 'VAT',
                    region_label: 'DE',
                    _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                    _org: '739224',
                    _owners: [
                      {
                        org_id: '739224',
                        user_id: '11000933'
                      }
                    ],
                    _created_at: '2024-08-27T14:24:53.739Z',
                    _updated_at: '2024-08-27T14:24:53.739Z',
                    _title: 'test 19% tax',
                    _acl: {
                      view: ['org_739224'],
                      edit: ['org_739224'],
                      delete: ['org_739224']
                    }
                  },
                  amount: 4
                }
              ],
              type: 'recurring',
              billing_period: 'weekly',
              currency: 'EUR',
              unit_amount_net: 10,
              unit_amount_gross: 12,
              amount_subtotal: 20,
              amount_total: 24,
              amount_tax: 4,
              tiers_details: [
                {
                  quantity: 2,
                  unit_amount: 10,
                  unit_amount_decimal: '0.1',
                  unit_amount_gross: 12,
                  unit_amount_net: 10,
                  amount_subtotal: 20,
                  amount_total: 24,
                  amount_tax: 4
                }
              ],
              is_tax_inclusive: false,
              unit_amount_net_decimal: '0.1',
              unit_amount_gross_decimal: '0.119',
              amount_subtotal_decimal: '0.2',
              amount_total_decimal: '0.238'
            },
            {
              price_id: 'c9ac3f90-f4d2-482f-a1cd-9667d3e659b8',
              product_id: 'c9835627-2471-4a51-8344-bea082e7eab2',
              quantity: 1,
              description: 'With unit_price',
              unit_amount: 1234,
              unit_amount_decimal: '12.34',
              pricing_model: 'per_unit',
              _price: {
                _id: 'c9ac3f90-f4d2-482f-a1cd-9667d3e659b8',
                _tags: [],
                active: true,
                billing_duration_unit: 'months',
                description: 'With unit_price',
                is_tax_inclusive: true,
                long_description: 'with unit_price description ',
                notice_time_unit: 'months',
                price_display_in_journeys: 'show_price',
                pricing_model: 'per_unit',
                renewal_duration_unit: 'months',
                termination_time_unit: 'months',
                tiers: [],
                type: 'one_time',
                unit: 'kwh',
                unit_amount: 1234,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '12.34',
                variable_price: false,
                billing_period: 'weekly',
                internal_description: 'With unit_price',
                _title: 'With unit_price'
              },
              _product: {
                _purpose: [],
                _tags: [],
                active: true,
                description: 'product description',
                feature: [],
                internal_name: '[Graduated] - Composite',
                name: '[Graduated] - Composite',
                price_options: [
                  {
                    _id: '4555625e-e408-476e-aa35-0392868ba372',
                    _schema: 'price',
                    _tags: [],
                    active: true,
                    billing_duration_unit: 'months',
                    description: '[Graduated] - Composite',
                    is_composite_price: true,
                    is_tax_inclusive: true,
                    long_description: 'price option description',
                    notice_time_unit: 'months',
                    price_components: [],
                    price_display_in_journeys: 'show_price',
                    pricing_model: 'per_unit',
                    renewal_duration_unit: 'months',
                    termination_time_unit: 'months',
                    tiers: [],
                    type: 'one_time',
                    unit_amount: 0,
                    unit_amount_currency: 'EUR',
                    unit_amount_decimal: '0',
                    variable_price: false,
                    billing_period: 'weekly',
                    internal_description: '[Graduated] - Composite',
                    _org: '739224',
                    _owners: [
                      {
                        org_id: '739224',
                        user_id: '11000933'
                      }
                    ],
                    _created_at: '2024-08-27T14:24:55.184Z',
                    _updated_at: '2024-08-27T14:24:55.184Z',
                    _title: '[Graduated] - Composite',
                    _acl: {
                      view: ['org_739224'],
                      edit: ['org_739224'],
                      delete: ['org_739224']
                    },
                    $relation: {
                      entity_id: '4555625e-e408-476e-aa35-0392868ba372'
                    }
                  }
                ],
                type: 'product',
                order_number: 'PRODUCT-1079',
                _id: 'c9835627-2471-4a51-8344-bea082e7eab2',
                _title: '[Graduated] - Composite'
              },
              taxes: [
                {
                  rate: 'nontaxable',
                  rateValue: 0,
                  amount: 0
                }
              ],
              type: 'one_time',
              currency: 'EUR',
              unit_amount_net: 1234,
              unit_amount_net_decimal: '12.34',
              unit_amount_gross: 1234,
              unit_amount_gross_decimal: '12.34',
              amount_subtotal: 1234,
              amount_total: 1234,
              amount_tax: 0,
              is_tax_inclusive: true,
              amount_subtotal_decimal: '12.34',
              amount_total_decimal: '12.34'
            },
            {
              price_id: 'f021b371-ca04-4fda-b9b7-dc79538bc452',
              product_id: 'c9835627-2471-4a51-8344-bea082e7eab2',
              quantity: 1,
              description: 'with unit_price piece',
              unit_amount: 4321,
              unit_amount_decimal: '43.21',
              pricing_model: 'per_unit',
              _price: {
                _id: 'f021b371-ca04-4fda-b9b7-dc79538bc452',
                _tags: [],
                active: true,
                billing_duration_unit: 'months',
                description: 'with unit_price piece',
                is_tax_inclusive: true,
                long_description: 'price description with unit_price/piece',
                notice_time_unit: 'months',
                price_display_in_journeys: 'show_price',
                pricing_model: 'per_unit',
                renewal_duration_unit: 'months',
                termination_time_unit: 'months',
                tiers: [],
                type: 'one_time',
                unit_amount: 4321,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '43.21',
                variable_price: false,
                billing_period: 'weekly',
                internal_description: 'with unit_price piece',
                _title: 'with unit_price piece'
              },
              _product: {
                _purpose: [],
                _tags: [],
                active: true,
                description: 'product description',
                feature: [],
                internal_name: '[Graduated] - Composite',
                name: '[Graduated] - Composite',
                price_options: [
                  {
                    _id: '4555625e-e408-476e-aa35-0392868ba372',
                    _schema: 'price',
                    _tags: [],
                    active: true,
                    billing_duration_unit: 'months',
                    description: '[Graduated] - Composite',
                    is_composite_price: true,
                    is_tax_inclusive: true,
                    long_description: 'price option description',
                    notice_time_unit: 'months',
                    price_components: [],
                    price_display_in_journeys: 'show_price',
                    pricing_model: 'per_unit',
                    renewal_duration_unit: 'months',
                    termination_time_unit: 'months',
                    tiers: [],
                    type: 'one_time',
                    unit_amount: 0,
                    unit_amount_currency: 'EUR',
                    unit_amount_decimal: '0',
                    variable_price: false,
                    billing_period: 'weekly',
                    internal_description: '[Graduated] - Composite',
                    _org: '739224',
                    _owners: [
                      {
                        org_id: '739224',
                        user_id: '11000933'
                      }
                    ],
                    _created_at: '2024-08-27T14:24:55.184Z',
                    _updated_at: '2024-08-27T14:24:55.184Z',
                    _title: '[Graduated] - Composite',
                    _acl: {
                      view: ['org_739224'],
                      edit: ['org_739224'],
                      delete: ['org_739224']
                    },
                    $relation: {
                      entity_id: '4555625e-e408-476e-aa35-0392868ba372'
                    }
                  }
                ],
                type: 'product',
                order_number: 'PRODUCT-1079',
                _id: 'c9835627-2471-4a51-8344-bea082e7eab2',
                _title: '[Graduated] - Composite'
              },
              taxes: [
                {
                  rate: 'nontaxable',
                  rateValue: 0,
                  amount: 0
                }
              ],
              type: 'one_time',
              currency: 'EUR',
              unit_amount_net: 4321,
              unit_amount_net_decimal: '43.21',
              unit_amount_gross: 4321,
              unit_amount_gross_decimal: '43.21',
              amount_subtotal: 4321,
              amount_total: 4321,
              amount_tax: 0,
              is_tax_inclusive: true,
              amount_subtotal_decimal: '43.21',
              amount_total_decimal: '43.21'
            }
          ],
          _price: {
            _id: '4555625e-e408-476e-aa35-0392868ba372',
            _tags: [],
            active: true,
            billing_duration_unit: 'months',
            description: '[Graduated] - Composite',
            is_composite_price: true,
            is_tax_inclusive: true,
            long_description: 'price option description',
            notice_time_unit: 'months',
            price_components: [
              {
                _id: 'ecf5315a-2632-4c89-ad7d-3eca1a9592d1',
                _tags: [],
                active: true,
                billing_duration_unit: 'months',
                description: '[Graduated] - Consumption II',
                is_tax_inclusive: false,
                long_description: 'component description',
                notice_time_unit: 'months',
                price_display_in_journeys: 'show_price',
                pricing_model: 'tiered_graduated',
                renewal_duration_unit: 'months',
                tax: {
                  $relation: [
                    {
                      entity_id: '11bffddb-838b-48bc-b57f-c1e4b8dba459'
                    }
                  ]
                },
                termination_time_unit: 'months',
                tiers: [
                  {
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0',
                    unit_amount: 10,
                    unit_amount_decimal: '0.1',
                    up_to: 1000
                  },
                  {
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0',
                    unit_amount: 5,
                    unit_amount_decimal: '0.054',
                    up_to: 2000
                  },
                  {
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0',
                    unit_amount: 5,
                    unit_amount_decimal: '0.053',
                    up_to: 3000
                  },
                  {
                    flat_fee_amount: 0,
                    flat_fee_amount_decimal: '0',
                    unit_amount: 5,
                    unit_amount_decimal: '0.05'
                  }
                ],
                type: 'recurring',
                unit: 'kwh',
                unit_amount: 0,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '0',
                variable_price: true,
                billing_period: 'weekly',
                internal_description: '[Graduated] - Consumption II',
                _title: '[Graduated] - Consumption II'
              },
              {
                _id: 'c9ac3f90-f4d2-482f-a1cd-9667d3e659b8',
                _tags: [],
                active: true,
                billing_duration_unit: 'months',
                description: 'With unit_price',
                is_tax_inclusive: true,
                long_description: 'with unit_price description ',
                notice_time_unit: 'months',
                price_display_in_journeys: 'show_price',
                pricing_model: 'per_unit',
                renewal_duration_unit: 'months',
                termination_time_unit: 'months',
                tiers: [],
                type: 'one_time',
                unit: 'kwh',
                unit_amount: 1234,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '12.34',
                variable_price: false,
                billing_period: 'weekly',
                internal_description: 'With unit_price',
                _title: 'With unit_price'
              },
              {
                _id: 'f021b371-ca04-4fda-b9b7-dc79538bc452',
                _tags: [],
                active: true,
                billing_duration_unit: 'months',
                description: 'with unit_price piece',
                is_tax_inclusive: true,
                long_description: 'price description with unit_price/piece',
                notice_time_unit: 'months',
                price_display_in_journeys: 'show_price',
                pricing_model: 'per_unit',
                renewal_duration_unit: 'months',
                termination_time_unit: 'months',
                tiers: [],
                type: 'one_time',
                unit_amount: 4321,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '43.21',
                variable_price: false,
                billing_period: 'weekly',
                internal_description: 'with unit_price piece',
                _title: 'with unit_price piece'
              }
            ],
            price_display_in_journeys: 'show_price',
            pricing_model: 'per_unit',
            renewal_duration_unit: 'months',
            termination_time_unit: 'months',
            tiers: [],
            type: 'one_time',
            unit_amount: 0,
            unit_amount_currency: 'EUR',
            unit_amount_decimal: '0',
            variable_price: false,
            billing_period: 'weekly',
            internal_description: '[Graduated] - Composite',
            _title: '[Graduated] - Composite'
          },
          _product: {
            _purpose: [],
            _tags: [],
            active: true,
            description: 'product description',
            feature: [],
            internal_name: '[Graduated] - Composite',
            name: '[Graduated] - Composite',
            price_options: [
              {
                _id: '4555625e-e408-476e-aa35-0392868ba372',
                _schema: 'price',
                _tags: [],
                active: true,
                billing_duration_unit: 'months',
                description: '[Graduated] - Composite',
                is_composite_price: true,
                is_tax_inclusive: true,
                long_description: 'price option description',
                notice_time_unit: 'months',
                price_components: [],
                price_display_in_journeys: 'show_price',
                pricing_model: 'per_unit',
                renewal_duration_unit: 'months',
                termination_time_unit: 'months',
                tiers: [],
                type: 'one_time',
                unit_amount: 0,
                unit_amount_currency: 'EUR',
                unit_amount_decimal: '0',
                variable_price: false,
                billing_period: 'weekly',
                internal_description: '[Graduated] - Composite',
                _org: '739224',
                _owners: [
                  {
                    org_id: '739224',
                    user_id: '11000933'
                  }
                ],
                _created_at: '2024-08-27T14:24:55.184Z',
                _updated_at: '2024-08-27T14:24:55.184Z',
                _title: '[Graduated] - Composite',
                _acl: {
                  view: ['org_739224'],
                  edit: ['org_739224'],
                  delete: ['org_739224']
                },
                $relation: {
                  entity_id: '4555625e-e408-476e-aa35-0392868ba372'
                }
              }
            ],
            type: 'product',
            order_number: 'PRODUCT-1079',
            _id: 'c9835627-2471-4a51-8344-bea082e7eab2',
            _title: '[Graduated] - Composite'
          },
          price_mappings: [
            {
              price_id: 'ecf5315a-2632-4c89-ad7d-3eca1a9592d1',
              value: 2
            }
          ],
          currency: 'EUR',
          amount_subtotal: 5575,
          amount_total: 5579,
          amount_tax: 4,
          total_details: {
            amount_tax: 4,
            breakdown: {
              taxes: [
                {
                  tax: {
                    _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                    type: 'VAT',
                    rate: '19'
                  },
                  amount: 4
                },
                {
                  tax: {
                    rate: 0
                  },
                  amount: 0
                }
              ],
              recurrences: [
                {
                  type: 'recurring',
                  billing_period: 'weekly',
                  unit_amount_gross: 12,
                  unit_amount_net: 10,
                  amount_subtotal: 20,
                  amount_total: 24,
                  amount_subtotal_decimal: '0.2',
                  amount_total_decimal: '0.238',
                  amount_tax: 4
                },
                {
                  type: 'one_time',
                  unit_amount_gross: 5555,
                  unit_amount_net: 5555,
                  amount_subtotal: 5555,
                  amount_total: 5555,
                  amount_subtotal_decimal: '55.55',
                  amount_total_decimal: '55.55',
                  amount_tax: 0
                }
              ],
              recurrencesByTax: [
                {
                  type: 'recurring',
                  billing_period: 'weekly',
                  amount_total: 24,
                  amount_subtotal: 20,
                  amount_tax: 4,
                  tax: {
                    tax: {
                      _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                      type: 'VAT',
                      rate: '19'
                    },
                    amount: 4
                  }
                },
                {
                  type: 'one_time',
                  amount_total: 5555,
                  amount_subtotal: 5555,
                  amount_tax: 0,
                  tax: {
                    tax: {
                      rate: 0
                    },
                    amount: 0
                  }
                }
              ],
              cashbacks: []
            }
          },
          amount_subtotal_decimal: '55.75',
          amount_total_decimal: '55.788'
        }
      ],
      amount_subtotal: 30575,
      amount_total: 30579,
      total_details: {
        amount_tax: 4,
        breakdown: {
          taxes: [
            {
              tax: {
                rate: 0
              },
              amount: 0
            },
            {
              tax: {
                _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                type: 'VAT',
                rate: '19'
              },
              amount: 4
            }
          ],
          recurrences: [
            {
              type: 'one_time',
              unit_amount_gross: 30555,
              unit_amount_net: 30555,
              amount_subtotal: 30555,
              amount_total: 30555,
              amount_subtotal_decimal: '305.55',
              amount_total_decimal: '305.55',
              amount_tax: 0
            },
            {
              type: 'recurring',
              billing_period: 'weekly',
              unit_amount_gross: 12,
              unit_amount_net: 10,
              amount_subtotal: 20,
              amount_total: 24,
              amount_subtotal_decimal: '0.2',
              amount_total_decimal: '0.238',
              amount_tax: 4
            }
          ],
          recurrencesByTax: [
            {
              type: 'one_time',
              amount_total: 30555,
              amount_subtotal: 30555,
              amount_tax: 0,
              tax: {
                tax: {
                  rate: 0
                },
                amount: 0
              }
            },
            {
              type: 'recurring',
              billing_period: 'weekly',
              amount_total: 24,
              amount_subtotal: 20,
              amount_tax: 4,
              tax: {
                tax: {
                  _id: '11bffddb-838b-48bc-b57f-c1e4b8dba459',
                  type: 'VAT',
                  rate: '19'
                },
                amount: 4
              }
            }
          ],
          cashbacks: []
        }
      },
      currency: 'EUR',
      amount_tax: 4,
      prices: {
        $relation: [
          {
            entity_id: '1b646fd2-31d2-44d8-8341-87cb1600862b',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '0c05a72e-ffa8-44b8-92cc-93f0f9a6113a',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '8a837980-8d1c-4e1c-a92b-00f0feb94929',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '4555625e-e408-476e-aa35-0392868ba372',
            _schema: 'price',
            _tags: []
          }
        ]
      },
      products: {
        $relation: [
          {
            entity_id: '4f6d6292-05d0-4dc5-82ac-93fe9dc329c6',
            _schema: 'product',
            _tags: []
          },
          {
            entity_id: '5e42fccd-8f49-4a36-8459-14918d30cf61',
            _schema: 'product',
            _tags: []
          },
          {
            entity_id: 'e0ffaab9-3dae-4853-8753-8bf117c6d545',
            _schema: 'product',
            _tags: []
          },
          {
            entity_id: 'c9835627-2471-4a51-8344-bea082e7eab2',
            _schema: 'product',
            _tags: []
          }
        ]
      },
      journey_actions: [
        {
          journey_id: '4d596c30-e3b0-11ef-8191-3534fb26b0f6',
          action_label: {
            en: 'contract meter',
            de: 'contract meter'
          },
          slug: 'contract'
        },
        {
          journey_id: 'cac19350-b2a0-11ef-849e-ed9de792d714',
          action_label: {
            en: 'General Request',
            de: 'Allgemeine Anfrage'
          },
          slug: 'contract',
          rules: []
        }
      ]
    }
  ]
}
