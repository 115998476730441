export const MOCK_CONSUMPTIONS: {
  consumptions: { timestamp: string; value: number }[]
} = {
  consumptions: [
    {
      timestamp: '2025-03-04T22:00:00.000Z',
      value: 0.09
    },
    {
      timestamp: '2025-03-04T23:00:00.000Z',
      value: 0.04
    },
    {
      timestamp: '2025-03-05T00:00:00.000Z',
      value: 0.05
    },
    {
      timestamp: '2025-03-05T01:00:00.000Z',
      value: 0.06
    },
    {
      timestamp: '2025-03-05T02:00:00.000Z',
      value: 0.06
    },
    {
      timestamp: '2025-03-05T03:00:00.000Z',
      value: 0.07
    },
    {
      timestamp: '2025-03-05T04:00:00.000Z',
      value: 0.07
    },
    {
      timestamp: '2025-03-05T05:00:00.000Z',
      value: 0.14
    },
    {
      timestamp: '2025-03-05T06:00:00.000Z',
      value: 0.16
    },
    {
      timestamp: '2025-03-05T07:00:00.000Z',
      value: 0.18
    },
    {
      timestamp: '2025-03-05T08:00:00.000Z',
      value: 0.19
    },
    {
      timestamp: '2025-03-05T09:00:00.000Z',
      value: 0.2
    },
    {
      timestamp: '2025-03-05T10:00:00.000Z',
      value: 0.22
    },
    {
      timestamp: '2025-03-05T11:00:00.000Z',
      value: 0.14
    },
    {
      timestamp: '2025-03-05T12:00:00.000Z',
      value: 0.15
    },
    {
      timestamp: '2025-03-05T13:00:00.000Z',
      value: 0.16
    },
    {
      timestamp: '2025-03-05T14:00:00.000Z',
      value: 0.17
    },
    {
      timestamp: '2025-03-05T15:00:00.000Z',
      value: 0.18
    },
    {
      timestamp: '2025-03-05T16:00:00.000Z',
      value: 0.4
    },
    {
      timestamp: '2025-03-05T17:00:00.000Z',
      value: 0.42
    },
    {
      timestamp: '2025-03-05T18:00:00.000Z',
      value: 0.44
    },
    {
      timestamp: '2025-03-05T19:00:00.000Z',
      value: 0.46
    },
    {
      timestamp: '2025-03-05T20:00:00.000Z',
      value: 0.48
    },
    {
      timestamp: '2025-03-05T21:00:00.000Z',
      value: 0.27
    }
  ]
}
