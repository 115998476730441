import { ConsentStatus, Contact } from '../../context/ContactContext'
import { Locale } from '../../utils/LanguageUtils'
import { RepeatablePaymentOptionType } from '../../utils/PaymentUtils'

export const MOCK_CONTACT_DETAILS: { entity: Contact } = {
  entity: {
    last_name: 'Doe',
    _created_at: '2022-09-01T09:36:00.000Z',
    _id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
    _title: 'John test Doe',
    _org: '739224',
    _schema: 'contact',
    salutation: 'Mr.',
    first_name: 'John test',
    birthdate: '2022-09-01T09:36:00.000Z',
    email: [
      {
        _tags: [],
        email: 'john.doe@email.com',
        id: 'ufiror5'
      }
    ],
    payment: [
      {
        _tags: [],
        type: RepeatablePaymentOptionType?.INVOICE,
        id: 'e8yhazp'
      },
      {
        _tags: [],
        type: RepeatablePaymentOptionType?.SEPA,
        data: {
          bank_name: 'Landesbank Berlin - Berliner Sparkasse',
          iban: '',
          bic_number: 'BELADEBEXXX',
          fullname: 'John Doe'
        },
        id: 'ybpa4iz'
      }
    ],
    consent_email_marketing: {
      status: 'pending' as ConsentStatus,
      identifiers: [
        {
          identifier: 'john.doe@email.com',
          status: 'declined' as ConsentStatus,
          events: [
            {
              type: 'OPT_OUT',
              identifier: 'john.doe@email.com',
              topic: 'EMAIL_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                  token_use: 'id',
                  auth_time: 1667409905,
                  exp: 1667467782,
                  iat: 1667464182,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.83.214',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-11-03T09:04:55.035Z'
            },
            {
              type: 'OPT_IN',
              identifier: 'john.doe@email.com',
              topic: 'EMAIL_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                  token_use: 'id',
                  auth_time: 1667409905,
                  exp: 1667467782,
                  iat: 1667464182,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.83.214',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-11-03T09:03:40.067Z'
            },
            {
              type: 'OPT_OUT',
              identifier: 'john.doe@email.com',
              topic: 'EMAIL_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                  token_use: 'id',
                  auth_time: 1664957438,
                  exp: 1664976775,
                  iat: 1664973175,
                  email: 'john.doe@email.com'
                },
                ip_address: '217.110.198.75',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-05T13:07:28.666Z'
            },
            {
              type: 'OPT_IN',
              identifier: 'john.doe@email.com',
              topic: 'EMAIL_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                  token_use: 'id',
                  auth_time: 1664957438,
                  exp: 1664976775,
                  iat: 1664973175,
                  email: 'john.doe@email.com'
                },
                ip_address: '217.110.198.75',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-05T13:03:22.884Z'
            },
            {
              type: 'OPT_OUT',
              identifier: 'john.doe@email.com',
              topic: 'EMAIL_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                  token_use: 'id',
                  auth_time: 1664957438,
                  exp: 1664976775,
                  iat: 1664973175,
                  email: 'john.doe@email.com'
                },
                ip_address: '217.110.198.75',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-05T13:02:55.970Z'
            },
            {
              type: 'OPT_IN',
              identifier: 'john.doe@email.com',
              topic: 'EMAIL_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                  token_use: 'id',
                  auth_time: 1664957438,
                  exp: 1664976775,
                  iat: 1664973175,
                  email: 'john.doe@email.com'
                },
                ip_address: '217.110.198.75',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-05T13:01:44.621Z'
            },
            {
              type: 'OPT_OUT',
              identifier: 'john.doe@email.com',
              topic: 'EMAIL_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                  token_use: 'id',
                  auth_time: 1663843771,
                  exp: 1664880494,
                  iat: 1664876894,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.56.132',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-04T10:35:35.502Z'
            },
            {
              type: 'OPT_IN',
              identifier: 'john.doe@email.com',
              topic: 'EMAIL_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                  token_use: 'id',
                  auth_time: 1663843771,
                  exp: 1664880494,
                  iat: 1664876894,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.56.132',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-04T10:35:24.980Z'
            },
            {
              type: 'OPT_OUT',
              identifier: 'john.doe@email.com',
              topic: 'EMAIL_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                  token_use: 'id',
                  auth_time: 1663843771,
                  exp: 1664880494,
                  iat: 1664876894,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.56.132',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-04T10:30:51.821Z'
            },
            {
              type: 'OPT_IN',
              identifier: 'john.doe@email.com',
              topic: 'EMAIL_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                  token_use: 'id',
                  auth_time: 1663843771,
                  exp: 1664880494,
                  iat: 1664876894,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.56.132',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-04T10:30:28.760Z'
            }
          ]
        }
      ]
    },
    consent_sms_marketing: {
      status: 'unknown' as ConsentStatus,
      identifiers: []
    },
    consent_phone_call: {
      status: 'unknown' as ConsentStatus,
      identifiers: []
    },
    consent_print_marketing: {
      status: 'pending' as ConsentStatus,
      identifiers: [
        {
          identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          status: 'declined' as ConsentStatus,
          events: [
            {
              type: 'OPT_OUT',
              identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              topic: 'PRINT_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                  token_use: 'id',
                  auth_time: 1667409905,
                  exp: 1667467782,
                  iat: 1667464182,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.83.214',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-11-03T09:17:38.821Z'
            },
            {
              type: 'OPT_IN',
              identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              topic: 'PRINT_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                  token_use: 'id',
                  auth_time: 1667409905,
                  exp: 1667467782,
                  iat: 1667464182,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.83.214',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-11-03T09:04:58.952Z'
            },
            {
              type: 'OPT_OUT',
              identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              topic: 'PRINT_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                  token_use: 'id',
                  auth_time: 1667409905,
                  exp: 1667467782,
                  iat: 1667464182,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.83.214',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-11-03T09:04:31.461Z'
            },
            {
              type: 'OPT_IN',
              identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              topic: 'PRINT_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                  token_use: 'id',
                  auth_time: 1667409905,
                  exp: 1667467782,
                  iat: 1667464182,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.83.214',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-11-03T09:03:24.890Z'
            },
            {
              type: 'OPT_OUT',
              identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              topic: 'PRINT_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                  token_use: 'id',
                  auth_time: 1667409905,
                  exp: 1667467782,
                  iat: 1667464182,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.83.214',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-11-03T09:03:09.922Z'
            },
            {
              type: 'OPT_OUT',
              identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              topic: 'PRINT_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                  token_use: 'id',
                  auth_time: 1667409905,
                  exp: 1667467782,
                  iat: 1667464182,
                  email: 'john.doe@email.com'
                },
                ip_address: '2.211.83.214',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-11-03T09:02:56.532Z'
            },
            {
              type: 'OPT_IN',
              identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              topic: 'PRINT_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                  token_use: 'id',
                  auth_time: 1664957438,
                  exp: 1664976775,
                  iat: 1664973175,
                  email: 'john.doe@email.com'
                },
                ip_address: '217.110.198.75',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-05T13:03:22.858Z'
            },
            {
              type: 'OPT_OUT',
              identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              topic: 'PRINT_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                  token_use: 'id',
                  auth_time: 1664957438,
                  exp: 1664976775,
                  iat: 1664973175,
                  email: 'john.doe@email.com'
                },
                ip_address: '217.110.198.75',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-05T13:03:10.372Z'
            },
            {
              type: 'OPT_IN',
              identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              topic: 'PRINT_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                  token_use: 'id',
                  auth_time: 1664957438,
                  exp: 1664976775,
                  iat: 1664973175,
                  email: 'john.doe@email.com'
                },
                ip_address: '217.110.198.75',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-05T13:02:45.003Z'
            },
            {
              type: 'OPT_OUT',
              identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
              topic: 'PRINT_MARKETING',
              meta: {
                user: {
                  username: 'john.doe@email.com',
                  attributes: {
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    'custom:ivy_org_id': '739224',
                    'custom:ivy_user_id': '10011645',
                    email: 'john.doe@email.com'
                  },
                  email: 'john.doe@email.com',
                  user_id: '10011645',
                  org_id: '739224'
                },
                auth_api: {
                  ivyOrganization: '739224',
                  ivyOrganizationId: '739224',
                  ivyUser: '10011645',
                  ivyUserId: '10011645',
                  organizationId: '739224',
                  roles: '',
                  userId: '10011645',
                  userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                  userPoolId: 'eu-central-1_4hwUyRZEB',
                  username: 'john.doe@email.com',
                  sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                  email_verified: true,
                  iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                  'custom:ivy_org_id': '739224',
                  'cognito:username': 'john.doe@email.com',
                  'custom:ivy_user_id': '10011645',
                  aud: '47pr7st7l4umbmpfbijv67mhua',
                  event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                  token_use: 'id',
                  auth_time: 1664957438,
                  exp: 1664976775,
                  iat: 1664973175,
                  email: 'john.doe@email.com'
                },
                ip_address: '217.110.198.75',
                user_agent:
                  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
              },
              organization_id: '739224',
              source: 'http://localhost:9000',
              created_at: '2022-10-05T13:01:44.506Z'
            }
          ]
        }
      ]
    },
    address: [
      {
        _id: '5fp75ot',
        _tags: [],
        street: 'Haupstarsse',
        street_number: '12',
        postal_code: '50670',
        city: 'Berlin',
        country: 'DE',
        additional_info: '',
        id: '5fp75ot'
      },
      {
        _id: 'ri0yliz',
        _tags: [],
        country: 'BR',
        city: 'Rio',
        postal_code: '66890',
        street: 'MullerStrasse',
        street_number: '90',
        additional_info: 'Abrigado!',
        id: 'ri0yliz'
      }
    ],
    phone: null,
    language: Locale?.EN,
    files: [],
    _updated_at: '2023-04-17T09:42:00.088Z'
  }
}
