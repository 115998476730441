type PricingComponents = {
  auction_price_amount: number
  auction_price_amount_decimal: string
  grid_fee_amount: number
  grid_fee_amount_decimal: string
  margin_amount: number
  margin_amount_decimal: string
  source_fee_amount: number
  source_fee_amount_decimal: string
  taxes_levies_amount: number
  taxes_levies_amount_decimal: string
}

type PricingDetails = {
  timestamp: string // ISO 8601 formatted date string
  unit_amount: number // Raw unit amount
  unit_amount_decimal: string // String representation with precise decimals
  tax_behavior: 'exclusive' | 'inclusive' // Enum for tax behavior
  tax_rate: number // Percentage value of tax rate
  components: PricingComponents // Nested object for price breakdown
}

export const MOCK_PRICES: { prices: PricingDetails[] } = {
  prices: [
    {
      timestamp: '2025-03-04T22:00:00.000+00:00',
      unit_amount: 29.99,
      unit_amount_decimal: '0.30',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 10.7,
        auction_price_amount_decimal: '0.11',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-04T23:00:00.000+00:00',
      unit_amount: 28.29,
      unit_amount_decimal: '0.28',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 9,
        auction_price_amount_decimal: '0.09',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T00:00:00.000+00:00',
      unit_amount: 27.59,
      unit_amount_decimal: '0.28',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 8.3,
        auction_price_amount_decimal: '0.08',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T01:00:00.000+00:00',
      unit_amount: 27.59,
      unit_amount_decimal: '0.28',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 8.3,
        auction_price_amount_decimal: '0.08',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T02:00:00.000+00:00',
      unit_amount: 27.29,
      unit_amount_decimal: '0.27',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 8,
        auction_price_amount_decimal: '0.08',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T03:00:00.000+00:00',
      unit_amount: 27.49,
      unit_amount_decimal: '0.27',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 8.2,
        auction_price_amount_decimal: '0.08',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T04:00:00.000+00:00',
      unit_amount: 28.49,
      unit_amount_decimal: '0.28',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 9.2,
        auction_price_amount_decimal: '0.09',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T05:00:00.000+00:00',
      unit_amount: 30.89,
      unit_amount_decimal: '0.31',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 11.6,
        auction_price_amount_decimal: '0.12',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T06:00:00.000+00:00',
      unit_amount: 33.29,
      unit_amount_decimal: '0.33',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 14,
        auction_price_amount_decimal: '0.14',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T07:00:00.000+00:00',
      unit_amount: 27.89,
      unit_amount_decimal: '0.28',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 8.6,
        auction_price_amount_decimal: '0.09',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T08:00:00.000+00:00',
      unit_amount: 23.49,
      unit_amount_decimal: '0.23',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 4.2,
        auction_price_amount_decimal: '0.04',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T09:00:00.000+00:00',
      unit_amount: 19.29,
      unit_amount_decimal: '0.19',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 0,
        auction_price_amount_decimal: '0.00',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T10:00:00.000+00:00',
      unit_amount: 19.29,
      unit_amount_decimal: '0.19',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 0,
        auction_price_amount_decimal: '0.00',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T11:00:00.000+00:00',
      unit_amount: 18.89,
      unit_amount_decimal: '0.19',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: -0.4,
        auction_price_amount_decimal: '-0.00',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T12:00:00.000+00:00',
      unit_amount: 18.89,
      unit_amount_decimal: '0.19',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: -0.4,
        auction_price_amount_decimal: '-0.00',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T13:00:00.000+00:00',
      unit_amount: 19.29,
      unit_amount_decimal: '0.19',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 0,
        auction_price_amount_decimal: '0.00',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T14:00:00.000+00:00',
      unit_amount: 24.19,
      unit_amount_decimal: '0.24',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 4.9,
        auction_price_amount_decimal: '0.05',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T15:00:00.000+00:00',
      unit_amount: 28.29,
      unit_amount_decimal: '0.28',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 9,
        auction_price_amount_decimal: '0.09',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T16:00:00.000+00:00',
      unit_amount: 32.59,
      unit_amount_decimal: '0.33',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 13.3,
        auction_price_amount_decimal: '0.13',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T17:00:00.000+00:00',
      unit_amount: 33.89,
      unit_amount_decimal: '0.34',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 14.6,
        auction_price_amount_decimal: '0.15',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T18:00:00.000+00:00',
      unit_amount: 33.89,
      unit_amount_decimal: '0.34',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 14.6,
        auction_price_amount_decimal: '0.15',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T19:00:00.000+00:00',
      unit_amount: 31.59,
      unit_amount_decimal: '0.32',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 12.3,
        auction_price_amount_decimal: '0.12',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T20:00:00.000+00:00',
      unit_amount: 30.09,
      unit_amount_decimal: '0.30',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 10.8,
        auction_price_amount_decimal: '0.11',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    },
    {
      timestamp: '2025-03-05T21:00:00.000+00:00',
      unit_amount: 30.19,
      unit_amount_decimal: '0.30',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      components: {
        auction_price_amount: 10.9,
        auction_price_amount_decimal: '0.11',
        grid_fee_amount: 10.43,
        grid_fee_amount_decimal: '0.10',
        margin_amount: 2.57,
        margin_amount_decimal: '0.03',
        source_fee_amount: 0,
        source_fee_amount_decimal: '0.00',
        taxes_levies_amount: 6.291,
        taxes_levies_amount_decimal: '0.06'
      }
    }
  ]
}
