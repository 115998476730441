import { PortalUser } from '@epilot/customer-portal-client'

export const MOCK_PORTAL_USER: { data: PortalUser } = {
  data: {
    email: [
      {
        _tags: ['primary'],
        email: 'z.solyom@epilot.cloud',
        _id: 'oiH-TkOyl6SMYEIW1m-vF'
      }
    ],
    registration_status: 'Registered',
    password: null,
    origin: 'END_CUSTOMER_PORTAL',
    access_status: true,
    mapping_status: 'Mapped',
    _schema: 'portal_user',
    _id: '7ca2d9ca-e52d-44a4-b462-745e8af4c0fb',
    _org: '739224',
    _created_at: '2023-04-14T13:57:38.285Z',
    _title: 'z.solyom@epilot.cloud',
    mapped_contact: {
      $relation: [
        {
          entity_id: '754dce41-8c6f-48e0-801b-673abf56f83e',
          _tags: []
        }
      ]
    },
    _updated_at: '2024-07-15T10:24:29.510Z',
    testing: 'second'
  }
}
