type PricingDetails = {
  timestamp: string // ISO 8601 formatted date string
  tax_behavior: 'exclusive' | 'inclusive' // Enum for tax behavior
  tax_rate: number // Percentage value of tax rate
  unit_amount: number // Raw unit amount
  unit_amount_currency: string // Currency code (ISO 4217)
  unit_amount_decimal: string // String representation with precise decimals
}

export const MOCK_COSTS: { costs: PricingDetails[] } = {
  costs: [
    {
      timestamp: '2025-03-04T22:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 2.6990999999999996,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '2.70'
    },
    {
      timestamp: '2025-03-04T23:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 1.1316,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '1.13'
    },
    {
      timestamp: '2025-03-05T00:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 1.3795000000000002,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '1.38'
    },
    {
      timestamp: '2025-03-05T01:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 1.6554,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '1.66'
    },
    {
      timestamp: '2025-03-05T02:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 1.6374,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '1.64'
    },
    {
      timestamp: '2025-03-05T03:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 1.9243000000000001,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '1.92'
    },
    {
      timestamp: '2025-03-05T04:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 1.9943000000000002,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '1.99'
    },
    {
      timestamp: '2025-03-05T05:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 4.3246,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '4.32'
    },
    {
      timestamp: '2025-03-05T06:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 5.3264,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '5.33'
    },
    {
      timestamp: '2025-03-05T07:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 5.0202,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '5.02'
    },
    {
      timestamp: '2025-03-05T08:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 4.4631,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '4.46'
    },
    {
      timestamp: '2025-03-05T09:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 3.858,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '3.86'
    },
    {
      timestamp: '2025-03-05T10:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 4.2438,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '4.24'
    },
    {
      timestamp: '2025-03-05T11:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 2.6446000000000005,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '2.64'
    },
    {
      timestamp: '2025-03-05T12:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 2.8335,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '2.83'
    },
    {
      timestamp: '2025-03-05T13:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 3.0864,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '3.09'
    },
    {
      timestamp: '2025-03-05T14:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 4.1123,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '4.11'
    },
    {
      timestamp: '2025-03-05T15:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 5.0922,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '5.09'
    },
    {
      timestamp: '2025-03-05T16:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 13.036000000000001,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '13.04'
    },
    {
      timestamp: '2025-03-05T17:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 14.2338,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '14.23'
    },
    {
      timestamp: '2025-03-05T18:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 14.9116,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '14.91'
    },
    {
      timestamp: '2025-03-05T19:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 14.531400000000001,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '14.53'
    },
    {
      timestamp: '2025-03-05T20:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 14.4432,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '14.44'
    },
    {
      timestamp: '2025-03-05T21:00:00.000+00:00',
      tax_behavior: 'exclusive',
      tax_rate: 19,
      unit_amount: 8.1513,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '8.15'
    }
  ]
}
