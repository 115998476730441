import { MeterCounter } from '@epilot/metering-client'

export const MOCK_METER_COUNTER_DETAILS: MeterCounter[] = [
  {
    obis_number: '7SLB5500095093',
    direction: 'feed-out',
    tariff_type: 'nt',
    unit: 'kWh',
    _tags: [],
    _schema: 'meter_counter',
    _id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
    _org: '739224',
    _created_at: '2023-11-28T10:49:42.914Z',
    _updated_at: '2024-09-27T13:10:45.658Z',
    _title: 'Zählwerk'
  }
]
export const MOCK_METER_COUNTER_DETAIL: { data: MeterCounter } = {
  data: {
    obis_number: '7SLB5500095093',
    direction: 'feed-out',
    tariff_type: 'nt',
    unit: 'kWh',
    _tags: [],
    _schema: 'meter_counter',
    _id: '1eb32c20-9747-4f01-88ca-0a5c2f0b49c2',
    _org: '739224',
    _created_at: '2023-11-28T10:49:42.914Z',
    _updated_at: '2024-09-27T13:10:45.658Z',
    _title: 'Zählwerk'
  }
}
