import { getCustomerApiClient } from 'api/CustomerApiClient'
import { getDummyApi, isInDummyMode } from 'dummy/static'
import CommonUtils from 'utils/CommonUtils'

const bootstrap = async () => {
  if (window.bootstrapped) return

  const isCanary = !!window.localStorage.getItem('isCanary')

  if (isCanary || import.meta.env.DEV) {
    console.log('[!] Using Canary')

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line import/no-unresolved
    import('/src/index.tsx')
  } else {
    console.log('[-] Using Stable')

    const stablePath = '/stable/chunk-index.js'

    // eslint-disable-next-line no-unsanitized/method
    import(/* @vite-ignore */ stablePath)
  }

  const portalConfig = await getCustomerApiClient()
    .getPortalConfigByDomain(CommonUtils.getEcpDomain())
    .then((res) => res.data)

  const isDummy = isInDummyMode(portalConfig)

  if (isDummy) {
    const Dummy = await getDummyApi()

    await Dummy.getConfig(portalConfig)
  }

  const isCanaryEnabledInSettings =
    portalConfig?.org_settings?.['canary']?.enabled

  if (!isCanary && isCanaryEnabledInSettings) {
    console.log('[>] Switching to Canary')

    window.localStorage.setItem('isCanary', 'true')
    window.location.reload()

    return
  } else if (isCanary && !isCanaryEnabledInSettings) {
    console.log('[>] Switching to Stable')

    window.localStorage.removeItem('isCanary')

    window.location.reload()

    return
  }

  window.bootstrapped = true

  // Respect no search engine indexing
  if (portalConfig.prevent_search_engine_indexing) {
    let metaRobots = document.head.querySelector('meta[name="robots"]')

    if (!metaRobots) {
      metaRobots = document.createElement('meta')
      metaRobots.setAttribute('name', 'robots')
      document.head.appendChild(metaRobots)
    }

    metaRobots.setAttribute('content', 'noindex, nofollow')
  }
}

export default bootstrap
