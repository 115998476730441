import { createContext, ReactNode, useContext, useMemo } from 'react'

import { EntitySchemaItem } from 'api/ApiTypes'
import { getCustomerApiClient } from 'api/CustomerApiClient'
import { CUSTOMER_QUERY_IDS } from 'api/Queries'
import { useQueryWithMock } from 'dummy/static'

import { EntityType } from '../utils/EntityUtils'

import AuthContext from './AuthContext'
interface SchemasContextState {
  isLoading: boolean
  schemas: EntitySchemaItem[]
}

const defaultState: SchemasContextState = {
  isLoading: true,
  schemas: []
}

const SchemasContext = createContext<SchemasContextState>(defaultState)

interface SchemasContextProps {
  children: ReactNode
}

const SchemasContextProvider = (props: SchemasContextProps) => {
  const { data: authData } = useContext(AuthContext)

  const schemasQuery: any = useQueryWithMock({
    queryKey: [CUSTOMER_QUERY_IDS.GET_SCHEMAS],
    queryFn: () => getCustomerApiClient().getSchemas(),
    enabled: Boolean(authData.user)
  })

  const schemas = useMemo(() => {
    return schemasQuery?.data?.schemas || []
  }, [schemasQuery?.data?.schemas])

  return (
    <SchemasContext.Provider
      value={{ schemas, isLoading: schemasQuery.isLoading }}
    >
      {props.children}
    </SchemasContext.Provider>
  )
}

const useContextSchema = (schemaSlug: EntityType) => {
  const { schemas, isLoading } = useContext(SchemasContext)

  const schema = schemas.find(
    (schema: EntitySchemaItem) => schema.slug === schemaSlug
  )

  return { schema, isLoading }
}

export { SchemasContextProvider, useContextSchema }
export default SchemasContext
