import { OrderDetails } from '../../utils/OrderUtils'

export const MOCK_ORDER_DETAILS: OrderDetails = {
  journey_actions: [],
  entity: {
    billing_address: [
      {
        street1: 'Wallstreet',
        street2: 1,
        city: 'New York city',
        country: 'USA',
        country_code: 'US',
        additional_info: 'Headquarters office'
      }
    ],
    delivery_address: [
      {
        street1: 'Berverly hills avenue',
        street2: 'block 1',
        city: 'Berverly hills',
        country: 'California',
        country_code: 'CA',
        additional_info: 'Remote co-work place'
      }
    ],
    line_items: [
      {
        quantity: 3,
        product_id: 'b7185fb7-b10f-4875-bda7-288631446555',
        price_id: '67ea3c64-3193-4650-b194-322047d65e80',
        currency: 'EUR',
        _price: {
          _id: '67ea3c64-3193-4650-b194-322047d65e80',
          unit_amount: 12055,
          type: 'recurring',
          billing_period: 'monthly',
          billing_duration_amount: '1',
          billing_duration_unit: 'years',
          notice_time_amount: '1',
          notice_time_unit: 'months',
          termination_time_amount: '2',
          termination_time_unit: 'weeks',
          renewal_duration_amount: '1',
          renewal_duration_unit: 'years',
          active: true,
          sales_tax: 'reduced',
          tax_behavior: 'exclusive',
          description: 'Winter Lease',
          billing_scheme: 'Per Unit',
          tiers_mode: 'Standard',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-11-10T18:03:49.503Z',
          _updated_at: '2021-12-14T16:58:06.070Z',
          _title: 'price 67ea3c64-3193-4650-b194-322047d65e80',
          unit_amount_decimal: '120.55456634',
          unit_amount_currency: 'EUR'
        },
        _product: {
          _id: 'b7185fb7-b10f-4875-bda7-288631446555',
          code: '904460583',
          type: 'product',
          name: 'Alfen Eve Single S-line Wallbox',
          _title: 'Alfen Eve Single S-line Wallbox'
        },
        description: 'Winter Lease',
        unit_amount: 12055,
        unit_amount_net: 12055,
        amount_subtotal: 36166,
        amount_total: 38698,
        taxes: [
          {
            rate: 'reduced',
            rateValue: 7,
            amount: 2532
          }
        ]
      },
      {
        quantity: 2,
        product_id: '607d2952-8f3e-484f-a82b-4880528b7f55',
        price_id: '7b028fdf-0b0a-4077-a8f1-d0cbbd14b7cf',
        currency: 'EUR',
        _price: {
          unit_amount: 0,
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '0.00',
          sales_tax: 'standard',
          tax_behavior: 'inclusive',
          active: true,
          price_display_in_journeys: 'show_price',
          type: 'one_time',
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_composite_price: true,
          _tags: ['composite'],
          _schema: 'price',
          _title: 'Composite',
          price_components: [
            {
              _id: 'bc4193b0-f8f8-4203-ad2a-d5b4fd25ef08',
              unit_amount: 50000,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '500',
              sales_tax: 'standard',
              tax_behavior: 'inclusive',
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'one_time',
              _schema: 'price',
              _title: 'Equipment fee',
              description: 'Equipment fee',
              _org: '739224',
              _created_at: '2022-08-10T10:18:15.342Z',
              billing_period: 'monthly',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              _updated_at: '2022-08-24T16:25:58.433Z',
              $relation: {
                entity_id: 'bc4193b0-f8f8-4203-ad2a-d5b4fd25ef08',
                _schema: 'price',
                _tags: []
              }
            },
            {
              _id: '3c794cf8-c52a-42bf-a3de-88d4501a6bc0',
              unit_amount: 9000,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '90',
              sales_tax: 'standard',
              tax_behavior: 'inclusive',
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'recurring',
              billing_period: 'monthly',
              _schema: 'price',
              _title: 'Basic Services',
              description: 'Basic Services',
              _org: '739224',
              _created_at: '2022-08-17T14:08:00.793Z',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              _updated_at: '2022-08-24T16:28:16.106Z',
              $relation: {
                entity_id: '3c794cf8-c52a-42bf-a3de-88d4501a6bc0',
                _schema: 'price',
                _tags: []
              }
            },
            {
              _id: 'b4aa272e-3f5d-4b74-a2c1-f5803e56fd2a',
              unit_amount: 3,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0.03',
              sales_tax: 'standard',
              tax_behavior: 'inclusive',
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'recurring',
              billing_period: 'monthly',
              _schema: 'price',
              _title: 'Ground price',
              description: 'Ground price',
              unit: 'm',
              variable_price: true,
              _org: '739224',
              _created_at: '2022-08-17T10:35:05.538Z',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              _updated_at: '2022-09-09T11:55:33.606Z',
              $relation: {
                entity_id: 'b4aa272e-3f5d-4b74-a2c1-f5803e56fd2a',
                _schema: 'price',
                _tags: []
              }
            },
            {
              _id: '11f84011-6b4c-421d-9025-2b66dacade71',
              unit_amount: 2000,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '20',
              sales_tax: 'standard',
              tax_behavior: 'inclusive',
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'recurring',
              billing_period: 'weekly',
              _schema: 'price',
              _title: 'Premium Support',
              description: 'Premium Support',
              _org: '739224',
              _created_at: '2022-08-24T16:00:01.575Z',
              _updated_at: '2022-08-24T16:00:01.575Z',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              $relation: {
                entity_id: '11f84011-6b4c-421d-9025-2b66dacade71',
                _schema: 'price',
                _tags: []
              }
            },
            {
              _id: '2edc1d43-73e2-45a2-aa72-e3a1dff23619',
              unit_amount: 10,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0.10',
              sales_tax: 'standard',
              tax_behavior: 'inclusive',
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'recurring',
              _schema: 'price',
              _title: 'GROUND PRICE 2',
              description: 'GROUND PRICE 2',
              variable_price: true,
              unit: 'kwh',
              _org: '739224',
              _created_at: '2022-08-31T10:48:53.246Z',
              billing_period: 'yearly',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              _updated_at: '2022-09-15T11:57:25.430Z',
              $relation: {
                entity_id: '2edc1d43-73e2-45a2-aa72-e3a1dff23619',
                _schema: 'price',
                _tags: []
              }
            }
          ],
          _id: '7b028fdf-0b0a-4077-a8f1-d0cbbd14b7cf',
          _org: '739224',
          _created_at: '2022-08-10T10:12:38.072Z',
          _updated_at: '2022-09-15T11:57:27.754Z',
          description: 'Composite'
        },
        _product: {
          _id: '607d2952-8f3e-484f-a82b-4880528b7f55',
          type: 'product',
          name: 'Simple Product',
          _title: 'Simple Product'
        },
        description: 'Composite',
        item_components: [
          {
            quantity: 1,
            type: 'one_time',
            price_id: 'bc4193b0-f8f8-4203-ad2a-d5b4fd25ef08',
            product_id: '607d2952-8f3e-484f-a82b-4880528b7f55',
            _price: {
              _id: 'bc4193b0-f8f8-4203-ad2a-d5b4fd25ef08',
              unit_amount: 50000,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '500',
              sales_tax: 'standard',
              tax_behavior: 'inclusive',
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'one_time',
              _schema: 'price',
              _title: 'Equipment fee',
              description: 'Equipment fee',
              _org: '739224',
              _created_at: '2022-08-10T10:18:15.342Z',
              billing_period: 'monthly',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              _updated_at: '2022-08-24T16:25:58.433Z',
              $relation: {
                entity_id: 'bc4193b0-f8f8-4203-ad2a-d5b4fd25ef08',
                _schema: 'price',
                _tags: []
              }
            },
            _product: {
              _id: '607d2952-8f3e-484f-a82b-4880528b7f55',
              type: 'product',
              name: 'Simple Product',
              _title: 'Simple Product'
            },
            taxes: [
              {
                rate: 'standard',
                rateValue: 19,
                amount: 7983
              }
            ],
            currency: 'EUR',
            description: 'Equipment fee',
            unit_amount: 50000,
            unit_amount_net: 42017,
            amount_subtotal: 42017,
            amount_total: 50000
          },
          {
            quantity: 1,
            type: 'recurring',
            price_id: '3c794cf8-c52a-42bf-a3de-88d4501a6bc0',
            product_id: '607d2952-8f3e-484f-a82b-4880528b7f55',
            _price: {
              _id: '3c794cf8-c52a-42bf-a3de-88d4501a6bc0',
              unit_amount: 9000,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '90',
              sales_tax: 'standard',
              tax_behavior: 'inclusive',
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'recurring',
              billing_period: 'monthly',
              _schema: 'price',
              _title: 'Basic Services',
              description: 'Basic Services',
              _org: '739224',
              _created_at: '2022-08-17T14:08:00.793Z',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              _updated_at: '2022-08-24T16:28:16.106Z',
              $relation: {
                entity_id: '3c794cf8-c52a-42bf-a3de-88d4501a6bc0',
                _schema: 'price',
                _tags: []
              }
            },
            _product: {
              _id: '607d2952-8f3e-484f-a82b-4880528b7f55',
              type: 'product',
              name: 'Simple Product',
              _title: 'Simple Product'
            },
            taxes: [
              {
                rate: 'standard',
                rateValue: 19,
                amount: 1437
              }
            ],
            currency: 'EUR',
            description: 'Basic Services',
            unit_amount: 9000,
            unit_amount_net: 7563,
            amount_subtotal: 7563,
            amount_total: 9000
          },
          {
            quantity: 1,
            type: 'recurring',
            price_id: 'b4aa272e-3f5d-4b74-a2c1-f5803e56fd2a',
            product_id: '607d2952-8f3e-484f-a82b-4880528b7f55',
            _price: {
              _id: 'b4aa272e-3f5d-4b74-a2c1-f5803e56fd2a',
              unit_amount: 3,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0.03',
              sales_tax: 'standard',
              tax_behavior: 'inclusive',
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'recurring',
              billing_period: 'monthly',
              _schema: 'price',
              _title: 'Ground price',
              description: 'Ground price',
              unit: 'm',
              variable_price: true,
              _org: '739224',
              _created_at: '2022-08-17T10:35:05.538Z',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              _updated_at: '2022-09-09T11:55:33.606Z',
              $relation: {
                entity_id: 'b4aa272e-3f5d-4b74-a2c1-f5803e56fd2a',
                _schema: 'price',
                _tags: []
              }
            },
            _product: {
              _id: '607d2952-8f3e-484f-a82b-4880528b7f55',
              type: 'product',
              name: 'Simple Product',
              _title: 'Simple Product'
            },
            taxes: [
              {
                rate: 'standard',
                rateValue: 19,
                amount: 0
              }
            ],
            currency: 'EUR',
            description: 'Ground price',
            unit_amount: 3,
            unit_amount_net: 3,
            amount_subtotal: 3,
            amount_total: 3
          },
          {
            quantity: 1,
            type: 'recurring',
            price_id: '11f84011-6b4c-421d-9025-2b66dacade71',
            product_id: '607d2952-8f3e-484f-a82b-4880528b7f55',
            _price: {
              _id: '11f84011-6b4c-421d-9025-2b66dacade71',
              unit_amount: 2000,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '20',
              sales_tax: 'standard',
              tax_behavior: 'inclusive',
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'recurring',
              billing_period: 'weekly',
              _schema: 'price',
              _title: 'Premium Support',
              description: 'Premium Support',
              _org: '739224',
              _created_at: '2022-08-24T16:00:01.575Z',
              _updated_at: '2022-08-24T16:00:01.575Z',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              $relation: {
                entity_id: '11f84011-6b4c-421d-9025-2b66dacade71',
                _schema: 'price',
                _tags: []
              }
            },
            _product: {
              _id: '607d2952-8f3e-484f-a82b-4880528b7f55',
              type: 'product',
              name: 'Simple Product',
              _title: 'Simple Product'
            },
            taxes: [
              {
                rate: 'standard',
                rateValue: 19,
                amount: 319
              }
            ],
            currency: 'EUR',
            description: 'Premium Support',
            unit_amount: 2000,
            unit_amount_net: 1681,
            amount_subtotal: 1681,
            amount_total: 2000
          },
          {
            quantity: 1,
            type: 'recurring',
            price_id: '2edc1d43-73e2-45a2-aa72-e3a1dff23619',
            product_id: '607d2952-8f3e-484f-a82b-4880528b7f55',
            _price: {
              _id: '2edc1d43-73e2-45a2-aa72-e3a1dff23619',
              unit_amount: 10,
              unit_amount_currency: 'EUR',
              unit_amount_decimal: '0.10',
              sales_tax: 'standard',
              tax_behavior: 'inclusive',
              active: true,
              price_display_in_journeys: 'show_price',
              type: 'recurring',
              _schema: 'price',
              _title: 'GROUND PRICE 2',
              description: 'GROUND PRICE 2',
              variable_price: true,
              unit: 'kwh',
              _org: '739224',
              _created_at: '2022-08-31T10:48:53.246Z',
              billing_period: 'yearly',
              billing_duration_unit: 'months',
              notice_time_unit: 'months',
              termination_time_unit: 'months',
              renewal_duration_unit: 'months',
              _updated_at: '2022-09-15T11:57:25.430Z',
              $relation: {
                entity_id: '2edc1d43-73e2-45a2-aa72-e3a1dff23619',
                _schema: 'price',
                _tags: []
              }
            },
            _product: {
              _id: '607d2952-8f3e-484f-a82b-4880528b7f55',
              type: 'product',
              name: 'Simple Product',
              _title: 'Simple Product'
            },
            taxes: [
              {
                rate: 'standard',
                rateValue: 19,
                amount: 2
              }
            ],
            currency: 'EUR',
            description: 'GROUND PRICE 2',
            unit_amount: 10,
            unit_amount_net: 8,
            amount_subtotal: 8,
            amount_total: 10
          }
        ]
      }
    ],
    metadata: [
      {
        key: '_origin',
        value: 'journey checkout'
      }
    ],
    _tags: ['journey'],
    status: 'placed',
    billing_first_name: 'Martin',
    billing_last_name: 'Juan',
    billing_vat: '123892321',
    billing_phone: '+44233242423',
    billing_email: 'johndoe@company.com',
    billing_company_name: 'company limited',
    additional_addresses: [],
    payment_method: {
      _tags: ['Primary'],
      type: 'payment_sepa',
      data: {
        fullname: 'Pedro Yani',
        iban: '',
        bank_name: 'Landesbank Berlin - Berliner Sparkasse',
        bic_number: 'BELADEBEXXX'
      },
      customerTitle: 'Martin Juan'
    },
    currency: 'EUR',
    prices: {
      $relation: [
        {
          entity_id: '67ea3c64-3193-4650-b194-322047d65e80',
          _schema: 'price',
          _tags: []
        },
        {
          entity_id: '7b028fdf-0b0a-4077-a8f1-d0cbbd14b7cf',
          _schema: 'price',
          _tags: []
        }
      ]
    },
    products: {
      $relation: [
        {
          entity_id: 'b7185fb7-b10f-4875-bda7-288631446555',
          _schema: 'product',
          _tags: []
        },
        {
          entity_id: '607d2952-8f3e-484f-a82b-4880528b7f55',
          _schema: 'product',
          _tags: []
        }
      ]
    },
    amount_subtotal: 138709,
    amount_total: 160724,
    total_details: {
      amount_tax: 22015,
      breakdown: {
        taxes: [
          {
            tax: {
              rate: 7
            },
            amount: 2532
          },
          {
            tax: {
              rate: 19
            },
            amount: 19483
          }
        ],
        recurrences: [
          {
            type: 'recurring',
            billing_period: 'monthly',
            amount_subtotal: 51297,
            amount_total: 56704,
            amount_tax: 5407
          },
          {
            type: 'one_time',
            amount_subtotal: 84034,
            amount_total: 100000,
            amount_tax: 15966
          },
          {
            type: 'recurring',
            billing_period: 'weekly',
            amount_subtotal: 3361,
            amount_total: 4000,
            amount_tax: 639
          },
          {
            type: 'recurring',
            billing_period: 'yearly',
            amount_subtotal: 17,
            amount_total: 20,
            amount_tax: 3
          }
        ]
      }
    },
    _schema: 'order',
    _id: '14aa1f0e-ca12-456c-966d-4ea802cf7fef',
    _org: '739224',
    _created_at: '2022-10-21T23:27:06.175Z',
    order_number: 'OR-266',
    source: {
      title: 'manual',
      href: null,
      source_type: 'manual'
    },
    _title: 'OR-266',
    billing_contact: {
      $relation: [
        {
          entity_id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
          _tags: []
        }
      ]
    },
    workflows: [
      {
        id: '9dwe54vlbjn',
        name: 'ECP Labeling',
        workflow_status: 'STARTED',
        workflow_assignees: ['10016846'],
        workflow_definition_id: 'ERRpfKSO',
        next_open_step_id: '1z2luc45fpq',
        next_open_step_name: 'A first step in the root',
        next_open_section_id: 'khhqxpulvjg',
        next_open_section_name: 'Second'
      }
    ],
    _updated_at: '2023-03-31T10:32:52.731Z',
    _acl_sync: '2023-03-31T10:32:52.245Z',
    _owners: [
      {
        org_id: '739224'
      }
    ],
    _acl: {
      view: ['org_906717'],
      edit: ['org_906717'],
      delete: ['org_906717']
    }
  },
  files: [],
  relations: [
    {
      _id: 'b7185fb7-b10f-4875-bda7-288631446555',
      name: 'Alfen Eve Single S-line Wallbox',
      code: '904460583',
      categories: ['House Connection'],
      type: 'product',
      active: true,
      _schema: 'product',
      _org: '739224',
      _created_at: '2021-11-10T18:01:39.681Z',
      _updated_at: '2023-03-31T10:38:41.171Z',
      _title: 'Alfen Eve Single S-line Wallbox',
      _tags: ['product review demo', 'asd'],
      feature: [
        {
          _tags: [],
          feature: 'Bis zu 11 kW Ladeleistung (5x schneller laden)'
        },
        {
          _tags: [],
          feature: 'Konfigurierbare Ladeleistung'
        },
        {
          _tags: [],
          feature: 'Zugangskontrolle über RFID-Karten'
        }
      ],
      price_options: {
        $relation: [
          {
            entity_id: '8ca293d6-89f5-4b20-98f5-fa68341ef611',
            _tags: []
          },
          {
            entity_id: '67ea3c64-3193-4650-b194-322047d65e80',
            _tags: []
          },
          {
            entity_id: 'e3a426da-1f24-4e6e-b804-f30415ea4ecd',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: 'dfc0c532-4e97-4dbc-9eeb-07d06a1fdd0b',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '42dd91e3-329b-45bf-9339-871b3d4840ba',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: 'b9ad49ae-2bd8-43ae-b620-ab8aa857ed17',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '4d37db74-d501-4059-a564-4d02d3d5f472',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '1a9d853b-4651-4da3-897a-0d2f77676e92',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: 'e3ba142c-95e9-4188-aa0a-2a8d219fd932',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '57ab66bf-08a1-4f1d-9d1c-65af6a401851',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '4ff1b065-2ab1-4a84-a997-367262ab3eb6',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '819f50d4-250f-4f9a-bce9-d207ef4b6ab4',
            _tags: []
          },
          {
            entity_id: '2186ac62-c632-4abf-b80c-aefdd4156745',
            _schema: 'price',
            _tags: []
          }
        ]
      },
      product_images: {
        $relation: [
          {
            entity_id: '2bad25df-63af-4e61-95e7-729ac61d3537',
            _tags: []
          }
        ]
      },
      _acl_sync: '2023-03-31T10:38:40.880Z',
      _owners: [
        {
          org_id: '739224'
        }
      ],
      _acl: {
        view: [],
        edit: [],
        delete: []
      }
    },
    {
      unit_amount: 0,
      unit_amount_currency: 'EUR',
      unit_amount_decimal: '0.00',
      sales_tax: 'standard',
      tax_behavior: 'inclusive',
      active: false,
      price_display_in_journeys: 'show_price',
      type: 'one_time',
      billing_period: 'weekly',
      billing_duration_unit: 'months',
      notice_time_unit: 'months',
      termination_time_unit: 'months',
      renewal_duration_unit: 'months',
      is_composite_price: true,
      _tags: ['composite'],
      _schema: 'price',
      _title: 'Composite',
      price_components: {
        $relation: [
          {
            entity_id: 'bc4193b0-f8f8-4203-ad2a-d5b4fd25ef08',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '3c794cf8-c52a-42bf-a3de-88d4501a6bc0',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: 'b4aa272e-3f5d-4b74-a2c1-f5803e56fd2a',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '11f84011-6b4c-421d-9025-2b66dacade71',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '2edc1d43-73e2-45a2-aa72-e3a1dff23619',
            _schema: 'price',
            _tags: []
          }
        ]
      },
      _id: '7b028fdf-0b0a-4077-a8f1-d0cbbd14b7cf',
      _org: '739224',
      _created_at: '2022-08-10T10:12:38.072Z',
      _updated_at: '2022-11-15T15:25:13.985Z',
      description: 'Composite'
    },
    {
      _id: '67ea3c64-3193-4650-b194-322047d65e80',
      unit_amount: 12055,
      type: 'recurring',
      billing_period: 'monthly',
      billing_duration_amount: '1',
      billing_duration_unit: 'years',
      notice_time_amount: '1',
      notice_time_unit: 'months',
      termination_time_amount: '2',
      termination_time_unit: 'weeks',
      renewal_duration_amount: '1',
      renewal_duration_unit: 'years',
      active: true,
      sales_tax: 'reduced',
      tax_behavior: 'exclusive',
      description: 'Winter Lease',
      billing_scheme: 'Per Unit',
      tiers_mode: 'Standard',
      _schema: 'price',
      _org: '739224',
      _created_at: '2021-11-10T18:03:49.503Z',
      _updated_at: '2021-12-14T16:58:06.070Z',
      _title: 'price 67ea3c64-3193-4650-b194-322047d65e80',
      unit_amount_decimal: '120.55456634',
      unit_amount_currency: 'EUR'
    },
    {
      type: 'product',
      _schema: 'product',
      _title: 'Simple Product',
      name: 'Simple Product',
      description: '',
      price_options: {
        $relation: [
          {
            entity_id: '411a2296-33e9-4543-a337-47712a343af3',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '919b6a71-65e5-4bc6-8a27-f5b1af72551a',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '7b028fdf-0b0a-4077-a8f1-d0cbbd14b7cf',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: 'fc4ac6ee-a1ea-4d0b-b921-efbbb2a06256',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: 'a6d40d9a-bbe1-4944-905e-cd82563e797c',
            _schema: 'price',
            _tags: []
          },
          {
            entity_id: '91af43de-661f-4eeb-a935-c8eb686a0543',
            _schema: 'price',
            _tags: []
          }
        ]
      },
      _id: '607d2952-8f3e-484f-a82b-4880528b7f55',
      _org: '739224',
      _created_at: '2022-07-26T11:57:52.052Z',
      _updated_at: '2023-03-31T10:38:40.922Z',
      product_images: {
        $relation: [
          {
            entity_id: '39d8b2e7-13af-44c7-948a-238baaa63d4a'
          }
        ]
      },
      active: false,
      feature: [],
      product_downloads: {
        $relation: [
          {
            entity_id: '9159b7b2-cfef-4d46-bbae-4829655100d2',
            _tags: []
          },
          {
            entity_id: 'd67620f5-e3e0-484b-a115-52686ca4958b'
          },
          {
            entity_id: 'e90e1b1c-63dc-4805-96d0-abae1144f8de',
            _tags: []
          }
        ]
      },
      _acl_sync: '2023-03-31T10:38:40.880Z',
      _owners: [
        {
          org_id: '739224'
        }
      ],
      _acl: {
        view: [],
        edit: [],
        delete: []
      }
    },
    {
      _created_at: '2022-09-01T09:36:00.000Z',
      last_name: 'Juan',
      full_name: 'Martin',
      demo_test: 'something',
      _id: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
      _title: 'Martin Juan',
      _org: '739224',
      _schema: 'contact',
      test: 'Solar Panel 2',
      salutation: 'Contact Person',
      title: 'Dr.',
      first_name: 'Martin Juan',
      birthdate: '2022-09-01T09:36:00.000Z',
      contact_owner: [
        {
          type: 'user',
          org_id: '739224',
          user_id: '10011645',
          display_name: 'Martin J.',
          image_uri: {}
        },
        {
          type: 'user',
          user_id: '10014532',
          display_name: 'Alex',
          image_uri: {},
          org_id: '739224',
          email: 'alex.doe@test.com',
          phone: '78458 64524'
        }
      ],
      email: [
        {
          _tags: [],
          email: 'm.juan@test.cloud',
          id: '5gfa5o1'
        }
      ],
      payment: [
        {
          _tags: [],
          type: 'payment_invoice'
        }
      ],
      consent_email_marketing: {
        status: 'pending',
        identifiers: [
          {
            identifier: 'm.juan@test.com',
            status: 'declined',
            events: [
              {
                type: 'OPT_OUT',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:04:55.035Z'
              },
              {
                type: 'OPT_IN',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:03:40.067Z'
              },
              {
                type: 'OPT_OUT',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:07:28.666Z'
              },
              {
                type: 'OPT_IN',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:03:22.884Z'
              },
              {
                type: 'OPT_OUT',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:02:55.970Z'
              },
              {
                type: 'OPT_IN',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:01:44.621Z'
              },
              {
                type: 'OPT_OUT',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                    token_use: 'id',
                    auth_time: 1663843771,
                    exp: 1664880494,
                    iat: 1664876894,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.56.132',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-04T10:35:35.502Z'
              },
              {
                type: 'OPT_IN',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                    token_use: 'id',
                    auth_time: 1663843771,
                    exp: 1664880494,
                    iat: 1664876894,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.56.132',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-04T10:35:24.980Z'
              },
              {
                type: 'OPT_OUT',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                    token_use: 'id',
                    auth_time: 1663843771,
                    exp: 1664880494,
                    iat: 1664876894,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.56.132',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-04T10:30:51.821Z'
              },
              {
                type: 'OPT_IN',
                identifier: 'm.juan@test.com',
                topic: 'EMAIL_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'd5e40791-544a-4f82-863f-6de4a5c03573',
                    token_use: 'id',
                    auth_time: 1663843771,
                    exp: 1664880494,
                    iat: 1664876894,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.56.132',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-04T10:30:28.760Z'
              }
            ]
          }
        ]
      },
      consent_sms_marketing: {
        status: 'unknown',
        identifiers: []
      },
      consent_phone_call: {
        status: 'unknown',
        identifiers: []
      },
      consent_print_marketing: {
        status: 'pending',
        identifiers: [
          {
            identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
            status: 'declined',
            events: [
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:17:38.821Z'
              },
              {
                type: 'OPT_IN',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:04:58.952Z'
              },
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:04:31.461Z'
              },
              {
                type: 'OPT_IN',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:03:24.890Z'
              },
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:03:09.922Z'
              },
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: 'e034ac1b-ce9d-4373-a5b0-a16a09206588',
                    token_use: 'id',
                    auth_time: 1667409905,
                    exp: 1667467782,
                    iat: 1667464182,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '2.211.83.214',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-11-03T09:02:56.532Z'
              },
              {
                type: 'OPT_IN',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:03:22.858Z'
              },
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:03:10.372Z'
              },
              {
                type: 'OPT_IN',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:02:45.003Z'
              },
              {
                type: 'OPT_OUT',
                identifier: '2c533fad-6c2e-4121-95b4-05ef71f3c86e',
                topic: 'PRINT_MARKETING',
                meta: {
                  user: {
                    username: 'm.juan@test.com',
                    attributes: {
                      sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                      email_verified: true,
                      'custom:ivy_org_id': '739224',
                      'custom:ivy_user_id': '10011645',
                      email: 'm.juan@test.com'
                    },
                    email: 'm.juan@test.com',
                    user_id: '10011645',
                    org_id: '739224'
                  },
                  auth_api: {
                    ivyOrganization: '739224',
                    ivyOrganizationId: '739224',
                    ivyUser: '10011645',
                    ivyUserId: '10011645',
                    organizationId: '739224',
                    roles: '',
                    userId: '10011645',
                    userPoolClientId: '47pr7st7l4umbmpfbijv67mhua',
                    userPoolId: 'eu-central-1_4hwUyRZEB',
                    username: 'm.juan@test.com',
                    sub: 'adfc29ac-8e6f-46c3-ad0f-1a881e6e566b',
                    email_verified: true,
                    iss: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_4hwUyRZEB',
                    'custom:ivy_org_id': '739224',
                    'cognito:username': 'm.juan@test.com',
                    'custom:ivy_user_id': '10011645',
                    aud: '47pr7st7l4umbmpfbijv67mhua',
                    event_id: '8967c6c8-50f9-420b-aa6c-9639e757be12',
                    token_use: 'id',
                    auth_time: 1664957438,
                    exp: 1664976775,
                    iat: 1664973175,
                    email: 'm.juan@test.com'
                  },
                  ip_address: '217.110.198.75',
                  user_agent:
                    'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'
                },
                organization_id: '739224',
                source: 'http://localhost:9000',
                created_at: '2022-10-05T13:01:44.506Z'
              }
            ]
          }
        ]
      },
      address: [
        {
          _tags: [],
          street: 'Krefelder',
          street_number: '898',
          postal_code: '4567890',
          city: 'London',
          country: 'UA',
          additional_info: ''
        }
      ],
      phone: null,
      language: 'en',
      account: [
        {
          _id: '18bb4d9f-1d82-4bda-9680-d02576c1baf0',
          _org: '739224',
          _schema: 'account',
          email: [
            {
              email: 'alezandre@asfafa.afsf'
            }
          ],
          payment: [
            {
              _tags: [],
              _id: 'MJoCluZ7_ubKLMasED5NM',
              type: 'payment_invoice'
            }
          ],
          address: [
            {
              country: 'DE',
              city: 'Köln',
              _tags: ['billing', 'delivery'],
              additional_info: '999',
              street: 'Am Salzmagazin',
              street_number: '22222',
              _id: 'Zw7eBpd94iwY5vhKFTTMh',
              postal_code: '50668'
            }
          ],
          name: 'LEXCOMP',
          _created_at: '2022-11-03T15:20:03.672Z',
          _updated_at: '2022-11-16T08:31:02.106Z',
          _title: 'LEXCOMP',
          $relation: {
            entity_id: '18bb4d9f-1d82-4bda-9680-d02576c1baf0'
          }
        }
      ],
      customer_number: '100000',
      company_name: 'Company name',
      product_types: [],
      mj_test: 'This is me!',
      new_attribute_1676161815664: 'MJD',
      customer: 'Beautiful.',
      _files: {
        $relation: [
          {
            entity_id: 'ffc934a6-2723-40da-9edb-662f19ff7a27'
          },
          {
            entity_id: '4cab5a3e-98dc-45a0-a403-5910212ae44e'
          }
        ]
      },
      number_attribute: '2',
      date: '2023-03-04T16:59:00.000Z',
      test_phone_number: '',
      demo_sample: 'demo test value',
      new_attribute_1679427761812: 'dfghjkl',
      _acl_sync: '2023-04-07T07:13:43.121Z',
      _owners: [
        {
          org_id: '739224'
        }
      ],
      _acl: {
        view: ['org_907251', 'org_911215', 'org_911221'],
        edit: ['org_907251', 'org_911215', 'org_911221'],
        delete: ['org_911215', 'org_911221']
      },
      files: [],
      test_number_attribute: 'dasf',
      _updated_at: '2023-04-11T12:26:38.910Z'
    }
  ],
  products: [
    {
      _id: 'b7185fb7-b10f-4875-bda7-288631446555',
      name: 'Alfen Eve Single S-line Wallbox',
      code: '904460583',
      categories: ['House Connection'],
      type: 'product',
      active: true,
      _schema: 'product',
      _org: '739224',
      _created_at: '2021-11-10T18:01:39.681Z',
      _updated_at: '2023-03-31T10:38:41.171Z',
      _title: 'Alfen Eve Single S-line Wallbox',
      _tags: ['product review demo', 'asd'],
      feature: [
        {
          _tags: [],
          feature: 'Bis zu 11 kW Ladeleistung (5x schneller laden)'
        },
        {
          _tags: [],
          feature: 'Konfigurierbare Ladeleistung'
        },
        {
          _tags: [],
          feature: 'Zugangskontrolle über RFID-Karten'
        }
      ],
      price_options: [
        {
          _id: '8ca293d6-89f5-4b20-98f5-fa68341ef611',
          unit_amount: 12055,
          type: 'one_time',
          active: true,
          sales_tax: 'standard',
          tax_behavior: 'inclusive',
          description: 'Winter Sale',
          billing_scheme: 'Per Unit',
          tiers_mode: 'Standard',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-11-10T18:02:58.318Z',
          _updated_at: '2021-12-14T16:58:06.072Z',
          _title: 'price 8ca293d6-89f5-4b20-98f5-fa68341ef611',
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '120.55456634',
          $relation: {
            entity_id: '8ca293d6-89f5-4b20-98f5-fa68341ef611',
            _tags: []
          }
        },
        {
          _id: '67ea3c64-3193-4650-b194-322047d65e80',
          unit_amount: 12055,
          type: 'recurring',
          billing_period: 'monthly',
          billing_duration_amount: '1',
          billing_duration_unit: 'years',
          notice_time_amount: '1',
          notice_time_unit: 'months',
          termination_time_amount: '2',
          termination_time_unit: 'weeks',
          renewal_duration_amount: '1',
          renewal_duration_unit: 'years',
          active: true,
          sales_tax: 'reduced',
          tax_behavior: 'exclusive',
          description: 'Winter Lease',
          billing_scheme: 'Per Unit',
          tiers_mode: 'Standard',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-11-10T18:03:49.503Z',
          _updated_at: '2021-12-14T16:58:06.070Z',
          _title: 'price 67ea3c64-3193-4650-b194-322047d65e80',
          unit_amount_decimal: '120.55456634',
          unit_amount_currency: 'EUR',
          $relation: {
            entity_id: '67ea3c64-3193-4650-b194-322047d65e80',
            _tags: []
          }
        },
        {
          _id: 'e3a426da-1f24-4e6e-b804-f30415ea4ecd',
          billing_period: 'weekly',
          sales_tax: 'reduced',
          tax_behavior: 'inclusive',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-11-21T23:51:24.869Z',
          _updated_at: '2023-01-06T17:11:51.863Z',
          _title: '',
          unit_amount: 12055,
          active: true,
          type: 'recurring',
          unit_amount_decimal: '120.55456634',
          unit_amount_currency: 'EUR',
          price_display_in_journeys: 'show_price',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_tax_inclusive: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: 'e3a426da-1f24-4e6e-b804-f30415ea4ecd',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: 'dfc0c532-4e97-4dbc-9eeb-07d06a1fdd0b',
          description: 'Summer Sale',
          unit_amount_currency: 'USD',
          unit_amount: 2350,
          unit_amount_decimal: '23.50',
          type: 'recurring',
          tiers_mode: 'Standard',
          billing_period: 'yearly',
          billing_duration_amount: '1',
          billing_duration_unit: 'weeks',
          notice_time_amount: '1',
          notice_time_unit: 'weeks',
          termination_time_amount: '1',
          termination_time_unit: 'weeks',
          renewal_duration_amount: '1',
          renewal_duration_unit: 'weeks',
          active: true,
          sales_tax: 'nontaxable',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-12-01T09:20:31.544Z',
          _updated_at: '2023-01-06T17:11:29.834Z',
          _title: 'Summer Sale',
          tax_behavior: 'inclusive',
          price_display_in_journeys: 'show_price',
          is_tax_inclusive: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: 'dfc0c532-4e97-4dbc-9eeb-07d06a1fdd0b',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: '42dd91e3-329b-45bf-9339-871b3d4840ba',
          description: 'Spring Sale',
          unit_amount_currency: 'USD',
          unit_amount: 260,
          unit_amount_decimal: '2.60',
          type: 'one_time',
          tiers_mode: 'Standard',
          active: true,
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-12-01T09:29:01.113Z',
          _updated_at: '2023-01-06T17:11:48.575Z',
          _title: 'Spring Sale',
          sales_tax: 'standard',
          tax_behavior: 'inclusive',
          price_display_in_journeys: 'show_price',
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_tax_inclusive: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: '42dd91e3-329b-45bf-9339-871b3d4840ba',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: 'b9ad49ae-2bd8-43ae-b620-ab8aa857ed17',
          description: 'Test - 8 ',
          unit_amount: 123400,
          unit_amount_decimal: '1234',
          unit_amount_currency: 'EUR',
          type: 'one_time',
          tiers_mode: 'Standard',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-12-01T11:09:55.218Z',
          _updated_at: '2023-01-17T15:22:36.238Z',
          _title: 'Test - 8',
          active: true,
          sales_tax: 'standard',
          tax_behavior: 'inclusive',
          price_display_in_journeys: 'show_as_on_request',
          tax: [
            {
              _id: '79cac814-18c8-4047-ae45-1d0828598a18',
              type: 'Custom',
              rate: 19,
              behavior: 'inclusive',
              active: true,
              region: 'DE',
              _schema: 'tax',
              _title: 'GST',
              description: 'GST',
              _org: '739224',
              _created_at: '2022-04-26T08:41:38.696Z',
              _updated_at: '2022-12-06T15:28:16.765Z',
              $relation: {
                entity_id: '79cac814-18c8-4047-ae45-1d0828598a18'
              }
            }
          ],
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_tax_inclusive: true,
          variable_price: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: 'b9ad49ae-2bd8-43ae-b620-ab8aa857ed17',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: '4d37db74-d501-4059-a564-4d02d3d5f472',
          description: 'Test - 13',
          unit_amount: 1400,
          unit_amount_decimal: '14',
          unit_amount_currency: 'EUR',
          type: 'one_time',
          tiers_mode: 'Standard',
          sales_tax: 'nontaxable',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-12-01T11:27:14.017Z',
          _updated_at: '2023-01-06T17:11:43.902Z',
          _title: 'Test - 13',
          active: true,
          tax_behavior: 'inclusive',
          price_display_in_journeys: 'show_price',
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_tax_inclusive: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: '4d37db74-d501-4059-a564-4d02d3d5f472',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: '1a9d853b-4651-4da3-897a-0d2f77676e92',
          description: 'Test - 14',
          unit_amount: 1350,
          unit_amount_decimal: '13.50',
          unit_amount_currency: 'USD',
          type: 'one_time',
          tiers_mode: 'Standard',
          sales_tax: 'nontaxable',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-12-01T11:27:55.650Z',
          _updated_at: '2023-01-06T17:11:52.049Z',
          _title: 'Test - 14',
          active: true,
          tax_behavior: 'inclusive',
          price_display_in_journeys: 'show_price',
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_tax_inclusive: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: '1a9d853b-4651-4da3-897a-0d2f77676e92',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: 'e3ba142c-95e9-4188-aa0a-2a8d219fd932',
          description: 'Test - 15',
          unit_amount_currency: 'CHF',
          unit_amount: 1365,
          unit_amount_decimal: '13.65',
          type: 'one_time',
          tiers_mode: 'Standard',
          sales_tax: 'nontaxable',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-12-01T11:29:12.334Z',
          _updated_at: '2023-01-06T17:11:42.263Z',
          _title: 'Test - 15',
          active: true,
          tax_behavior: 'inclusive',
          price_display_in_journeys: 'show_price',
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_tax_inclusive: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: 'e3ba142c-95e9-4188-aa0a-2a8d219fd932',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: '57ab66bf-08a1-4f1d-9d1c-65af6a401851',
          description: 'Test - 16',
          unit_amount: 1489,
          unit_amount_decimal: '14.89',
          unit_amount_currency: 'EUR',
          type: 'recurring',
          tiers_mode: 'Standard',
          billing_period: 'weekly',
          billing_duration_amount: '1',
          billing_duration_unit: 'weeks',
          notice_time_amount: '1',
          notice_time_unit: 'weeks',
          termination_time_amount: '1',
          termination_time_unit: 'months',
          renewal_duration_amount: '1',
          renewal_duration_unit: 'months',
          active: true,
          sales_tax: 'reduced',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-12-01T11:30:12.424Z',
          _updated_at: '2023-01-06T17:11:16.937Z',
          _title: 'Test - 16',
          tax_behavior: 'inclusive',
          price_display_in_journeys: 'show_price',
          is_tax_inclusive: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: '57ab66bf-08a1-4f1d-9d1c-65af6a401851',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: '4ff1b065-2ab1-4a84-a997-367262ab3eb6',
          description: 'Test - 17',
          unit_amount: 0,
          unit_amount_decimal: '0',
          unit_amount_currency: 'EUR',
          type: 'one_time',
          tiers_mode: 'Standard',
          sales_tax: 'nontaxable',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-12-01T11:34:50.676Z',
          _updated_at: '2023-01-06T17:11:14.003Z',
          _title: 'Test - 17',
          active: true,
          tax_behavior: 'inclusive',
          price_display_in_journeys: 'show_price',
          variable_price: true,
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_tax_inclusive: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: '4ff1b065-2ab1-4a84-a997-367262ab3eb6',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: '819f50d4-250f-4f9a-bce9-d207ef4b6ab4',
          description: 'Test - 18',
          unit_amount_currency: 'USD',
          unit_amount: 1290,
          unit_amount_decimal: '12.90',
          type: 'one_time',
          billing_scheme: 'Per Unit',
          tiers_mode: 'Standard',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-12-01T11:35:30.915Z',
          _updated_at: '2021-12-14T16:58:06.074Z',
          _title: 'price 819f50d4-250f-4f9a-bce9-d207ef4b6ab4',
          $relation: {
            entity_id: '819f50d4-250f-4f9a-bce9-d207ef4b6ab4',
            _tags: []
          }
        },
        {
          _id: '2186ac62-c632-4abf-b80c-aefdd4156745',
          description: 'Test - 20',
          _schema: 'price',
          _org: '739224',
          _created_at: '2021-12-01T16:48:24.960Z',
          _updated_at: '2023-01-06T17:11:10.665Z',
          _title: 'Test - 20',
          active: true,
          unit_amount: {
            unit_amount: 0,
            unit_amount_currency: 'EUR',
            unit_amount_decimal: '0.00'
          },
          sales_tax: 'standard',
          tax_behavior: 'inclusive',
          price_display_in_journeys: 'show_price',
          type: 'one_time',
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_tax_inclusive: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: '2186ac62-c632-4abf-b80c-aefdd4156745',
            _schema: 'price',
            _tags: []
          }
        }
      ],
      product_images: [
        {
          _id: '2bad25df-63af-4e61-95e7-729ac61d3537',
          filename: 'Image from iOS (1) (1).jpg',
          access_control: 'public-read',
          size_bytes: 230672,
          mime_type: 'image/jpeg',
          readable_size: '225.27 KB',
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/43244367-ec6b-4610-8eb8-ab3f67c1cae5/Image%2520from%2520iOS%2520(1)%2520(1).jpg',
          versions: [
            {
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/43244367-ec6b-4610-8eb8-ab3f67c1cae5/Image%20from%20iOS%20(1)%20(1).jpg'
              },
              filename: 'Image from iOS (1) (1).jpg',
              access_control: 'public-read',
              size_bytes: 230672,
              mime_type: 'image/jpeg',
              readable_size: '225.27 KB',
              type: 'image',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/43244367-ec6b-4610-8eb8-ab3f67c1cae5/Image%2520from%2520iOS%2520(1)%2520(1).jpg'
            }
          ],
          _schema: 'file',
          _org: '739224',
          _created_at: '2022-04-05T13:26:38.909Z',
          _updated_at: '2022-04-05T13:26:38.909Z',
          _title: 'Image from iOS (1) (1).jpg',
          $relation: {
            entity_id: '2bad25df-63af-4e61-95e7-729ac61d3537',
            _tags: []
          }
        }
      ],
      _acl_sync: '2023-03-31T10:38:40.880Z',
      _owners: [
        {
          org_id: '739224'
        }
      ],
      _acl: {
        view: [],
        edit: [],
        delete: []
      },
      _relations: [
        {
          entity_id: 'df1d4cdc-2b42-4e4a-9131-00c772242c27',
          _acl: '{"view":["org:911218"],"edit":["org:911218"],"delete":[]}'
        },
        {
          entity_id: 'e9db1b28-9e3a-4470-b173-663832b5d3e3',
          _acl: '{"view":["org_906717"],"edit":["org_906717"],"delete":["org_906717"]}'
        },
        {
          entity_id: '1fdd896f-35a5-4b5a-ab66-9a8286c75b79'
        },
        {
          entity_id: 'ced33fcf-6f19-463e-985d-7599486d7d74'
        },
        {
          entity_id: '51a8e6be-64d9-408a-8c8b-9f4ca29158f3'
        },
        {
          entity_id: '493953dc-fc20-4edc-8706-8cae9938ba35'
        },
        {
          entity_id: '14aa1f0e-ca12-456c-966d-4ea802cf7fef',
          _acl: '{"view":["org_906717"],"edit":["org_906717"],"delete":["org_906717"]}'
        },
        {
          entity_id: '121ea267-93d3-40c4-9655-a1a11ad6ee74'
        },
        {
          entity_id: 'a4323cf3-ce35-4806-a4c6-0e18648901ee'
        },
        {
          entity_id: '60917bfc-8552-40fa-a3d1-6e7c2023e63a'
        },
        {
          entity_id: '1c3a62fe-0f00-4be0-968b-15bf6012a230'
        },
        {
          entity_id: '2186ac62-c632-4abf-b80c-aefdd4156745'
        },
        {
          entity_id: '4ff1b065-2ab1-4a84-a997-367262ab3eb6'
        },
        {
          entity_id: 'e3ba142c-95e9-4188-aa0a-2a8d219fd932'
        },
        {
          entity_id: '42dd91e3-329b-45bf-9339-871b3d4840ba'
        },
        {
          entity_id: '1a9d853b-4651-4da3-897a-0d2f77676e92'
        },
        {
          entity_id: 'e3a426da-1f24-4e6e-b804-f30415ea4ecd'
        },
        {
          entity_id: 'dfc0c532-4e97-4dbc-9eeb-07d06a1fdd0b'
        },
        {
          entity_id: '4d37db74-d501-4059-a564-4d02d3d5f472'
        },
        {
          entity_id: 'b9ad49ae-2bd8-43ae-b620-ab8aa857ed17'
        },
        {
          entity_id: '57ab66bf-08a1-4f1d-9d1c-65af6a401851'
        },
        {
          entity_id: '6003c34f-fa21-43fe-94f7-d5b0868ec4fa'
        },
        {
          entity_id: '2d5a9d1a-ccd3-42c4-a34a-8b1ae600ac61'
        },
        {
          entity_id: 'f5e7fe68-4214-4c38-8031-d86408e239f8'
        },
        {
          entity_id: 'a868f0c5-d467-4f64-a355-77902746d21c'
        },
        {
          entity_id: 'f1dc05b0-7c07-4015-87b4-19eccfeccc2a'
        },
        {
          entity_id: '97c014e3-e831-407d-b3a0-7336fc803b6c',
          _acl: '{"view":["org:911215"],"edit":[],"delete":[]}'
        },
        {
          entity_id: 'ae31116f-6e56-417d-a965-8a4e9be53a92'
        },
        {
          entity_id: '3b6713e3-413d-4c21-89c5-ecb2e7ad6a4c'
        },
        {
          entity_id: '04094ad3-d5ca-4741-b030-0ad8cc511251'
        },
        {
          entity_id: '5674a011-9427-4026-98d3-c3f3c4da6862'
        },
        {
          entity_id: 'c7594587-57fc-4550-9695-fcd0e372fb29'
        },
        {
          entity_id: 'a6d82f85-ad9d-4f50-93a6-ca9f056d32de'
        },
        {
          entity_id: '4efff55a-6064-4d6b-992c-c394e462e404'
        },
        {
          entity_id: 'caa377f6-1c33-4a0c-9cd6-b17e054d6fb6'
        },
        {
          entity_id: 'beab2b0a-5975-47ca-ab09-868d1cc8048a'
        },
        {
          entity_id: 'c4c4deec-3cc4-49cb-a71d-92cb19c196b9'
        },
        {
          entity_id: 'b02a0b28-17e3-428c-9ed3-4ce07d7e5c7a'
        },
        {
          entity_id: 'e77ad844-bdda-4826-aa88-d0711a729db8'
        },
        {
          entity_id: 'c9519a5b-c986-4fdc-aba5-b13c19d37961'
        },
        {
          entity_id: '1ca15dec-1c14-4516-b0c6-2ad08944e65f'
        },
        {
          entity_id: '7a40dde1-9eba-43af-87ab-bb09a2b6299b'
        },
        {
          entity_id: 'c8be4300-a0e7-4cf0-bdd3-5fede0208684'
        },
        {
          entity_id: '49f2ab68-e4b0-46f1-85d6-0b59eefb5c3d'
        },
        {
          entity_id: '7999c74b-62e0-4520-8bc2-a2d48b857e13'
        },
        {
          entity_id: '2296c3fa-c148-4022-8bf0-640efe32c0ca'
        },
        {
          entity_id: 'e9384811-e550-4352-89f1-7d0d5f8906a9'
        },
        {
          entity_id: '53a52c75-5d51-4558-bef7-96624282f779'
        },
        {
          entity_id: 'fb38dc1f-1909-41cb-8283-9c65384886e6'
        },
        {
          entity_id: '5b5cd864-7ae2-4d50-bbb3-54aa8b03079e'
        },
        {
          entity_id: '9a8b1897-5300-45de-a2ab-09e63e926348'
        },
        {
          entity_id: '981fff15-51a4-46c8-a393-196f8af9a9ec'
        },
        {
          entity_id: '92d6bb76-cd5f-49ac-81e4-1489570e3c60'
        },
        {
          entity_id: 'dffe03eb-f780-4742-818e-ac9ae18e6ee5'
        },
        {
          entity_id: '5197fa89-fbb6-4747-a291-ab19b693b8cc'
        },
        {
          entity_id: '725c1c74-9844-436e-9b79-292732ac3947'
        },
        {
          entity_id: 'cc8960ae-97a7-4c13-b991-be5b9cbebc5b'
        },
        {
          entity_id: '2d14e57a-6ec2-49fd-9eba-ef1a5796d680'
        },
        {
          entity_id: '1b9076eb-0544-466c-a230-e63911b018c0'
        },
        {
          entity_id: '4605e076-5037-44c5-aa50-78d3ff054dbb'
        },
        {
          entity_id: 'd5d1d885-be24-496b-a5a4-32c70a3fc041'
        },
        {
          entity_id: '7eb31da0-dd05-4c5d-ad19-59fe8a198366'
        },
        {
          entity_id: 'a617b245-1f0a-4508-8732-8d74a34810c5'
        },
        {
          entity_id: 'bc58c3e0-8d16-4df4-a5af-c1b43057ebfe'
        },
        {
          entity_id: '0210cf90-983f-4717-baff-3742285e2b5b'
        },
        {
          entity_id: '37f2a0bf-2e6a-4530-a527-aa42bc0c52c4'
        },
        {
          entity_id: 'c8ca98d6-29c7-42bb-8c84-d181736c8240'
        },
        {
          entity_id: 'fdd72a0a-068d-4c8e-b2c1-a08d9c871595'
        },
        {
          entity_id: 'eceb1b25-fe60-40cd-8b68-d843bed04a1e'
        },
        {
          entity_id: 'f402d783-16fe-4315-abb7-21dcc4f13ef1'
        },
        {
          entity_id: '39c7d94e-659d-4ac4-9330-c2071c73b9e0'
        },
        {
          entity_id: 'f6f0d894-2f9b-4dbf-9eeb-be596b64c9e6'
        },
        {
          entity_id: 'e86e6249-63ae-49a7-8a71-fa9ea867c2a4'
        },
        {
          entity_id: '91f64cfc-3706-4dac-ba8c-f0bd1a37d793'
        },
        {
          entity_id: '47ac9280-cdf7-4ac2-8099-f5e3f728f4ad'
        },
        {
          entity_id: '7d6c0f88-1de6-45cf-b23d-d4dc60eb0388'
        },
        {
          entity_id: 'd3eb1951-0e93-4678-afd6-a257e2b1a837'
        },
        {
          entity_id: '643961a1-57b0-442d-9337-b9ebe95b017e'
        },
        {
          entity_id: '1c2f9041-fa5b-4898-88ca-bc2d367ff708'
        },
        {
          entity_id: 'd0b0d610-9bbf-47ba-b82e-726d9d6938c6'
        },
        {
          entity_id: '0db5c1de-2719-4bfa-b012-134210453ec9'
        },
        {
          entity_id: 'e6774b0e-6d8b-4d70-b243-27072752da37'
        },
        {
          entity_id: '0dc048ff-d329-4264-8f4e-651a3f66f1c9'
        },
        {
          entity_id: 'cb8c4a16-febe-4bb7-8a3b-92ff000488e9'
        },
        {
          entity_id: '77d07e6b-0702-4b62-962b-4a1fd0868c02'
        },
        {
          entity_id: 'b73321c4-9783-4fdc-aa3c-5d99a9255b4f'
        },
        {
          entity_id: 'e517a539-5abb-458b-8d74-cc7b7399cfa7'
        },
        {
          entity_id: 'a397f5f3-c18d-48bd-bcc5-25e78075ce51'
        },
        {
          entity_id: 'a5bbfed8-c30f-4ff0-9d90-211f193bbf1c'
        },
        {
          entity_id: '9a981091-fd06-40f8-95f2-cdfd4e2a633c'
        },
        {
          entity_id: 'd086bdd7-34fc-4224-a06c-96f95369a15e'
        },
        {
          entity_id: '113afa23-9a34-40ab-ab09-d1dcdf62c626'
        },
        {
          entity_id: 'cf797c62-4097-4da4-a628-e4c16364fb1e'
        },
        {
          entity_id: 'd77535db-e551-4f14-a0e0-497eeeb0dcd6'
        },
        {
          entity_id: '2a350df2-7281-4092-af9f-617ca873ab30'
        },
        {
          entity_id: 'b3eceb18-41ef-441e-bb4f-e20d6b7cb958'
        },
        {
          entity_id: 'd8376b54-3d08-4534-bc71-474fa1c7e1f5'
        },
        {
          entity_id: '80558491-dad7-428e-9c1d-d8e4c87e4b0c'
        },
        {
          entity_id: '38791bbf-5450-4066-b671-6bc8e5190e0f'
        },
        {
          entity_id: '37f86610-d2e9-46d1-8257-9965876c19b0'
        },
        {
          entity_id: '9b81b9b3-44ea-487f-b3fc-91110f431df7'
        },
        {
          entity_id: 'bba8f26d-7894-4d5f-8cde-4f932d758fc4'
        },
        {
          entity_id: '2e71ec9b-9c99-4128-b98d-ac2ef57c33a1'
        }
      ]
    },
    {
      type: 'product',
      _schema: 'product',
      _title: 'Simple Product',
      name: 'Simple Product',
      description: '',
      price_options: [
        {
          _id: '411a2296-33e9-4543-a337-47712a343af3',
          unit_amount: 4500,
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '45',
          sales_tax: 'standard',
          tax_behavior: 'inclusive',
          active: true,
          price_display_in_journeys: 'show_price',
          type: 'recurring',
          _schema: 'price',
          _title: 'Test',
          description: 'Test',
          _org: '739224',
          _created_at: '2022-08-03T13:28:23.796Z',
          _updated_at: '2023-01-06T17:11:20.759Z',
          billing_period: 'monthly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_tax_inclusive: true,
          pricing_model: 'per_unit',
          $relation: {
            entity_id: '411a2296-33e9-4543-a337-47712a343af3',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: '919b6a71-65e5-4bc6-8a27-f5b1af72551a',
          pricing_model: 'standard',
          unit_amount: 20000,
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '200',
          sales_tax: 'standard',
          active: true,
          price_display_in_journeys: 'show_price',
          type: 'one_time',
          _schema: 'price',
          _title: 'New Price',
          _org: '739224',
          _created_at: '2022-07-26T11:57:41.421Z',
          _updated_at: '2022-07-29T10:19:25.219Z',
          description: 'New Price',
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          $relation: {
            entity_id: '919b6a71-65e5-4bc6-8a27-f5b1af72551a',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: '7b028fdf-0b0a-4077-a8f1-d0cbbd14b7cf',
          unit_amount: 0,
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '0.00',
          sales_tax: 'standard',
          tax_behavior: 'inclusive',
          active: false,
          price_display_in_journeys: 'show_price',
          type: 'one_time',
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_composite_price: true,
          _tags: ['composite'],
          _schema: 'price',
          _title: 'Composite',
          price_components: {
            $relation: [
              {
                entity_id: 'bc4193b0-f8f8-4203-ad2a-d5b4fd25ef08',
                _schema: 'price',
                _tags: []
              },
              {
                entity_id: '3c794cf8-c52a-42bf-a3de-88d4501a6bc0',
                _schema: 'price',
                _tags: []
              },
              {
                entity_id: 'b4aa272e-3f5d-4b74-a2c1-f5803e56fd2a',
                _schema: 'price',
                _tags: []
              },
              {
                entity_id: '11f84011-6b4c-421d-9025-2b66dacade71',
                _schema: 'price',
                _tags: []
              },
              {
                entity_id: '2edc1d43-73e2-45a2-aa72-e3a1dff23619',
                _schema: 'price',
                _tags: []
              }
            ]
          },
          _org: '739224',
          _created_at: '2022-08-10T10:12:38.072Z',
          _updated_at: '2022-11-15T15:25:13.985Z',
          description: 'Composite',
          $relation: {
            entity_id: '7b028fdf-0b0a-4077-a8f1-d0cbbd14b7cf',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: 'fc4ac6ee-a1ea-4d0b-b921-efbbb2a06256',
          unit_amount: 6000,
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '60',
          sales_tax: 'standard',
          tax_behavior: 'inclusive',
          active: true,
          price_display_in_journeys: 'show_price',
          type: 'recurring',
          billing_period: 'yearly',
          _schema: 'price',
          _title: 'Annual package',
          description: 'Annual package',
          _org: '739224',
          _created_at: '2022-08-17T13:45:02.749Z',
          _updated_at: '2022-12-06T11:46:28.289Z',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          $relation: {
            entity_id: 'fc4ac6ee-a1ea-4d0b-b921-efbbb2a06256',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: 'a6d40d9a-bbe1-4944-905e-cd82563e797c',
          unit_amount: 0,
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '0.00',
          sales_tax: 'standard',
          tax_behavior: 'inclusive',
          active: true,
          price_display_in_journeys: 'show_as_on_request',
          type: 'one_time',
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_composite_price: true,
          _tags: ['composite'],
          _schema: 'price',
          _title: 'Family Bundle - 41',
          description: 'Family Bundle - 41',
          price_components: {
            $relation: [
              {
                entity_id: '032faa45-7e5a-40e0-a6a2-ba6dbf72b4b6',
                _schema: 'price',
                _tags: []
              },
              {
                entity_id: '51aa99a5-ac1d-4305-a4e7-c22853453cba',
                _schema: 'price',
                _tags: []
              },
              {
                entity_id: '2355b5e2-3f54-42a0-8843-baa44ce6d998',
                _schema: 'price',
                _tags: []
              }
            ]
          },
          _org: '739224',
          _created_at: '2022-08-24T16:12:05.232Z',
          _updated_at: '2022-12-06T11:46:28.319Z',
          $relation: {
            entity_id: 'a6d40d9a-bbe1-4944-905e-cd82563e797c',
            _schema: 'price',
            _tags: []
          }
        },
        {
          _id: '91af43de-661f-4eeb-a935-c8eb686a0543',
          unit_amount: 0,
          unit_amount_currency: 'EUR',
          unit_amount_decimal: '0.00',
          sales_tax: 'standard',
          tax_behavior: 'inclusive',
          active: true,
          price_display_in_journeys: 'show_price',
          type: 'one_time',
          billing_period: 'weekly',
          billing_duration_unit: 'months',
          notice_time_unit: 'months',
          termination_time_unit: 'months',
          renewal_duration_unit: 'months',
          is_composite_price: true,
          _tags: ['composite'],
          _schema: 'price',
          _title: 'New composite price component',
          price_components: {
            $relation: [
              {
                entity_id: 'b4aa272e-3f5d-4b74-a2c1-f5803e56fd2a',
                _schema: 'price',
                _tags: []
              },
              {
                entity_id: '7b028fdf-0b0a-4077-a8f1-d0cbbd14b7cf',
                _tags: [],
                _schema: 'price'
              },
              {
                entity_id: '07dc6431-e463-427c-b55d-e0bdf53f8811',
                _tags: [],
                _schema: 'price'
              },
              {
                entity_id: '19c4d69a-6a6d-404a-93f2-8873f22b306f',
                _tags: []
              }
            ]
          },
          description: 'New composite price component',
          _org: '739224',
          _created_at: '2022-09-12T09:23:27.915Z',
          _updated_at: '2022-09-12T12:04:53.421Z',
          $relation: {
            entity_id: '91af43de-661f-4eeb-a935-c8eb686a0543',
            _schema: 'price',
            _tags: []
          }
        }
      ],
      _id: '607d2952-8f3e-484f-a82b-4880528b7f55',
      _org: '739224',
      _created_at: '2022-07-26T11:57:52.052Z',
      _updated_at: '2023-03-31T10:38:40.922Z',
      product_images: [
        {
          _id: '39d8b2e7-13af-44c7-948a-238baaa63d4a',
          filename: '2023-tesla-roadster-front-view-carbuzz-847781.jpeg',
          access_control: 'public-read',
          size_bytes: 86728,
          mime_type: 'image/jpeg',
          readable_size: '84.7 KB',
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/3f590a9b-59e8-46f3-8652-cd643a6db75e/2023-tesla-roadster-front-view-carbuzz-847781.jpeg',
          versions: [
            {
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/3f590a9b-59e8-46f3-8652-cd643a6db75e/2023-tesla-roadster-front-view-carbuzz-847781.jpeg'
              },
              filename: '2023-tesla-roadster-front-view-carbuzz-847781.jpeg',
              access_control: 'private',
              size_bytes: 86728,
              mime_type: 'image/jpeg',
              readable_size: '84.7 KB',
              type: 'image',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/3f590a9b-59e8-46f3-8652-cd643a6db75e/2023-tesla-roadster-front-view-carbuzz-847781.jpeg'
            }
          ],
          _schema: 'file',
          _org: '739224',
          _created_at: '2022-01-02T19:58:58.338Z',
          _updated_at: '2022-04-08T14:18:04.131Z',
          _title: '2023-tesla-roadster-front-view-carbuzz-847781.jpeg',
          $relation: {
            entity_id: '39d8b2e7-13af-44c7-948a-238baaa63d4a'
          }
        }
      ],
      active: false,
      feature: [],
      product_downloads: [
        {
          _id: '9159b7b2-cfef-4d46-bbae-4829655100d2',
          filename: 'availability-file-template (1).csv',
          access_control: 'public-read',
          size_bytes: 422,
          mime_type: 'text/csv',
          readable_size: '422 Bytes',
          type: 'spreadsheet',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/ea80a878-06ad-461f-a1cf-e13394fd6aed/availability-file-template%2520(1).csv',
          versions: [
            {
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/ea80a878-06ad-461f-a1cf-e13394fd6aed/availability-file-template%20(1).csv'
              },
              filename: 'availability-file-template (1).csv',
              access_control: 'public-read',
              size_bytes: 422,
              mime_type: 'text/csv',
              readable_size: '422 Bytes',
              type: 'spreadsheet',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/ea80a878-06ad-461f-a1cf-e13394fd6aed/availability-file-template%2520(1).csv'
            }
          ],
          _schema: 'file',
          _org: '739224',
          _created_at: '2022-08-09T10:05:55.288Z',
          _updated_at: '2022-09-16T07:45:44.245Z',
          _title: 'availability-file-template (1).csv',
          _tags: ['message_attachment'],
          $relation: {
            entity_id: '9159b7b2-cfef-4d46-bbae-4829655100d2',
            _tags: []
          }
        },
        {
          _id: 'd67620f5-e3e0-484b-a115-52686ca4958b',
          filename: '2023-tesla-roadster-front-view-carbuzz-847781.jpeg',
          access_control: 'public-read',
          size_bytes: 86728,
          mime_type: 'image/jpeg',
          readable_size: '84.7 KB',
          type: 'image',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/4c46eb30-b735-4469-94c7-266fb6860bfa/2023-tesla-roadster-front-view-carbuzz-847781.jpeg',
          versions: [
            {
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/4c46eb30-b735-4469-94c7-266fb6860bfa/2023-tesla-roadster-front-view-carbuzz-847781.jpeg'
              },
              filename: '2023-tesla-roadster-front-view-carbuzz-847781.jpeg',
              access_control: 'private',
              size_bytes: 86728,
              mime_type: 'image/jpeg',
              readable_size: '84.7 KB',
              type: 'image',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/4c46eb30-b735-4469-94c7-266fb6860bfa/2023-tesla-roadster-front-view-carbuzz-847781.jpeg'
            }
          ],
          _schema: 'file',
          _org: '739224',
          _created_at: '2022-01-02T11:57:06.746Z',
          _updated_at: '2023-04-05T10:55:36.827Z',
          _title: '2023-tesla-roadster-front-view-carbuzz-847781.jpeg',
          _acl_sync: '2023-04-05T10:55:35.966Z',
          _owners: [
            {
              org_id: '739224'
            }
          ],
          _acl: {
            view: ['org_911215'],
            edit: ['org_911215'],
            delete: ['org_911215']
          },
          $relation: {
            entity_id: 'd67620f5-e3e0-484b-a115-52686ca4958b'
          }
        },
        {
          _id: 'e90e1b1c-63dc-4805-96d0-abae1144f8de',
          filename:
            'sadkoapskdoapsdkaopskdaopskdsaopkdaopskdsaopdkaopsdkasopdkaspodkasopdkasopdkasopdakspodkaspodkaspodkasopdkaspodkopaskdaopskdopakdopaskdoapskdapodskopaskdaskdpaskdpdoas.pdf',
          access_control: 'public-read',
          size_bytes: 1562703,
          mime_type: 'application/pdf',
          readable_size: '1.49 MB',
          type: 'document',
          public_url:
            'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/f37766f4-783c-4005-b3b2-48631fc4f57a/sadkoapskdoapsdkaopskdaopskdsaopkdaopskdsaopdkaopsdkasopdkaspodkasopdkasopdkasopdakspodkaspodkaspodkasopdkaspodkopaskdaopskdopakdopaskdoapskdapodskopaskdaskdpaskdpdoas.pdf',
          versions: [
            {
              s3ref: {
                bucket: 'epilot-dev-user-content',
                key: '739224/f37766f4-783c-4005-b3b2-48631fc4f57a/sadkoapskdoapsdkaopskdaopskdsaopkdaopskdsaopdkaopsdkasopdkaspodkasopdkasopdkasopdakspodkaspodkaspodkasopdkaspodkopaskdaopskdopakdopaskdoapskdapodskopaskdaskdpaskdpdoas.pdf'
              },
              filename:
                'sadkoapskdoapsdkaopskdaopskdsaopkdaopskdsaopdkaopsdkasopdkaspodkasopdkasopdkasopdakspodkaspodkaspodkasopdkaspodkopaskdaopskdopakdopaskdoapskdapodskopaskdaskdpaskdpdoas.pdf',
              access_control: 'public-read',
              size_bytes: 1562703,
              mime_type: 'application/pdf',
              readable_size: '1.49 MB',
              type: 'document',
              public_url:
                'https://epilot-dev-user-content.s3.eu-central-1.amazonaws.com/739224/f37766f4-783c-4005-b3b2-48631fc4f57a/sadkoapskdoapsdkaopskdaopskdsaopkdaopskdsaopdkaopsdkasopdkaspodkasopdkasopdkasopdakspodkaspodkaspodkasopdkaspodkopaskdaopskdopakdopaskdoapskdapodskopaskdaskdpaskdpdoas.pdf'
            }
          ],
          _schema: 'file',
          _org: '739224',
          _created_at: '2022-08-11T09:00:14.743Z',
          _updated_at: '2022-08-11T09:00:14.743Z',
          _title:
            'sadkoapskdoapsdkaopskdaopskdsaopkdaopskdsaopdkaopsdkasopdkaspodkasopdkasopdkasopdakspodkaspodkaspodkasopdkaspodkopaskdaopskdopakdopaskdoapskdapodskopaskdaskdpaskdpdoas.pdf',
          $relation: {
            entity_id: 'e90e1b1c-63dc-4805-96d0-abae1144f8de',
            _tags: []
          }
        }
      ],
      _acl_sync: '2023-03-31T10:38:40.880Z',
      _owners: [
        {
          org_id: '739224'
        }
      ],
      _acl: {
        view: [],
        edit: [],
        delete: []
      },
      _relations: [
        {
          entity_id: 'e9db1b28-9e3a-4470-b173-663832b5d3e3',
          _acl: '{"view":["org_906717"],"edit":["org_906717"],"delete":["org_906717"]}'
        },
        {
          entity_id: '25656c78-ea40-4048-97de-42fa6682380e'
        },
        {
          entity_id: 'cb4408f3-dfb2-41f4-ba6b-1798cc502e30'
        },
        {
          entity_id: 'b012fce1-99db-45f5-aa67-ccc8f6ecd946'
        },
        {
          entity_id: 'ae31116f-6e56-417d-a965-8a4e9be53a92'
        },
        {
          entity_id: '3b6713e3-413d-4c21-89c5-ecb2e7ad6a4c'
        },
        {
          entity_id: '04094ad3-d5ca-4741-b030-0ad8cc511251'
        },
        {
          entity_id: '5674a011-9427-4026-98d3-c3f3c4da6862'
        },
        {
          entity_id: 'c7594587-57fc-4550-9695-fcd0e372fb29'
        },
        {
          entity_id: '5a10a0eb-e885-47ec-9ff8-98c0b795b1c8'
        },
        {
          entity_id: 'd67620f5-e3e0-484b-a115-52686ca4958b',
          _acl: '{"view":["org_911215"],"edit":["org_911215"],"delete":["org_911215"]}'
        },
        {
          entity_id: '39d8b2e7-13af-44c7-948a-238baaa63d4a'
        },
        {
          entity_id: 'e90e1b1c-63dc-4805-96d0-abae1144f8de'
        },
        {
          entity_id: '411a2296-33e9-4543-a337-47712a343af3'
        },
        {
          entity_id: 'c175b21d-48f8-4dbc-9ee1-52cae209308b'
        },
        {
          entity_id: 'a9d8e8e9-78bf-4ae9-9cbc-5356ba868860'
        },
        {
          entity_id: 'c4c4deec-3cc4-49cb-a71d-92cb19c196b9'
        },
        {
          entity_id: '14aa1f0e-ca12-456c-966d-4ea802cf7fef',
          _acl: '{"view":["org_906717"],"edit":["org_906717"],"delete":["org_906717"]}'
        },
        {
          entity_id: '078aa2fb-08a7-497a-ae14-3f26e9f31488'
        }
      ]
    }
  ],
  crossSellableProducts: {
    products: [],
    prices: [],
    simpleProducts: []
  },
  workflow: []
}
