export const MOCK_ORGANIZATION_SETTINGS = {
  data: {
    '360_features': {
      enabled: true
    },
    '360_service_view': {
      enabled: true
    },
    auto_entity_linking: {
      enabled: true
    },
    automation_entity_mapping: {
      enabled: true
    },
    automation_preview: {
      enabled: true
    },
    bulk_email: {
      enabled: true
    },
    canary: {
      enabled: false
    },
    central_inbox_preview_setting: {
      enabled: true
    },
    commodity_billing: {
      enabled: true
    },
    contextual_entities: {
      enabled: true
    },
    contracts_preview_setting: {
      enabled: true
    },
    convert_api_enabled: {
      enabled: true
    },
    custom_entities: {
      enabled: true
    },
    dashboard_task_overview_actions: {
      enabled: false
    },
    dashboard_v2: {
      enabled: false
    },
    datalake_v2: {
      enabled: true
    },
    disable_ivy: {
      enabled: false
    },
    disable_wf_container: {
      enabled: false
    },
    document_generation_v2: {
      enabled: true
    },
    double_opt_in: {
      enabled: true,
      confirm_template_id: '975366e0-be45-4a03-8f0d-29ab78e427e8'
    },
    'dynamic-pricing': {
      enabled: true
    },
    dynamic_pricing: {
      enabled: true
    },
    ecommerce_catalog_preview: {
      enabled: true
    },
    ecommerce_opportunities_preview: {
      enabled: true
    },
    ecommerce_preview: {
      enabled: true
    },
    ecp_dashboard: {
      enabled: true
    },
    enable_wf_task_overview: {
      enabled: true
    },
    enable_wf_visualisations: {
      enabled: true
    },
    end_customer_portal: {
      enabled: true
    },
    entity_header_summary: {
      enabled: true
    },
    entity_manager: {
      enabled: true
    },
    entity_schema_builder: {
      enabled: true
    },
    export_import_blueprints: {
      enabled: true
    },
    export_unlimited: {
      enabled: true
    },
    export_v1: {
      enabled: false
    },
    export_v2: {
      enabled: true
    },
    ff_360_saved_views: {
      enabled: false
    },
    ff_metering: {
      enabled: false
    },
    ff_relations_from_dynamodb_enabled: {
      enabled: true
    },
    file_bulk_actions: {
      enabled: true
    },
    file_search_actions: {
      enabled: true
    },
    genai: {
      enabled: true
    },
    genai_message_reply: {
      enabled: true
    },
    genai_message_summaries: {
      enabled: true
    },
    get_ag_integration: {
      enabled: true
    },
    installer_portal: {
      enabled: true
    },
    ivy_mappings: {
      enabled: true,
      desiredMigrationDate: null,
      mappings: {
        Anrede: {
          schema: 'opportunity',
          attribute: {
            label: 'Source',
            name: 'source',
            type: 'link',
            readonly: true,
            group: 'Opportunity Details',
            protected: true,
            default_value: {
              title: 'manual',
              href: null,
              source_type: 'manual'
            },
            id: '3924b6fa-d6e2-4a64-9008-96bd23d3228c',
            order: 4,
            _purpose: []
          },
          groupSettings: {
            id: 'Opportunity Details',
            label: 'Opportunity Details',
            expanded: true,
            order: 3,
            _purpose: [],
            schema: 'opportunity'
          }
        },
        'AGB und Datenschutzerklärung gelesen und akzeptiert': {
          schema: 'do_not_map',
          attribute: {
            name: 'do_not_map',
            label: 'Do not map',
            group: 'general'
          },
          is_date: false
        },
        'Anlagenerrichter E-Mail Adresse': {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'datetime_attribute',
            id: '4436a3a0-1771-4260-aa01-99e46741b8b7',
            label: 'Datetime attribute',
            readonly: false,
            required: false,
            hidden: false,
            repeatable: false,
            has_primary: false,
            type: 'datetime',
            _purpose: [],
            order: 9
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Datetime attribute'
        },
        'Abweichender Rechnungsempfänger Nachname': {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'single_select_attribute',
            id: 'b7846849-6f13-4c9f-9455-c02de4b9e57f',
            label: 'Single select attribute',
            options: ['test', 'value', 'here'],
            readonly: false,
            required: false,
            hidden: false,
            repeatable: false,
            has_primary: false,
            type: 'select',
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 3
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Single select attribute'
        },
        'Anlagenbetreiber=Grundstückseigentümer': {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'tags_attribute',
            id: '7f189925-e056-40c6-91dd-75b34a5e0a33',
            label: 'Tags attribute',
            suggestions: ['tags', 'suggestions', 'here'],
            readonly: false,
            required: false,
            hidden: false,
            type: 'tags',
            _purpose: [],
            order: 8
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Tags attribute'
        },
        'Abweichender Rechnungsempfänger Vorname': {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'status_attribute',
            id: '1a5756d5-447e-4112-a16e-f50818f7179f',
            label: 'Status attribute',
            order: 10,
            options: [],
            readonly: true,
            required: false,
            hidden: false,
            repeatable: false,
            has_primary: false,
            type: 'status'
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Status attribute'
        },
        Anlagenart: {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'tags_attribute',
            id: '7f189925-e056-40c6-91dd-75b34a5e0a33',
            label: 'Tags attribute',
            suggestions: ['tags', 'suggestions', 'here'],
            readonly: false,
            required: false,
            hidden: false,
            type: 'tags',
            _purpose: [],
            order: 8
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Tags attribute'
        },
        'Antragsteller Anrede': {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'email_attribute',
            id: 'c899f13c-f87f-4f95-be5d-bcc0244249bf',
            label: 'Email attribute',
            readonly: false,
            required: false,
            hidden: false,
            repeatable: false,
            has_primary: false,
            type: 'email',
            _purpose: [],
            order: 6
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Email attribute'
        },
        'AGB gelesen und akzeptiert': {
          schema: 'do_not_map',
          attribute: {
            name: 'do_not_map',
            label: 'Do not map',
            group: 'general'
          },
          is_date: false
        },
        'Einzugdatum (Gas)': {
          schema: 'do_not_map',
          attribute: {
            name: 'do_not_map',
            label: 'Do not map',
            group: 'general'
          },
          is_date: true
        },
        'Gewünschter Lieferbeginn (Gas)': {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'datetime_attribute',
            id: '852a69d6-061c-4899-8c7a-44775d3d30e1',
            label: 'Datetime attribute',
            readonly: false,
            required: false,
            hidden: false,
            repeatable: false,
            has_primary: false,
            type: 'datetime',
            _purpose: [],
            order: 9
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Datetime attribute',
          is_date: true
        },
        'AGB und Datenschutzerklärung gelesen und akzeptiert.': {
          schema: 'opportunity',
          attribute: {
            label: 'Due Date',
            name: 'due_date',
            type: 'datetime',
            show_in_table: false,
            group: 'Opportunity Details',
            layout: 'half_width',
            protected: true,
            id: '8884f147-f2c5-4adb-8040-d9f8e11d40dd'
          },
          groupSettings: {
            id: 'Opportunity Details',
            label: 'Opportunity Details',
            expanded: true,
            order: 3,
            schema: 'opportunity',
            translatedName: 'Opportunity Details'
          },
          translatedName: 'Due Date'
        },
        'Einzugdatum (Strom)': {
          schema: 'opportunity',
          attribute: {
            label: 'Dates',
            name: 'dates',
            type: 'datetime',
            show_in_table: false,
            hide_label: true,
            group: 'Appointments & Dates',
            layout: 'full_width',
            repeatable: true,
            protected: true
          },
          groupSettings: {
            id: 'Appointments & Dates',
            label: 'Appointments & Dates',
            expanded: true,
            order: 50,
            schema: 'opportunity',
            translatedName: 'Termine & Daten'
          },
          translatedName: 'Termindaten',
          is_date: true
        }
      },
      purposesMapping: {
        Broadband: {
          id: null,
          name: 'do_not_map'
        },
        'Commissioning gas': {
          name: 'Water sales',
          id: '4633a1a5-e47e-480b-98da-f8259fb7d22c',
          created_at: '2022-12-01T09:00:27.606Z',
          updated_at: '2022-12-01T09:00:27.606Z'
        },
        'Commissioning power': {
          name: 'Water sales',
          id: '4633a1a5-e47e-480b-98da-f8259fb7d22c',
          created_at: '2022-12-01T09:00:27.606Z',
          updated_at: '2022-12-01T09:00:27.606Z'
        },
        Gas: {
          name: 'test_purpose_alex',
          id: '2b5c5eb3-1abc-4580-856d-f849c53fa334',
          created_at: '2023-04-20T09:41:14.792Z',
          updated_at: '2023-04-20T09:41:14.792Z'
        },
        Generic: null
      }
    },
    ivy_mappings_2: {
      enabled: true,
      mappings: {
        'AGB und Datenschutzerklärung gelesen und akzeptiert': {
          schema: 'opportunity',
          attribute: {
            label: 'Due Date',
            name: 'due_date',
            type: 'datetime',
            show_in_table: false,
            group: 'Opportunity Details',
            layout: 'half_width',
            protected: true,
            id: 'cb77a5b4-4e0c-414e-b192-f7f51c292a92',
            order: 3,
            _purpose: []
          },
          groupSettings: {
            id: 'Opportunity Details',
            label: 'Opportunity Details',
            expanded: true,
            order: 3,
            _purpose: [],
            schema: 'opportunity'
          }
        },
        'Abweichender Rechnungsempfänger Nachname': {
          schema: 'opportunity',
          attribute: {
            label: 'Status',
            name: 'status',
            type: 'status',
            options: [
              {
                value: 'open',
                title: 'Open'
              },
              {
                value: 'in_progress',
                title: 'In Progress'
              },
              {
                value: 'closed',
                title: 'Closed'
              },
              {
                value: 'cancelled',
                title: 'Cancelled'
              }
            ],
            show_in_table: true,
            group: 'Opportunity Details',
            layout: 'half_width',
            protected: true,
            id: '53372ea3-7fcf-45df-8509-2a8267c8e345',
            order: 2,
            _purpose: []
          },
          groupSettings: {
            id: 'Opportunity Details',
            label: 'Opportunity Details',
            expanded: true,
            order: 3,
            _purpose: [],
            schema: 'opportunity'
          }
        },
        'Jährlicher Verbrauch (Gas)': {
          schema: 'opportunity',
          attribute: {
            label: 'Due Date',
            name: 'due_date',
            type: 'datetime',
            show_in_table: false,
            group: 'Opportunity Details',
            layout: 'half_width',
            protected: true,
            id: 'cb77a5b4-4e0c-414e-b192-f7f51c292a92',
            order: 3,
            _purpose: []
          },
          groupSettings: {
            id: 'Opportunity Details',
            label: 'Opportunity Details',
            expanded: true,
            order: 3,
            _purpose: [],
            schema: 'opportunity'
          }
        },
        'Jährlicher Verbrauch (KWh)': {
          schema: 'opportunity',
          attribute: {
            label: 'Status',
            name: 'status',
            type: 'status',
            options: [
              {
                value: 'open',
                title: 'Open'
              },
              {
                value: 'in_progress',
                title: 'In Progress'
              },
              {
                value: 'closed',
                title: 'Closed'
              },
              {
                value: 'cancelled',
                title: 'Cancelled'
              }
            ],
            show_in_table: true,
            group: 'Opportunity Details',
            layout: 'half_width',
            protected: true,
            id: '53372ea3-7fcf-45df-8509-2a8267c8e345',
            order: 2,
            _purpose: []
          },
          groupSettings: {
            id: 'Opportunity Details',
            label: 'Opportunity Details',
            expanded: true,
            order: 3,
            _purpose: [],
            schema: 'opportunity'
          }
        },
        IBAN: {
          schema: 'opportunity',
          attribute: {
            label: 'Opportunity Number',
            name: 'opportunity_number',
            type: 'sequence',
            show_in_table: true,
            group: 'Opportunity Details',
            layout: 'half_width',
            readonly: true,
            protected: true,
            prefix: 'OP-',
            start_number: 100,
            id: 'b3364b7b-653a-4e7b-b514-04bbd5089143',
            order: 1,
            _purpose: []
          },
          groupSettings: {
            id: 'Opportunity Details',
            label: 'Opportunity Details',
            expanded: true,
            order: 3,
            _purpose: [],
            schema: 'opportunity'
          }
        },
        'Name/n oder Firmenname 3': {
          schema: 'opportunity',
          attribute: {
            label: 'Due Date',
            name: 'due_date',
            type: 'datetime',
            show_in_table: false,
            group: 'Opportunity Details',
            layout: 'half_width',
            protected: true,
            id: 'cb77a5b4-4e0c-414e-b192-f7f51c292a92',
            order: 3,
            _purpose: []
          },
          groupSettings: {
            id: 'Opportunity Details',
            label: 'Opportunity Details',
            expanded: true,
            order: 3,
            _purpose: [],
            schema: 'opportunity'
          }
        },
        'Sollen wir Ihren bestehenden Vertrag für Sie kündigen? (Strom)': {
          schema: 'opportunity',
          attribute: {
            label: 'Source',
            name: 'source',
            type: 'link',
            readonly: true,
            group: 'Opportunity Details',
            protected: true,
            default_value: {
              title: 'manual',
              href: null,
              source_type: 'manual'
            },
            id: '3924b6fa-d6e2-4a64-9008-96bd23d3228c',
            order: 4,
            _purpose: []
          },
          groupSettings: {
            id: 'Opportunity Details',
            label: 'Opportunity Details',
            expanded: true,
            order: 3,
            _purpose: [],
            schema: 'opportunity'
          }
        },
        'AGB gelesen und akzeptiert': {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'radio_attribute',
            id: 'fb9a7ef7-23dc-437e-9ad0-5704124ef2e2',
            label: 'Radio attribute',
            options: ['radio', 'options'],
            readonly: false,
            required: false,
            hidden: false,
            repeatable: false,
            has_primary: false,
            type: 'radio',
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 1,
            layout: 'half_width'
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Radio attribute'
        },
        'Anlagenbetreiber=Grundstückseigentümer': {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'status_attribute',
            id: '1a5756d5-447e-4112-a16e-f50818f7179f',
            label: 'Status attribute',
            order: 10,
            options: [],
            readonly: true,
            required: false,
            hidden: false,
            repeatable: false,
            has_primary: false,
            type: 'status'
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Status attribute'
        },
        'AGB und Datenschutzerklärung gelesen und akzeptiert.': {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'text_attribute',
            type: 'string',
            id: '3327c169-47a2-4850-9847-d86d8fa4757f',
            label: 'Text attribute',
            _purpose: [],
            order: 5
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Text attribute'
        },
        'Abweichender Rechnungsempfänger Vorname': {
          schema: 'opportunity',
          attribute: {
            group: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            name: 'phone_attribute',
            id: '1d3273a3-2d64-42bd-9e0f-3f3a254ab6fc',
            label: 'Phone attribute',
            readonly: false,
            required: false,
            hidden: false,
            repeatable: false,
            has_primary: false,
            type: 'phone',
            _purpose: [],
            order: 7
          },
          groupSettings: {
            id: 'a7d22495-ba23-41b5-b6b9-81c26c4b4816',
            label: 'New Sigma group',
            expanded: true,
            _purpose: ['200b9566-14ee-4f1c-a4e4-e45e51d53767'],
            order: 0,
            schema: 'opportunity',
            translatedName: 'New Sigma group'
          },
          translatedName: 'Phone attribute'
        }
      }
    },
    journeyLauncher: {
      enabled: true
    },
    journey_analytics: {
      enabled: false
    },
    journey_builder_advanced_settings: {
      enabled: true
    },
    journey_builder_custom_block_enabled: {
      enabled: true
    },
    logic_editor_preview: {
      enabled: true
    },
    metering: {
      enabled: true
    },
    metering_settings: {
      enabled: true
    },
    mfa_enabled: {
      enabled: true
    },
    navigation_2024: {
      enabled: true
    },
    new_navigation: {
      enabled: true
    },
    new_searchbar: {
      enabled: true
    },
    new_workflow_ui: {
      enabled: true
    },
    notracking: {
      enabled: false
    },
    partnering: {
      enabled: true
    },
    partnering_geolocation: {
      enabled: true
    },
    partnering_offering: {
      enabled: true
    },
    phases_count_enabled: {
      enabled: true
    },
    phases_duration_enabled: {
      enabled: true
    },
    platform_alerts: {
      enabled: true
    },
    'product-availability': {
      enabled: true
    },
    purposes_on_top: {
      enabled: true
    },
    related_entities_deletion: {
      enabled: true
    },
    release_candidate: {
      enabled: false
    },
    saved_views: {
      enabled: false
    },
    setup_entity_operations_pipeline: {
      enabled: true
    },
    setup_entity_operations_pipeline_enterprise: {
      enabled: true
    },
    setup_workflow_pipeline: {
      enabled: true
    },
    setup_workflow_pipeline_enterprise: {
      enabled: true
    },
    sfx: {
      enabled: true
    },
    sso: {
      enabled: false
    },
    submission_preview: {
      enabled: true
    },
    summary_apply_purposes: {
      enabled: true
    },
    user_roles_preview: {
      enabled: true
    }
  }
}
